<template>
  <v-select :value="value" @input="$emit('input', $event)" v-bind="selectProps" :items="roundingOptions"></v-select>
</template>

<script>
  import { TaxesAPIService } from "@/servicehandlers/TaxesAPIService";

  const taxesAPIService = new TaxesAPIService();

  export default {
    name: "RoundingSelect",
    props: {
      selectProps: {
        type: Object,
        default: () => ({label: 'Round'})
      },
      value: {
        type: String,
      }
    },
    data: () => ({
      roundingOptions: []
    }),
    created() {
      taxesAPIService.getRoundingOptions(this.$router)
        .then(roundings => {
          this.roundingOptions = roundings;
        })
    }
  }
</script>

<style scoped>

</style>