function intervalToMilliseconds(interval) {
  const conversionFactors = {
    milliseconds: 1,
    seconds: 1000
  };
  conversionFactors.minutes = conversionFactors.seconds * 60;
  conversionFactors.hours = conversionFactors.minutes * 60;
  conversionFactors.days = conversionFactors.hours * 24;
  conversionFactors.months = conversionFactors.days * 30.5;
  conversionFactors.years = conversionFactors.months * 24;

  return Object.keys(interval).reduce((milli, unit) => milli + conversionFactors[unit] * interval[unit], 0);
}

export {intervalToMilliseconds};