<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @confirm="deleteTax" @close="$emit('input', !value)"
                 title="Are you sure?" max-width="500" confirm-class="white--text" confirm-color="red darken-2"
                 confirm-text="Delete">
    <warning-alert class="mt-4">
      Once a tax rate is deleted, <strong>it cannot be recovered.</strong> The previous tax rate will become the current rate.
    </warning-alert>
  </action-dialog>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import WarningAlert from "../../WarningAlert";
  import { TaxesAPIService } from "@/servicehandlers/TaxesAPIService";

  const taxesAPIService = new TaxesAPIService();

  export default {
    name: "DeleteTaxDialog",
    components: {WarningAlert, ActionDialog},
    props: {
      deleteId: {
        type: [String, Number],
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      deleteTax() {
        taxesAPIService.deleteTax(this.deleteId, this.$router)
          .then(() => {
            this.$emit('input', !this.value);
            this.$emit('deleted');
          })
      }
    }
  }
</script>

<style scoped>

</style>