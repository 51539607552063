<template>
  <v-row class="fill-height">
    <v-col>
      <div @mouseenter="expandDisplay = true" @mouseleave="expandDisplay = false">
        <v-navigation-drawer v-model="drawer" style="overflow: visible" :mini-variant="mini" class="mt-12" fixed>
          <v-row justify="end">
            <v-fade-transition>
              <v-avatar v-if="expandDisplay" size="25" style="position: absolute; z-index: 1" class="mt-4 ml-n1 elevation-1"
                        color="secondary" >
                <v-icon @click="toggleMini" color="white" small>{{ this.expandIcon }}</v-icon>
              </v-avatar>
            </v-fade-transition>
          </v-row>

          <v-list>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium" v-if="!mini">
                  System Management
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mb-n4" @click="returnToWork" link>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2 font-weight-regular">
                  <v-icon class="mr-4 mb-1">mdi-arrow-left-circle</v-icon>Return to work
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="mb-0"></v-divider>

          <div v-for="(item, index) in items" :key="item.title" v-if="!mini">
            <v-list-group v-model="item.open" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="(child, i) in item.children" v-can="child.scope" :key="i" link
                           @click="$router.push(child.path)">
                <v-list-item-title class="text-subtitle-2 font-weight-regular" v-text="child.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-divider class="mb-0" v-if="index !== items.length-1"></v-divider>
          </div>
        </v-navigation-drawer>
      </div>

    </v-col>
    <v-col class="pl-8 pt-8" :cols="contentCols">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "Manager",
    computed: {
      contentCols() {
        if (!this.mini && this.drawer) return 10;
        else if (this.mini && this.drawer) return 11;
        else return 12;
      },
      expandIcon() {
        return !this.mini ? 'mdi-chevron-left' : 'mdi-chevron-right';
      }
    },
    data () {
      return {
        drawer: true,
        expandDisplay: false,
        items: [
          {
            title: 'Billing',
            children: [
              {title: 'MGA Billing Entities', path: '/manage/mgaBilling', scope: 'mga-billing-management'}
            ],
            open: true
          },
          {
            title: 'Commissions',
            children: [
              {title: 'MGA Rates', path: '/manage/commissions', scope: 'mga-commissions-management'},
              {title: 'Producer Rates', path: '/manage/producerCommissions', scope: 'producer-commissions-management'}
            ],
            open: true
          },
          {
            title: 'Permissions',
            children: [
              {title: 'Roles', path: '/manage/roles', scope: 'page-rights'}
            ],
            open: true
          },
          {
            title: 'Taxes',
            children: [
              {title: 'State Rates', path: '/manage/taxes', scope: 'tax-management'}
            ],
            open: true
          },
        ],
        mini: false,
        previousRoute: ''
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => { vm.previousRoute = from.path });
    },
    methods: {
      toggleMini() {
        this.mini = !this.mini;
      },
      returnToWork() {
        const path = (this.previousRoute === '/' || !this.previousRoute) ? '/search/firms/firmsSearch/' : this.previousRoute;
        this.$router.push(path);
      }
    }
  }
</script>

<style scoped>

</style>