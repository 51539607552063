<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="createMga"
                 title="Create MGA" max-width="400" bottom-divider :disabled="!valid">
    <v-form v-model="valid">
      <v-row>
        <v-col class="dense">
          <contacts-select v-model="mga" :options="{label: 'MGA', rules: [rules.required]}"></contacts-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dense">
          <contacts-select v-model="billingMga" :options="{label: 'Billing MGA', rules: [rules.required]}"></contacts-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dense">
          <span class="text-caption grey--text text--darken-1">When the selected MGA is listed on a policy, the
            "Billing MGA" will be the entity that receives commission.</span>
        </v-col>
      </v-row>
    </v-form>
    <loading-overlay v-model="loading" absolute></loading-overlay>
  </action-dialog>
</template>

<script>
  import { MgasAPIService } from "@/servicehandlers/MgasAPIService";
  import ActionDialog from "../../ActionDialog";
  import LoadingOverlay from "../../LoadingOverlay";
  import ContactsSelect from "../../ContactsSelect";

  const mgasAPIService = new MgasAPIService();
  export default {
    name: "CreateMgaBillingDialog",
    components: {ContactsSelect, LoadingOverlay, ActionDialog},
    props: {
      value: {
        required: true,
        type: Boolean
      }
    },
    data: () => ({
      billingMga: '',
      loading: false,
      mga: '',
      rules: {
        required: (x) => !!x || 'This field is required.'
      },
      valid: false
    }),
    methods: {
      createMga() {
        this.loading = true;
        mgasAPIService.createMGA({contact_id: this.mga, billing_mga: this.billingMga}, this.$router)
          .then(() => {
            this.loading = false;
            this.$emit('input', !this.value);
            this.$emit('created');
          });
      }
    }
  }
</script>

<style scoped>

</style>