<template>
  <v-row justify="center">
    <v-col lg="4" md="7" sm="11">
      <v-card>
        <v-card-title>
          Your session has timed out.
        </v-card-title>
        <v-card-text>
          <idle-form ref="idleForm" @valid="(newValue) => { valid = newValue }" @relogin="$router.go(-1); loading = false"></idle-form>
          <v-row justify="end">
            <v-btn class="mr-4" color="secondary" :disabled="!valid" @click="relogin" :loading="loading">Confirm</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import IdleForm from "./IdleForm";
  export default {
    name: "IdlePage",
    components: {IdleForm},
    data: () => ({
      loading: false,
      valid: false
    }),
    methods: {
      relogin() {
        this.loading = true;
        this.$refs.idleForm.relogin();
      }
    }
  }
</script>

<style scoped>

</style>