<template>
  <div>
    <v-row justify="center">
      <v-col lg="6" md="9" sm="11">
        <h4 class="font-weight-regular mb-5">
          Producer Commissions
        </h4>
        <v-card>
          <v-card-title class="font-weight-regular">
            Default Rate
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="dense" cols="4">
                <v-text-field @keyup.enter="updateDefault" label="Rate" v-model="defaultCommission.commission">
                  <template v-slot:prepend>
                    <h6 class="mt-1 mx-1">%</h6>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="dense" cols="4">
                <v-text-field label="Placement" value="All" disabled></v-text-field>
              </v-col>
              <v-col class="dense" cols="3">
                <v-btn class="mt-4" @click="updateDefault" small color="secondary">Update</v-btn>
              </v-col>
            </v-row>
            <span class="text-caption grey--text text--darken-1">This is the rate will apply to all producers (except
              those listed below).</span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-title class="font-weight-regular">

            <v-row>
              <v-col class="dense">
                Exceptions
              </v-col>
              <v-col class="dense">
                <v-row justify="end">
                  <v-btn class="elevation-2 mr-5 mb-1" fab x-small color="secondary" @click="createDialog = true">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table :items="producerCommissions" :headers="headers" hide-default-footer :mobile-breakpoint="0"
                          :sort-by="['short']">
              <template v-slot:item.commission="{ item }">
                {{ item.commission }}%
              </template>
              <template v-slot:item.renewal="{ item }">
                {{ formatRenewal(item.renewal) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="openUpdateDialog(item)" small>mdi-pencil</v-icon>
                  </template>
                  <span>Change this commission</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-2" v-on="on" @click="openDeleteDialog(item.id)" small>mdi-trash-can-outline</v-icon>
                  </template>
                  <span>Delete commission</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading-overlay v-model="loading"></loading-overlay>
    <success-snackbar v-model="success" :message="successMessage"></success-snackbar>
    <create-producer-commission-dialog v-model="createDialog" v-if="createDialog"
                                       @created="getProducerCommissions"></create-producer-commission-dialog>
    <delete-producer-commission-dialog v-model="deleteDialog" v-if="deleteDialog" :id="deleteId"
                                       @deleted="getProducerCommissions"></delete-producer-commission-dialog>
    <update-producer-commission-dialog v-model="updateDialog" v-if="updateDialog" :commission="updateCommission"
                                       @updated="getProducerCommissions"></update-producer-commission-dialog>
  </div>
  
</template>

<script>
  import { ProducerCommissionsAPIService } from "../../servicehandlers/ProducerCommissionsAPIService";
  import LoadingOverlay from "../LoadingOverlay";
  import SuccessSnackbar from "../SuccessSnackbar";
  import UpdateProducerCommissionDialog from "./producerCommissions/UpdateProducerCommissionDialog";
  import CreateProducerCommissionDialog from "./producerCommissions/CreateProducerCommissionDialog";
  import DeleteProducerCommissionDialog from "./producerCommissions/DeleteProducerCommissionDialog";

  const producerCommissionsAPIService = new ProducerCommissionsAPIService();
  export default {
    name: "ProducerCommissionsManager",
    components: {
      DeleteProducerCommissionDialog,
      CreateProducerCommissionDialog, UpdateProducerCommissionDialog, SuccessSnackbar, LoadingOverlay},
    data: () => ({
      createDialog: false,
      defaultCommission: {},
      deleteDialog: false,
      deleteId: '',
      headers: [
        {text: 'Producer', value: 'short'},
        {text: 'Commission', value: 'commission'},
        {text: 'Placement', value: 'renewal'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      loading: false,
      producerCommissions: [],
      success: false,
      successMessage: '',
      updateCommission: {},
      updateDialog: false
    }),
    created() {
      this.getProducerCommissions();
    },
    methods: {
      formatRenewal(renewal) {
        switch(renewal) {
          case true: return 'Renewal';
          case false: return 'New Business';
          default: return 'All';
        }
      },
      getProducerCommissions() {
        this.loading = true;
        producerCommissionsAPIService.list(this.$router)
          .then(prodComs => {
            prodComs = prodComs.map(com => {
              com.commission = Math.round(com.commission * 100000) / 1000; //Avoid parse float errors
              return com;
            });
            this.defaultCommission = prodComs.find(com => com.list_id === null);
            this.producerCommissions = prodComs.filter(com => com.list_id !== null);
            this.loading = false;
          })
      },
      openUpdateDialog(com) {
        this.updateCommission = com;
        this.updateDialog = true;
      },
      openDeleteDialog(id) {
        this.deleteId = id;
        this.deleteDialog = true;
      },
      updateDefault() {
        const com = this.defaultCommission;
        producerCommissionsAPIService.update(com.id, {commission: com.commission/100}, this.$router)
          .then(() => {
            this.getProducerCommissions();
            this.success = true;
            this.successMessage = 'Default commission successfully updated.'
          })
      }
    }
  }
</script>

<style scoped>

</style>