import CommonAPIService from './CommonHandler';
import { store } from '../store';
import { intervalToMilliseconds } from '../utils';

export class MeAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getUserData(router) {
    const url = `/api/me`;
    return this.CommonAPIService.getCall(url, router)
      .then(data => {
        store.dispatch('ADD_USERID', data.id);
        store.dispatch('ADD_USERNAME', data.username);
        store.dispatch('ADD_USER_GROUPS', data.auth_groups);
        store.dispatch('SET_LOGGED_IN', true);
        store.commit('SET_TIMEOUT', intervalToMilliseconds(data.timeout));
        store.commit('SET_ACTIVE_TIMER');
        return data;
      });
  }
}
export default MeAPIService;