import Vue from 'vue';
import { router } from './router';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { AuthAPIService} from "./servicehandlers/AuthAPIService";
import {ListsAPIService} from "./servicehandlers/ListsAPIService";

const listsAPIService = new ListsAPIService();
const authAPIService = new AuthAPIService();

Vue.use(Vuex);
export const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    activeTimer: null,
    timeout: null,
    username: "",
    userId: 0,
    lists: [],
    userScopes: [],
    userGroups: [],
    loggedIn: false,
    timedOut: false,
    sideMenu: false,
    snack: { open: false },
    userPreferences: {},
    currentRightsArray: [],
    noteData: {},
    seeMoreFirmsOptions: false,
  },
  actions: {
    ADD_USERNAME({ commit }, username) {
      commit('SET_USERNAME', username);
    },

    ADD_USERID({ commit }, userId) {
      commit('SET_USERID', userId);
    },

    ADD_USER_SCOPES({ commit }, userScopes) {
      commit('SET_USER_SCOPES', userScopes);
    },

    ADD_LISTS({ commit }, lists) {
      commit('SET_LISTS', lists);
    },

    ADD_USER_GROUPS({ commit }, userGroups) {
      commit('SET_USER_GROUPS', userGroups);
    },

    RESET_ACTIVE_TIMER({ dispatch, commit }) {
      commit('CLEAR_ACTIVE_TIMER');
      dispatch('INITIALIZE_ACTIVE_TIMER');
    },

    SET_LOGGED_IN({ commit }, loggedIn) {
      commit('SET_LOGIN', loggedIn);
    },

    LOGOUT({ commit }) {
      commit('SET_LOGOUT');
      authAPIService.logout(router);
    },

    async TIMED_OUT({ dispatch, commit }) {
      const session = await authAPIService.getSession(router);
      if (session.time_remaining <= 0) { // Session is truly timed out
        commit('SET_TIMED_OUT', true);
        return;
      }
      dispatch('INITIALIZE_ACTIVE_TIMER', session.time_remaining);
    },

    INITIALIZE_ACTIVE_TIMER({ dispatch, commit, state }, time=null) {
      if (!state.timeout || !state.loggedIn) return;
      time = time || state.timeout;
      const timerId = setTimeout(() => dispatch('TIMED_OUT') , time);
      commit('SET_ACTIVE_TIMER', timerId);
    },

    SET_SIDE_MENU({ commit }, sideMenu) {
      commit('SET_SIDE_MENU', sideMenu);
    },

    SET_SNACK({ commit }, snack) {
      commit('SET_SNACK', snack);
    },

    SET_USER_PREFERENCES({ commit }, userPreferences) {
      commit('SET_USER_PREFERENCES', userPreferences);
    },

    //---------  shared state for firms, notes ---------//

    SET_CURRENT_RIGHTS_ARRAY({ commit }, currentRightsArray) {
      commit('SET_CURRENT_RIGHTS_ARRAY', currentRightsArray);
    },

    SET_NOTES_DATA({ commit }, noteData) {
      commit('SET_NOTES_DATA', noteData);
    },

    SET_FIRMS_SEARCH_OPEN({ commit }, seeMoreFirmsOptions) {
      commit('SET_FIRMS_SEARCH_OPEN', seeMoreFirmsOptions);
    },
  },

  mutations: {

    SET_ACTIVE_TIMER(state, timerId) {
      state.activeTimer = timerId;
    },

    CLEAR_ACTIVE_TIMER(state) {
      if (!state.activeTimer) return;
      clearTimeout(state.activeTimer);
    },

    SET_LOGIN(state, loggedIn) {
      state.loggedIn = loggedIn;
    },

    SET_TIMED_OUT(state, timedOut) {
      state.timedOut = timedOut;
    },

    SET_TIMEOUT(state, timeout) {
      const maxTimeout = 2147483647; // Largest time interval that setTimeout will take
      state.timeout = timeout > maxTimeout ? maxTimeout : timeout;
    },

    SET_USERNAME(state, username) {
      state.username = username;
    },

    SET_USERID(state, userId) {
      state.userId = userId;
    },

    SET_LISTS(state, lists) {
      state.lists = lists;
    },

    SET_USER_GROUPS(state, userGroups) {
      state.userGroups = [];
      state.userScopes = [];
      for (let i = 0; i < userGroups.length; i += 1) {
        state.userGroups.push(userGroups[i].name);
        const scopes = userGroups[i].scopes;

        // now get the scopes for each item
        for (let k = 0; k < scopes.length; k += 1) {
          const scope = {
            name: scopes[k].name,
            type: scopes[k].description,
            id: scopes[k].id,
          };
          state.userScopes.push(scope);
        }
      }
    },

    SET_SIDE_MENU(state, sideMenu) {
      state.sideMenu = sideMenu;
    },

    SET_SNACK(state, snack) {
      state.snack = snack;
    },

    SET_USER_PREFERENCES(state, userPreferences) {
      state.userPreferences = userPreferences;
    },

    SET_CURRENT_RIGHTS_ARRAY(state, currentRightsArray) {
      state.currentRightsArray = currentRightsArray;
    },

    SET_NOTES_DATA(state, noteData) {
      state.noteData = noteData;
    },

    SET_FIRMS_SEARCH_OPEN(state, seeMoreFirmsOptions) {
      state.seeMoreFirmsOptions = seeMoreFirmsOptions;
    },

    SET_LOGOUT(state) {
      if (state.activeTimer) clearTimeout(state.activeTimer);
      state.activeTimer = null;
      state.lists = [];
      state.timeout = null;
      state.username = '';
      state.userId = '';
      state.userScopes = [];
      state.userGroups = [];
      state.loggedIn = false;
      state.timedOut = false;
      state.sideMenu = false;
      state.snack = {};
      state.userPreferences = {};
      state.seeMoreFirmsOptions = false;
      state.noteData = {};
      state.currentRightsArray = [];
      sessionStorage.clear();
    },
  },

  getters: {
    username: state => state.username,
    userId: state => state.userId,
    lists: state => state.lists,
    groups: state => state.userGroups,
    scopes: state => state.userScopes,
    loggedIn: state => state.loggedIn,
    sideMenu: state => state.sideMenu,
    snack: state => state.snack,
    userPreferences: state => state.userPreferences,
    seeMoreFirmsOptions: state => state.seeMoreFirmsOptions,
    noteData: state => state.noteData,
    currentRightsArray: state => state.currentRightsArray,
  },
});
