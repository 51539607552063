<template>
  <v-row justify="center">
    <v-col lg="5" md="7" sm="10">
      <v-row class="mb-2">
        <v-col class="dense">
          <h4 class="font-weight-regular mt-6">
            MGA Billing Entites
          </h4>
        </v-col>
        <v-col class="dense" cols="5">
          <v-text-field class="mr-4" v-model="search" prepend-icon="mdi-magnify" label="search"></v-text-field>
        </v-col>
        <v-col class="dense" cols="1">
          <v-row justify="end">
            <v-btn class="elevation-2 mt-4 mr-5" fab x-small color="secondary" @click="createDialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-card>
        <v-card-text>
          <v-data-table :items="mgas" :headers="headers" :sort-by="['mga_contact.sort']" :search="search"
                        :mobile-breakpoint="0" :footer-props="{itemsPerPageOptions: [50, 100, 150, -1]}">
            <template v-slot:item.billing_contact.sort="{ item }">
              <span v-if="!item.edit">{{ item.billing_contact.sort }}</span>
              <div v-else>
                <v-progress-circular v-if="item.selectLoading" indeterminate size="25" color="secondary"></v-progress-circular>
                <contacts-select v-show="!item.selectLoading" @loaded="item.selectLoading = false" v-model="item.billing_mga"
                                 :options="{label: '', dense: true}"></contacts-select>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="!item.edit">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="item.edit = true; item.selectLoading = true" small>mdi-pencil</v-icon>
                  </template>
                  <span>Change the billing MGA</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mr-2" v-on="on" @click="updateMga(item)" small>mdi-upload</v-icon>
                  </template>
                  <span>Save changes</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="cancelEdit()" small>mdi-close</v-icon>
                  </template>
                  <span>Discard changes</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <loading-overlay v-model="loading"></loading-overlay>
    <create-mga-billing-dialog v-model="createDialog" v-if="createDialog" @created="getMgas"></create-mga-billing-dialog>
    <success-snackbar v-model="success" :message="successMessage"></success-snackbar>
  </v-row>
</template>

<script>
  import { MgasAPIService } from "../../servicehandlers/MgasAPIService";
  import LoadingOverlay from "../LoadingOverlay";
  import ContactsSelect from "../ContactsSelect";
  import SuccessSnackbar from "../SuccessSnackbar";
  import CreateMgaBillingDialog from "./mgaBilling/CreateMgaBillingDialog";

  const mgasAPIService = new MgasAPIService();

  export default {
    name: "MgaBillingManager",
    components: {CreateMgaBillingDialog, SuccessSnackbar, ContactsSelect, LoadingOverlay},
    data: () => ({
      createDialog: false,
      headers: [
        {text: 'MGA', value: 'mga_contact.sort'},
        {text: 'Billing MGA', value: 'billing_contact.sort', width: 200},
        {text: 'Actions', value: 'actions', sortable: false, width: 50}
      ],
      loading: false,
      mgas: [],
      mgaJSON: '',
      search: '',
      success: false,
      successMessage: ''
    }),
    created() {
      this.getMgas();
    },
    methods: {
      cancelEdit() {
        this.mgas = JSON.parse(this.mgaJSON);
      },
      getMgas() {
        this.loading = true;
        mgasAPIService.list(this.$router)
          .then(mgas => {
            this.loading = false;
            this.mgas = mgas.map(mga => {
              mga.selectLoading = false;
              mga.edit = false;
              return mga;
            });
            this.mgaJSON = JSON.stringify(this.mgas);
          })
      },
      updateMga(mga) {
        this.loading = true;
        mgasAPIService.updateMGA(mga.id, {billing_mga: mga.billing_mga}, this.$router)
          .then(() => {
            this.getMgas();
            this.success = true;
            this.successMessage = 'MGA successfully updated.'
          })
      }
    }
  }
</script>

<style scoped>

</style>