<template>
  <div>
    <v-alert outlined prominent border="left" type="warning"
             v-if="showAlertToGenerateReportFirst === true">
             First generate a report so there will be data for the download.</v-alert>
    <v-card style="border-top: 1px solid white !important;" class="px-1 d-flex flex-row justify-space-around" tile dark block
            v-sticky sticky-z-index="99" :key="$_sideMenu" sticky-offset="$_offset" color='primary'>
      <div>
        <v-btn class="px-1" x-large tile text v-on:click="generateReport()">Generate Report</v-btn>
      </div>
      <div>
        <download-excel
          :fetch   = "fetchData"
          :before-generate = "startDownload"
          :before-finish = "finishDownload"
          worksheet = "Reports"
          name = "reports.xls">
          <v-btn class="px-1" x-large tile text>Download</v-btn>
        </download-excel>
      </div>
    </v-card>
    <br>

    <v-card tile class="px-6 my-n3">
      <v-row class="d-flex py-3 mb-2">
        <v-col class="py-0 px-6" cols="12" md="8" style="z-index: 89">
          <v-autocomplete
            clearable
            name="claimpol"
            v-model="queryType"
            :items="claimsOrPolicies"
            label="Report">
          </v-autocomplete>
        </v-col>
        <v-col class="py-0 px-6" cols="12" md="4" style="z-index: 89">
          <ListViewer v-model="whereColumns.state" :nameonly="true" sorttable="short" clearable
                      listname="states" :titles="true" listtitle="All"/>
        </v-col>
        <v-col class="px-6 pt-1 pb-0 onLine" cols="12" md="8" style="z-index: 89">
          <v-autocomplete
            spellcheck="false"
            v-model="whereColumns.type"
            :items="types"
            item-text='name'
            item-value='value'
            return-object
            multiple
            label="Type"
            deletable-chips
            small-chips
            clearable>
          </v-autocomplete>
        </v-col>
        <v-col class="py-0 px-6" cols="12" md="4" style="z-index: 89">
          <v-autocomplete
            clearable
            name="aggtype"
            v-model="aggType"
            :items="aggtypes"
            hint="Aggregate Type"
            label="Agg">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-card>
    <br>

      <v-expansion-panels class="my-n4" tile accordion focusable>
        <v-expansion-panel class="elevation-4">
          <v-expansion-panel-header tile class="py-5 subtitle-1">More Search Options</v-expansion-panel-header>
          <v-expansion-panel-content class="py-3 px-0">
              <v-row>
                <v-col cols="12" md="4" style="z-index: 89">
                  <ListViewer v-model="whereColumns.status"
                              clearable
                              label="Status"
                              listname="clmstatus"
                              listtitle="Status"
                              :nameonly="true"
                              sort="0,1,2,3,4"/>
                </v-col>
                <v-col class="mt-1 onLine" cols="12" md="4" style="z-index: 89">
                  <v-autocomplete v-model="whereColumns.carrier"
                                  label="Carrier"
                                  :items="carrierOptions"
                                  item-text='name'
                                  item-value='value'
                                  multiple
                                  deletable-chips
                                  small-chips
                                  clearable>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" style="z-index: 89">
                  <v-autocomplete v-model="whereColumns.contract"
                                  clearable
                                  label="Contract"
                                  :items="contractOptions"
                                  item-text='name'
                                  item-value='value'
                                  name="contracts">
                  </v-autocomplete>
                </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    <br>

    <v-card v-if="this.searchResultsLoaded" class="pt-3" align="center" height="50px" tile dark
            block color='primary'>Total Records: {{ this.reports.length }}</v-card>
    <div v-if="searchLaunched">
    <v-data-table class="fullSizeTable elevation-5"
                  :loading="this.loading"
                  loading-text="Loading... Please wait"
                  fixed-header
                  :headers="colHeaders"
                  :custom-sort="customSort"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :mobile-breakpoint="0"
                  :options.sync="options"
                  :items="reports"
                  :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100] }"
                  :items-per-page="15"
    ></v-data-table>
    <br>
    <v-data-table :loading="this.loading" loading-text="Loading... Please wait"
                  height="100px"
                  hide-default-footer
                  :headers="colHeaders"
                  :mobile-breakpoint="0"
                  :items="totals"
                  disable-sort
                  class="elevation-1"
    ></v-data-table>
    </div>
    <br><br>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>
<script>
  import ErrorDialog from "../ErrorDialog";
  import { ReportsAPIService } from '@/servicehandlers/ReportsAPIService';
  import { PoliciesAPIService } from '@/servicehandlers/PoliciesAPIService';
  import { ListsAPIService } from '@/servicehandlers/ListsAPIService';
  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  import ListViewer from "../ListViewer";

  const reportsAPIService = new ReportsAPIService();
  const policyAPIService = new PoliciesAPIService();
  const listApiService = new ListsAPIService();
  const apiService = new CommonAPIService();

  export default {
    name: 'ReportsSearch',
    components: { ListViewer, ErrorDialog },
    data() {
      return {
        allFields: { // required for doing a lookup and easy setting of the objects
          id: { label: 'FID', sortable: true },
          fein: { label: 'fein', sortable: true },
          source: { label: 'source', sortable: true },
          sort: { label: 'sort', sortable: true },
          insured: { label: 'insured', sortable: true },
          contact: { label: 'contact', sortable: true },
          salutation: { label: 'salutation', sortable: true },
          name1: { label: 'name1', sortable: true },
          name2: { label: 'name2', sortable: true },
          address1: { label: 'address1', sortable: true },
          address2: { label: 'address2', sortable: true },
          city: { label: 'city', sortable: true },
          zip: { label: 'zip', sortable: true },
          carrier: { label: 'Carrier', sortable: true },
          phone: { label: 'phone', sortable: true },
          fax: { label: 'fax', sortable: true },
          email: { label: 'email', sortable: true },
          website: { label: 'website', sortable: true },
          note: { label: 'note', sortable: true },
          estab: { label: 'estab', sortable: true },
          sales: { label: 'sales', sortable: true },
          people: { label: 'People', sortable: false },
          state: { label: 'Locale', sortable: true },
          locale: { label: 'Locale', sortable: true },
          fid: { label: 'Firm ID', sortable: true },
          county: { label: 'Locale', sortable: true },
          status: { label: 'Status', sortable: false },
          solicitor: { label: 'Sub', sortable: false },
          // solicitor: { label: 'Solicitor', sortable: false },
          producer: { label: 'Producer', sortable: false },
          reserve: { label: 'Reserve', sortable: true },
          monitor: { label: 'Monitor', sortable: true },
          res_mon: { label: 'Reserve', sortable: true },
          res_def: { label: 'Reserve', sortable: true },
          damages: { label: 'Damages', sortable: true },
          defense: { label: 'Defense', sortable: true },
          total: { label: 'Total', sortable: true },
          claims: { label: 'Claims', sortable: true },
          policies: { label: 'Policies', sortable: true },
          premium: { label: 'Premium', sortable: true },
          lossratio: { label: 'Loss Ratio', sortable: true },
          reserveloss: { label: 'Reserve Loss', sortable: true },
          monitorloss: { label: 'Monitor Loss', sortable: true },
          res_mon_loss: { label: 'Reserve', sortable: true },
          res_def_loss: { label: 'Reserve', sortable: true },
          damagesloss: { label: 'Damages Loss', sortable: true },
          defenseloss: { label: 'Defense Loss', sortable: true },
          totalloss: { label: 'Total Loss', sortable: true },
          totaldamage: { label: 'Total Damages', sortable: true },
          totalreserve: { label: 'Total Reserves', sortable: true },
          totaldefense: { label: 'Total Defense', sortable: true },
          totalmonitor: { label: 'Total Monitor', sortable: true },
          totalresmon: { label: 'Total Res Mon', sortable: true },
          totalresdef: { label: 'Total Res Def', sortable: true },
          totalpremium: { label: 'Total Premium', sortable: true },
          totalclaims: { label: 'Total Claims', sortable: true },
        }, // standard columns that exist as attributes
        fields: {}, // fields are the displayable fields for the DB
        colHeaders: [], // for vuetify table
        totals: [],
        // fields are the displayable fields for the DB
        reports: [], // data for the b-table
        sortedItems: [],
        checkedColumns: [], // columns that are specific to the where clause, in table firms
        contracts: [],
        includeColumns: [], // columns that are specific to the include checkboxes
        whereColumns: { state: 'all' }, // columns that are specific to the where clause
        includeCheckColumns: {}, // columns that are specific to include boxes, but with "where"
        queryType: 'claims', // columns that are specific to include boxes, but with "where"
        aggType: 'sum', // columns that are specific to include boxes, but with "where"
        aggtypes: [{text: 'SUM', value: 'sum'}, {text: 'MIN', value: 'min'},
          {text: 'MAX', value: 'max'}, {text: 'AVG', value: 'avg'}, {text: 'GLR', value: 'glr'}],
        claimsOrPolicies: [{text: 'Claims', value: 'claims'}, {text: 'Policies', value: 'policies'}],
        totalPages: 0, // for pagination
        currentPage: 1, // for pagination
        perPage: 100, // for pagination
        sortDesc: false, // for the b-table
        sortBy: null,
        currentSort: 'state',
        currentSortDir: false,
        loading: false,
        showAlertToGenerateReportFirst: false,
        searchLaunched: false,
        searchResultsLoaded: false,
        filter: null, // for the b-table
        contractOptions: [],
        carrierOptions: [],
        loggedIn: false,
        options: {
          "page": 1,
          "itemsPerPage": 100,
          "sortBy": [null],
          "sortDesc": [false],
        },
        types: [],
      };
    },

    watch: {
      options: {
        // when options change, send new values in searchItems to api
        handler () {
          this.setSearchItemsAndCallApi ()
        },
        deep: true,
      },

      includeCheckColumns() {
        let includes = [];
        Object.keys(this.includeCheckColumns).forEach(key => {
          includes.push(key);
        });
        this.includeColumns = includes;
      },
    },

    mounted() {
      document.title = 'Reports';
      policyAPIService.getContracts('', this.$router).then((data) => {
        const newOptions = [];
        // format data into options we can actually use
        for (let i = 1; i < data.length; i += 1) {
          newOptions.push({
            'name': data[i].contract,
            'value': data[i].id,
          });
        }
        this.contractOptions = newOptions;
      }).catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      listApiService.getList('carrier','0',true,false, 'ASC', 'short', null, this.$router).then((data) => {
        const newOptions = [];
        // format data into options we can actually use
        for (let i = 0; i < data.length; i += 1) {
          newOptions.push({
            'name': data[i].text,
            'value': data[i].value,
          });
        }
        this.carrierOptions = newOptions;
      })
        .catch((e) => {
        this.$refs.errorDialog.showError({
          errorMessage: "Not able to get carriers from the database",
          forDevelopers: e
        })
      });
      listApiService.getList('ptype','0',false,false, 'ASC', 'short', null, this.$router).then((data) => {
        const newOptions = [];
        // format data into options we can actually use
        for (let i = 1; i < data.length; i += 1) {// no text for data[0] so skip
          newOptions.push({
            'name': data[i].text,
            'value': data[i].value,
          });
        }
        this.types = newOptions;
      }).catch((e) => {
        this.$refs.errorDialog.showError({
          forDevelopers: e
        })
      });
    },

    methods: {
      customSort: function (items, index, isDesc) {
        if (index[0]) {
          let key = index[0];
          items.sort((a, b) => {
            let valA = '';
            let valB = '';
            let newKey = '';
            if (key === 'state' || key === 'county') {
              // sort alphanumerically
              valA = a[key];
              valB = b[key];
              if (isDesc[0] === false) {
                if (valA) {
                  return valA.localeCompare(valB);
                } else {
                  return -1;
                }
              } else {
                if (valB) {
                  return valB.localeCompare(valA);
                } else {
                  return -1;
                }
              }
            } else if (key === 'premium') {
              newKey = key + 'num';
              isNaN(a[newKey]) ? valA = 0 : valA = parseInt(a[newKey]);
              isNaN(b[newKey]) ? valB = 0 : valB = parseInt(b[newKey]);

            } else if (key === 'claims' || key === 'policies' || key === 'state' || key === 'locale'
              || key === 'county' || key === 'fid' || key === 'firm') {
              newKey = key;
              valA = parseInt(a[newKey]);
              valB = parseInt(b[newKey]);
            } else if (key === '') {
              newKey = key;
              valA = a[newKey].toString();
              valB = b[newKey].toString();
            } else {
              newKey = key + 'num';
              valA = parseFloat(a[newKey]);
              valB = parseFloat(b[newKey]);
            }
            // If both compared fields are native numbers
            if (isDesc[0] === true) {
            return valA < valB ? 1 : valA > valB ? -1 : 0;
            } else {
            return valA < valB ? -1 : valA > valB ? 1 : 0;
            }
          });
          this.sortedItems = items;
          return items;
        } else return items;
      },

      customSorting(a, b, key) {
        let valA = '';
        let valB = '';
        let newKey = '';
        if (key === 'state' || key === 'county') {
          // sort alphanumerically
          valA = a[key];
          valB = b[key];
          if (valA) {
            return valA.localeCompare(valB);
          } else {
            return -1;
          }
        } else if (key === 'premium') {
          newKey = key + 'num';
          valA = parseInt(a[newKey]);
          valB = parseInt(b[newKey]);
        } else if (key === 'claims' || key === 'policies' || key === 'state' || key === 'locale'
        || key === 'county' || key === 'fid' || key === 'firm') {
          newKey = key;
          valA = parseInt(a[newKey]);
          valB = parseInt(b[newKey]);
        } else if (key === '') {
          newKey = key;
          valA = a[newKey].toString();
          valB = b[newKey].toString();
        } else {
          newKey = key + 'num';
          valA = parseFloat(a[newKey]);
          valB = parseFloat(b[newKey]);
        }
        // If both compared fields are native numbers
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      },

      showReportItem() {
      },

      finishDownload() {
      },
      startDownload() {
      },
      async fetchData() {
        this.colHeaders = [];
        this.buildFields();
        this.cleanColumns();

        const searchItems = {
          page: this.options.page,
          perPage: this.options.itemsPerPage,
          sortDesc: this.options.sortDesc[0],
          sortBy: this.options.sortBy[0],
          checkedColumns: this.checkedColumns,
          whereColumns: this.whereColumns,
          includeCheckColumns: this.includeCheckColumns,
          includeColumns: this.includeColumns,
          queryType: this.queryType,
          aggType: this.aggType,
          simple: false,
        };

        // we need to formulate the include condition here
        let allData = [];
        const data = await reportsAPIService.getReport(searchItems, this.$router);
        Object.keys(data.totals).forEach(key => {
          allData.push(data.totals[key]);
        });
        Object.keys(data.data).forEach(key => {
          allData.push(data.data[key]);
        });

        return allData;
      },

      generateReport() {
        this.colHeaders = [];
        this.fields = {};
        this.searchLaunched = true;
        this.loading = true;
        this.buildFields();

        if (this.whereColumns.carrier && this.whereColumns.carrier.length <= 0) {
          delete this.whereColumns.carrier;
        }
        if (this.whereColumns.type && this.whereColumns.type.length <= 0) {
          delete this.whereColumns.type;
        }
        if (this.whereColumns.contract && this.whereColumns.contract === 0) {
          delete this.whereColumns.contract;
        }
        if (!this.whereColumns.contract) {
          delete this.whereColumns.contract;
        }
        this.setSearchItemsAndCallApi();

      },

      setSearchItemsAndCallApi () {
        const searchItems = {
          page: this.options.page,
          perPage: this.options.itemsPerPage,
          sortDesc: this.options.sortDesc[0],
          sortBy: this.options.sortBy[0],
          checkedColumns: this.checkedColumns,
          whereColumns: this.whereColumns,
          includeCheckColumns: this.includeCheckColumns,
          includeColumns: this.includeColumns,
          queryType: this.queryType,
          aggType: this.aggType,
          simple: false,
        };

        // we need to formulate the include condition here
        reportsAPIService.getReport(searchItems, this.$router).then((data) => {
          this.reports = data.data;
          if (this.reports.length > 0) {this.showAlertToGenerateReportFirst = false};
          this.totals = data.totals;
          this.totalRecords = this.reports.length;
          this.searchResultsLoaded = true;
          this.loading = false;
        }).catch((e) => {
          this.loading = false;
          this.$refs.errorDialog.showError({
            errorMessage: "An error occurred while trying to access the database. " +
              "Be sure to fill out all the fields, including type, completely and  " +
              "if the problem persists please notify the development team with a detailed " +
              "description of the error.",
            forDevelopers: e
          })
        });
       },

      // clean columns
      // even though you uncheck certain items, they still might be included
      // in the where clause even though the value is empty.
      // this method cleans that up before sending to the API
      clearReport() {
      },

      cleanColumns() {
        Object.keys(this.whereColumns).forEach(key => {
          if (this.whereColumns.hasOwnProperty(key)) {
            const value = this.whereColumns[key];
            if (value.length <= 0) {
              // get rid of it
              delete this.whereColumns[key];
            }
          }
        });
      },

      // purpose of this method is to build the fields that will be displayed
      buildFields() {
        // loop through each checkedColumn and build out the fields
        // if a column is checked, simply update the fields to reflect it
        this.fields = {}; // wipe out the old fields
        for (let i = 0; i < this.checkedColumns.length; i += 1) {
          // get the corresponding field from allColumns
          const key = this.checkedColumns[i];
          this.fields[key] = this.allFields[key];
        }

        if (this.queryType === 'claims') {
          if (this.aggType === 'glr') {
            if (this.whereColumns.state === 'all') {
              this.fields['state'] = this.allFields['state'];
            } else if (this.whereColumns.state === 'firm') {
              this.fields['fid'] = this.allFields['fid'];
              delete this.fields['state'];
            } else if (this.whereColumns.state === 'zone') {
              this.fields['state'] = this.allFields['state'];
            } else {
              this.fields['county'] = this.allFields['county'];
              delete this.fields['state'];
            }
            this.fields['claims'] = this.allFields['claims'];
            this.fields['policies'] = this.allFields['policies'];
            this.fields['premium'] = this.allFields['premium'];
            this.fields['res_mon_loss'] = this.allFields['res_mon_loss'];
            this.fields['monitorloss'] = this.allFields['monitor'];
            this.fields['res_def_loss'] = this.allFields['res_def_loss'];
            this.fields['defenseloss'] = this.allFields['defense'];
            this.fields['reserveloss'] = this.allFields['reserve'];
            this.fields['damagesloss'] = this.allFields['damages'];
            this.fields['totalloss'] = this.allFields['total'];
          } else {
            if (this.whereColumns.state === 'all') {
              this.fields['state'] = this.allFields['state'];
            } else if (this.whereColumns.state === 'zone') {
              this.fields['state'] = this.allFields['state'];
            } else if (this.whereColumns.state === 'firm') {
              this.fields['fid'] = this.allFields['fid'];
              delete this.fields['state'];
            } else {
              this.fields['county'] = this.allFields['county'];
              delete this.fields['state'];
            }
            this.fields['claims'] = this.allFields['claims'];
            this.fields['res_mon'] = this.allFields['res_mon'];
            this.fields['monitor'] = this.allFields['monitor'];
            this.fields['res_def'] = this.allFields['res_def'];
            this.fields['defense'] = this.allFields['defense'];
            this.fields['reserve'] = this.allFields['reserve'];
            this.fields['damages'] = this.allFields['damages'];
            this.fields['total'] = this.allFields['total'];
          }
        } else if (this.queryType === 'policies') {
          if (this.whereColumns.state === 'all') {
            this.fields['state'] = this.allFields['state'];
          } else if (this.whereColumns.state === 'zone') {
            this.fields['state'] = this.allFields['state'];
          } else if (this.whereColumns.state === 'firm') {
            this.fields['fid'] = this.allFields['fid'];
            delete this.fields['state'];
          } else {
            this.fields['county'] = this.allFields['county'];
            delete this.fields['state'];
          }
          this.fields['premium'] = this.allFields['premium'];
          this.fields['policies'] = this.allFields['policies'];

          if (this.aggType === 'glr') {
            if (this.whereColumns.state === 'all') {
              this.fields['state'] = this.allFields['state'];
            } else if (this.whereColumns.state === 'zone') {
              this.fields['state'] = this.allFields['state'];
            } else if (this.whereColumns.state === 'firm') {
              this.fields['fid'] = this.allFields['fid'];
              delete this.fields['state'];
            } else {
              this.fields['county'] = this.allFields['county'];
              delete this.fields['state'];
            }
            this.fields['policies'] = this.allFields['policies'];
            this.fields['claims'] = this.allFields['claims'];
            this.fields['premium'] = this.allFields['premium'];
            this.fields['res_mon_loss'] = this.allFields['res_mon_loss'];
            this.fields['monitorloss'] = this.allFields['monitor'];
            this.fields['res_def_loss'] = this.allFields['res_def_loss'];
            this.fields['defenseloss'] = this.allFields['defense'];
            this.fields['reserveloss'] = this.allFields['reserve'];
            this.fields['damagesloss'] = this.allFields['damages'];
            this.fields['totalloss'] = this.allFields['total'];
          }
        }
        delete this.fields['type'];
        delete this.fields['Type'];

        // now loop through all of the includeColumns for any of them that were checked
        for (let i = 0; i < this.includeColumns.length; i += 1) {
          const key = this.includeColumns[i];
          this.fields[key] = this.allFields[key];
        }
        delete this.fields['carrier'];

        // take fields object and create a fieldsArray for vuetify v-table
        let fieldsArray = Object.entries(this.fields);
        // using fieldsArray create array of headers needed for v-table
        let header = {};
        for (let i = 0; i < fieldsArray.length; i += 1) {
          header = {value: fieldsArray[i][0], text: fieldsArray[i][1]['label'], width: "120px", align: 'end'};
            this.colHeaders.push(header);
        }
      },
    },

  };
</script>

<style>
/* When a chip is added to an autocomplete field, this adds space to the field and causes it to no longer line up with*/
/* adjacent fields. Setting the chip to sit on the line with "margin-bottom: 0" (using the onLine class) fixes that.  */
  .onLine .v-chip.v-size--small {
    margin-bottom: 0 !important;
  }

  .theme--light.v-data-table thead tr:last-child th {
    z-index: 0;
  }

  .list-horizontal li {
    display: inline-block;
  }

  .list-horizontal li:before {
    content: '\00a0\2022\00a0\00a0';
    color: #999;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
  }

  .list-horizontal li:first-child:before {
    content: '';
  }

</style>
