import CommonAPIService from './CommonHandler';

export class MgasAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createMGA(data, router) {
    const url = '/api/mgas';
    return this.CommonAPIService.postCall(url, data, router);
  }

  deleteMGA(id, router) {
    const url = `/api/mgas/${id}`;
    return this.CommonAPIService.deleteCall(url, router)
  }

  list(router) {
    const url = '/api/mgas';
    return this.CommonAPIService.getCall(url, router);
  }

  updateMGA(id, updates, router) {
    const url = `/api/mgas/${id}`;
    return this.CommonAPIService.putCall(url, updates, router);
  }
}