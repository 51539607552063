import { render, staticRenderFns } from "./ViewFirmNoNote.vue?vue&type=template&id=c54ee0c6&scoped=true"
import script from "./ViewFirmNoNote.vue?vue&type=script&lang=js"
export * from "./ViewFirmNoNote.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c54ee0c6",
  null
  
)

export default component.exports