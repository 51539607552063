// src/mixin.js
export default {
  data: function () {
    return {
      loggedIn: false,
    }
  },

  created() {
    // console.log('in MIXIN!!!')
  },

  methods: {
    rightsCheckMixin(rightsToCheck) { // first element in rightsToCheck array is current page
      // do I have rights to view first param or edit remaining params?
      let seePage = false;
      let rightsArray = [];
      const rights = this.$store.getters.scopes;
      for (let i = 0; i < rights.length; i += 1) {
        // console.debug(rights[i].name);
        for (let j = 0; j < rightsToCheck.length; j += 1) {
          if (rights[i].name === rightsToCheck[0]) {
            seePage = true;
          }
          if (rights[i].name === rightsToCheck[j]) {
            rightsArray.push(rightsToCheck[j]);
          }
        }
      }
      if (!seePage) {
        this.$router.push({
          name: 'NoPermissionToView',
        });
      }
      return rightsArray;
    },

    loginCheckMixin(rightsToCheck){
      return this.rightsCheckMixin(rightsToCheck);
    }
  }
};
