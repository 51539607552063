<template>
  <div :key="refresh" style="z-index: 100">
    <v-skeleton-loader v-if="policyLoading" type="article, list-item-three-line@9"></v-skeleton-loader>
    <div v-else>
      <v-divider class="mx-auto my-1 secondary" style="border-width: 1px"></v-divider>
      <!--    GENERAL POLICY FIELDS -->
      <v-expansion-panels class="rounded-0" focusable popout v-model="panel.general">
        <v-expansion-panel>
          <v-expansion-panel-header class="pl-5 pr-1 pr-sm-5 title">Edit Policy</v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0 mx-n6">
            <v-btn class="pt-1 mt-1 elevation-9 rounded-0 secondary" @click="save(policy, -1)" block
                   :dark="valid" :disabled="!valid" small tile>Save Policy</v-btn>
            <v-card class="px-2 pt-2 mx-auto mt-1 mb-n4 scroll rounded-0" style="max-width: 1324px">
              <div v-for="(row, index) in rows" class="d-flex flex-nowrap px-1" :key="row">
                <v-col
                    :class="row === 5 ? 'mt-n6 mb-n4' : ''"
                    class="px-2 ma-0"
                    :key="refreshFees + field.name"
                    v-for="field in policyFields[index]"
                >
                  <v-form v-model="valid">
                    <span v-if="field.name === 'Button'">
                      <button-tip btnclass="ml-n1 mt-2 mr-n3" @click="showFees = true, rows = 5, refreshFees = !refreshFees"
                                  icon iname="mdi-eye-outline" tip="Show Fees" v-if="showFees !== true" xsmall/>
                      <button-tip btnclass="ml-n1 mt-2 mr-n3" @click="showFees = false, rows = 4, refreshFees = !refreshFees"
                                  icon iname="mdi-eye-off-outline" tip="Show Fees" v-if="showFees === true" xsmall/>
                    </span>
                    <smart-input
                        :chips="field.multiple"
                        :class="row === 5 ? 'font-weight-light' : ''"
                        class="changedfield small"
                        :clean-value="policyClean[field.val]"
                        clearable
                        :deletable-chips="field.multiple"
                        dense
                        :dirty-check="!policyLoading && !readonlyFields.includes(field.val)"
                        :items="field.select ? field.val === 'ppid' ? policyItems['ppid'] : $_lists[field.list] : []"
                        :label="field.name"
                        :multiple="field.multiple"
                        :no-data-text="'loading...'"
                        :rules="dates.includes(field.val) ? [rules.date] : []"
                        :readonly="readonlyFields.includes(field.val)"
                        :small-chips="field.multiple"
                        :style="{ width: field.width, fontSize: '14px' }"
                        :type="field.select ? 'VAutocomplete' : 'VTextField'"
                        v-else
                        v-model="readonlyFields.includes(field.val) ? addFields(policy, field.val) : policy[field.val]"
                    ></smart-input>
                  </v-form>
                </v-col>
              </div>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!--    OPTIONS  -->
      <v-expansion-panels class="mt-2 rounded-0" focusable :key="refreshOptions" popout v-model="panel.options">
        <v-expansion-panel class="pa-0 ma-0">
          <v-expansion-panel-header class="pl-5 pr-1 pr-sm-5 py-0">
            <v-row class="d-flex flex-row">
              <div class="pl-3 mt-4 mb-n2">
                <span class="mt-2 title">Options <span class="body-1">({{policiesAdded.length}})</span></span>
                <information-dialog-icon confirmClass="mr-3 mb-3 title" confirmColor="secondary darken-1"
                  icon-class="ml-1 mt-n1" title="Options Policies" tclass="headline grey lighten-2 mb-8">
                  <i v-if="program">Program: {{ program.prid }} - {{ program.carrier }}  {{ program.type }}<br><br></i>
                  Click "Options" to see list of policies that are in the same program and group with the current policy
                  being edited. Click "+" to copy the current policy being edited and add it to the group with new
                  limits, deductible, and premium amounts.
                </information-dialog-icon>
              </div>
              <div class="d-flex flex-nowrap px-0 mx-auto mt-2" style="margin-bottom: -13px">
                <!--     add new linked policy with: limit deduc premium-->
                <v-col
                    class="px-2 mx-1 ma-0 mt-n1"
                    @click.stop=""
                    :key="field.name"
                    v-for="field in addPolicyFields">
                  <smart-input
                      class="changedfield small"
                      clearable
                      dense
                      :items="field.select ? $_lists[field.list] : []"
                      :label="field.name"
                      :no-data-text="'loading...'"
                      :style="{ width: field.width, fontSize: '14px' }"
                      :type="field.select ? 'VAutocomplete' : 'VTextField'"
                      v-model="policyToAdd[field.val]"
                  ></smart-input>
                </v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn color="secondary" @click.stop="addPolicy" class="mt-2 mr-2 mr-sm-4" elevation="5"
                           :disabled="!policyToAdd.limits || !policyToAdd.deduc || !policyToAdd.premium"
                           fab v-on="on" x-small><v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Policy Copy to Group</span>
                </v-tooltip>
              </div>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0 ml-n3 mr-n6" :key="refreshOptionFees">
            <v-card color="grey lighten-4" class="px-0 pt-2 mx-n2 mt-2 mb-n4 rounded-0">
              <div class="scroll" style="max-height: 446px">
                <div v-for="(row, index) in policiesAdded.length" class="pl-4"
                     :key="row + Math.random() + showFees" style="margin-left: -3px">
                  <v-row class="d-flex flex-nowrap px-1">
                    <button-tip btnclass="ml-0 mr-n2 mt-3" @click="save(policiesAdded[index], index)"
                                icon iname="mdi-content-save-edit-outline" tip="Save Option"/>
                    <button-tip btnclass="ml-0 mr-n2 mt-3" @click="policiesAdded[index].showFees = false, refreshOptionFees = !refreshOptionFees"
                                icon iname="mdi-eye-off-outline" tip="Hide Option Fees" v-if="policiesAdded[index].showFees === true"/>
                    <button-tip btnclass="ml-0 mr-n2 mt-3" @click="policiesAdded[index].showFees = true, refreshOptionFees = !refreshOptionFees"
                                icon iname="mdi-eye-outline" tip="Show Option Fees" v-if="policiesAdded[index].showFees !== true"/>
                    <button-tip btnclass="ml-0 mr-n3 mt-3" @click="deletePolicyOption(policiesAdded[index])" icolor="error"
                                icon iname="mdi-trash-can-outline" tip="Delete Option" v-if="groups.includes('admin')"/>
                    <v-col
                        class="ma-0"
                        :key="field.name"
                        style="padding-left: 4.9px; padding-right: 4.9px"
                        v-for="field in policyFields[3]">
                      <smart-input
                          class="changedfield small"
                          :clean-value="policiesAddedClean[index][field.val]"
                          clearable
                          dense
                          :dirty-check="!policiesAddedLoading && !readonlyFields.includes(field.val)"
                          :items="field.select ? $_lists[field.list] : []"
                          :label="field.name"
                          :no-data-text="'loading...'"
                          :readonly="field.val === 'taxes' || field.val === 'fees' || field.val === 'total'"
                          :style="field.name === 'Limit' ? { width: '160px', fontSize: '14px' } : { width: field.width, fontSize: '14px' }"
                          :type="field.select ? 'VAutocomplete' : 'VTextField'"
                          v-if="field.name !== 'Program' && field.name !== 'Button'"
                          v-model="readonlyFields.includes(field.val) ? addFields(policiesAdded[index],field.val) : policiesAdded[index][field.val]"
                      ></smart-input>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex flex-nowrap mt-n6 mb-5" v-if="!policiesAdded[index].showFees !== true">
                    <div :style="groups.includes('admin') ? 'min-width: 91px' : 'min-width: 70px'"></div>
                    <v-col
                        class="px-1 ma-0"
                        :key="field.name"
                        v-for="field in policyFields[4]">
                      <smart-input
                          class="changedfield font-weight-light small"
                          :clean-value="policiesAddedClean[index][field.val]"
                          clearable
                          dense
                          :dirty-check="!policiesAddedLoading"
                          :items="field.select ? $_lists[field.list] : []"
                          :label="field.name"
                          :no-data-text="'loading...'"
                          :style="field.name === 'Limit' ? { width: '160px', fontSize: '14px' } : { width: field.width, fontSize: '14px' }"
                          :type="field.select ? 'VAutocomplete' : 'VTextField'"
                          v-if="field.name !== 'Program' && field.name !== 'Button'"
                          v-model="policiesAdded[index][field.val]"
                      ></smart-input>
                    </v-col>
                  </v-row>
                </div>
              </div>
          </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!--    TEXT FIELDS (HTML) -these 4 fields are updated to the whole group, unless grpnum = 0, then just update policy -->
      <v-expansion-panels class="my-2 rounded-0" focusable popout v-model="panel.text">
        <v-expansion-panel class="ma-0 top-offset" @click="scrollIntoView('text')" id="text">
          <v-expansion-panel-header class="pl-5 pr-1 pr-sm-5 title">Text Fields</v-expansion-panel-header>
          <v-expansion-panel-content class="px-0 mx-n6 mt-n2 mb-n5">
            <v-btn block class="elevation-9 rounded-0 secondary" @click="save(policy, -1)" small
                   v-if="policy.grpnum === 0">Save Policy</v-btn>
            <v-btn block class="elevation-6 rounded-0 secondary" @click="saveToGroup(policy)" small
                   v-else>Save text fields to group</v-btn>
            <v-row class="px-0 mx-0 mt-1 row-wrap" dense>
              <v-col class="px-0 mx-0" cols="12" :key="field + resetEditor" v-for="field in htmlFields">
                <v-card class="pa-1 mx-0 mt-n1 rounded-0">
                  <v-row justify="start" class="mr-4 mb-n3">
                    <v-card-title class="mt-n2">{{ field.toUpperCase() }}</v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="rendered[field]" text x-large color="secondary darken-1"
                           class="pb-0 font-weight-bold" @click="revertToPlainText(field)">
                      Revert To Plain Text
                    </v-btn>
                    <v-btn v-else-if="!rendered[field]" text x-large color="secondary darken-2"
                           class="pb-0 font-weight-bold" @click="convertToHtml(field)">
                      Convert To HTML
                    </v-btn>
                    <information-dialog-icon confirmClass="mr-3 mb-3 title" confirmColor="secondary darken-1"
                      icon-class="mb-n5 mt-2 ml-n5 mr-n4" tclass="headline grey lighten-2 mb-8" title="Rendering HTML Field">
                      Converting to HTML means that you will be provided an HTML editor to edit with as
                      opposed to a plain text box. This will add HTML tags to your field, and give you more styling options. You
                      won't be able to see the HTML tags unless you edit it in in.dominioninsurance.com. If you have already
                      converted the field to be HTML text, a "revert to plain text" button will appear, and the system will
                      attempt to strip and replace any added HTML tags with plain text characters. Note that if you have a table
                      or other additional non-text elements, there will be no way to remove these and maintain the contents
                      of your field. Those HTML tags will remain, even after reverting to plain text.
                    </information-dialog-icon>
                  </v-row>
                  <ckeditor :config="editorConfig" type="classic" v-if="rendered[field]"
                            v-model="policy[field]" :editor="editor"
                  ></ckeditor>
                  <v-textarea class="px-3 pb-0 mx-1 mt-n1" dense rows="8"
                              style="margin-bottom: -8px" v-else v-model="policy[field]">
                  </v-textarea>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!--    LEDGER FIELDS -->
      <v-expansion-panels class="my-2 rounded-0" focusable :key="refreshLedger + 'ledger'" popout v-model="panel.ledger">
        <v-expansion-panel class="top-offset" @click="scrollIntoView('ledger')" id="ledger">
          <v-expansion-panel-header class="pl-8 pr-1 pr-sm-5 title">
            <v-row class="pr-1 pr-sm-6 mr-1 mr-sm-12 d-flex flex-row">
              Ledger (currently view only)
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <!--    TODO add button below when ledgers ui is complete -->
<!--                  <v-btn color="secondary" @click.stop="addLedgerEntry" class="mx-4"-->
<!--                         :disabled="ledgerLoading || !ledger.length" elevation="5" fab v-on="on" x-small><v-icon>mdi-plus</v-icon>-->
<!--                  </v-btn>-->
                </template>
                <span>Add Ledger Entry</span>
              </v-tooltip>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pb-0 mr-n10 rounded-0">
<!--            <v-row v-if="!ledgerLoading && !ledger.length">-->
<!--              <v-btn block class="mx-n4 rounded-0 secondary" @click="bookPreCheck" small>book</v-btn>-->
<!--            </v-row>-->
            <v-card class="pt-4 mx-n5 mb-16 mb-sm-n4 scroll">
              <v-row class="pa-4 d-flex flex-nowrap" v-for="row in ledger.length" :key="row">
                <v-col
                    class="px-2 mx-0"
                    :key="refresh + Math.random()"
                    v-for="field in ledgerFields[0]">
                  <smart-input
                      class="my-n6 changedfield small"
                      :clean-value="ledgerClean[row-1][field.val]"
                      clearable
                      :dirty-check="!ledgerLoading"
                      dense
                      :items="field.select ? field.val === 'cid' ? ledgerItems['cid'] : $_lists[field.list] : []"
                      :item-text="field.val === 'cid' ? 'sort' : 'text'"
                      :item-value="field.val === 'cid' ? 'cid' : 'value'"
                      :label="field.name"
                      readonly
                      :style="{ width: field.width, fontSize: '14px' }"
                      :type="field.select ? 'VAutocomplete' : 'VTextField'"
                      v-model="ledger[row-1][field.val]"
                  ></smart-input>
                </v-col>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!--    EDIT FILES -->
      <v-expansion-panels class="rounded-0" focusable popout v-model="panel.files">
        <v-expansion-panel @click="scrollIntoView('files')">
          <v-expansion-panel-header class="pl-5 pr-1 pr-sm-5 title">Files</v-expansion-panel-header>
          <v-expansion-panel-content class="pl-3 pb-3" :key="refresh">
            <span id="files"><EditFiles ref="editFiles" v-bind="{ fid, groups, type, typeId }"/></span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

  <!--    Add space at bottom for mobile friendliness-->
      <v-row class="mb-16 mb-sm-1"></v-row>

      <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='EditPolicy'"></snack>

      <ActionDialog :value="unsavedPolicyChanges" @input="$emit('input', $event)" max-width="700"
                    title="Book Policy" confirm-text="Proceed" confirm-color="red lighten-2"
                    @close="unsavedPolicyChanges = false" @confirm="save(policy,-1,true)" divider>
          There are unsaved changes to this policy.  Click "Proceed" to save changes to policy and book policy.
      </ActionDialog>
    </div>
  </div>
</template>

<script>
import ActionDialog from '../../ActionDialog';
import ClassicEditor from "ckeditor5-build-classic-plus";// npm install --save ckeditor5-build-classic-plus
import EditFiles from "./EditFiles";
import InformationDialogIcon from "../../InformationDialogIcon";
import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
import { LedgersAPIService } from "@/servicehandlers/LedgersAPIService";
import { ListsAPIService } from "@/servicehandlers/ListsAPIService";
import { PoliciesAPIService } from "@/servicehandlers/PoliciesAPIService";
import { ProgramsAPIService } from "@/servicehandlers/ProgramsAPIService";
import moment from "moment";

const contactsAPIService = new ContactAPIService();
const firmsAPIService = new FirmsAPIService();
const ledgersAPIService = new LedgersAPIService();
const listsAPIService = new ListsAPIService();
const policiesAPIService = new PoliciesAPIService();
const programsAPIService = new ProgramsAPIService();

export default {
  name: 'EditPolicy',
  components: { ActionDialog, EditFiles, InformationDialogIcon },
  props: {
    id: [String, Number],
    default() {
      return {};
    }
  },
  data() {
    return {
      dates: ['incept', 'expiry', 'submitted', 'quoted', 'bndreq', 'booked', 'bound', 'issued', 'canceled', 'bstamp'],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', 'bulletedList', 'numberedList', '|', 'blockQuote', 'insertTable', '|', 'undo', 'redo']
      },
      groups: [],
      htmlFields: ['forms', 'features', 'docs', 'note'],
      ledger: [],
      ledgerClean: [],
      ledgerItems: [],
      ledgerLoading: true,
      panel: {
        general: 0,
        files: '',
        options: '',
        text: '',
      },
      policy: {},
      policyClean: {},
      policyItems: [],
      policyLoading: true,
      policyToAdd: {},
      policiesAdded: [],// policies that were added to the group (options)
      policiesAddedClean: [],
      policiesAddedLoading: true,
      program: {},
      readonlyFields: ['taxes', 'fees', 'total'],
      refresh: false,
      refreshFees: false,// for showing extra row of fees in main policy
      refreshLedger: false,
      refreshOptionFees: false,// for showing extra row of fees in policy option
      refreshOptions: false,// for refreshing when options list changes
      rendered: [],
      resetEditor: false,
      rows: 4,
      rules: { date: (x) => (!x || this.valDate(x)) || `Must be ${this.rulesDateFormat}`},
      rulesDateFormat: 'M-D-YYYY',
      showFees: false,
      type: 'policy',// for EditFiles.vue
      unsavedPolicyChanges: false,
      valid: true,
    };
  },

  computed: {
    addPolicyFields() {
      return [
        {name: 'Limit',      val: 'limits', width: 'calc(20px + 14vw)', select: true, list: 'limits', sort: '' },
        {name: 'Deductible', val: 'deduc',  width: 'calc(30px + 14vw)', select: true, list: 'deduc',  sort: '' },
        {name: 'Premium',    val: 'premium',width: 'calc(7px + 14vw)',  select: false },
      ]
    },

    fid() {
      return this.policy.fid;// EditFiles.vue uses fid for firmsAPIService.getFirmFiles & FirmFilesUpload
    },

    ledgerFields() {// double fields are 2x short + 16px(space between fields)
      let short  = '136px';
      return [// array of 1 row
        [
          {name: 'Entity',   val: 'cid',  width: short, select: true },
          {name: 'Type',     val: 'type', width: short, select: true, list: 'ledgertype', sort: '' },
          {name: 'Account',  val: 'acct', width: short, select: true, list: 'ledgeracct', sort: '' },
          {name: 'Ref',      val: 'num',  width: short, select: false },
          {name: 'Amount',   val: 'amt',  width: short, select: false },
          {name: 'Due',      val: 'due',  width: short, select: false },
          {name: 'Paid',     val: 'paid', width: short, select: false },
          {name: 'Deposited',val: 'dep',  width: short, select: false },
          {name: 'Note',     val: 'note', width: short, select: false },
        ],
      ];
    },

    policyFields() {
      let shortest= '78px';
      let shorter = '112px';
      let short   = '142px';
      let first   = '172px';
      let double  = '300px';
      return [// array of rows
        [
          {name: 'Producer', val: 'prodname',width: first, select: true, list: 'name', sort: '1'},
          {name: 'Zip',      val: 'zip',     width: short, select: false },
          {name: 'Insured',  val: 'insured', width: double,select: false },
          {name: 'Polnum',   val: 'polnum',  width: short, select: false },
          {name: 'Prior',    val: 'ppid',    width: double,select: true },
          {name: 'Sales',    val: 'sales',   width: short, select: false },
        ],
        [
          {name: 'Status',   val: 'status',  width: first, select: true, list: 'pstatus',sort: '1'},
          {name: 'Placement',val: 'is_new',  width: short, select: true, list: 'is_new', sort: '' },
          {name: 'Carrier',  val: 'carrier', width: short, select: true, list: 'carrier',sort: '0'},
          {name: 'Type',     val: 'type',    width: short, select: true, list: 'ptype',  sort: '0'},
          {name: 'Incept',   val: 'incept',  width: short, select: false },
          {name: 'Expiry',   val: 'expiry',  width: short, select: false },
          {name: 'Retro',    val: 'retro',   width: short, select: false },
          {name: 'Bstamp',   val: 'bstamp',  width: short, select: false },
        ],
        [
          {name: 'Submitted',val: 'submitted',       width: first, select: false },
          {name: 'Quoted',   val: 'quoted',          width: short, select: false },
          {name: 'Bindreq',  val: 'bndreq',          width: short, select: false },
          {name: 'Booked',   val: 'booked',          width: short, select: false },
          {name: 'Bound',    val: 'bound',           width: short, select: false },
          {name: 'Issued',   val: 'issued',          width: short, select: false },
          {name: 'Canceled', val: 'canceled',        width: short, select: false },
          {name: 'Reason',   val: 'cancellationcode',width: short, select: true, list: 'cancat',  sort: ''},
        ],
        [
          // {name: 'Group',      val: 'grpnum',  width: '140px'},
          {name: 'Button',         val: 'button', width: '0px',   select: false },
          {name: 'Limit',          val: 'limits', width: short,   select: true, list: 'limits', sort: '' },
          {name: 'Limit Type',     val: 'ltype',  width: short,   select: true, list: 'ltype',  sort: '0'},
          {name: 'Deductible',     val: 'deduc',  width: short,   select: true, list: 'deduc',  sort: '' },
          {name: 'Deductible Type',val: 'dtype',  width: short,   select: true, list: 'dtype',  sort: '0'},
          {name: 'Premium',        val: 'premium',width: short,   select: false },
          {name: 'Taxes',          val: 'taxes',  width: shortest,select: false },
          {name: 'Fees',           val: 'fees',   width: shortest,select: false },
          {name: 'Total',          val: 'total',  width: shorter, select: false },
          {name: 'Down',           val: 'down',   width: short,   select: false },
        ],
        [
          // {name: 'Program',   val: 'prid',   width: shortest,  select: false },
          {name: 'DOI tax',   val: 'doifee',  width: first, select: false },
          {name: 'SLA tax',   val: 'slafee',  width: short, select: false },
          {name: 'Broker',    val: 'profee',  width: short, select: false },
          {name: 'Sub-broker',val: 'subfee',  width: short, select: false },
          {name: 'MGA-1',     val: 'depfee',  width: short, select: false },
          {name: 'MGA-2',     val: 'mgafee',  width: short, select: false },
          {name: 'Source',    val: 'srcfee',  width: short, select: false },
          {name: 'Sponsor',   val: 'spnfee',  width: short, select: false },
        ],
          // {name: 'Group',      val: 'grpnum',  width: '98%',  select: true, multiple: true },
      ]
    },

    typeId() {
      if (!this.policy.pid) return null;
      return this.policy.pid; // EditFiles.vue attaches files to policy with this policy id
    }

  },

  created: async function() {
    this.$_getLists();// gets most of the dropdowns
    await this.getPolicy();
    this.groups = this.$store.getters.groups;
    contactsAPIService.getContactList(this.$router).then(contacts => {
        contacts = contacts.filter(contact => contact.sort !== ''); // remove empty rows from dropdown
        this.ledgerItems['cid'] = contacts;// special case for dropdown
    });
    this.getLedger('initial');
    programsAPIService.getProgram(this.policy.prid, this.$router).then((program)  => {
      // console.debug('program', program);
      this.program = program;
      // TODO need to format program field after api/s available - list those with prid === this.policy.prid;
      // let programsFiltered = programs.filter(program => (program.prid === this.policy.prid));
    });

  },

  methods: {
    aCheck(num) {// check for NaN
      return isNaN(parseFloat(num)) ? 0 : parseFloat(num);
    },

    addFields(policy, field) {
      let taxes = (this.aCheck(policy.doifee) + this.aCheck(policy.slafee)).toFixed(2);
      let fees = (this.aCheck(policy.profee)
          + this.aCheck(policy.subfee)
          + this.aCheck(policy.depfee)
          + this.aCheck(policy.mgafee)
          + this.aCheck(policy.srcfee)
          + this.aCheck(policy.spnfee)).toFixed(2);
      let total = (this.aCheck(policy.premium)
          + this.aCheck(policy.taxes)
          + this.aCheck(policy.fees)).toFixed(2);
      return field === 'taxes' ? taxes : field === 'fees' ? fees : total;
    },

    addLedgerEntry() {
      ledgersAPIService.addLedger({pid: this.policy.pid}, this.$router).then(() => this.getLedger());
    },

    addPolicy() {// copy this policy with user-entered limit deduc premium
      let policyToAdd = JSON.parse(JSON.stringify(this.policy));
      // delete fields that cause api errors
      delete policyToAdd.contract;
      delete policyToAdd.fees;
      delete policyToAdd.pid;
      delete policyToAdd.taxes;
      delete policyToAdd.total;
      // replace limits, deduc, and premium fields with user-entered values
      for (let fld of this.addPolicyFields) if (this.policyToAdd[fld.val]) policyToAdd[fld.val] = this.policyToAdd[fld.val];
      this.panel.options = 0;
      policiesAPIService.addPolicy(policyToAdd, this.$router).then((added) => {
        // if this.policy.grpnum = 0 then it isn't in a group so need to send both pids to updateGroupNumByPids API
        // this will assign both policies the same unique grpnum (update both policies with new grpnum)
        if (this.policy.grpnum === 0) policiesAPIService.updateGroupNumByPids(this.policy.pid, added.pid, this.$router).then((response) => {
          this.policy.grpnum = response.grpnum;
          this.getPoliciesFor('options');// gets/sets rows for options (policies linked by group)
        });
        else {
          this.getPoliciesFor('options');// gets/sets rows for options (policies linked by group)
        }
          this.$store.dispatch('SET_SNACK', {cmpnt: 'EditPolicy', open: true, msg: 'Policy was added to the group.'});
      });
    },

    book() {
      // call API to book policy, pass this.policy.pid
      this.$store.dispatch('SET_SNACK', { cmpnt: 'EditPolicy', open: true, msg: 'Policy was booked.'});
    },

    bookPreCheck() {// check for unsaved policy changes before booking, alert user
      // this.policyClean = {...this.policy};
      this.policyClean !== this.policy ? this.unsavedPolicyChanges = true : this.book();
      // this.policyClean !== {...this.policy} ? this.unsavedPolicyChanges = true : this.book();
    },

    convertToHtml(field) {
      this.rendered[field] = true;
      this.policy[field] = this.policy[field].replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.resetEditor = !this.resetEditor;
    },

    dateAdd(date, add) {// add number to year part of yyyy-mm-dd date
      let parts = date.split('-');
      parts[0] = parseInt(parts[0]) + add;
      return `${parts[0]}-${parts[1]}-${parts[2]}`;
    },

    dateReorder(date) {// mm-dd-yyyy -> yyyy-mm-dd - for comparing and sorting
      let parts = date.split('-');
      return `${parts[2]}-${parts[0]}-${parts[1]}`;
    },

    dateWithinNumYears(date, num) {// check if date is within num years +/- of this.policy.expiry (or incept +1)
      if (this.valDate(date)) {
        let expiry = this.valDate(this.policy.expiry)
            ? this.dateReorder(this.policy.expiry) : this.dateAdd(this.dateReorder(this.policy.incept),1);
        return this.dateAdd(expiry,-1 * num) <= date && date <= this.dateAdd(expiry, num);
      } else return true;
    },

    deletePolicyOption(policy) {
      policiesAPIService.deletePolicy(policy.pid, this.$router)
        .then((data) => {
          this.getPoliciesFor('options');// gets/sets rows for options (policies linked by group)
          this.$store.dispatch('SET_SNACK', { cmpnt: 'EditPolicy', open: true, msg: 'Policy has been deleted.'});
        })
    },

    getLedger(time) {
      this.ledgerLoading = true;
      ledgersAPIService.getLedgers(this.policy.pid, this.$router).then((ledger) => {
        this.ledger = ledger;
        this.ledgerClean = JSON.parse(JSON.stringify(ledger));
        this.ledgerLoading = false;
        if (time !== 'initial') {// if initial getLedger, don't scrollIntoView
          this.scrollIntoView('ledger');
          this.panel.ledger = 0;
          this.refreshLedger = !this.refreshLedger;
        }
      });
    },

    getPoliciesFor(fields) {// call api once to get policies for 'options' and/or for 'prior' dropdown
      firmsAPIService.getFirmPolicies(this.policy.fid, this.$router).then((policies) => {
        this.policyItems['ppid'] = [];
        this.policiesAdded = [];
        let validMainPolicyDate = this.valDate(this.policy.expiry) || this.valDate(this.policy.incept);
        for (let p of policies) {// check each policy to see if it goes in 'options' and/or 'prior' dropdown
          // check if one of policy.expiry & policy.incept is valid and one of p,expiry && p.incept is valid
          let validDates = validMainPolicyDate && (this.valDate(p.expiry) || this.valDate(p.incept));
          // if expiry is missing then set reordered expiry to reordered(incept + 1 year)
          if (validDates) p.reorderedExpiry = this.valDate(p.expiry) ? this.dateReorder(p.expiry) : this.dateAdd(this.dateReorder(p.incept),1);
          if (!validDates || this.dateWithinNumYears(p.reorderedExpiry, 1)) {
            if (fields.includes('prior')) {
              if (['Book','dncLost','Lost','Past','Tail'].includes(p.status)) {
                p.text = `${p.carrier || ''} (${p.type || ''}${p.type ? '-' : ''}${p.status || ''}) ${p.expiry || ''}`;
                p.value = p.pid;
                this.policyItems['ppid'].push(p);
              }
            }
            // if it isn't the main policy being edited, and grpnum nonzero and prid and grpnums match
            if (fields.includes('options') && p.pid !== this.policy.pid && p.grpnum !== 0
                && p.prid === this.policy.prid && p.grpnum === this.policy.grpnum) this.policiesAdded.push(p);
          }
        }
        if (fields.includes('prior')) {
          this.policyItems['ppid'].sort(function(a, b) {// sort 'prior' dropdown items by expiry
            return new Date(a.reorderedExpiry) - new Date(b.reorderedExpiry);
          });
        }
        if (fields.includes('options')) {
          this.policiesAdded.sort(function(a, b) {// sort options by premium
            return a.premium - b.premium;
          });
          this.policiesAddedClean = JSON.parse(JSON.stringify(this.policiesAdded));
        }
        this.policiesAddedLoading = false;
        this.refreshOptions = !this.refreshOptions;
        if (this.policiesAdded.length < 1) this.panel.options = null;
      });
    },

    getPolicy: async function() {
      // get policy to edit, get firm for document title, get policies for options and for 'prior' dropdown
      try {
        this.policy = await policiesAPIService.getPolicy(this.id, this.$router);
        // grpnum = 0 is used to indicate policy is not in a group, grpnum should not be null, set to zero if null
        if (this.policy.grpnum === null) this.policy.grpnum = 0;
        this.getPoliciesFor(['options','prior']);
        this.policyClean = {...this.policy};
        this.policyLoading = false;
        this.$nextTick(() => this.$refs.editFiles.getAllFiles(this.policy.fid, this.policy.pid));
        firmsAPIService.getSingleFirm(this.policy.fid, this.$router).then((firm) => {
          document.title=`${this.policy.fid}  ${firm.sort} - ${this.policy.incept} ${this.policy.carrier}`;
        })
      } catch (e) {
      }
    },

    revertToPlainText(field) {
      this.rendered[field] = false;
      this.policy[field] = this.policy[field].replace(/<br\s?\/?>|<\s?\/\s?p>/gm, '\n').replace(/<p\s?>/gm, '').replace(/&nbsp;/gm, ' ');
      this.resetEditor = !this.resetEditor;
    },

    saveToGroup(policy) {// update all policies with matching fid, prid, & grpnum with forms, features, docs, & status fields
      let updateData = {
        forms: policy.forms,
        features: policy.features,
        docs: policy.docs,
        note: policy.note,
      }
      policiesAPIService.updateMatchingGroups(policy.grpnum, policy.fid, policy.prid, updateData, this.$router)
        .then((response) => {
          this.$store.dispatch('SET_SNACK', { cmpnt: 'EditPolicy', open: true, msg: 'Text fields have been saved to group.'});
        });
    },

    save(policy, index, book) {// -1 index indicates this.policy
      return policiesAPIService.updatePolicy(policy.pid, policy, this.$router)
        .then((data) => {
          if (book) this.book();
          if (index === -1) this.unsavedPolicyChanges = false;
          if (index === -1) this.policyClean = {...this.policy};// reset clean policy to be what was just saved
          if (index > -1)  this.policiesAddedClean[index] = {...policy};// reset clean policy to be what was just saved
          this.$store.dispatch('SET_SNACK', { cmpnt: 'EditPolicy', open: true, msg: 'Policy changes have been saved.'});
        })
        .catch((error) => {
          console.log('ERROR: ', error);
        });
    },

    scrollIntoView(id) {
        setTimeout(() => {
          let element = document.getElementById(id);
          element.scrollIntoView();
        }, 500);
    },

    valDate(date) {
      let valid = moment(date, this.rulesDateFormat, true).isValid();
      return valid;
    },

  },
};

</script>
<style>
  .top-offset { scroll-margin-top: 48px }
  .changedfield .v-input__append-outer .v-icon { color: firebrick !important }
  .small label { font-size: 14px !important }
</style>
