<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="createCommission"
                 max-width="400" title="Commission change" :disabled="!valid" bottom-divider>
    <v-form v-model="valid">
      <v-row class="mt-3">
        <v-col class="dense">
          <v-text-field v-model="commissionData.commission" label="Commission" :rules="[rules.percent]">
            <template v-slot:prepend-inner>
              <h6 class="mt-1 mx-1">%</h6>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dense">
          <date-type-select v-model="commissionData.start_date_type" :options="{label: 'Date',
          prependInnerIcon: 'mdi-calendar-start', rules: [rules.required]}"></date-type-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dense">
          <date-picker-text-field v-model="commissionData.start_date" label="After" picker-color="secondary"
                                  input-color="primary" :rules="[rules.required, rules.date]"
                                  prepend-inner-icon="mdi-calendar-month"></date-picker-text-field>
        </v-col>
      </v-row>
    </v-form>

    <loading-overlay v-model="loading" absolute></loading-overlay>
    <v-row justify="center" class="mb-n5 mt-3">
      <v-alert v-if="error" type="error" text>
        An error occurred. Please try again, or contact development if the error persists.
      </v-alert>
    </v-row>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import {_} from 'vue-underscore';
  import { CommissionsAPIService } from "@/servicehandlers/CommissionsAPIService";
  import ActionDialog from "../../ActionDialog";
  import ContactsSelect from "../../ContactsSelect";
  import CarriersSelect from "../../CarriersSelect";
  import DatePickerTextField from "../../DatePickerTextField";
  import ErrorAlert from "../../ErrorAlert";
  import PolicyTypesSelect from "../../PolicyTypesSelect";
  import DateTypeSelect from "../../DateTypeSelect";
  import LoadingOverlay from "../../LoadingOverlay";

  const commissionsAPIService = new CommissionsAPIService();

  export default {
    name: "UpdateCommissionDialog",
    components: {
      LoadingOverlay,
      DateTypeSelect,
      PolicyTypesSelect, ErrorAlert, DatePickerTextField, CarriersSelect, ContactsSelect, ActionDialog},
    props: {
      commission: {
        required: true,
        type: Object
      },
      value: {
        required: true,
        type: Boolean
      }
    },
    data: (vm) => ({
      commissionData: {start_date: moment().format('YYYY-MM-DD')},
      error: false,
      loading: false,
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        fractional: (x) => x <= 1 || 'Must be less than or equal to 1.',
        numeric: (x) => !isNaN(parseInt(x)) || 'Must be a numeric value.',
        percent: (x) => (x >= 0 && x <= 100) || 'Must be a valid percentage.',
        positive: (x) => x > 0 || 'Must be a positive number.',
        required: (x) => !!x || 'This field is required.',
      },
      valid: false
    }),
    created() {
      this.commissionData.mga_id = this.commission.mga_id;
      this.commissionData.carrier = this.commission.carrier;
      this.commissionData.condition = this.commission.condition;
      this.commissionData.types = JSON.parse(JSON.stringify(this.commission.types));
      this.commissionData.renewal = this.commission.renewal;
      this.commissionData.commission = this.commission.commission
    },
    methods: {
      createCommission() {
        this.loading = true;
        let comData = JSON.parse(JSON.stringify(this.commissionData));
        comData.commission = comData.commission / 100;
        commissionsAPIService.createCom(comData, this.$router)
          .then(com => {
            this.$emit('input', !this.value);
            this.$emit('updated');
          })
          .catch(error => {
            this.error = true;
          })
          .finally(() => { this.loading = false })
      },
    }
  }
</script>

<style scoped>

</style>