<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" :transition="transition"
          :offset-y="offsetY"
          :min-width="minWidth">
    <template v-slot:activator="{ on }">
      <v-text-field :value="value" @input="$emit('input', $event)" :label="label"
                    :prepend-icon="prependIcon" :prepend-inner-icon="prependInnerIcon" :readonly="readonly"
                    v-on="on" :color="inputColor" :rules="rules"></v-text-field>
    </template>
    <v-date-picker :value="value" @input="picked($event)" :color="pickerColor"></v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    name: "DatePickerTextField",
    props: {
      inputColor: {
        type: String,
        default: 'black'
      },
      label: String,
      minWidth: {
        default: '290'
      },
      offsetY: {
        type: Boolean,
        default: false
      },
      prependIcon: {
        type: String,
        default: undefined
      },
      prependInnerIcon: {
        type: String,
        default: undefined
      },
      pickerColor: {
        type: String,
        default: 'black'
      },
      readonly: {
        type: Boolean,
        default: false
      },
      rules: {
        default: () => []
      },
      transition: {
        default: 'scale-transition'
      },
      value: String,
    },
    data: function() {
      return {
        menu: false
      }
    },
    methods: {
      picked: function (value) {
        this.$emit('input', value);
        this.menu = false;
      }
    }
  }
</script>

<style scoped>

</style>
