<template>
  <v-form v-model="valid">
    <v-row>
      <v-col class="dense">
        <producer-select v-model="commissionModel.list_id" :options="{label: 'Producer', disabled: update,
          prependIcon: 'mdi-account-tie', rules: [rules.required]}" item-value="id"></producer-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="dense">
        <v-text-field v-model="commissionModel.commission" label="Rate" :rules="[rules.required, rules.percentage]">
          <template v-slot:prepend>
            <h6 class="mt-1 mx-1">%</h6>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="dense">
        <v-select v-model="commissionModel.renewal" :items="placements" label="Placement" :rules="[rules.required]"
                  prepend-icon="mdi-file-question"></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { ProducerCommissionsAPIService } from "@/servicehandlers/ProducerCommissionsAPIService";
  import ProducerSelect from "../../../ProducerSelect";

  const producerCommissionsAPIService = new ProducerCommissionsAPIService();
  export default {
    name: "ProducerCommissionForm",
    components: {ProducerSelect},
    props: {
      commission: {
        type: Object
      },
      update: {
        type: Boolean
      }
    },
    watch: {
      valid(valid) {
        this.$emit('valid', valid);
      }
    },
    data: () => ({
      commissionModel: {renewal: null},
      placements: [
        {text: 'All', value: null},
        {text: 'Renewal', value: true},
        {text: 'New Business', value: false}
      ],
      rules: {
        percentage: (x) => (x <= 100 && x >= 0) || 'Must be a valid percentage.',
        required: (x) => (x !== undefined && x !== '') || 'This field is required.',
      },
      valid: false
    }),
    created() {
      if (this.update) this.commissionModel = JSON.parse(JSON.stringify(this.commission));
    },
    methods: {
      createProdCom() {
        const data = {
          list_id: this.commissionModel.list_id,
          commission: this.commissionModel.commission / 100,
          renewal: this.commissionModel.renewal
        };

        return producerCommissionsAPIService.createProdCom(data, this.$router);
      },
      updateProdCom() {
        const updates = {
          commission: this.commissionModel.commission / 100,
          renewal: this.commissionModel.renewal
        };

        return producerCommissionsAPIService.updateProdCom(this.commissionModel.id, updates, this.$router);
      }
    }
  }
</script>

<style scoped>

</style>