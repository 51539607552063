<template>
  <div>
    <v-dialog
      scrollable
      :value="newContactDialog"
      @input="$emit('input', !newContactDialog)"
      width="450"
    >
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          New Contact
        </v-card-title>
        <v-card-text class="pt-2">
          <v-text-field
            v-model="contact.sort"
            outlined
            label="Sort"
            hide-details="auto"
            class="mt-2 font-weight-regular"
          >
          </v-text-field>

          <v-text-field
            v-model="contact.name1"
            outlined
            label="Name 1"
            hide-details="auto"
            :rules="[rules.required]"
            autofocus
            class="mt-2 font-weight-regular"
          >
          </v-text-field>
          <v-text-field
            v-model="contact.name2"
            outlined
            label="Name 2"
            hide-details="auto"
            class="mt-2  font-weight-regular"
          >
          </v-text-field>
          <v-select
            v-model="contactTypes"
            :items="types"
            chips
            class="mt-2"
            label="Type"
            multiple
            outlined
            hide-details="auto"
          />

          <v-text-field
            v-model="contact.address1"
            outlined
            label="Address 1"
            hide-details="auto"
            class="mt-8  font-weight-regular"
          >
          </v-text-field>

          <v-text-field
            v-model="contact.address2"
            outlined
            label="Address 2"
            hide-details="auto"
            class="mt-2 font-weight-regular"
          >
          </v-text-field>
          <div class="d-flex">
            <v-text-field
              v-model="contact.city"
              outlined
              label="City"
              hide-details="auto"
              class="mt-2  font-weight-regular"
            >
            </v-text-field>

            <ListViewer
              v-model="contact.state"
              listname="states"
              nameonly
              outlined
              clearable
              sorttable="short"
              :menuprops="{ maxHeight: 200 }"
              listtitle="State"
              :hide-details="true"
              class="mt-2 mr-1 ml-1 flex-shrink-0 "
              style="width: 125px"
              title
            />

            <v-text-field
              v-model="contact.zip"
              outlined
              label="Zip"
              hide-details="auto"
              class="mt-2  font-weight-regular"
            >
            </v-text-field>
          </div>
          <v-text-field
            v-model="contact.phone"
            outlined
            label="Phone"
            hide-details="auto"
            class="mt-8 font-weight-regular"
          >
          </v-text-field>

          <v-text-field
            v-model="contact.fax"
            outlined
            label="Fax"
            hide-details="auto"
            class="mt-2 font-weight-regular"
          >
          </v-text-field>

          <v-text-field
            v-model="contact.email"
            outlined
            label="Email"
            hide-details="auto"
            class="mt-2 font-weight-regular"
          >
          </v-text-field>

          <v-text-field
            v-model="contact.website"
            outlined
            label="Website"
            hide-details="auto"
            class="mt-2 font-weight-regular"
          >
          </v-text-field>

          <v-text-field
            v-model="contact.fein"
            outlined
            label="FEIN"
            hide-details="auto"
            class="  font-weight-regular mt-2"
          >
          </v-text-field>

          <v-textarea
            v-model="contact.note"
            hide-details="auto"
            outlined
            label="Note"
            class="mt-2 font-weight-regular"
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions class="mr-8">
          <v-spacer></v-spacer>

          <v-btn @click="$emit('update:new-contact-dialog', false), clearContact()">
            cancel
          </v-btn>
          <v-btn color="secondary" :loading="newContactLoading" @click="onNewContactDialogSubmit()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <success-snackbar :value="newContactSuccess" :message="successMessage" />
    <v-snackbar color="error" v-model="newContactError" style="top: 80px;" top>
      Error: {{ newContactResponse }}
      <v-btn color="white" @click="newContactError = false" text>Close</v-btn>
    </v-snackbar>
    <v-snackbar color="error" v-model="newContactBadInput" style="top: 80px;" top>
      Error: "Name 1" field is not filled out and is required.
      <v-btn color="white" @click="newContactBadInput = false" text>Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
import ListViewer from "@/components/ListViewer";
import SuccessSnackbar from "../../SuccessSnackbar";

const contactAPIService = new ContactAPIService();

export default {
  name: "NewContactForm",
  components: { ListViewer, SuccessSnackbar },
  props: ["refresh", "newContactDialog", "callback"],
  data() {
    return {
      contact: {
        fein: "",
        name1: "",
        name2: "",
        sort: "",
        type: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        fax: "",
        email: "",
        website: "",
        note: ""
      },
      types: [
        "Adjuster",
        "Association",
        "Broker",
        "Government",
        "Insurer",
        "Panel",
        "Producer",
        "Underwriter",
        "Vendor",
        "BOP",
        "E&O",
        "D&O",
        "PL",
        "WCP"
      ],
      newContactLoading: false,
      newContactError: false,
      newContactBadInput: false,
      newContactSuccess: false,
      newContactResponse: {},
      rules: {
        required: value => !!value || "Required."
      }
    };
  },

  computed: {
    contactTypes: {
      get() {
        return "";
      },
      set(val) {
        this.contact.type = "~" + val.join("~");
        return val;
      }
    },
    successMessage() {
      return 'New contact: "' + this.newContactResponse.name1 + '"  successfully added.';
    }
  },

  methods: {
    clearContact() {
      for (const key in this.contact) this.contact[key] = "";
    },
    onNewContactDialogSubmit() {
      if (this.contact.name1 !== "") {
        this.newContactLoading = true;
        this.contact.stamp = new Date().toUTCString();
        contactAPIService
          .createContact(this.contact, this.$router)
          .then(res => {
            console.log(res);
            this.newContactResponse = res;
            this.$emit("update:refresh", true); //refreshes the contacts table after submit
            this.$emit("view-contacts");
            this.newContactSuccess = true;
          })
          .catch(err => {
            this.newContactResponse = err;
            this.newContactError = true;
          });
        this.clearContact();
        this.$emit("update:new-contact-dialog", false); // updates the state of the dialog of the parent so that it closes
        this.newContactLoading = false;
      } else {
        this.newContactBadInput = true;
      }
    }
  }
};
</script>

<style scoped>
.v-autocomplete.v-select.v-input--is-focused input #states {
  min-width: 25px !important;
}

.v-autocomplete.v-select.v-input #states {
  min-width: 25px !important;
}
</style>
