import CommonAPIService from './CommonHandler';

export class StatesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getStates(router) {
    const url = '/api/nu-states';
    return this.CommonAPIService.getCall(url, router);
  }
}

export default StatesAPIService;