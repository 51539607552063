import CommonAPIService from './CommonHandler';

export class TaxesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createTax(taxData, router) {
    const url = '/api/taxes';
    return this.CommonAPIService.postCall(url, taxData, router);
  }

  deleteTax(id, router) {
    const url = `/api/taxes/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getRoundingOptions(router) {
    const url = '/api/taxes/roundings';
    return this.CommonAPIService.getCall(url, router);
  }

  getTaxes(router) {
    const url = `/api/taxes`;
    return this.CommonAPIService.getCall(url, router);
  }

  getTaxesByDate(date, router) {
    const url = `/api/taxes?date=${date}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getTaxesByPeriod(date1, date2, router) {
    const url = `/api/taxes?period=${date1},${date2}`;
    return this.CommonAPIService.getCall(url, router);
  }

  removeTaxEndDate(id, router) {
    const url = `/api/taxes/${id}`;
    return this.CommonAPIService.putCall(url, {end_date: null}, router);
  }

  setTaxEndDate(id, endDate, router) {
    const url = `/api/taxes/${id}`;
    return this.CommonAPIService.putCall(url, {end_date: endDate}, router);
  }
}

export default TaxesAPIService;