<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="createTax"
                 max-width="1000" title="Configure Tax Rate" :disabled="!valid">
    <v-form v-model="valid">
      <v-row>
        <v-col class="dense" lg="2">
          <v-text-field v-model="entity" label="Entity" :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col class="dense" lg="2">
          <v-text-field v-model="rate" label="Rate" :rules="[rules.numeric, rules.positive]"></v-text-field>
        </v-col>
        <v-col class="dense" lg="2">
          <v-text-field v-model="flat" label="Flat" :rules="[rules.numeric, rules.positive]"></v-text-field>
        </v-col>
        <v-col class="dense" lg="6">
          <v-autocomplete v-model="fees" :items="feeOptions" item-disabled="disabled" multiple clearable
                          label="Taxed Fees"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="dense" lg="2">
          <rounding-select v-model="round" :rules="[rules.required]"></rounding-select>
        </v-col>
        <v-col class="dense" lg="2">
          <v-checkbox v-model="admitted" label="Admitted"></v-checkbox>
        </v-col>
        <v-col class="dense" lg="2">
          <v-select v-model="field" :items="['DOI', 'SLA']" label="Field" disabled></v-select>
        </v-col>
        <v-col class="dense" lg="3">
          <date-picker-text-field v-model="startDate" picker-color="secondary" input-color="primary" bottom
                                  offset-y label="Start Date" :rules="[rules.required, rules.date]"></date-picker-text-field>
        </v-col>
      </v-row>
    </v-form>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import ActionDialog from "../../ActionDialog";
  import RoundingSelect from "./RoundingSelect";
  import DatePickerTextField from "../../DatePickerTextField";
  import { TaxesAPIService } from "@/servicehandlers/TaxesAPIService";

  const taxesAPIService = new TaxesAPIService();

  export default {
    name: "UpdateTaxDialog",
    components: {DatePickerTextField, RoundingSelect, ActionDialog},
    props: {
      taxData: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    watch: {
      fees(fees) {
        if (fees && fees.includes('*')) {
          for (let fee of this.feeOptions) {
            if (fee.value === '*') continue;
            fee.disabled = true;
          }
        } else if (fees && fees.length > 0) {
          for (let fee of this.feeOptions) {
            if (fee.value !== '*') continue;
            fee.disabled = true;
          }
        } else {
          for (let fee of this.feeOptions) {
            fee.disabled = false;
          }
        }
      },
      taxData(tax) {
        this.admitted = tax.admitted;
        this.entity = tax.entity;
        this.fees = tax.fees;
        this.field = tax.field;
        this.flat = tax.flat;
        this.rate = tax.rate;
        this.round = tax.round;
        this.startDate = tax.start_date;
      }
    },
    data: (vm) => ({
      admitted: false,
      entity: '',
      fees: [],
      feeOptions: [
        {text: 'All', value: '*', disabled: false},
        {text: 'MGA 1st', value: 'depfee', disabled: false},
        {text: 'MGA 2nd', value: 'mgafee', disabled: false},
        {text: 'Producer', value: 'producer', disabled: false},
        {text: 'Sub Producer', value: 'subfee', disabled: false},
        {text: 'Source', value: 'srcfee', disabled: false},
        {text: 'Sponsor', value: 'spnfee', disabled: false},
      ],
      field: '',
      flat: '',
      rate: '',
      round: '',
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        numeric: (x) => (!x || !isNaN(parseInt(x))) || 'Must be a numeric value.',
        positive: (x) => (!x || x > 0) || 'Must be a positive number.',
        required: (x) => !!x || 'This field is required.',
      },
      startDate: '',
      valid: true
    }),
    methods: {
      createTax() {
        const taxData = {
          state: this.taxData.state,
          entity: this.entity,
          rate: this.rate,
          flat: this.flat,
          taxed_fees: this.fees ? this.fees : [],
          round: this.round,
          admitted: this.admitted,
          field: this.field,
          start_date: this.startDate
        };

        taxesAPIService.createTax(taxData, this.$router)
          .then(() => {
            this.$emit('input', !this.value);
            this.$emit('updated');
          })
      }
    }
  }
</script>

<style scoped>

</style>