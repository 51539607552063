<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @confirm="save" @close="$emit('input', !value)"
                 confirm-text="save" max-width="500">
    <v-container fluid class="mt-n4 mb-n2 py-0">
<!--      {{userPrefs}}-->
      ...under construction -- partial functionality so far...
      <v-row row class="lowerLabel d-flex justify-space-around">
          <v-checkbox color="accent1" v-model="userPrefs.noTooltips" label="No Hover Tips"
                      hide-details></v-checkbox>
<!--          <v-checkbox color="accent1" v-model="userPrefs.noPopups" label="No Popup Windows"-->
<!--                      hide-details></v-checkbox>-->
          <v-checkbox color="accent1" v-model="userPrefs.dense" label="Dense Tables"
                    hide-details></v-checkbox>
      </v-row>
      <v-row>
        <v-col cols="6">Home
          <v-radio-group class="mb-n4 mt-n1" v-model="userPrefs.home">
            <v-radio class="my-n1" label="Firms" value="firms"></v-radio>
            <v-radio class="my-n1" label="Claims" value="claims"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6">Search
          <v-radio-group class="mb-n4 mt-n1" v-model="userPrefs.searchAutoSetting">
            <v-radio class="my-n1" label="Auto Clear" value="clear"></v-radio>
            <v-radio class="my-n1" label="Auto Save" value="save"></v-radio>
          </v-radio-group>
        </v-col>
    </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-menu right nudge-right :close-on-content-click="false" v-for="(color, index) in colors" :key="index">
      <template v-slot:activator="{ on }">
        <v-text-field v-model="color.value" :label="color.label" v-on="on">
          <template v-slot:append>
            <v-icon :color="color.value">mdi-circle</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-card>
        <v-card-text>
          <v-color-picker v-model="color.value" show-swatches></v-color-picker>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-btn @click="reset">reset</v-btn>
<!--    <div class="headline px-3 pt-1 pb-n1">Density</div>-->
  </action-dialog>
</template>

<script>
  import ActionDialog from "./ActionDialog";
  export default {
    name: "ThemeEditor",
    components: {ActionDialog},
    props: {
      value: {
        required: true,
      }
    },
    watch: {
      theme: function (val) {
        if (val) {
          let theme = JSON.parse(val);

          this.colors.forEach(function (color) {
            switch (color.color) {
              case 'primary':
                color.value = theme.primary ;
                break;

              case 'secondary':
                color.value = theme.secondary;
                break;

              case 'accent':
                color.value = theme.accent;
                break;

              case 'background':
                color.value = theme.background;
                break;
            }
          });
        }
      }
    },
    data: function () {
      return {
        userPrefs: {
          noTooltips: false,
          // noPopups: false,//might not be needed
          dense: false,
          home: 'Firms',
          searchAutoSetting: 'Save',
        },
        colors: [
          {color: 'primary', label: 'Primary', value: ''},
          {color: 'secondary', label: 'Secondary', value: ''},
          {color: 'accent', label: 'Accent', value: ''},
          {color: 'background', label: 'Background', value: ''}
        ],
        original: {},
      }
    },
    computed: {
      theme: function () {
        // return this.$root.state.user.theme;
      }
    },
    created() {
      this.original = {...this.$vuetify.theme.themes.light};
      let vm = this;

      this.colors.forEach(function (color) {
        switch (color.color) {
          case 'primary':
            color.value = vm.original.primary ;
            break;

          case 'secondary':
            color.value = vm.original.secondary;
            break;

          case 'accent':
            color.value = vm.original.secondary;
            break;

          case 'background':
            color.value = vm.original.background;
            break;
        }
      });
    },
    methods: {
      save: function () {
        this.$store.dispatch('SET_USER_PREFERENCES', this.userPrefs);

        let light = this.$vuetify.theme.themes.light;

        this.colors.forEach(function (color) {
          switch (color.color) {
            case 'primary':
              light.primary = color.value;
              break;

            case 'secondary':
              light.secondary = color.value;
              break;

            case 'accent':
              light.secondary = color.value;
              break;

            case 'background':
              light.background = color.value;
              break;

          }
        });

        // this.$axios.put('/api/v1/users/' + this.$root.state.user.id, {
        //   UserUpdates: {
        //     custom_theme: JSON.stringify(light)
        //   }
        // });
        this.$emit('input', !this.value);
      },
      reset: function () {
        let vm = this;

        this.colors.forEach(function (color) {
          switch (color.color) {
            case 'primary':
              color.value = vm.original.primary ;
              break;

            case 'secondary':
              color.value = vm.original.secondary;
              break;

            case 'accent':
              color.value = vm.original.secondary;
              break;

            case 'background':
              color.value = vm.original.background;
              break;
          }
        });

        this.$vuetify.theme.themes.light = {...this.original};
        this.$axios.put('/api/v1/users/' + this.$root.state.user.id, {
          UserUpdates: {
            custom_theme: null
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
