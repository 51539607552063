<template>
  <v-autocomplete :value="value" @input="$emit('inupt', $event)" :items="placements" item-value="short" item-text="short"
                  v-bind="options"></v-autocomplete>
</template>

<script>
  import { ListsAPIService } from "@/servicehandlers/ListsAPIService";

  const listsAPIService = new ListsAPIService();
  export default {
    name: "PolicyPlacementsSelect",
    props: {
      options: {
        type: Object,
        default: () => ({})
      },
      value: {
        required: true,
      }
    },
    data: () => ({
      placements: []
    }),
    created() {
      listsAPIService.getPolicyPlacements(this.$router)
        .then(placements => {
          this.placements = placements
        })
    }
  }
</script>

<style scoped>

</style>