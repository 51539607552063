import CommonAPIService from './CommonHandler';

export class PoliciesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  addPolicy(policyData, router) {
    const url = '/api/policies';
    return this.CommonAPIService.postCall(url, policyData, router);
  }

  calculateTax(pid, state, admitted, router) {
    const url = `/api/policies/${pid}/taxes?state=${JSON.stringify(state)}&admitted=${admitted}`;
    return this.CommonAPIService.getCall(url, router);
  }

  calculateLegacyFees(premium, mgaFirstFee, mgaSecondFee, router) {
    const url = `/api/policies/fees?formula=legacy&premium=${premium}&mgaFirstFee=${mgaFirstFee}&mgaSecondFee=${mgaSecondFee}`;
    return this.CommonAPIService.getCall(url, router);
  }

  calculateDISIwFees(premium, router) {
    const url = `/api/policies/fees?formula=DISIw&premium=${premium}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPolicy(pid, router) {
    const fields = 'bndreq,booked,bound,bstamp,canceled,cancellationcode,carrier,contract,deduc,depfee,docs,doifee,' +
      'doifee,down,dtype,expiry,features,fees,fid,finnum,' +
      'forms,grpnum,incept,insured,is_new,issued,limits,ltype,mgafee,pid,polnum,ppid,premium,prid,prodname,profee,quoted,' +
      'retro,sales,slafee,spnfee,srcfee,status,subfee,submitted,taxes,total,type,zip,note';
    const url = `/api/policies/${pid}?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPolicyFiles(pid, router) {
    const fields = 'loid,loidfid,file_description,filename,size';
    const url = `/api/policies/${pid}/files?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPolicies(router) {
    const url = '/api/policies';
    return this.CommonAPIService.getCall(url, router);
  }

  updateFiles(id, loids, router) {
    const url = `/api/policies/${id}/files`;
    return this.CommonAPIService.putCall(url, loids, router);
  }

  getBookedPolicies(router) {
    const fields = 'type,carrier,incept,expiry,retro,limits,deduc,premium,taxes,fees,total';
    const url = `/api/policies?fields=${fields}&status=Book&status=Lost&status=Past&status=dncLost`;
    return this.CommonAPIService.getCall(url, router);
  }

  getContracts(search, router) {
    const url = '/api/contracts';
    return this.CommonAPIService.postCall(url, search, router);
  }

  getCommissions(pid, router) {
    const url = `/api/policies/${pid}/commissions`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  updatePolicy(pid, updates, router) {
    const url = `/api/policies/${pid}`;
    return this.CommonAPIService.putCall(url, updates, router);
  }

  deletePolicy(pid, router) {
    const url = `/api/policies/${pid}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  // updateGroupNum API takes 2 PIDs, if the grpnum for both policies are zero, then assign both policies
  // the same unique grpnum (update both policies with new grpnum), if one is nonzero
  // then update the nonzero grpnum to match the other grpnum.
  // prevent on front end the case where both policies have a non-zero grpnum
  updateGroupNum(pid1, pid2, router) {
    const url = `/api/policies/${pid1}/group?pid=${pid2}`;
    return this.CommonAPIService.putCall(url, null, router);
  }

 updateGroupNumByPids(pid1, pid2, router) {
    const url = `/api/policies/${pid1}/group?pid=${pid2}`;
    return this.CommonAPIService.putCall(url, null, router);
  }

  updateMatchingGroups(grpnum, fid, prid, updateData, router) {
    const url = `/api/policies/group/${grpnum}?fid=${fid}&prid=${prid}`;
    return this.CommonAPIService.putCall(url, updateData, router);
  }

  getRenewals(router) {
    const fields = 'sort,pid,fid,carrier,is_new,type,policies.status,firms.status,expiry,retro,producer,solicitor,agent,premium';
    const url = `/api/policies/renewals?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default PoliciesAPIService;
