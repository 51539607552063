<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @confirm="removeEndDate" @close="$emit('input', !value)"
                 title="Remove end date" max-width="500" confirm-text="Remove Date" bottom-divider>
    <info-alert v-if="!error" class="mt-4">
      This should only be done on the most recent commission rate. After the date is removed, the selected commission
      rate will become the current commission rate.
    </info-alert>
    <v-alert v-if="error" type="error" text>{{ error }}</v-alert>
    <loading-overlay absolute v-model="loading" @close="loading = false"></loading-overlay>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import CommissionsAPIService from "@/servicehandlers/CommissionsAPIService";
  import ActionDialog from "../../ActionDialog";
  import InfoAlert from "../../InfoAlert";
  import LoadingOverlay from "../../LoadingOverlay";
  import ErrorAlert from "../../ErrorAlert";

  const commissionsAPIService = new CommissionsAPIService();
  export default {
    name: "RemoveEndDateDialog",
    components: {ErrorAlert, LoadingOverlay, InfoAlert, ActionDialog},
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    data: () => ({
      error: '',
      loading: false,
    }),
    methods: {
      removeEndDate() {
        this.loading = true;
        commissionsAPIService.removeComEndDate(this.id, this.$router)
          .then(() => {
            this.$emit('updated');
            this.$emit('input', !this.value);
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            if (error.response.data.status === 'INVALID_PARAMS') {
              this.error = `This is not the most recent commission rate for this rule. Please try again with the most recent
              commission rate.`;
              return;
            }
            this.error = 'An error occurred. Please try again, or contact development if the error persists.'
          })
      }
    }
  }
</script>

<style scoped>

</style>