<template>
  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
    <v-card class="pb-3">
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <v-text-field class="mb-8" @input="emit" :key="increasingNumber" :label="fields[0].name" reverse
                      :rules="[rules.amt]" v-model.lazy="clm[fields[0].value]" v-money="money"/>
        <v-text-field class="mb-8" @input="emit" :label="fields[1].name" reverse :rules="[rules.amt]"
                      v-model.lazy="clm[fields[1].value]" v-money="money"/>
        <v-text-field class="mb-8" @input="emit" :label="fields[2].name" reverse :rules="[rules.amt]"
                      v-if="fields[2].value" v-model.lazy="clm[fields[2].value]" v-money="money"/>
        <v-text-field label="Remaining Reserve" readonly reverse style="margin-bottom: 118px"
                      v-if="fields[2].value === ''" v-model="remaining"/>
        <v-text-field label="Remaining Reserve" readonly reverse v-else v-model="remaining"
                      style="margin-bottom: 20px"/>
      </v-card-text>
    </v-card>
  </v-col>

</template>

<script>
  import currency from 'currency.js';
  import {VMoney} from 'v-money'

  export default {
    name: "AmountCard",
    props: {
      clmProp: Object,
      title: String,
      fields: Array,
      default() {
        return {};
      }
    },
    directives: {money: VMoney},
    data() {
      return {
        money: {
          decimal: '.',
          thousands: ',',
          prefix: '$',
          suffix: '',
          precision: 2,
        },
        clm: this.clmProp,
        changed: false,
        increasingNumber: 1,
        rules: {
          amt: (x) => (!x || parseFloat(x.replace(/[$|,]/g,'')) >= 0 ) || 'Must be greater than or equal to 0',
        },
      };
    },

    computed: {
      remaining() {
        return this.sub2(this.clm[this.fields[0].value], this.clm[this.fields[1].value],
          this.clm[this.fields[2].value]);
      },
    },

    methods: {
      zero() {
        let fld = this.fields;// rewritten with currency.js: set reserve = incurred + current (so remaining reserve = 0)
        this.clm[fld[0].value] = currency(this.clm[fld[1].value]).add(this.clm[fld[2].value]).format();
        this.increasingNumber += 1; // key to update field
        this.$emit('input', this.clm);
      },

      emit (value) {
        this.$emit('input', this.clm);
      },

      sub2 (a,b,c) {
        let diff = currency(a).subtract(b).subtract(c);// rewritten with currency.js
        return diff < 0 ? '--' : diff.format();
      },
    }

}
</script>
<style>
</style>

