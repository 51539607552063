import CommonAPIService from './CommonHandler';

export class GroupsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getUsers(router) {
    // return the full list of users
    const url = '/api/users';
    return this.CommonAPIService.getCall(url, router);
  }

  getScopes(router) {
    // return the full list of users
    const url = '/api/scopes';
    return this.CommonAPIService.getCall(url, router);
  }

  getGroups(search, router) {
    const url = '/api/authgroups';
    return this.CommonAPIService.getCall(url, router);
  }
}

export default GroupsAPIService;
