<template>
  <v-dialog :value="value" @input="$emit('input', $event)" :max-width="windowOptions[step].width">
    <v-card class="mx-auto">
      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>{{ windowOptions[step].title }}</span>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <v-form v-model="windowOptions[step].valid">
              <v-row class="mb-2 mt-n2">
                <v-col class="dense">
                  <date-type-select v-model="startDateType" :options="{label: 'Date',
                                prependInnerIcon: 'mdi-calendar-start', rules: [rules.required]}"></date-type-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="dense">
                  <date-picker-text-field v-model="startDate" label="After" picker-color="secondary"
                                          prepend-inner-icon="mdi-calendar-month" input-color="primary"
                                          :rules="[rules.required, rules.date]"></date-picker-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <v-data-table  v-model="comsToKeep" :items="commissions" :headers="headers" :mobile-breakpoint="0"
                           multi-sort hide-default-footer show-select :sort-by="['mga_name', 'carrier', 'condition']">
              <template v-slot:item.types="{ item }">
                {{ item.types ? item.types.join(', ') : '' }}
              </template>
              <template v-slot:item.renewal="{ item }">
                {{ formatRenewal(item.renewal) }}
              </template>
              <template v-slot:item.commission="{ item }">
                {{ item.commission }}%
              </template>
              <template v-slot:item.start_date="{ item }">
                  <span class="grey--text text--darken-1">
                    {{ item.start_date_type.charAt(0).toUpperCase() + item.start_date_type.slice(1) }} after
                  </span>
                {{ item.start_date }}
              </template>
            </v-data-table>

            <v-row class="mt-6">
              <v-col class="dense">
                <span class="text-caption grey--text text--darken-1">Commission rates that are not selected will be
                  terminated on the new effective date.</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text class="mt-n8">
            <v-card v-for="(com, index) in comsToKeep" :key="com.id" class="my-8">
              <v-row v-if="comsCount > 1">
                <v-col>
                  <v-avatar color="secondary" class="white--text font-weight-bold ml-2" size="35">
                    {{ index + 1 }}/{{ comsCount }}
                  </v-avatar>
                </v-col>
              </v-row>
              <commission-form :commission="com" :ref="`form${index}`" @valid="validate" update :start-date="startDate"></commission-form>
            </v-card>

            <v-card v-for="(com, index) in comsToCreate" :key="`create${index}`" class="my-8">
              <v-row v-if="comsCount > 1">
                <v-col>
                  <v-avatar color="secondary" class="white--text font-weight-bold ml-2" size="35">
                    {{ index + comsToKeep.length + 1 }}/{{ comsCount }}
                  </v-avatar>
                </v-col>
                <v-col>
                  <v-row justify="end">
                    <v-btn class="mr-6" icon>
                      <v-icon @click="comsToCreate = comsToCreate.filter(comToCreate => comToCreate !== com)">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <commission-form :commission="com" :ref="`form${index + comsToKeep.length}`" @valid="validate" :start-date="startDate"></commission-form>
            </v-card>

            <v-row>
              <v-col class="dense">
                <v-btn @click="addCommission" color="secondary">New Commission</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">

        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn :disabled="step === 1" text @click="step--">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', !value)" color="grey">
          Close
        </v-btn>
        <v-btn :disabled="!windowOptions[step].valid" color="secondary" @click="windowOptions[step].action">
          {{ windowOptions[step].buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <loading-overlay v-model="loading" absolute></loading-overlay>
  </v-dialog>
</template>

<script>
  import { CommissionsAPIService } from "@/servicehandlers/CommissionsAPIService";
  import CommissionForm from "./forms/CommissionForm";
  import moment from 'moment';
  import DatePickerTextField from "../../DatePickerTextField";
  import DateTypeSelect from "../../DateTypeSelect";
  import LoadingOverlay from "../../LoadingOverlay";

  const commissionsAPIService = new CommissionsAPIService();
  export default {
    name: "EditAllDialog",
    components: {LoadingOverlay, DateTypeSelect, DatePickerTextField, CommissionForm},
    props: {
      commissions: {
        required: true,
        type: Array
      },
      value: {
        required: true,
        type: Boolean
      }
    },
    computed: {
      comsToTerminate() {
        const comsToKeep = this.comsToKeep.map(com => JSON.stringify(com));
        return this.commissions.filter(com => !comsToKeep.includes(JSON.stringify(com)));
      },
      comsCount() {
        return this.comsToKeep.length + this.comsToCreate.length;
      }
    },
    data: (vm) => ({
      comsToCreate: [],
      comsToKeep: [],
      headers: [
        {text: 'MGA', value: 'mga_name'},
        {text: 'Carrier', value: 'carrier'},
        {text: 'Types', value: 'types'},
        {text: 'Placement', value: 'renewal', sortable: false},
        {text: 'Condition', value: 'condition'},
        {text: 'Commission', value: 'commission'},
        {text: 'Start Date', value: 'start_date'},
        {text: '', value: 'data-table-select'}
      ],
      loading: false,
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        required: (x) => !!x || 'This field is required.',
      },
      startDate: moment().format('YYYY-MM-DD'),
      startDateType: '',
      step: 1,
      windowOptions: {
        '1': {
          action: () => { vm.step++ },
          buttonText: 'Next',
          title: 'When will these changes take effect?',
          valid: true,
          width: 400
        },
        '2': {
          action: () => {
            vm.step++;
            if (!vm.comsToKeep.length) vm.addCommission();
          },
          buttonText: 'Next',
          title: 'Select which rates you want to change/keep.',
          valid: true,
          width: 1100
        },
        '3': {
          action: vm.confirm,
          buttonText: 'Confirm',
          title: 'Configure new commission rates',
          valid: true,
          width: 900
        },
      }
    }),
    methods: {
      addCommission() {
        this.comsToCreate.push({mga_id: this.commissions[0].mga_id, types: null})
      },
      validate() {
        this.windowOptions['3'].valid = Object.keys(this.$refs).reduce((valid, ref) => valid && this.$refs[ref][0].valid, true);
      },
      formatRenewal(renewal) {
        switch(renewal) {
          case true: return 'Renewal';
          case false: return 'New Business';
          default: return '';
        }
      },
      async confirm() {
        this.loading = true;
        for (const com of this.comsToTerminate) {
          await commissionsAPIService.setComEndDate(com.id, this.startDate, this.$router);
        }
        for (const form in this.$refs) {
          if (!await this.$refs[form][0].createCommission()) {
            this.loading = false;
            return;
          }
        }
        this.$emit('created');
        this.$emit('input', !this.value);
        this.loading = false;
      }
    }
  }
</script>

<style scoped>

</style>