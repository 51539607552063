<template>
  <div v-can="'firm-file-upload'">
    <v-tooltip class="ml-n2 pl-0 pr-2" nudge-bottom="10" style="z-index: 99" top>
      <template v-slot:activator="{ on }">
        <v-btn class="px-3 ml-2 mr-n1"
               @click="filesToUpload = [], $refs.file.value = '',$refs.file.click()"
               color="secondary" elevation="5" fab v-on="on" x-small><v-icon>mdi-plus</v-icon>
        </v-btn>
        <input
          @input="nameFiles"
          multiple
          ref="file"
          style="display: none"
          type="file"
        >
      </template>
      <span>Upload File/s</span>
    </v-tooltip>

    <v-dialog v-model="fileDescriptionDialog" max-width="900" persistent>
      <v-card class="mt-3">
        <v-card-title class="pl-5">{{ filesToUpload.length ? 'Enter Description/s' : 'File/s' }}</v-card-title>
        <v-card-actions class="pl-5 pr-1 pt-0 mt-n11 mr-1 mb-3" :key="refresh">
          <v-spacer></v-spacer>
          <v-btn class="mr-n3 mr-sm-1" @click="uploadFile" color="secondary darken-3" :disabled="!filesToUpload.length"
                 :loading="uploading" style="margin-top: 1.5px" text>
            Upload
          </v-btn>
          <v-btn color="secondary darken-3" text icon @click="missingFieldDialog = false, fileDescriptionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="px-2 px-sm-4 pb-1">
          <v-row class="caption error--text" v-if="filesWithoutExtensions.length">
            <v-col cols="7">The file/s in red will not be uploaded because there is no file extension:</v-col>
            <v-col cols="5">
              <div v-for="fle in filesWithoutExtensions">{{ fle.name }}</div>
              <br>
              <br>
            </v-col>
          </v-row>
          <div v-for="fle in filesToUpload">
            <v-row>
              <v-col class="mt-8 mb-n3 mt-sm-1 mb-sm-1" cols="12" sm="3" md="2">
                <v-select
                  class="mt-n3"
                  :class="{'caption':$vuetify.breakpoint.xs}"
                  dense
                  :items="types"
                  label="Type"
                  v-model="fle.f_type"
                />
              </v-col>
              <v-col class="mb-8 mb-sm-1" cols="8" sm="6">
                <v-text-field
                  class="mt-n2"
                  :class="{'caption':$vuetify.breakpoint.xs}"
                  clearable
                  dense
                  :error-messages="uploadTriggered && !fle.file_description ? 'Required' : '' "
                  :key="fle.file_description + refresh"
                  label="Description"
                  @keyup.enter="uploadFile"
                  :rules="rules"
                  v-model="fle.file_description">
                  <template v-slot:append-outer>
                    <button-tip @click="copyFilename(fle)" istyle="margin-top: -14px" icolor="secondary" icon
                                iname="mdi-arrow-left-bold" tip="Use filename for description (removes extension)"/>
                  </template>
                </v-text-field>
              </v-col>
                <v-col class="px-0 ml-n3 mt-n1" cols="4" sm="3" md="4">
                <span class="caption">{{ fle.name }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ActionDialog :value="missingFieldDialog" @input="$emit('input', $event)" max-width="700"
                  title="Need Description/s" confirm-text="Ok" confirm-color="red lighten-2"
                  :second-button="false" @confirm="missingFieldDialog = false">
      <v-divider color="red"></v-divider>
      Please fill <strong>file description</strong> field/s.
    </ActionDialog>

    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='FirmFilesUpload'"></snack>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
  import ActionDialog from '../../ActionDialog';
  import ErrorDialog from "../../ErrorDialog";
  import { FileobjectsAPIService } from '@/servicehandlers/FileobjectsAPIService';

  const file_objects = new FileobjectsAPIService();

  export default {
    name: 'FirmFilesUpload',
    components: { ErrorDialog, ActionDialog },
    props: ['fid'],
    data: () => ({
      fileDescriptionDialog: false,
      filesToUpload: [],
      filesUploaded: [],
      filesWithoutExtensions: [],
      missingFieldDialog: false,
      refresh: false,
      rules: [
        (v) => !!v || 'Required',
      ],
      types: [
        {value: 1, text: 'Admin'},
        {value: 3, text: 'Form'},
        {value: 6, text: 'Other'},
        {value: 17, text: 'Original'}
      ],
      uploading: false,
      uploadTriggered: false,
    }),

    methods: {
      copyFilename(file) {
        file.file_description = file.name.replace( /\.[^/\\.]+$/, '');// remove file extension
        this.refresh = !this.refresh;
      },

      nameFiles(e) {// move files w/ missing extensions to this.filesWithoutExtensions, then open fileDescriptionDialog
        // methods ".splice()" & ".some()" need an actual array, e.target.files is array-like obj, so convert to array
        this.uploadTriggered = false;
        this.filesToUpload = [...e.target.files];
        this.filesWithoutExtensions = [];
        for (let i = 0; i < this.filesToUpload.length; i++) {
          this.filesToUpload[i].f_type = 6;// set default to 6 ('Other')
          if (!this.$_hasExtension(this.filesToUpload[i].name)) {
            this.filesWithoutExtensions.push(this.filesToUpload[i]);
            this.filesToUpload.splice(i, 1);
            i--;
          }
        }
        this.fileDescriptionDialog = true;
      },

      saveFileDescriptions() {// file_descriptions & file_type_description have a separate api
        let count = 1;//count how many descriptions to update
        for (let f of this.filesToUpload) {
          // find file, update description & f_type in this.filesUploaded and send to database, & after last file, emit
          let file = this.filesUploaded.find(x => x.filename === f.name);
          file.file_description = f.file_description;
          file.file_type = f.f_type;
          const file_object = {file_description: f.file_description, file_type: f.f_type};
          file_objects.updateFileObject(file.loid, file_object, this.$router).then((response) => {
            if (this.filesToUpload.length === count) {// if last one then emit
              let msg = `File${this.filesToUpload.length > 1 ? 's' : ''} uploaded successfully`;
              this.$store.dispatch('SET_SNACK', {cmpnt: 'FirmFilesUpload', msg: msg, open: true});
              this.$emit('filesUploaded', this.filesUploaded);
            } else {
              count++;
            }
          })
          .catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Error saving type and description for uploaded file/s",
              forDevelopers: e
            })
          });
        }
      },

      uploadFile(e) {
        setTimeout(() => { this.uploadTriggered = true }, 500);// show missing description error/s
        if (!this.filesToUpload.length) return; // If there are no files to upload, exit the function immediately.
        this.missingFieldDialog = this.filesToUpload.some(file => !file.file_description);
        if (this.missingFieldDialog) return;
        let formData = new FormData();
        for (const file of this.filesToUpload) formData.append('domfiles', file);
        this.uploading = true;
        file_objects.uploadFileToFirm(formData, this.fid, this.$router).then(response => {
          if (!response.data) {
            this.$refs.errorDialog.showError({
              errorMessage: "Error uploading file/s",
              forDevelopers: response
            })
          }
          else {
            this.filesUploaded = response.data;
            this.saveFileDescriptions();
          }
          this.fileDescriptionDialog = false;
          this.uploading = false;
        })
        .catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Error uploading file/s",
            forDevelopers: e
          })
          this.uploading = false;
          this.fileDescriptionDialog = false;
        });
      }

    },
  };
</script>

<style>

</style>



