<template>
  <v-autocomplete :value="value" @input="$emit('input', $event)" :items="dateTypes" v-bind="options"></v-autocomplete>
</template>

<script>
  export default {
    name: "DateTypeSelect",
    props: {
      options: {
        default: () => ({})
      },
      value: {
        required: true
      }
    },
    data: () => ({
      dateTypes: [
        {text: 'Incept', value: 'incept'},
        {text: 'Expiry', value: 'expiry'},
        {text: 'Quoted', value: 'quoted'},
        {text: 'Booked', value: 'booked'},
        {text: 'Bound', value: 'Bound'}
      ]
    }),
  }
</script>

<style scoped>

</style>