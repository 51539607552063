<template>
  <div v-if="listname === 'states'">
    <v-autocomplete style="font-weight: 400;" @input="selected" :disabled="disabled" :dense="dense"
                    v-bind:id="this.listname" v-bind:name="this.listname" :readonly="this.readonly"
                    v-bind:value="value" :items="listitems" :clearable="clearable"
                    :menu-props="menuprops" :outlined="outlined" :label="this.listtitle" :hint="hint" :hide-details="hideDetails"
    ></v-autocomplete>

  </div>
  <div v-else>
    <v-autocomplete style="font-weight: 400;"  @input="selected" v-bind:name="value" :dense="dense"
                    :label="(this.title ? this.listtitle : '')" :filled="filled" :hide-details="hideDetails"
                    :rules="this.reqrules ? rule : []" :menu-props="menuprops" :clearable="clearable"
                    :items="listitems" v-bind:id="this.listname" v-bind:value="value"
                    :multiple="multiple"  :readonly="readonly" :disabled="disabled"
    ></v-autocomplete>
  </div>
</template>

<script>
  import { ListsAPIService } from '../servicehandlers/ListsAPIService';

  const apiService = new ListsAPIService();

  export default {
    name: 'ListViewer',
    components: {},
    data() {
      return {
        listitems: [],
      };
    },
    props: {
      clearable: {
        type: Boolean,
        default() {
          return false;
        }
      },
      outlined: {
        type: Boolean,
        default() {
          return false;
        }
      },
      dense: {
        type: Boolean,
        default() {
          return false;
        }
      },
      disabled: {
        type: Boolean,
        default() {
          return false;
        }
      },
      filled: {
        type: Boolean,
        default() {
          return false;
        }
      },
      hideDetails: {
        type: Boolean,
        default() {
          return false;
        }
      },
      hint: {
        type: String,
      },
      listname: {
        type: String,
      },
      listtitle: {
        type: String,
      },
      longonly: {
        type: Boolean,
        default() {
          return false;
        }
      },
      menuprops: {
        type: Object,
      },
      multiple: {
        type: Boolean,
        default() {
          return false;
        }
      },
      nameonly: {
        type: Boolean,
        default() {
          return false;
        }
      },
      readonly: {
        type: Boolean,
        default() {
          return false;
        }
      },
      reqrules: {
        type: Boolean,
        default() {
          return false;
        }
      },
      rule: {
        type: Array,
      },
      sort: {
        type: String,
      },
      sortorder: {
        type: String,
        default() {
          return 'ASC';
        }
      },
      sorttable: {
        type: String,
        default() {
          return 'sort';
        }
      },
      titles: {
        type: Boolean,
        default() {
          return false;
        }
      },
      title: {
        type: Boolean,
        default() {
          return false;
        }
      },
      value: {
        default() {
          return null;
        }
      },
    },
    methods: {
      selected(value) {
        this.$emit('input', value);
      },

      getAllLists() {
        this.listitems = [];
        if (this.listname === 'states') {
          apiService.getList(this.listname,
            this.sort, this.nameonly, this.longonly, this.sortorder, this.sorttable, null, this.$router).then((data) => {
            let all = {
              'text': 'All',
              'value': 'all',
            };
            let firm = {
              'text': 'Firm',
              'value': 'firm',
            };
            let zone = {
              'text': 'Zone',
              'value': 'zone',
            };
            this.listitems = data;
            for (let i = 0; i < this.listitems.length; i += 1) {
              // take empty lines out of dropdown list
              if (!this.listitems[i].text || this.listitems[i].text === ""
                || !this.listitems[i].text === " "
                || !this.listitems[i].text.replace(/\s/g, '').length) {
                this.listitems.splice(i, 1);
                i = i - 1;
              }
            }
            if (this.titles) { // no titles is set to true
              this.listitems.shift();
              this.listitems.unshift(all);
              this.listitems.unshift(zone);
              this.listitems.unshift(firm);
            }

          });
        } else if ( this.listname === 'contacts' ) {
          apiService.getContactList(this.$router).then((data) => {
            let title = {
              'text': this.listtitle,
              // 'text': 'Select a Contact',
              'value': null,
            };
            this.listitems = data;
            for (let i = 0; i < this.listitems.length; i += 1) {
              // take empty lines out of dropdown list
              if (!this.listitems[i].text || this.listitems[i].text === ""
                || !this.listitems[i].text === " "
                || !this.listitems[i].text.replace(/\s/g, '').length) {
                this.listitems.splice(i, 1);
                i = i - 1;
              }
            }
            this.listitems.unshift(title);
          });
        } else if ( this.listname === 'people' ) {
          apiService.getPersonList(this.$router, this.sort).then((data) => {
            let title = {
              // 'text': 'Select a Person',
              'text': this.listtitle,
              'value': null,
            };
            this.listitems = data;
            for (let i = 0; i < this.listitems.length; i += 1) {
              if (!this.listitems[i].text || this.listitems[i].text === ""
                || !this.listitems[i].text === " "
                || !this.listitems[i].text.replace(/\s/g, '').length) {
                this.listitems.splice(i, 1);
                i = i - 1;
              }
            }
            this.listitems.unshift(title);
          });

        } else {
          // console.log('this.sorttable', this.sorttable)
          apiService.getList(this.listname,
            this.sort, this.nameonly, this.longonly, this.sortorder, this.sorttable, null,
            this.$router).then((data) => {
            this.listitems = data;
            if (!!this.listtitle && !this.title) {
              let title = {
                'text': this.listtitle,
                'value': null,
              };
            this.listitems.unshift(title);
            }
            for (let i = 0; i < this.listitems.length; i += 1) {
              if (!this.listitems[i].text || this.listitems[i].text === ""
                || !this.listitems[i].text === " "
                || !this.listitems[i].text.replace(/\s/g, '').length) {
                this.listitems.splice(i, 1);
                i = i - 1;
              }
            }
          });
        }
      },
    },
    mounted() {
      this.listitems = this.getAllLists(this.listname);
    },
  };
</script>

<style scoped>
  .list-horizontal li {
    display: inline-block;
  }

  .list-horizontal li:before {
    content: '\00a0\2022\00a0\00a0';
    color: #999;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
  }

  .list-horizontal li:first-child:before {
    content: '';
  }

</style>
<style>

  .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: rgba(0, 0, 0, 0.5);

  }

</style>
