<template>
  <v-dialog :value="value" @input="$emit('input', $event)"
            class="ml-n2" :max-width="maxWidth" :persistent="persistent">
    <v-card :class="float ? 'pb-12' : ''">
      <v-card-title>
        <slot name="header">
          <h4 class="font-weight-regular"> {{ title }} </h4>
        </slot>

      </v-card-title>
      <v-card-text>
        <v-divider v-if="divider"></v-divider>
        <slot></slot>
      </v-card-text>
      <div :style="float ? { marginRight: '10px', float: 'right', position: 'sticky',
        bottom: 'calc(100% - 60px)'} : ''">
        <v-divider v-if="bottomDivider"></v-divider>
        <v-card-actions>
            <slot name="actions">
              <slot name="leftAction"></slot>
              <v-spacer></v-spacer>
              <v-btn v-if="secondButton" color="grey" text @click="$emit('close')" class="mr-2">
                {{ closeText }}</v-btn>
              <v-btn :class="confirmClass" :color="confirmColor" @click="$emit('confirm')" :disabled="disabled">
                <slot name="confirmButton">
                  {{ confirmText }}
                </slot>
              </v-btn>
            </slot>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ActionDialog",
    props: {
      secondButton: {
        default: true
      },
      closeText: {
        default: 'Close'
      },
      confirmClass: {
        default: ''
      },
      confirmText: {
        default: 'Confirm'
      },
      confirmColor: {
        default: 'secondary'
      },
      float: Boolean,
      disabled: Boolean,
      divider: Boolean,
      bottomDivider: Boolean,
      width: [String, Number],
      maxWidth: [String, Number],
      persistent: Boolean,
      title: String,
      value: Boolean
    },
  }
</script>

<style scoped>

</style>
