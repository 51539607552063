<template>
  <v-autocomplete :value="value" @input="$emit('input', $event)" :items="carriers" item-text="short" item-value="short"
                  v-bind="options"></v-autocomplete>
</template>

<script>
  import { ListsAPIService } from "@/servicehandlers/ListsAPIService";

  const listsAPIService = new ListsAPIService();

  export default {
    name: "CarriersSelect",
    props: {
      options: {
        default: () => ({})
      },
      value: {
        required: true
      }
    },
    data: () => ({
      carriers: []
    }),
    created() {
      listsAPIService.getCarriersList(this.$router)
        .then(carriers => { this.carriers = carriers });
    }
  }
</script>

<style scoped>

</style>