<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="500">
    <v-card class="mx-auto" >
      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>{{ titles[this.step] }}</span>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <v-row>
              <v-col cols="7">
                <v-select v-model="state" :items="states" item-text="name" :item-value="(item) => item" label="State"></v-select>
              </v-col>
              <v-col cols="5">
                <v-checkbox class="level" v-model="admitted" label="Admitted"></v-checkbox>
              </v-col>
            </v-row>
            <span class="text-caption grey--text text--darken-1">
              The state is retrieved from the firm's contact.
            </span>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text v-if="!taxes || !taxes.length">
            <span>There are no applicable taxes for this policy's inception date.</span>
            <br><br><br><br>
            <span class="text-caption grey--text text--darken-1">Tax calculations are only available for policies with
              an inception date after Jan 01, 2021.</span>
          </v-card-text>
          <v-card-text class="mb-8" v-if="taxes && taxes.length" v-for="(tax, index) in taxes" :key="tax.entity">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">Entity</th>
                  <th class="text-left">Rate</th>
                  <th class="text-left">Total</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ tax.entity }}</td>
                <td>{{ tax.rate ? tax.rate : 'Flat fee' }}</td>
                <td>
                  ${{ tax.amount.toFixed(2) }}
                </td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-expansion-panels inset>
              <v-expansion-panel>
                <v-expansion-panel-header class="secondary--text font-weight-bold">
                  Calculation steps for {{ tax.entity }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item two-line v-for="(step, index) in tax.steps" :key="step">
                    <v-list-item-content>
                      <v-list-item-title class="secondary--text font-weight-bold">Step {{ index + 1 }}</v-list-item-title>
                      <p class="text-sm-subtitle-2">{{ step }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="$emit('input', !value)">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="step === 1" text @click="step--">Back</v-btn>
        <v-btn :disabled="step === 2" color="secondary" @click="step++">Next</v-btn>
      </v-card-actions>
    </v-card>
    <loading-overlay v-model="loading" :errors="errors" @close="loading = false"></loading-overlay>
  </v-dialog>
</template>

<script>
  import { FirmsAPIService } from "../../servicehandlers/FirmsAPIService";
  import { PoliciesAPIService } from "../../servicehandlers/PoliciesAPIService";
  import { StatesAPIService } from "../../servicehandlers/StatesAPIService";
  import LoadingOverlay from "../LoadingOverlay";

  const firmsAPIService = new FirmsAPIService();
  const policiesAPIService = new PoliciesAPIService();
  const statesAPIService = new StatesAPIService();

  export default {
    name: "TaxDialog",
    components: {LoadingOverlay},
    props: {
      fid: {
        required: true
      },
      pid: {
        required: true
      },
      value: {
        type: Boolean,
        required: true,
      }
    },
    watch: {
      step(step) {
        if (step === 2) this.calculateTaxes();
      }
    },
    data: () => ({
      admitted: false,
      errors: [],
      loading: false,
      state: '',
      states: [],
      step: 1,
      taxes: [],
      titles: {
        1: 'Confirm the information below',
        2: 'Calculated Rates'
      },
    }),
    created() {
      this.getStates();
    },
    methods: {
      calculateTaxes() {
        this.loading = true;
        policiesAPIService.calculateTax(this.pid, this.state, this.admitted, this.$router)
          .then(taxes => {
            this.taxes = taxes;
            this.loading = false;
          })
          .catch(error => {
            this.errors = [error.message];
          })
      },
      getFirm() {
        firmsAPIService.getSingleFirm(this.fid, this.$router)
          .then((firm) => {
            if (!firm) return;
            this.state = this.states.filter(state => state.code === firm.state)[0];
          });
      },
      getStates() {
        statesAPIService.getStates(this.$router)
          .then(states => {
            this.states = states;
            this.getFirm();
          });
      },
    }
  }
</script>

<style scoped>

</style>
