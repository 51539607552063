import CommonAPIService from './CommonHandler';

export class TranlogAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  deleteTranlog(id, router) {
    const url = `/api/tranlogs/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getTranlog(nid, router) {
    const url = `/api/tranlogs/${nid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  createTranlog(tranlog, router) {
    const url = '/api/tranlogs';
    return this.CommonAPIService.postCall(url, tranlog, router);
  }

  updateTranlog(id, tranlog, router) {
    const url = `/api/tranlogs/${id}`;
    return this.CommonAPIService.putCall(url, tranlog, router);
  }
}

export default TranlogAPIService;
