<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @confirm="setEndDate" @close="$emit('input', !value)"
                 title="Add an end date" max-width="500" confirm-text="Add Date" bottom-divider :disabled="!valid">
    <v-form v-model="valid">
      <date-type-select v-model="endDateType" :options="{label: 'Date',
          prependInnerIcon: 'mdi-calendar-start', rules: [rules.required]}"></date-type-select>
      <date-picker-text-field v-model="endDate" label="After" picker-color="secondary"
                              input-color="primary" :rules="[rules.required, rules.date]"
                              prepend-inner-icon="mdi-calendar-month"></date-picker-text-field>
    </v-form>
    <info-alert class="mt-4">
      You should only add an end date if the commission rate is ending and there will not be a new one to replace it. If the
      commission rate is simply being replaced, click the <v-icon small color="grey darken-3">mdi-pencil</v-icon> icon instead. This
      action can be undone later by clicking the <v-icon small color="grey darken-3">mdi-calendar-minus</v-icon> icon.
    </info-alert>
    <loading-overlay v-model="loading" :errors="errors" absolute @close="loading = false"></loading-overlay>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import CommissionsAPIService from "@/servicehandlers/CommissionsAPIService";
  import ActionDialog from "../../ActionDialog";
  import InfoAlert from "../../InfoAlert";
  import DatePickerTextField from "../../DatePickerTextField";
  import LoadingOverlay from "../../LoadingOverlay";
  import DateTypeSelect from "../../DateTypeSelect";

  const commissionsAPIService = new CommissionsAPIService();
  export default {
    name: "AddEndDateDialog",
    components: {DateTypeSelect, LoadingOverlay, DatePickerTextField, InfoAlert, ActionDialog},
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    data: () => ({
      endDate: moment().format('YYYY-MM-DD'),
      endDateType: '',
      errors: [],
      loading: false,
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        required: (x) => !!x || 'This field is required.',
      },
      valid: false,
    }),
    methods: {
      setEndDate() {
        this.loading = true;
        commissionsAPIService.setComEndDate(this.id, this.endDate, this.endDateType, this.$router)
          .then(() => {
            this.$emit('updated');
            this.$emit('input', !this.value);
            this.loading = false;
          })
          .catch(error => {
            this.errors = [error.message];
          })
      }
    }
  }
</script>

<style scoped>

</style>