<template>
  <v-container fluid>
    <v-row justify="center">
      <div>
        <v-img style="margin-left: 30px" max-height="115" contain :src="require('@/assets/logo_wht_crop.png')"/>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  // @ is an alias to /src
  import Login from '@/components/Login.vue';
  export default {
    name: 'home',
    components: {
      Login,
    },
    mounted: function() {
      // console.log('CURRENT ROUTE ON HOME PAGE: ', this.$route);
      const loggedIn = this.$store.getters.loggedIn;
      if ( ! loggedIn ) {
        this.$router.push({
          name: 'Login',
        });
      }
    },

    created: function() {
      // console.log('CURRENT ROUTE ON HOME PAGE: ', this.$route);
      // check to see if logged in or not, if not, load the Login
      // we have to see if the user has a cookie that they've logged into the other site with
      // proceed down that path
      const loggedIn = this.$store.getters.loggedIn;
      if ( ! loggedIn ) {
        this.$router.push({
          name: 'Login',
        });
      }
    },
  };
</script>
