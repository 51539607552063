<template>
  <v-container fluid>
    <v-row class="d-flex justify-center">
      <div v-if="errors && errors.length">
        <div v-for="error of errors">
          <v-alert outlined prominent border="left" type="warning">
            {{error.response.data.description}}
          </v-alert>
        </div>
      </div>
      <v-flex sm9 md6 lg5 xl4>
        <v-card class="elevation-12" style="margin-top: 10%">
          <v-toolbar color="primary" dark text prominent>
            <v-layout align-center justify-center>
              <v-img max-height="115" contain :src="require('@/assets/logo_wht_crop.png')"/>
            </v-layout>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                :class="{'py-12':$vuetify.breakpoint.xs}"
                v-model="login.username"
                label="Username"
                name="username"
                prepend-icon="mdi-account"
                type="text"
              ></v-text-field>
              <v-text-field
                :class="{'py-12':$vuetify.breakpoint.xs}"
                v-model="login.password"
                id="password"
                label="Password"
                name="password"
                :append-icon="hide ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="function() {hide = !hide}"
                :type="hide ? 'password' : 'text'"
                prepend-icon="mdi-lock"
                @keyup.enter="onSubmit"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' x-large tile block @click="onSubmit"
                   class="white--text">Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-row>

  </v-container>

</template>

<script>
  import { AuthAPIService } from '@/servicehandlers/AuthAPIService';

  const authAPIService = new AuthAPIService();

  export default {
    name: 'Login',
    data: () => ({
        errors: [],
        groups: [],
        hide: true,
        login: {},
        state: null
    }),

    mounted: function () {
      if (this.$store.getters.loggedIn) this.goToHome();
    },

    methods: {

      goToHome() {
        this.$_getLists();// gets lists for many dropdowns throughout app
        // let homePage=this.$store.state.userPreferences.home === 'firms' ? 'FirmsSearch' : 'ClaimsSearch';
        let homePage=this.$store.state.userPreferences.home === 'claims' ? 'ClaimsSearch' : 'FirmsSearch';
        // homePage is preferred page if user has rights to see, else other search
        // based on all having at least one of these two page rights
        if ( !this.$_can('page-firm') ) homePage = 'ClaimsSearch';
        if ( !this.$_can('page-claim') ) homePage = 'FirmsSearch';
        this.$router.push({
          name: homePage
        });
      },

      // manual submit login detected.
      onSubmit() {
        authAPIService.login(this.login, this.$router)
        .then(() => {
          if (this.$store.getters.loggedIn) this.goToHome();
        })
        .catch((error) => {
          this.errors.push(error);
        });
      }
    },
  }
</script>

<style>
  .v-dialog--custom {
    width: 100%;
  }
</style>

