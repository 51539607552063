import CommonAPIService from './CommonHandler';

// this needs to be in a config file, not hardcoded!
export class ListsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createCarrier(carrierData, router) {
    const url = '/api/lists';
    return this.CommonAPIService.postCall(url, carrierData, router);
  }


  async getList(type, sort, nameonly, longonly, sortorder = 'ASC', sortTable = 'sort', longAlso, router) {
    let searchParams = new URLSearchParams({
      orderBy: sortTable,
      directions: sortorder,
      fields: '*',
      type
    }).toString();

    //Sometimes sort gets passed as an array [1,2,3] or string '1,2,3,4' (account for both)
    if (Array.isArray(sort)) {
      searchParams += `&${sort.map(sortNum => `sort=${sortNum}`).join('&')}`;
    } else if (sort) {
      sort = sort.toString().split(',');
      searchParams += `&${sort.map(sortNum => `sort=${sortNum}`).join('&')}`;
    }

    const url = `/api/lists?${searchParams}`;
    const lists = await this.CommonAPIService.getCall(url, router);
    const results = lists.map(list => {
      const result = {
        value: nameonly ? list.short : list.lst_id,
        text: longonly ? list.long : list.short
      };

      if (longAlso) result.long = list.long;

      return result;
    });

    return results;
  }

  getStateList(router) {
    const url = '/api/states';
    return this.CommonAPIService.getCall(url, router);
  }

  getContactList(router) {
    const url = '/api/contacts';
    return this.CommonAPIService.getCall(url, router);
  }

  getPersonList(router, searchType) {
    const url = '/api/people/search';
    return this.CommonAPIService.postCall(url, { searchType }, router);
  }

  getPolicyStatusList(router) {
    const fields = 'short';
    const type = 'pstatus';
    const orders = 'sort,short';
    const directions = 'ASC,ASC';
    const url = `/api/lists?fields=${fields}&type=${type}&orderBy=${orders}&directions=${directions}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPolicyPlacements(router) {
    const url = '/api/lists?type=is_new';
    return this.CommonAPIService.getCall(url, null, router);
  }

  updateList(id, body, router) {
    const url = `/api/lists/${id}`;
    return this.CommonAPIService.putCall(url, body, router);
  }

  getPolicyTypes(router) {
    const url ='/api/lists?type=ptype';
    return this.CommonAPIService.getCall(url, router);
  }

  removeList(id, router) {
    const url = `/api/lists/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getProducers(router) {
    const url = '/api/lists?type=name&orderBy=short&directions=ASC';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getCarriersList(router) {
    const url = "/api/lists?fields=short&type=carrier&orderBy=short";
    return this.CommonAPIService.getCall(url, router);
  }

  searchLists(search, router) {
    const url = `/api/lists/search?search=${search}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getAnsTypeLists(router) {
    return this.searchLists('anstype', router);
  }

  getAopCodeLists(router) {
    return this.searchLists('aop_code', router);
  }

  getAssocLists(router) {
    return this.searchLists('assoc', router);
  }

  getCancatLists(router) {
    return this.searchLists('cancat', router);
  }

  getCarrierLists(router) {
    return this.searchLists('carrier', router);
  }

  getClaimStatusLists(router) {
    return this.searchLists('clmstatus', router);
  }

  getContactsTypeLists(router) {
    return this.searchLists('ctype', router);
  }

  getCutoffLists(router) {
    return this.searchLists('cutoff', router);
  }

  getDatStatLists(router) {
    return this.searchLists('datstat', router);
  }

  getDeducLists(router) {
    return this.searchLists('deduc', router);
  }

  getDefCodeLists(router) {
    return this.searchLists('def_code', router);
  }

  getDeductableTypeLists(router) {
    return this.searchLists('dtype', router);
  }

  getErrorCodeLists(router) {
    return this.searchLists('err_code', router);
  }

  getEntityLists(router) {
    return this.searchLists('entity', router);
  }

  getFirmTypeLists(router) {
    return this.searchLists('frmtype', router);
  }

  getFStatusLists(router) {
    return this.searchLists('fstatus', router);
  }

  getFTypeLists(router) {
    return this.searchLists('ftype', router);
  }

  getIsNewLists(router) {
    return this.searchLists('is_new', router);
  }

  getLedgerAccountList(router) {
    return this.searchLists('ledgeracct', router);
  }

  getLedgerTypeList(router) {
    return this.searchLists('ledgertype', router);
  }

  getLicenseSlList(router) {
    return this.searchLists('lic_sl', router);
  }

  getLimitsList(router) {
    return this.searchLists('limits', router);
  }

  getLimitTypeList(router) {
    return this.searchLists('ltype', router);
  }

  getMonitorList(router) {
    return this.searchLists('monitor', router);
  }

  getNuzStatusList(router) {
    return this.searchLists('nuzstatus', router);
  }

  getPridQueueList(router) {
    return this.searchLists('prid_queue', router);
  }

  getRolesList(router) {
    return this.searchLists('role', router);
  }

  getSourceList(router) {
    return this.searchLists('source', router);
  }
}

export default ListsAPIService;
