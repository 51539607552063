<template>
  <v-form v-model="valid">
    <v-row>
      <v-col>
        <span>What were you doing when your session timed out?</span>
      </v-col>
    </v-row>
    <v-row class="mt-n2">
      <v-col class="dense">
        <v-autocomplete v-model="sessionNotesType" label="Activity" :items="sessionNotesTypes" :rules="[rules.required]"
                        item-value="id">
          <template v-slot:selection="{ item }">
            <span v-html="item.description"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-html="item.description"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col>
        <span>Provide a description of what you were working on.</span>
      </v-col>
    </v-row>
    <v-row class="mt-n2">
      <v-col>
        <v-textarea v-model="sessionNotes" label="Description" outlined
                    :rules="sessionNotesType === 1 ? [rules.required] : []"></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col>
        <span>Enter your password in order to login.</span>
      </v-col>
    </v-row>
    <v-row class="mt-n2">
      <v-col>
        <v-text-field v-model="password" @keyup.enter="relogin" @click:append="hide = !hide" :rules="[rules.required]"
                      :type="hide ? 'password' : 'text'" label="Password"
                      :append-icon="hide ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert v-if="error" type="error" text>
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { SessionNotesAPIService } from "../../servicehandlers/SessionNotesAPIService";
  import { AuthAPIService } from "../../servicehandlers/AuthAPIService";

  const sessionNotesAPIService = new SessionNotesAPIService();
  const authAPIService = new AuthAPIService();
  export default {
    name: "IdleForm",
    watch: {
      valid(newVal) {
        this.$emit('valid', newVal);
      }
    },
    data: () => ({
      error: false,
      errorMessage: '',
      hide: true,
      password: '',
      rules: {
        required: (x) => !!x || 'This field is required.',
      },
      sessionNotes: '',
      sessionNotesType: '',
      sessionNotesTypes: [],
      valid: true
    }),
    created() {
      sessionNotesAPIService.getTypes(this.$router)
        .then(types => { this.sessionNotesTypes = types });
    },
    methods: {
      relogin() {
        const { password, sessionNotesType, sessionNotes } = this;
        authAPIService.relogin({password, sessionNotesType, sessionNotes}, this.$router)
          .then(() => {
            this.$store.commit('SET_TIMED_OUT', false);
            this.$emit('relogin');
          })
          .catch(error => {
            this.error = true;
            this.errorMessage = error.response.data.message;
          });
      }
    }
  }
</script>

<style scoped>

</style>