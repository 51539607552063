import CommonAPIService from './CommonHandler';

export class RecipientAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  create(nid, recipients, router) {
    const url = '/api/recipients/' + nid;
    return this.CommonAPIService.postCall(url, recipients, router);
  }

  update(nid, recipients, router) {
    const url = '/api/recipients/' + nid;
    return this.CommonAPIService.putCall(url, recipients, router);
  }

  list(nid, router) {
    const url = '/api/recipients/' + nid;
    return this.CommonAPIService.getCall(url, router);
  }

  destroy(nid, router) {
    const url = '/api/recipients/' + nid;
    return this.CommonAPIService.deleteCall(url, router);
  }
}

export default RecipientAPIService;
