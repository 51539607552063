<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @confirm="setEndDate" @close="$emit('input', !value)"
                 title="Add an end date" max-width="500" confirm-text="Add Date">
    <v-form>
      <date-picker-text-field v-model="endDate" offset-y label="End Date" prepend-icon="mdi-calendar-month"
                              input-color="primary" picker-color="secondary" :rules="[rules.required, rules.date]" />
    </v-form>
    <info-alert class="mt-4">
      You should only add an end date if the tax rate is ending and there will not be a new one to replace it. If the
      tax rate is simply being replaced, click the <v-icon small color="grey darken-3">mdi-pencil</v-icon> icon instead. This
      action can be undone later by clicking the <v-icon small color="grey darken-3">mdi-calendar-minus</v-icon> icon.
    </info-alert>
    <loading-overlay v-model="loading" :errors="errors" absolute></loading-overlay>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import TaxesAPIService from "@/servicehandlers/TaxesAPIService";
  import ActionDialog from "../../ActionDialog";
  import InfoAlert from "../../InfoAlert";
  import DatePickerTextField from "../../DatePickerTextField";
  import LoadingOverlay from "../../LoadingOverlay";

  const taxesAPIService = new TaxesAPIService();
  export default {
    name: "AddEndDateDialog",
    components: {LoadingOverlay, DatePickerTextField, InfoAlert, ActionDialog},
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    data: () => ({
      endDate: moment().format('YYYY-MM-DD'),
      errors: [],
      loading: false,
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        required: (x) => !!x || 'This field is required.',
      },
    }),
    methods: {
      setEndDate() {
        this.loading = true;
        taxesAPIService.setTaxEndDate(this.id, this.endDate, this.$router)
          .then(() => {
            this.$emit('updated');
            this.$emit('input', !this.value);
            this.loading = false;
          })
          .catch(error => {
            this.errors = [error.message];
          })
      }
    }
  }
</script>

<style scoped>

</style>