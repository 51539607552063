<template>
  <v-autocomplete :value="value" @input="$emit('inupt', $event)" :items="types" item-value="short" item-text="short" v-bind="options">
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.short"></v-list-item-title>
        <v-list-item-subtitle v-text="item.long"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
  import { ListsAPIService } from "@/servicehandlers/ListsAPIService";

  const listsAPIService = new ListsAPIService();
  export default {
    name: "PolicyTypesSelect",
    props: {
      options: {
        type: Object,
        default: () => ({})
      },
      value: {
        required: true,
      }
    },
    data: () => ({
      types: []
    }),
    created() {
      listsAPIService.getPolicyTypes(this.$router)
        .then(types => { this.types = types })
    }
  }
</script>

<style scoped>

</style>