<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="updateProdCom"
                 title="Edit producer commission" max-width="400" bottom-divider :disabled="!valid">
    <producer-commission-form ref="prodComForm" :commission="commission" update @valid="(v) => valid = v"></producer-commission-form>
    <loading-overlay v-model="loading" absolute></loading-overlay>
  </action-dialog>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import ProducerCommissionForm from "./forms/ProducerCommissionForm";
  import LoadingOverlay from "../../LoadingOverlay";
  export default {
    name: "UpdateProducerCommissionDialog",
    components: {LoadingOverlay, ProducerCommissionForm, ActionDialog},
    props: {
      commission: {
        required: true,
        type: Object
      },
      value: {
        required: true,
        type: Boolean
      }
    },
    data: () => ({
      loading: false,
      valid: false
    }),
    methods: {
      updateProdCom() {
        this.loading = true;
        this.$refs.prodComForm.updateProdCom()
          .then(() => {
            this.loading = false;
            this.$emit('input', !this.value);
            this.$emit('updated');
          })
      }
    }
  }
</script>

<style scoped>

</style>