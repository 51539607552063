import CommonAPIService from './CommonHandler';

export class FirmsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getFirm(search, router) {
    const url = '/api/firms/search';
    return this.CommonAPIService.postCall(url, search, router);
  }

  getUnisearchFirm(search, router) {
    const colsToSearch = 'sort,name1,name2,phone,contact,firms.insured,firms.email,policies.polnum';
    const fields = 'fid,fein,source,producer,solicitor,firms.type,firms.status,entity,sort,firms.insured,contact,salutation,name1,name2,address1,address2,city' +
      ',state,firms.zip,phone,fax,email,website,firms.note,estab,firms.sales,agent,profs';
    const url = `/api/firms/unisearch/search?colsToSearch=${colsToSearch}&fields=${fields}&search=${search.search}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getAllFirmFiles(router) {
    const url = `/api/firms/forms`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmFiles(fid, page = 1, perPage = 50, router) {
    const fields = 'f_type,file_description,file_type_description,loidfid,filename,content_type,size,loid,file_rel_fid_id,dated';
    const url = `/api/firms/files/${fid}?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmForms(fid, router) {
    const url = `/api/firms/${fid}/forms`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmPeople(fid, router) {
    const fields = 'stamp,plid,fid,surname,given,salutation,prefix,suffix,address,city,state,zip,direct,ext,mobile,' +
      'fax,home,email,gender,race,role,licensed,license,licnum,ce,born,hired,fired,ssn,salary,hours,dependents,priorfirm,' +
      'membership,note,cid,userid';
    const url = `/api/firms/${fid}/people?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmMembers(fid, router) {
    const url = `/api/firms/members/${fid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmPlids(fid, router) {
    const url = `/api/firms/firmplids/${fid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmPolicies(fid, router) {
    const fields = 'depfee,doifee,grpnum,finnum,type,status,carrier,insured,' +
      'incept,expiry,retro,limits,ltype,mgafee,deduc,docs,dtype,pid,polnum,ppid,premium,prid,profee,' +
      'slafee,spnfee,srcfee,subfee,taxes,fees,total,down,features,forms,zip';
    const url = `/api/firms/${fid}/policies?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPoliciesExpiryRange(fid, pid, yearRange, router) {
    const fields = 'pid,type,status,carrier,insured,incept,expiry,retro,limits,deduc,premium,taxes,fees,total,down,forms';
    const url = `/api/firms/${fid}/policies?fields=${fields}&pid=${pid}&yearRange=${yearRange}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getBookedPolicies(fid, router) {
    const fields = 'pid,type,carrier,incept,expiry,retro,limits,deduc,premium,taxes,fees,total';
    const url = `/api/firms/${fid}/policies?fields=${fields}&status=Book&status=Lost&status=Past&status=dncLost`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPoliciesBooks(firmId, router) { // Outdated
    const url = `/api/firms/${firmId}/policies/booked`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmNotes(fid, page = 1, perPage = 50, router) {
    const url = `/api/firms/${fid}/notes?page=${page}&perPage=${perPage}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFirmClaimTotals(fid, router) {
    const url = `/api/firms/totals/${fid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getSingleFirm(fid, router) {
    const url = `/api/firms/${fid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  updateFirm(fid, firm, router) {
    const url = '/api/firms/' + fid;
    return this.CommonAPIService.putCall(url, firm, router);
  }

  getRecipients(fid, router) {
    const firms_fields = 'fid';
    const contacts_fields = 'cid,name1,email';
    const people_fields = 'plid,surname,given,email';
    const orders = 'people.email,plid';
    const directions = 'DESC'
    let url = `/api/firms/${fid}/recipients?firms_fields=${firms_fields}&contacts_fields=${contacts_fields}`
    url += `&people_fields=${people_fields}&orders=${orders}&directions=${directions}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getActiveFirms(router) {
    const url = '/api/firms?category=active';
    return this.CommonAPIService.getCall(url, router);
  }

  getNeglectedFirms(router) {
    const url = '/api/firms?category=neglected';
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   * Disconnects a file from a firm
   * @param { number } fid An integer representing the firm id
   * @param { number } loid An integer representing the file id
   * @param { Route } router The Route object
   * @returns {Promise<void>} A promise that returns OK when the file is disconnected
   * @example disconnectFileFromFirm(1, 2, this.$route);
   */
  disconnectFileFromFirm(fid, loid, router) {
    const url = `/api/firms/${fid}/files/${loid}`;
    return this.CommonAPIService.deleteCall(url, router);
  }
}

export default FirmsAPIService;
