import CommonAPIService from './CommonHandler';

export class ProducerCommissionsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createProdCom(data, router) {
    const url = '/api/producer_commissions';
    return this.CommonAPIService.postCall(url, data, router);
  }

  deleteProdCom(id, router) {
    const url = `/api/producer_commissions/${id}`;
    return this.CommonAPIService.deleteCall(url, router)
  }

  list(router) {
    const url = '/api/producer_commissions';
    return this.CommonAPIService.getCall(url, router);
  }

  updateProdCom(id, updates, router) {
    const url = `/api/producer_commissions/${id}`;
    return this.CommonAPIService.putCall(url, updates, router);
  }
}

export default ProducerCommissionsAPIService;