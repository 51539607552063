<template>
  <div :key="refresh">
    <v-card class="mx-1 elevation-0 rounded-0">
      <v-card-title class="pt-0 mt-0">
        <v-row>
          <v-col class="mx-auto" :style="$vuetify.breakpoint.name !== 'xs' && mode === 'More' ? 'max-width: 265px': ''">
            <v-row :class="$vuetify.breakpoint.name !== 'xs' ? mode === 'More' ? 'ml-n4 d-flex flex-column' : '' : 'mt-n2 mb-6'">
              <v-col class="mr-12 mb-n2">
                <v-row class="d-flex flex-nowrap mx-auto" style="margin-top: -2px">
                  <button-tip btnclass="ml-n4 mr-1 mt-2" @click="getItemsForThisFirm(firm.fid)" elevation="5" fab icolor="black"
                              iname="mdi-update" tip="Refresh Policies" xsmall/>
                  <v-btn-toggle
                    class="ml-0 mr-0 px-0"
                    color='secondary darken-2'
                    group
                    small
                    v-model="view"
                  >
                    <v-btn class="caption px-1 mx-1" value="View" style="min-width:24px">
                      View
                    </v-btn>
                    <v-btn class="caption px-1 mx-1" value="View All" style="min-width:24px">
                      All
                    </v-btn>
                    <v-btn class="caption px-1 mx-1" value="Policies">
                      Policies
                    </v-btn>
                    <v-btn class="caption px-1 mx-1" value="Quotes">
                      Quotes
                    </v-btn>
                  </v-btn-toggle>
                  <v-btn-toggle
                    class="ml-n2 mr-4 px-0"
                    color='secondary darken-2'
                    group
                    small
                    v-model="mode"
                  >
                    <v-btn class="caption px-2 ml-0 mr-12" value="Less" v-if="mode === 'More'" style="min-width:11px">
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                    <v-btn class="caption mr-12" value="More" v-else style="min-width:20px">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="mt-1 d-flex flex-nowrap">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" clearable dense
                                style="min-width: 90px; width: 60%" single-line hide-details class="pl-2 mx-auto"></v-text-field>
<!--                  <button-tip btnclass="mx-2" @click="createPolicyDialog = true" color="secondary" fab icolor="white"-->
<!--                              iname="mdi-plus" tip="Create Policy" v-can="'policy-edit'" xsmall/>-->
                  <!--                  TODO develop  createPolicy interface-->
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="7" lg="8" xl="9" class="pa-0 pt-2 mx-auto"
                 v-if="mode === 'More'"
                 style="height:160px; overflow-y: scroll; background-color: whitesmoke">
              <v-autocomplete
                  class="px-3 mb-n2"
                  clearable
                  @input="statusSelected=true"
                  @click:clear="statusSelected=true"
                  dense
                  :items="$_lists.pstatus"
                  placeholder="Status"
                  :menu-props="{ maxHeight: 450 }"
                  multiple
                  style="padding-top: 4px"
                  v-model="status"
              >
                <template v-slot:selection="{ item }">
                  <span class="caption"> {{ item.text }} &nbsp </span>
                </template>
              </v-autocomplete>
              <v-autocomplete
                  class="px-3 mb-n2"
                  clearable
                  dense
                  :items="$_lists.carrier"
                  placeholder="Carrier"
                  :menu-props="{ maxHeight: 450 }"
                  multiple
                  style="margin-bottom: 11px"
                  v-model="carrier"
              >
                <template v-slot:selection="{ item }">
                  <span class="caption"> {{ item }} &nbsp </span>
                </template>
              </v-autocomplete>
              <v-autocomplete
                  class="px-3"
                  clearable
                  dense
                  :items="$_lists.ptype"
                  placeholder="Type"
                  :menu-props="{ maxHeight: 450 }"
                  multiple
                  style="margin-top: -5px"
                  v-model="type"
              >
                <template v-slot:selection="{ item }">
                  <span class="caption"> {{ item }} &nbsp </span>
                </template>
              </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-data-table class="px-1 elevation-0 lessPadding rounded-0"
                  :class="tableClass"
                  @click:row="rowClick"
                  dense
                  fixed-header
                  :headers="colHeaders"
                  hide-default-footer
                  item-key="pid"
                  :items="policiesFiltered"
                  :items-per-page="-1"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  :mobile-breakpoint="0"
                  multi-sort
                  :search="search"
                  single-select
                  :sort-by="['type', 'incept']"
                  :sort-desc="[false, true]"
                  :style="$_cssVars"
    >
      <template v-slot:item.actions="{ item }">
        <v-lazy>
          <div>
            <button-tip btnclass="ml-0 mr-n1" @click="editPolicy(item)" icon iname="mdi-pencil" tip="Edit Policy" v-can="'policy-edit'"/>
            <button-tip btnclass="ml-n1" @click="viewPolicy(item)" icon iname="mdi-eye-outline" tip="View Policy"/>
            <v-menu open-on-hover offset-y close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon class="mr-3">mdi-calculator</v-icon>
                </v-btn>
              </template>

              <v-list>
                <div>
                  <v-list-item v-for="(feature, index) in calculationFeatures" :key="index" @click="feature.action(item)"
                               v-if="!feature.scope || $_can(feature.scope)">
                    <v-list-item-title>{{ feature.title }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
        </v-lazy>
      </template>
      <template v-slot:item.carrier="{ item }">
        <v-tooltip left v-if="item.carrier && item.carrier.length > 12">
          <template v-slot:activator="{ on }">
            <div style="cursor: pointer" v-on="on">
              {{ item.carrier ? item.carrier.slice(0, 9) + '...' : '' }}</div>
          </template>
          {{ item.carrier || '' }}
        </v-tooltip>
        <div v-else>{{ item.carrier || '' }}</div>
      </template>
      <template v-slot:item.incept="props">
        <span v-if="moment(props.item.incept, 'YYYY-MM-DD', true).isValid()">
        {{ moment(props.item.incept, 'YYYY-MM-DD').format('DD MMM YYYY')}}</span>
        <span v-else>{{props.item.incept}}</span>
      </template>
      <template v-slot:item.expiry="props">
        <span v-if="moment(props.item.expiry, 'YYYY-MM-DD', true).isValid()">
        {{ moment(props.item.expiry, 'YYYY-MM-DD').format('DD MMM YYYY')}}</span>
        <span v-else>{{props.item.expiry}}</span>
      </template>
      <template v-slot:item.retro="props">
        <span v-if="moment(props.item.retro, 'YYYY-MM-DD', true).isValid()">
        {{ moment(props.item.retro, 'YYYY-MM-DD').format('DD MMM YYYY')}}</span>
        <span v-else>{{props.item.retro}}</span>
      </template>
      <template v-slot:item.premium="props">
        ${{ (parseFloat(props.item.premium)).toFixed(2).toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
      </template>
      <template v-slot:item.taxes="props">
        ${{ (parseFloat(props.item.taxes)).toFixed(2).toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
      </template>
      <template v-slot:item.fees="props">
        ${{ (parseFloat(props.item.fees)).toFixed(2).toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
      </template>
      <template v-slot:item.total="props">
        ${{ (parseFloat(props.item.total)).toFixed(2).toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
      </template>
      <template v-slot:item.down="props">
        ${{ (parseFloat(props.item.down)).toFixed(2).toString()
        .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
      </template>
    </v-data-table>

    <v-dialog v-model="createPolicyDialog" persistent max-width="600" :key="refreshCreatePolicy">
      <v-card>
        <v-card-title>Create Policy</v-card-title>

        <v-card-text class="pa-6">
          <v-row
              class="d-inline-flex px-3 mx-1 my-2"
              :key="field.name"
              v-for="field in createPolicyFields">
            <smart-input
                clearable
                :items="field.select ? $_lists[field.val] : []"
                :label="field.name"
                :style="{ width: field.width, fontSize: '14px' }"
                :type="field.select ? 'VAutocomplete' : 'VTextField'"
                v-model="policyToCreate[field.val]"
            ></smart-input>
          </v-row>
        </v-card-text>
        <v-divider class="mx-6"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="createPolicyDialog = false">Cancel</v-btn>
          <v-btn color="secondary" @click="createPolicy(firm.fid)">Create Policy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <tax-dialog v-if="taxDialog" :fid="firm.fid" :pid="taxPid" v-model="taxDialog"/>
    <commissions-dialog v-if="commissionsDialog" :pid="comPid" v-model="commissionsDialog" />
  </div>
</template>

<script>
  import moment from 'moment';
  import EditPolicy from '@/components/search/firms/EditPolicy';
  import { FirmsAPIService } from "@/servicehandlers/FirmsAPIService";
  import { ListsAPIService } from '@/servicehandlers/ListsAPIService';
  import TaxDialog from '@/components/taxes/TaxDialog';
  import ViewPolicy from '@/components/search/firms/ViewPolicy';
  import CommissionsDialog from "../../commissions/CommissionsDialog";
  import {PoliciesAPIService} from "@/servicehandlers/PoliciesAPIService";

  const apiService = new FirmsAPIService();
  const listsAPIService = new ListsAPIService();
  const policiesAPIService = new PoliciesAPIService();

  export default {
    name: "FirmPoliciesTable",
    props: {
      firm: Object,
      notPopup: Boolean,
      rightsArray: Array,
      default() {
        return {};
      }
    },
    components: { EditPolicy, CommissionsDialog, TaxDialog, ViewPolicy },
    data() {
      return {
        carrier: [],// selected carriers
        carriers: [],// all carriers
        calculationFeatures: [
          {title: 'Taxes', action: (x) => this.openTaxDialog(x)},
          {title: 'Commissions', action: (x) => this.openCommissionsDialog(x), scope: 'calculate-commissions'}
        ],
        comPid: '',
        commissionsDialog: false,
        createPolicyDialog: false,
        createPolicyFields: [
          {name: 'Program',   val: 'prid',   width: '150px', select: false },
          {name: 'Incept',    val: 'incept', width: '150px', select: false },
          {name: 'Retro',     val: 'retro',  width: '150px', select: false },
          {name: 'Limit',     val: 'limits', width: '150px', select: true, list: 'limits', sort: '' },
          {name: 'Deductible',val: 'deduc',  width: '150px', select: true, list: 'deduc',  sort: '' },
          {name: 'Premium',   val: 'premium',width: '150px', select: false },
        ],
        createPolicyItems: [],
        errors: [],
        loading: false,
        mode: 'Less',
        policies: [],
        policiesFiltered: [],
        policy: {},
        policyToCreate: {},
        refresh: false,
        refreshCreatePolicy: false,
        search: '',
        showDatePicker: false,
        status: [],
        statusAllNames: [],
        statuses: [],
        statusSelected: false,
        tableClass: '',
        taxDialog: false,
        taxPid: '',
        type: '',
        types: [],
        view: 'View',// value for displaying initial default policies
      }
    },

    mounted () {// initialize tableClass
      this.tableClass = !this.notPopup ? 'popupLargeTableNoFooter' : 'largeSizeTableNoFooter';
      this.lists = this.$store.getters.lists;
    },

    computed: {
      colHeaders() {
        let amountWidth = '70px';
        let dateWidth = '94px';
        return [
          {value: 'actions',text: '',sortable: false, align: 'center', width: '90px' },

          // columns for testing
          // {value: 'grpnum',text: 'grpnum',sortable: true, align: 'start', width: '92px' },
          // {value: 'prid',text: 'prid',sortable: true, align: 'start', width: '92px' },
          // {value: 'status',text: 'status',sortable: true, align: 'start', width: '92px' },
          // {value: 'pid',text: 'pid',sortable: true, align: 'start', width: '92px' },

          // {value: 'submitted',text: 'submitted',sortable: true, align: 'start', width: '92px' },
          // {value: 'quoted',text: 'quoted',sortable: true, align: 'start', width: '92px' },
          // {value: 'bndreq',text: 'bndreq',sortable: true, align: 'start', width: '92px' },
          // {value: 'booked',text: 'booked',sortable: true, align: 'start', width: '92px' },
          // {value: 'bound',text: 'bound',sortable: true, align: 'start', width: '92px' },
          // {value: 'issued',text: 'issued',sortable: true, align: 'start', width: '92px' },
          // {value: 'canceled',text: 'canceled',sortable: true, align: 'start', width: '92px' },


          {value: 'carrier', text: 'Carrier',sortable: true, align: 'start', width: '92px' },
          {value: 'type',    text: 'Type',   sortable: true, align: 'start', width: this.colWidth },
          {value: 'status',  text: 'Status', sortable: true, align: 'start', width: '58px'  },
          {value: 'incept',  text: 'Incept', sortable: true, align: 'start', width: dateWidth },
          {value: 'expiry',  text: 'Expiry', sortable: true, align: 'start', width: dateWidth },
          {value: 'retro',   text: 'Retro',  sortable: true, align: 'start', width: dateWidth },
          {value: 'limits',  text: 'Limits', sortable: true, align: 'end', width: '53px' },
          {value: 'deduc',   text: 'Deduc',  sortable: true, align: 'end', width: amountWidth },
          {value: 'premium', text: 'Premium',sortable: true, align: 'end', width: amountWidth },
          {value: 'taxes',   text: 'Tax',    sortable: true, align: 'end', width: amountWidth },
          {value: 'fees',    text: 'Fees',   sortable: true, align: 'end', width: amountWidth },
          {value: 'total',   text: 'Total',  sortable: true, align: 'end', width: amountWidth },
          {value: 'down',    text: 'Down ',  sortable: true, align: 'end', width: amountWidth },
        ];
      },
      colWidth() {
        return !this.notPopup ? '48px' : '98px';
      },
    },

    created () {
      this.$_getLists();// gets dropdown lists and put in store for getter in computed $_lists
      this.getItemsForThisFirm(this.firm.fid);
    },

    watch: {
      carrier() {
        this.filterPolicies();
      },
      type() {
        this.filterPolicies();
      },
      status() {
        if (this.statusSelected) {
          this.view = undefined;
          this.statusSelected = false;
        }
        this.filterPolicies();
      },
      mode() {
        switch(this.mode) {
          case 'More':
            // convert layout to show dropdowns
            this.tableClass = !this.notPopup ? 'popupLargePoliciesTableNoFooter' : 'largeSizePoliciesTableNoFooter';
            break;
          default:
            // revert layout to initial
            this.tableClass = !this.notPopup ? 'popupLargeTableNoFooter' : 'largeSizeTableNoFooter';
        }
      },

      view() {
        this.updateTableView();
      },
    },

    methods: {
      createPolicy() {
        // User provides prid, incept, expiry (calculated by adding 1 year to incept),
        // retro, limits, deduc, premium —> sends to API which creates the policy
        // what will prid, grpnum be?

        let policyData = {
          fid: this.firm.fid,
          prid: this.policyToCreate.prid,
          incept: this.policyToCreate.incept,
          expiry: this.dateAdd(this.policyToCreate.incept,1),
          retro: this.policyToCreate.retro,
          limits: this.policyToCreate.limits,
          deduc: this.policyToCreate.deduc,
          premium: this.policyToCreate.premium,
          status: 'Past',// for testing
        };

        policiesAPIService.addPolicy(policyData, this.$router).then((policy) => {
          // console.debug('created policy pid', policy.pid);
          this.editPolicy(policy);
          this.createPolicyDialog = false;
          this.getItemsForThisFirm(this.firm.fid);
        });

        // policiesAPIService.createPolicy(policyData, this.$router).then((policy) => {
        //   this.editPolicy(policy);
        //   this.createPolicyDialog = false;
        //   this.getItemsForThisFirm(this.firm.fid);
        // });
      },

      dateAdd(date, add) {// add number to year part of mm-dd-yyyy date
        let parts = date.split('-');
        parts[2] = parseInt(parts[0]) + add;
        return `${parts[0]}-${parts[1]}-${parts[2]}`;
      },

      editPolicy(policy) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/editPolicy/' +  policy.pid, '_blank',
            'resizeable=yes, width=' + (1310)
            + ', left=' + (500)
            + ', top=' + (64)
            + ', height=' + (760));
      },

      filterPolicies() {
        // filters by dropdown selections, if no selection then show as if all are selected
        this.policiesFiltered = this.policies.filter(pol =>
          (this.carrier.includes(pol.carrier) || !this.carrier.length)
          && (this.type.includes(pol.type)    || !this.type.length)
          && (this.status.includes(pol.status) || !this.status.length)
        )
      },

      getItemsForThisFirm(id) {
        this.loading = true;
        apiService.getFirmPolicies(id, this.$router).then((policies) => {
          // need to convert dates so they will sort properly
          for (let policy of policies) {
            for (let date of ['incept','expiry','retro']) {
              if (moment(policy[date], 'MM-DD-YYYY', true).isValid()) {
                policy[date] = moment(policy[date], 'MM-DD-YYYY').format('YYYY-MM-DD')
              } else if (moment(policy[date], 'M-DD-YYYY', true).isValid()) {
                policy[date] = moment(policy[date], 'M-DD-YYYY').format('YYYY-MM-DD')
              }
            }
          }
          this.policies = policies;
          this.updateTableView();
          this.loading = false;
          this.refresh = !this.refresh;
        })
          .catch((error) => {
            this.errors.push(error);
            this.loading = false;
            if (error.response && error.response.status === 401) {
            }
          });
      },

      openCommissionsDialog(policy) {
        this.comPid = policy.pid;
        this.commissionsDialog = true;
      },

      openTaxDialog(policy) {
        this.taxPid = policy.pid;
        this.taxDialog = true;
      },

      rowClick: function (item, row) {
        row.select(true);
      },
      
      updateTableView() {// if view changes or policy created 
        this.carrier = [];
        this.type = [];
        if (this.view) this.status = [];
        switch(this.view) {
          case 'Policies':
            this.status = ['Book','dncLost','Lost','Past','Tail'];
            break;
          case 'Quotes':
            this.status = ['Quote','Indicate','Comp'];
            break;
          case 'View':
            this.status = ['Book','dncLost','Lost','Past','Tail',
              'App','BOR','Hold','Indicate','IndiRate','Info','notComp','Quote','Rate','Ready','Refuse','Sent','Submit',
              'Comp','Current','History'];
            break;
          case 'View All':
            this.status = this.statusAllNames;
            break;
        }
      },

      viewPolicy (policy) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/viewPolicy/' +  policy.pid, '_blank',
          'resizeable=yes, width=' + (1000)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (700));
      },
    },
  }
</script>

<style>
  .lessPadding.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    padding: 2px;
  }
  .lessPadding.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    padding: 2px;
  }
  tr.v-data-table__selected {
    background: lightgrey !important;
  }
</style>



