<template>
  <ActionDialog :value="$parent.showTranLog" @input="$emit('input', $event)" float
                title="Tran Log" confirm-text="Close" confirm-color="secondary" persistent
                @confirm="$parent.showTranLog = false" :second-button="false">
    <v-data-table
      dense
      disable-pagination
      elevation-6
      fixed-header
      :headers="tranLogHeaders"
      height="15vh"
      hide-default-footer
      :items="tranLog"
      :mobile-breakpoint="0"
    ></v-data-table>
    <br>
<!--    <v-data-table-->
<!--      dense-->
<!--      disable-pagination-->
<!--      elevation-6-->
<!--      fixed-header-->
<!--      :headers="recipHeaders"-->
<!--      height="30vh"-->
<!--      hide-default-footer-->
<!--      :items="recipients"-->
<!--    ></v-data-table>-->

  </ActionDialog>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import { TranlogAPIService } from '@/servicehandlers/TranlogAPIService';
  import moment from 'moment';

  const tranlog = new TranlogAPIService();

  export default {
    name: "TranLog",
    components: { ActionDialog },
    props: {
      nid: [Number, String],
    },
    data() {
      return {
        firm: {
          tel: '(801) 111-4444',
          fax: '(801) 111-4444',
        },
        recipHeaders: [// main is firm phone
          {value: 'type',     text: '',       width: "80px" },
          {value: 'name',     text: 'Name',   width: "200px" },
          {value: 'direct',   text: 'Main', width: "200px" },
          {value: 'phone',    text: 'Work', width: "200px" },
          {value: 'phone',    text: 'Cell',  width: "200px" },
          {value: 'phone',    text: 'Main Fax',  width: "200px" },
          {value: 'direct',   text: 'Work Fax',  width: "200px" },
          {value: 'address',  text: 'Address',width: "900px" },
        ],
        recipients: [
          {type: 'to',  name: 'Jimmy Smith',  direct: '(801) 222-4444', phone: '(801) 111-4444', address: '123 Main Street' },
          {type: 'bcc',  name: 'Jane Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '54321 Center St.' },
          {type: 'bcc',  name: 'Jane Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '54321 Center St.' },
          {type: 'bcc',  name: 'Jane Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '54321 Center St.' },
          {type: 'bcc',  name: 'Jane Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '54321 Center St.' },
          {type: 'cc',  name: 'Joe Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '123 Main Street' },
          {type: 'cc',  name: 'Joe Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '123 Main Street' },
          {type: 'cc',  name: 'Joe Smith',  direct: '(801) 111-4444', phone: '(801) 111-4444', address: '123 Main Street' },
        ],
        tranLog: [],
        tranLogHeaders: [
          {value: 'type',  text: 'Type',  width: "100px" },
          {value: 'status',text: 'Status',width: "100px" },
          {value: 'queued',text: 'Queued',width: "250px" },
          {value: 'sent',  text: 'Sent',  width: "250px" },
          {value: 'note',  text: 'Note',  width: "900px" },
        ],
      }
    },

    mounted: async function() {
      // console.debug('newly MOUNTED tranLog w this.nid', this.nid);
      await this.getTranLog();
    },

    methods: {

      async getTranLog() {

        tranlog.getTranlog(this.nid, this.$route)
          .then((response) => {
            // if there is an extension, show next to work number in parenthesis
            this.tranLog = response.map(entry => {
              entry.queued = moment(entry.queued).format('DD MMM YYYY, h:mm:ss A');
              entry.sent = moment(entry.sent).format('DD MMM YYYY, h:mm:ss A');
              return entry;
            });
            // console.debug('response', response);
          })
          .catch((error) => {
          });
      },
    }
  }
</script>

<style scoped>

</style>
