<template>
    <v-overlay :absolute="absolute" :value="value">
        <slot v-if="errors.length === 0">
            <v-progress-circular :size="loaderSize" color="white" indeterminate></v-progress-circular>
        </slot>
        <div v-else>
            <v-row justify="center">
                <slot name="error">
                    <ErrorAlert :errors="errors">
                        <slot name="errorMessage">An error has occurred. Please refresh or try again.</slot>
                    </ErrorAlert>
                </slot>
            </v-row>
            <v-row justify="center">
                <v-btn @click="$emit('close')" color="white" text>Close</v-btn>
            </v-row>
        </div>
    </v-overlay>
</template>

<script>
    import ErrorAlert from "./ErrorAlert";
    export default {
        name: "LoadingOverlay",
        components: {ErrorAlert},
        props: {
            absolute: Boolean,
            errors: {
                default: () => [],
                type: Array,
            },
            loaderSize: {
                default: '50',
                type: String
            },
            value: {
                required: true,
                type: Boolean
            }
        },
    }
</script>

<style scoped>

</style>