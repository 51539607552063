<template>
    <v-row justify="center">
        <v-dialog
                v-model="shown"
                max-width="500"
        >
            <v-card>
                <v-row class="mt-0">

                    <!-- Title -->
                    <div id="title" class="pl-3">
                        <v-card-title v-if="!errorTitle" class="headline">Sorry, there was a problem</v-card-title>
                        <v-card-title v-else class="headline">{{ errorTitle }}</v-card-title>
                    </div>
                    <v-spacer/>
                    <v-btn
                            color="primary"
                            @click="shown = false"
                            icon
                            class="mt-3 mr-6"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-row>

                <!-- Error Message -->
                <v-card-text>
                    <span class="body-1">
                      {{ !!errorMessage ? errorMessage : 'An error occurred while trying to access the database. ' +
                'If the problem persists please notify the development team with a detailed ' +
                'description of the error.' }}
<!--                        {{ errorMessage }}-->
                    </span>
                </v-card-text>

                <!-- "For Developers" message -->
                <div v-if="forDevelopers">
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>For Developers</v-expansion-panel-header>
                            <v-expansion-panel-content>{{ forDevelopers }}</v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>


            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: "ErrorDialog",
        data: () => ({
            shown: false,
            errorTitle: "",
            errorMessage: "",
            forDevelopers: ""
        }),
        methods: {
            toggleShown: function() {
                this.shown = !this.shown
            },
            showError: function(options){
                let that = this; // Binds this into scope.
                let keys = Object.keys(options);

                keys.forEach(function (key) {
                    // Set's the value of the key in the data object equal to the value of the
                    // corresponding key in the options object.
                    that._data[key] = options[key]
                });

                this.toggleShown()
            }
        }
    }
</script>

<style scoped>

</style>
