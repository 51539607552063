<template>
  <div class="pa-0 ma-0" style="max-height: 600px; background-color: white; overflow: scroll">
    <div class="hideTopSpace" style="line-height: 2.1"><br></div>
    <v-row dense class="pa-0 ma-0">
      <v-col cols="12" md="6" xl="3" style="width: 275px" class="pa-0 ma-0">
        <v-card elevation="0" rounded="0" class="pa-0 ma-0">
          <v-card-title class="pt-0 pt-sm-1 justify-center"> Names </v-card-title>
          <v-card-text class="pb-0" style="margin-top: -16px">
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="sort"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1"></v-checkbox>
              <v-text-field dense
                  @keyup.enter="searchFirms()"
                  v-model="whereColumns.sort"
                  spellcheck="false"
                  label="Sort Name"
                  class="mr-4"></v-text-field>
            </v-row>
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="contact"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1"></v-checkbox>
              <v-text-field dense
                  @keyup.enter="searchFirms()"
                  v-model="whereColumns.contact"
                  spellcheck="false"
                  label="Contact Name"
                  class="mr-4"></v-text-field>
            </v-row>
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="name1"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <v-text-field dense
                  @keyup.enter="searchFirms()"
                  v-model="whereColumns.name1"
                  spellcheck="false" label="Name 1" class="mr-4">
              </v-text-field>
            </v-row>
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="name2"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <v-text-field dense
                  @keyup.enter="searchFirms()"
                  v-model="whereColumns.name2"
                  spellcheck="false"
                  label="Name 2"
                  class="mr-4">
              </v-text-field>
            </v-row>
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="insured"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <v-text-field dense
                  @keyup.enter="searchFirms()"
                  v-model="whereColumns.insured"
                  spellcheck="false"
                  label="Legal Name"
                  class="mr-4">
              </v-text-field>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" xl="3" style="width: 275px" class="pa-0 ma-0">
        <v-card elevation="0" rounded="0" class="pa-0 ma-0">
          <v-card-title class="pt-0 pt-sm-1 justify-center"> Addresses </v-card-title>
          <v-card-text class="pb-0" style="margin-top: -16px">
            <v-row align="center" class="my-0">
              <v-checkbox color="#909090" value="address1"
                          v-model="checkedColumns"
                          hide-details class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <v-text-field dense
                  v-model="whereColumns.address1"
                  @keyup.enter="searchFirms()"
                  class="mr-4"
                  label="Address Line 1">
              </v-text-field>
            </v-row>
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          value="address2"
                          v-model="checkedColumns"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <v-text-field dense
                  v-model="whereColumns.address2"
                  @keyup.enter="searchFirms()"
                  class="mr-4"
                  label="Address Line 2">
              </v-text-field>
            </v-row>
            <v-row align="center" class="mt-n4 mb-n3">
              <v-col cols="4">
                <v-row class="py-0 my-0">
                  <v-checkbox color="#909090" value="city"
                              v-model="checkedColumns"
                              hide-details
                              class="shrink mr-2 ml-4 mt-2">
                  </v-checkbox>
                  <v-text-field dense
                                v-model="whereColumns.city"
                                @keyup.enter="searchFirms()"
                                class="mr-2 mb-2"
                                :style="{width: ($vuetify.breakpoint.name === 'xs' ? '13vw' : '9vw')}"
                                label="City">
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row justify="center" class="py-0 mt-n6">
                  <v-checkbox color="#909090"
                              value="state"
                              v-model="checkedColumns"
                              hide-details
                              class="shrink mx-0 mt-2">
                  </v-checkbox>
                  <span @keyup.enter="searchFirms()">
                      <ListViewer
                          dense
                          clearable
                          v-model="whereColumns.state"
                          listname="states"
                          nameonly
                          sorttable="short"
                          spellcheck="false"
                          listtitle="State"
                          title
                          label="State"
                          :style="{width: ($vuetify.breakpoint.name === 'xs' ? '13vw' : '10vw')}"
                          class="mx-0 mb-n1 mt-0"/>
                    </span>
                </v-row>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-row class="ml-1 mt-n5">
                  <v-checkbox color="#909090" value="zip"
                              name="zip"
                              v-model="checkedColumns"
                              hide-details
                              class="shrink mx-0 mt-2">
                  </v-checkbox>
                  <v-text-field dense
                      v-model="whereColumns.zip"
                      label="Zip"
                      spellcheck="false"
                      @keyup.enter="searchFirms()"
                      :style="{width: ($vuetify.breakpoint.name === 'xs' ? '13vw' : '9vw')}"
                      class="mr-4"></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-n6">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="email"
                          hide-details
                          class="shrink mr-2 ml-4 mt-0">
              </v-checkbox>
              <v-text-field dense
                  v-model="whereColumns.email"
                  label="Email"
                  @keyup.enter="searchFirms()"
                  class="mr-4 mt-1 mb-0">
              </v-text-field>
            </v-row>
            <v-row align="center" class="mt-2">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="website"
                          hide-details
                          class="shrink mr-2 ml-4 mt-0">
              </v-checkbox>
              <v-text-field dense
                            v-model="whereColumns.website"
                            label="Website"
                            @keyup.enter="searchFirms()"
                            class="mr-4">
              </v-text-field>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" xl="3" style="width: 275px" class="pa-0 ma-0">
        <v-card elevation="0" rounded="0" class="pa-0 ma-0">
          <v-card-title class="pt-1 pt-sm-5 justify-center">Producers/Other</v-card-title>
          <v-card-text class="pb-0 mb-0" style="margin-top: -16px">
            <v-row align="center" class="my-0">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="producer"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <span style="width: 82%" @keyup.enter="searchFirms()">
                  <ListViewer
                      dense
                      spellcheck="false"
                      v-model="whereColumns.producer"
                      listname="name"
                      nameonly
                      clearable
                      sorttable="short"
                      listtitle="Producer"
                      title
                      sort="1"/>
                </span>
            </v-row>
            <v-row align="center" class="my-0">
              <v-checkbox color="#909090"
                          value="solicitor"
                          v-model="checkedColumns"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <span style="width: 82%" @keyup.enter="searchFirms()">
                  <ListViewer
                      dense
                      spellcheck="false"
                      v-model="whereColumns.solicitor"
                      listname="name"
                      sorttable="short"
                      nameonly
                      clearable
                      listtitle="Sub"
                      title
                      sort="1"/>
                </span>
            </v-row>
            <v-row align="center" class="my-0">
              <v-checkbox color="#909090"
                          value="agent"
                          v-model="checkedColumns"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <span style="width: 82%" @keyup.enter="searchFirms()">
                  <ListViewer
                      dense
                      spellcheck="false"
                      v-model="whereColumns.agent"
                      listname="name"
                      sorttable="short"
                      nameonly
                      title
                      clearable
                      listtitle="Agent"
                      sort="1"/>
                </span>
            </v-row>
            <v-row align="center" class="my-0">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="type"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <span style="width: 82%" @keyup.enter="searchFirms()">
                  <ListViewer
                      dense
                      v-model="whereColumns.type"
                      listname="ftype"
                      nameonly
                      clearable
                      sorttable="short"
                      listtitle="Type"
                      title
                      sort="0"/>
                </span>
            </v-row>
            <v-row align="center" class="my-n1">
              <v-checkbox color="#909090"
                          v-model="checkedColumns"
                          value="status"
                          hide-details
                          class="shrink mr-2 ml-4 mt-n1">
              </v-checkbox>
              <span style="width: 82%" @keyup.enter="searchFirms()">
                  <ListViewer
                      dense
                      v-model="whereColumns.status"
                      listname="fstatus"
                      nameonly
                      clearable
                      sorttable="short"
                      listtitle="Status"
                      title
                      sort="0"/>
                </span>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" xl="3" style="width: 275px" class="pa-0 mt-4 ma-0">
        <v-card elevation="0" rounded="0" class="pa-0 ma-0">
          <v-card-title class="py-0 py-sm-1 justify-center"> Numbers </v-card-title>
          <v-card-text class="pb-0 mb-0" style="margin-top: 3px">
            <v-row align="center" class="my-n2">
              <v-checkbox color="#909090" value="fein"
                          v-model="checkedColumns"
                          hide-details
                          class="shrink mr-2 ml-4 mt-0">
              </v-checkbox>
              <v-text-field dense
                  v-model="whereColumns.fein"
                  label="FEIN"
                  @keyup.enter="searchFirms()"
                  class="mr-4">
              </v-text-field>
            </v-row>
            <v-row align="center" class="mt-2">
              <v-checkbox color="#909090" value="phone"
                          v-model="checkedColumns"
                          hide-details
                          class="shrink mr-2 ml-4 mt-0">
              </v-checkbox>
              <v-text-field dense
                  label="Phone"
                  v-model="whereColumns.phone"
                  @keyup.enter="searchFirms()"
                  class="mr-4">
              </v-text-field>
            </v-row>
            <v-row align="center" class="mb-sm-12" style="margin-bottom: 15px">
              <v-checkbox color="#909090"
                          value="fax"
                          v-model="checkedColumns"
                          hide-details
                          class="shrink mr-2 ml-4 mt-1">
              </v-checkbox>
              <v-text-field dense
                  label="Fax"
                  v-model="whereColumns.fax"
                  @keyup.enter="searchFirms()"
                  class="mr-4 mb-n2">
              </v-text-field>
            </v-row>
          </v-card-text>

        <div align="center">
          <v-btn class="mb-7" @click="searchFirms()" color="secondary">Search</v-btn>
          <v-btn class="mb-7" @click="$emit('clear')" text tile color="secondary">Clear</v-btn>
        </div>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListViewer from "../../ListViewer";

export default {
  name: "FirmsOptions",
  props: {
    checkedProp: Array,
    info: Array,
    whereProp: Object,
    default() {
      return {};
    }
  },
  components: { ListViewer },
  data() {
    return {
      checkedColumns: this.checkedProp,
      whereColumns: this.whereProp,
    }
  },

  watch: {
    // update parent component if needed values change
    checkedColumns: function(newValue, oldValue) {
      if (!this.checkedColumns.includes('fid')) {this.checkedColumns.unshift('fid')}
      this.emitInfo();
    },

    whereColumns: {
      handler() {
        this.emitInfo();
      },
      deep: true,
    },

    // update props
    checkedProp() { this.checkedColumns = this.checkedProp },
    whereProp() { this.whereColumns = this.whereProp },
  },

  methods: {
    emitInfo() {
      const info = [ this.checkedColumns, this.whereColumns ];
      this.$emit('update', info);
    },

    searchFirms() {
      this.$emit('search');
    },
  },

}
</script>

<style>
/*too much extra space above "Names" header from 600-775 screen width, so use .hideTopSpace to hide it*/
@media screen and (min-width: 600px) {.hideTopSpace { display: none !important }}
@media screen and (max-width: 775px) {.hideTopSpace { display: none !important }}
</style>