import CommonAPIService from './CommonHandler';

export class ProgramsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getPrograms(router) {
    const fields = 'prid,mgaid,proid,depid,subid,srcid,spnid,doiid,slaid,tax_type'; //pass wanted fields from programs
    const sortIds = 'mgaid,proid,spnid';
    const perPage = 10000;
    const page = 1;
    const hasLinkedPolicy = true;
    const url = `/api/programs?sortIds=${sortIds}&fields=${fields}&page=${page}&perPage=${perPage}&hasLinkedPolicy=${hasLinkedPolicy}`;
    return this.CommonAPIService.getCall(url, router);
  }

  addProgram(programData, router) {
    const url = '/api/programs';
    return this.CommonAPIService.postCall(url, programData, router);
  }

  updateProgram(prid, programData, router) {
    const url = `/api/programs/${prid}`;
    return this.CommonAPIService.putCall(url, programData, router);
  }

  getProgram(prid, router) {
    const fields = 'prid,mgaid,proid,depid,subid,srcid,spnid,doiid,slaid,tax_type'; //pass wanted fields from programs
    const sortIds = 'mgaid,proid,spnid';
    const hasLinkedPolicy = true;
    const url = `/api/programs/${prid}?sortIds=${sortIds}&fields=${fields}&withUnderWriters=true&hasLinkedPolicy=${hasLinkedPolicy}`;
    return this.CommonAPIService.getCall(url, router);
  }

  removeProgram(prid, router){
    const url = `/api/programs/${prid}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  duplicateProgram(prid, router) {
    const url = `/api/programs?action=duplicate`;
    return this.CommonAPIService.postCall(url, { prid }, router);
  }
}

export default ProgramsAPIService;
