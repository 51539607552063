<template>
  <div>
    <v-row justify="center">
      <v-col class="mx-2" xl="11">
        <v-row>
          <v-col cols="8" class="my-0 pt-4">
            <h4 class="font-weight-regular mb-5">
              Taxes
              <v-icon class="mb-1 ml-2" @click="helpDialog = true">mdi-information-outline</v-icon>
            </h4>
          </v-col>
          <v-col cols="2" class="my-0 py-0">
            <v-btn-toggle v-model="getMethod" tile color="secondary" group>
              <v-btn value="date">Date</v-btn>
              <v-btn value="year">Year</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="2" class="my-0 py-0">
            <year-select v-if="getMethod === 'year'" v-model="year" :start-date="moment().format('YYYY')"
                         :options="{label: 'Year'}" :years-before="moment().format('YYYY') - 2020"
                         :years-after="50"></year-select>
            <date-picker-text-field v-else v-model="startDate" offset-y label="Start Date" prepend-icon="mdi-calendar-month"
                                    input-color="primary" picker-color="secondary" :rules="[rules.required, rules.date]" />
          </v-col>
        </v-row>
        <span v-if="!taxes || !Object.keys(taxes).length">There are no tax rates for the selected start date.</span>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="state in states" :key="state.code">
            <v-expansion-panel-header>
              <h5 class="font-weight-regular">{{ stateMap[state.code] }}</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-data-table :headers="headers" :items="taxes[state.code]" :sort-by="['entity', 'start_date']"
                                :mobile-breakpoint="0" multi-sort :sort-desc="[false, true]">
                    <template v-slot:item.actions="{ item }">
                      <div v-if="!item.end_date">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="openUpdateDialog(item)" small>mdi-pencil</v-icon>
                          </template>
                          <span>Configure new tax rate</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="ml-2" v-on="on" @click="openDeleteDialog(item.id)" small>mdi-trash-can-outline</v-icon>
                          </template>
                          <span>Delete tax rate</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="ml-2" v-on="on" @click="openAddEndDialog(item.id)" small>mdi-calendar-plus</v-icon>
                          </template>
                          <span>Add end date</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="ml-2" v-on="on" @click="openRemoveEndDialog(item.id)" small>mdi-calendar-minus</v-icon>
                          </template>
                          <span>Remove end date</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="mt-2" justify="end">
                <v-btn v-if="taxes[state.code].length < 2" fab x-small color="secondary" elevation="3" @click="openCreateDialog(state)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <tax-info-dialog v-model="helpDialog"></tax-info-dialog>
    <add-end-date-dialog v-model="addEndDialog" :id="addEndId" @updated="endDateAdded"></add-end-date-dialog>
    <create-tax-dialog v-model="createDialog" :create-data="createData" @created="taxCreated"></create-tax-dialog>
    <delete-tax-dialog v-model="deleteDialog" :delete-id="deleteId" @deleted="taxDeleted"></delete-tax-dialog>
    <remove-end-date-dialog v-if="removeEndDialog" v-model="removeEndDialog" :id="removeEndId"
                            @updated="endDateRemoved"></remove-end-date-dialog>
    <update-tax-dialog v-model="updateDialog" :tax-data="updateTaxData" @updated="taxUpdated"></update-tax-dialog>
    <loading-overlay v-model="loading" :errors="errors" @close="errors = []; loading = false;"></loading-overlay>
    <success-snackbar v-model="success" :message="successMessage"></success-snackbar>
  </div>

</template>

<script>
  import moment from 'moment';
  import { StatesAPIService } from "../../servicehandlers/StatesAPIService";
  import { TaxesAPIService } from "@/servicehandlers/TaxesAPIService";
  import DatePickerTextField from "../DatePickerTextField";
  import UpdateTaxDialog from "./taxes/UpdateTaxDialog";
  import DeleteTaxDialog from "./taxes/DeleteTaxDialog";
  import LoadingOverlay from "../LoadingOverlay";
  import SuccessSnackbar from "../SuccessSnackbar";
  import CreateTaxDialog from "./taxes/CreateTaxDialog";
  import TaxInfoDialog from "./taxes/TaxInfoDialog";
  import YearSelect from "../YearSelect";
  import AddEndDateDialog from "./taxes/AddEndDateDialog";
  import RemoveEndDateDialog from "./taxes/RemoveEndDateDialog";

  const statesAPIService = new StatesAPIService();
  const taxesAPIService = new TaxesAPIService();

  export default {
    name: "TaxManager",
    components: {
      RemoveEndDateDialog,
      AddEndDateDialog,
      YearSelect, TaxInfoDialog, CreateTaxDialog, SuccessSnackbar, LoadingOverlay, DeleteTaxDialog, UpdateTaxDialog,
      DatePickerTextField },
    watch: {
      getMethod(newVal) {
        this.getTaxes();
      },
      startDate(newVal) {
        if (moment(newVal, 'YYYY-MM-DD', true).isValid()) this.getByDate();
      },
      year() {
        this.getByYear();
      }
    },
    data: () => ({
      addEndDialog: false,
      addEndId: '',
      createDialog: false,
      createData: {state: {}, fields: []},
      deleteDialog: false,
      deleteId: '',
      errors: [],
      fees: {
        depfee: 'MGA 1st',
        mgafee: 'MGA 2nd',
        profee: 'Producer',
        subfee: 'Sub Producer',
        srcfee: 'Source',
        spnfee: 'Sponsor'
      },
      getMethod: 'year',
      headers: [
        {text: 'Entity', value: 'entity'},
        {text: 'Rate', value: 'rate'},
        {text: 'Flat', value: 'flat'},
        {text: 'Taxed Fees', value: 'formatted_fees'},
        {text: 'Rounding', value: 'round'},
        {text: 'Admitted', value: 'formatted_admitted'},
        {text: 'Field', value: 'field'},
        {text: 'Start Date', value: 'start_date'},
        {text: 'End Date', value: 'end_date'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      helpDialog: false,
      loading: false,
      removeEndDialog: false,
      removeEndId: '',
      roundingOptions: [],
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        numeric: (x) => !isNaN(parseInt(x)) || 'Must be a numeric value.',
        positive: (x) => x > 0 || 'Must be a positive number.',
        required: (x) => !!x || 'This field is required.',
      },
      startDate: moment().format('YYYY-MM-DD'),
      states: [],
      stateMap: {},
      success: false,
      successMessage: '',
      taxes: {},
      updateDialog: false,
      updateTaxData: {},
      year: moment().format('YYYY')
    }),
    created() {
      this.getStates();
      this.getTaxes();
    },
    methods: {
      endDateAdded() {
        this.getTaxes();
        this.success = true;
        this.successMessage = 'End date successfully added.';
      },
      endDateRemoved() {
        this.getTaxes();
        this.success = true;
        this.successMessage = 'End date successfully removed.';
      },
      formatFees(fees) {
        if (!fees.length) return 'None';
        if (fees.includes('*')) return 'All';
        return fees.map(fee => this.fees[fee]).join(',');
      },
      getByDate() {
        this.loading = true;
        taxesAPIService.getTaxesByDate(this.startDate, this.$router)
          .then(taxes => {
            this.taxes = taxes.reduce((taxMap, tax) => {
              tax.formatted_fees = this.formatFees(tax.taxed_fees);
              tax.formatted_admitted = tax.admitted ? 'Yes' : 'No';

              taxMap[tax.state] ? taxMap[tax.state].push(tax) : taxMap[tax.state] = [tax];
              return taxMap;
            }, {});

            this.states = taxes.reduce((stateList, tax) => {
              const inList = stateList.reduce((flag, state) => flag || tax.state === state.code, false);
              if (!inList) stateList.push({code: tax.state});
              return stateList;
            }, []);

            this.loading = false;
          }).catch(() => this.errors = ['Failed to retrieve tax rates.']);
      },
      getByYear() {
        this.loading = true;
        taxesAPIService.getTaxesByPeriod(`${this.year}-01-01`, `${this.year}-12-31`)
          .then(taxes => {
            this.taxes = taxes.reduce((taxMap, tax) => {
              tax.formatted_fees = this.formatFees(tax.taxed_fees);
              tax.formatted_admitted = tax.admitted ? 'Yes' : 'No';

              taxMap[tax.state] ? taxMap[tax.state].push(tax) : taxMap[tax.state] = [tax];
              return taxMap;
            }, {});

            this.states = taxes.reduce((stateList, tax) => {
              const inList = stateList.reduce((flag, state) => flag || tax.state === state.code, false);
              if (!inList) stateList.push({code: tax.state});
              return stateList;
            }, []);

            this.loading = false;
          }).catch(() => this.errors = ['Failed to retrieve tax rates.']);
      },
      getStates() {
        statesAPIService.getStates(this.$router)
          .then(states => {
            this.stateMap = states.reduce((stateMap, state) => {
              stateMap[state.code] = state.name;
              return stateMap;
            }, {});
          })
      },
      getTaxes() {
        if (this.getMethod === 'year') this.getByYear();
        else this.getByDate();
      },
      openAddEndDialog(id) {
        this.addEndId = id;
        this.addEndDialog = true;
      },
      openCreateDialog(state) {
        this.createData.state = {...state, name: this.stateMap[state.code]};
        this.createData.fields = ['DOI', 'SLA'].filter(field => {
          for (let tax of this.taxes[state.code]) {
            if (tax.field === field) return false
          }
          return true;
        });
        this.createDialog = true;
      },
      openDeleteDialog(id) {
        this.deleteId = id;
        this.deleteDialog = true;
      },
      openRemoveEndDialog(id) {
        this.removeEndId = id;
        this.removeEndDialog = true;
      },
      openUpdateDialog(tax) {
        this.updateTaxData = tax;
        this.updateDialog = true;
      },
      taxCreated() {
        this.getTaxes();
        this.success = true;
        this.successMessage = 'Tax rate successfully created.';
      },
      taxDeleted() {
        this.getTaxes();
        this.success = true;
        this.successMessage = 'Tax rate successfully deleted.';
      },
      taxUpdated() {
        this.getTaxes();
        this.success = true;
        this.successMessage = 'New tax rate successfully configured.';
      }
    }
  }
</script>

<style scoped>

</style>