<template>
  <v-autocomplete :value="value" @input="$emit('input', $event)" :items="producers" item-text="short" :item-value="itemValue"
                  v-bind="options"></v-autocomplete>
</template>

<script>
  import { ListsAPIService } from "@/servicehandlers/ListsAPIService";

  const listsAPIService  = new ListsAPIService();

  export default {
    name: "ProducerSelect",
    props: {
      itemValue: {
        default: 'short'
      },
      options: {
        default: () => ({})
      },
      value: {
        required: true
      }
    },
    data: () => ({
      producers: []
    }),
    created() {
      listsAPIService.getProducers(this.$router)
        .then(producers => { this.producers = producers });
    }
  }
</script>

<style scoped>

</style>