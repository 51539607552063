<template>
  <div>
    <v-card block class="px-6 py-0 d-flex d-print-none flex-row justify-space-between" color="primary" dark
            :key="$_sideMenu"  large sticky-offset="$_offset" sticky-z-index="99"
            style="border-top: 1px solid white !important" tile v-sticky>
      <div>
        <information-dialog-icon confirmClass="mr-3 mb-1 title" confirmColor="secondary darken-1"
                                 icon-color="white" title="Loss Run Report" tclass="headline grey lighten-2 mb-8">
          <p>For the Loss Run, enter values for FID, Carrier, and Type, then press return or click
            LOSS<span class="hidden-sm-and-down"> RUN</span> button.</p>
          <p v-if="$_sideMenu">For better print results, switch to top menu by clicking the lion in the upper-left corner.</p>
          <p>After the report appears below, click <v-icon>mdi-printer</v-icon> to open the print dialog.</p>
          <p>Recommended printer settings include landscape, default margins, and Print backgrounds.</p>
          <p>The optional premium, reserves, and monitoring columns can be added
            to the report by checking the corresponding checkboxes.</p>
        </information-dialog-icon>
        <button-tip btnclass="px-6" @click="getLossRun()" :disabled="!fid || !carrier || !type || !formValid"
                    hideSmDownAfter=" Run Report" large text tile tip="Get loss run data" txt="Loss "/>
      </div>
      <div>
        <button-tip btnclass="px-8" @click="printDiv()" :disabled="!fid || !carrier || !type || !searchResultLoadedShow"
                    icolor="white" icon iname="mdi-printer" tip="Open print dialog"/>
      </div>
      <div>
        <button-tip btnclass="px-8" @click="clearSearch()" large text tile tip="Clear all the search fields"
                    txt="Clear"/>
      </div>
    </v-card>
    <v-card tile class="pr-6 mx-0 pt-2 pb-1 mt-3 mb-2 d-print-none elevation-18">
      <v-form ref="form" v-model="formValid">
        <v-row class="my-n1 mx-0 pl-0">
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center">
              <v-text-field clearable @keyup.enter="getLossRun()" label="FID" :rules="rules" v-model="fid"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input @enter="getLossRun()" :items="$_lists.carrier" label="Carrier" :rules="rules"
                            :type="'VAutocomplete'" v-model="carrier"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input @enter="getLossRun()" :items="$_lists.ptype" label="Type" :rules="rules"
                            :type="'VAutocomplete'" v-model="type"/>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row class="mt-n4 mb-0 justify-space-around">
              <v-checkbox class="mx-2 mt-0" color="accent1" hide-details label="Show Premium" v-model="showPremium"/>
              <v-checkbox class="mx-2 mt-0" color="accent1" hide-details label="Show Reserves" v-model="showReserves"/>
              <v-checkbox class="mx-2 mt-0" color="accent1" hide-details label="Show Monitor" v-model="showMonitoring"/>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <div class="px-4 pb-6" v-if="searchResultLoadedShow">
      <div class="ma-6 lossheader text-center" v-if="fid && lossRunData.length > 1">
        <h4 class="mb-0" v-if="carrier && type">{{ carrier }} {{ $_lists['ptype'].find(o => o.text === type).long }}</h4>
        <h5> Loss Run for  {{ firm.insured }}</h5>
      </div>
      <v-data-table class="px-0 mx-0 mt-6 elevation-4 left med-table-rows prtloss striped-table table-style"
                    :headers="colHeaders"
                    hide-default-footer
                    :items="lossRunData"
                    :items-per-page="-1"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :mobile-breakpoint="0"
                    no-data-text="There is no data available for that search.">
      </v-data-table>
    </div>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>
<script>
  import currency from 'currency.js';
  import ErrorDialog from "../ErrorDialog";
  import InformationDialogIcon from "../InformationDialogIcon";
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { ReportsAPIService } from '@/servicehandlers/ReportsAPIService';

  const firmsAPIService = new FirmsAPIService();
  const reportsAPIService = new ReportsAPIService();

  export default {
    name: 'LossRun',
    components: { ErrorDialog, InformationDialogIcon },

    data() {
      return {
        carrier: 'Lloyds',
        colHeaders: [], // for vuetify table
        fid: '',
        currencyFieldsToFormat: ['monitoring', 'reserves', 'defense', 'damages', 'total'],
        firm: {},
        firstSearch: true,
        formValid: false,
        loading: false,
        lossRunData: [],
        searchResultLoadedShow: false,
        showMonitoring: true,
        showPremium: true,
        showReserves: true,
        type: 'FPL',
        rules: [(v) => !!v || 'Field is required'],
      };
    },

    watch: {
      carrier() {// IF carrier is changed THEN loss run results no longer match selected search parameters, so don't show
        this.searchResultLoadedShow = false;
      },
      fid() {// IF fid is changed THEN loss run search results no longer match selected search parameters, so don't show
        this.searchResultLoadedShow = false;
      },
      showMonitoring() {
        this.sumAndFormatData();// re-sum the totals including/excluding Monitoring
        this.buildHeaders();// re-build headers to include/exclude Monitoring column
      },
      showPremium() {
        this.buildHeaders();// re-build headers to include/exclude Premium column
      },
      showReserves() {
        this.sumAndFormatData();// re-sum the totals including/excluding Reserves
        this.buildHeaders();// re-build headers to include/exclude Reserves column
      },
      type() {// IF type is changed THEN loss run search results no longer match selected search params, so don't show
        this.searchResultLoadedShow = false;
      },
    },

    created() {
      document.title = 'Loss Run';
    },

    methods: {
      getLossRun() {
        if (!this.formValid || !this.fid) return;
        this.loading = true;
        this.buildHeaders();
        reportsAPIService.getLossRun(this.fid, this.type, this.carrier, this.$router).then((lossRun) => {
          this.loading = false;
          this.searchResultLoadedShow = true;
          lossRun.totals.polnum = "Totals";// prepare totals row
          if (lossRun.data.length) lossRun.data.push(lossRun.totals);// add totals row to end of lossRun.data
          this.lossRunData = lossRun.data;
          document.body.style.overflow = this.lossRunData.length < 20 ? 'hidden' : 'revert';// don't print unneeded page
          this.sumAndFormatData();
        })
          .catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to get Loss Run data",
              forDevelopers: e
            })
          });
        firmsAPIService.getSingleFirm(this.fid, this.$router).then((firm) => {
          this.firm = firm;// used in Loss Run report heading
        })
          .catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to get firm",
              forDevelopers: e
            })
          });
      },

      buildHeaders() {// create header array
        this.colHeaders = [{value:'polnum', text:' Policies', align: 'center',sortable:false}];// create w/ 1st column
        this.colHeaders.push({value:'incept', text:'Incept', align: 'center', sortable: false});//push next col on array
        if (this.showPremium) this.colHeaders.push({value:'premium', text:' Premium', align: 'right',sortable: false});
        this.colHeaders.push({value:'claims_count', text:'Count', align: 'end', sortable: false});
        if (this.showMonitoring) this.colHeaders.push({value:'monitoring',text:'Monitor',align:'end',sortable: false});
        if (this.showReserves) this.colHeaders.push({value:'reserves', text:'Reserves', align:'end', sortable: false});
        this.colHeaders.push({value:'defense', text:'Defense',  align: 'end', sortable: false});
        this.colHeaders.push({value:'damages', text:'Damages',  align: 'end', sortable: false});
        this.colHeaders.push({value:'total', text:' Totals',  align: 'right', sortable: false});
      },

      clearSearch() {
        this.loading = false;
        this.searchResultLoadedShow = false;
        this.fid = undefined;
        this.carrier = '';
        this.type = '';
        this.showMonitoring = false;
        this.showPremium = false;
        this.showReserves = false;
      },

      printDiv() {
        window.print();
      },

      sumAndFormatData() {
        for (let row of this.lossRunData) {
          // create list of fields to sum for total column, based on user selection of 'monitoring' and 'reserves'
          let fieldsToSum = ['defense', 'damages' ];
          row.total = 0;// total of fieldsToSum
          if (this.showMonitoring) fieldsToSum.push('monitoring');
          if (this.showReserves) fieldsToSum.push('reserves');
          for (let f of fieldsToSum) {
            // if the f field in a row has a value and it's not null or zero, THEN add value to row total
            if (!!row[f] && (row[f] + 1 !== 1)) row.total = currency(row.total).add(row[f]);
          }
          for (let f of this.currencyFieldsToFormat) row[f] = currency(row[f]).format();// format currencyFieldsToFormat
        }
      },

    },
  }
</script>
<style>
  /* page header style */
  .lossheader {
    color: black;
    font-size: larger !important;
  }
  /* styles for last row of table - row of totals */
  .table-style tr:last-child td {
    background-color: lightyellow;
    border-top: 1px solid black;
    border-bottom: 2px solid black;
    color: black;
    font-weight: bolder;
    height: 36px !important;
  }
  /* table header style */
  .table-style.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    background-color: rgba(0, 0, 0, 0.12);/* grey header color helps hide strange vertical lines in print and pdf */
    border-bottom: 1px solid black;
    color: black;
  }
  /* remove horizontal table row lines */
  .table-style.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none;
  }
  /* add shading to alternate table rows */
  .striped-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .06);
  }
  /* position and style table for printing */
  @media print
  {
    body * { visibility: hidden }
    html, body { height: 100% }
    .lossheader * { visibility: visible }
    .lossheader { position: absolute !important; top: -60px !important; left: -40px !important; width: 98% !important }
    .prtloss * { visibility: visible }
    .prtloss { position: absolute !important; top: 25px !important; width: 98% !important; height: auto !important }
  }
  @page { margin: .5in .4in .5in .3in !important }
</style>
