<template>
  <v-autocomplete :value="value" @input="$emit('input', $event)" :items="years" v-bind="options"></v-autocomplete>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "YearSelect",
    props: {
      options: {
        default: () => ({}),
        type: Object
      },
      startDate: {
        default: moment().format('YYYY'),
        type: String
      },
      value: {
        required: true,
        type: String
      },
      yearsAfter: {
        default: 50,
        type: Number
      },
      yearsBefore: {
        default: 0,
        type: Number
      },
    },
    computed: {
      years() {
        return Array.from({length: this.yearsBefore + this.yearsAfter},
          (_, i) => (i + (this.startDate - this.yearsBefore)).toString());
      }
    }
  }
</script>

<style scoped>

</style>