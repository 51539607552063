import CommonAPIService from './CommonHandler';

export class ContactAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getContactsByFid(fid, router) {
    const fields = 'contacts.email,people.email,given,surname,plid,salutation'; //if col exists in people and contacts, pass as table.col
    const url = `/api/contacts/byfid/${fid}?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  deleteContact(cid, router) {
    const url = `/api/contacts/${cid}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getContacts(router) {
    const url = '/api/contacts';
    return this.CommonAPIService.getCall(url, router);
  }

  getContactById(cid, router) {
    const url = `/api/contacts/${cid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getContactList(router) {
    const fields = 'cid,sort';
    const order = 'sort';
    const url = `/api/contacts?fields=${fields}&order=${order}`;
    return this.CommonAPIService.getCall(url, router);
  }

  createContact(contact, router) {
    const url = '/api/contacts';
    return this.CommonAPIService.postCall(url, contact, router);
  }

  updateContact(id, contact, router) {
    const url = `/api/contacts/${id}`;
    return this.CommonAPIService.putCall(url, contact, router);
  }

  getByType(type, router) {
    const url = `/api/contacts/type/${type}`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default ContactAPIService;
