<template>
  <div>
    <claim-options :info="info" @update="update"
                   :optionsExpansionProp="optionsExpansionOpen"
                   :whereProp="whereColumns"
                   :checkedProp="checkedColumns"
                   :includeProp="includeColumns"
                   :includeCheckProp="includeCheckColumns"
    ></claim-options>
    <v-card v-show="searchResultsLoaded && this.totalRecords > 0" class="pa-4 mt-n1" align="center" tile dark block
            color='primary'>Total Records: {{ this.totalRecords }}</v-card>
      <div v-if="totalPages >= 1">
        <v-data-table class="pa-4 fullSizeTable elevation-1 left"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      :headers="colHeaders"
                      fixed-header
                      :mobile-breakpoint="0"
                      no-data-text="There is no claims data available for that search."
                      :server-items-length="this.totalRecords"
                      :options.sync="options"
                      :items="claims"
                      :footer-props="{ 'items-per-page-options': [15, 25, 50, 75, 100] }">
          <template v-slot:item.note="{ item }">
            <div class="pa-2" v-html="item.note"></div>
          </template>
          <template v-slot:item.facts="{ item }">
            <div class="pa-2" v-html="item.facts"></div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }" v-if="rightsArray.includes('edit-claim')">
                 <v-icon v-on="on" class="mr-4" @click="editClaimPage(item)">mdi-pencil</v-icon>
              </template>
                Edit Popup
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-2" @click="viewClaim(item)">mdi-open-in-new</v-icon>
              </template>
              Print/View Popup
            </v-tooltip>
          </template>
          <template v-slot:item.res_def="{ item }">
            <div v-if="!!item.res_def">${{ (parseFloat(item.res_def)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.defense="{ item }">
            <div v-if="!!item.defense">${{ (parseFloat(item.defense)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.cur_def="{ item }">
            <div v-if="!!item.cur_def">${{ (parseFloat(item.cur_def)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.damages="{ item }">
            <div v-if="!!item.damages">${{ (parseFloat(item.damages)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.cur_dam="{ item }">
            <div v-if="!!item.cur_dam">${{ (parseFloat(item.cur_dam)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.reserve="{ item }">
            <div v-if="!!item.reserve">${{ (parseFloat(item.reserve)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.res_mon="{ item }">
            <div v-if="!!item.res_mon">${{ (parseFloat(item.res_mon)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.monitor="{ item }">
            <div v-if="!!item.monitor">${{ (parseFloat(item.monitor)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.cur_mon="{ item }">
            <div v-if="!!item.cur_mon">${{ (parseFloat(item.cur_mon)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.deductible="{ item }">
            <div v-if="!!item.deductible">${{ (parseFloat(item.deductible)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.paid_ded="{ item }">
            <div v-if="!!item.paid_ded">${{ (parseFloat(item.paid_ded)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</div>
          </template>
          <template v-slot:item.facts="{ item }">
            <div v-if="item.facts">
              <v-tooltip bottom nudge-left nudge-width="200">
                <template v-slot:activator="{ on }">
                  <div style="height: 40px"
                       class="scroll px-0 caption" v-on="on"
                       v-html="item.facts.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                       @click="dialogShowFull(item.facts)">
                  </div>
                </template>
                <span>Click to See Facts</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.note="{ item }">
            <div v-if="item.note">
              <v-tooltip bottom nudge-left nudge-width="200">
                <template v-slot:activator="{ on }">
                  <div style="height: 40px"
                       class="scroll px-0 caption" v-on="on"
                       v-html="item.note.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                       @click="dialogShowFull(item.note)">
                  </div>
                </template>
                <span>Click to See Note</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </div>

    <v-dialog v-model="dialogToShowFullText">
      <v-card class="pa-4 rounded-0">
        <v-row justify="end" :style="{ position: 'sticky', top: '15px' }">
          <v-btn icon
                 x-large
                 class="mt-n4"
                 @click="dialogToShowFullText = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-card-text class="scroll" v-html="dialogText.replace(/(?:\r\n|\r|\n)/g, '<br>')">
        </v-card-text>
      </v-card>
    </v-dialog>

    <ActionDialog :value="showEmptySearchMessage" :max-width="$vuetify.breakpoint.name === 'xs' ? 300 : 600"
                  title="Empty Search" confirm-text="Ok" confirm-color="red lighten-2"
                  :second-button="false" @confirm="showEmptySearchMessage = false">
      <v-divider color="red"></v-divider>
      At least one search field must be filled to run a search.
    </ActionDialog>

   <ErrorDialog ref="errorDialog"/>

  </div>
</template>
<script>
  import ActionDialog from "../../ActionDialog";
  import ClaimOptions from "./ClaimOptions";
  import Datepicker from 'vuejs-datepicker';
  import EditClaim from "@/components/search/claims/EditClaim";
  import ErrorDialog from "../../ErrorDialog";
  import ListViewer from "../../ListViewer";
  import loginCheckMixin from '@/mixin';
  import ViewClaim from '@/components/search/claims/ViewClaim';
  import { ClaimsAPIService } from "@/servicehandlers/ClaimsAPIService";
  import { CommonAPIService } from "@/servicehandlers/CommonHandler";
  import { ContactAPIService } from '@/servicehandlers/ContactAPIService';

  const claimAPIService = new ClaimsAPIService();
  const contact = new ContactAPIService();
  const apiService = new CommonAPIService();

  export default {
    name: 'SearchClaims',
    mixins: [loginCheckMixin],
    components: { ActionDialog, ClaimOptions, ListViewer, Datepicker, EditClaim, ErrorDialog,
      ViewClaim },
    data() {
      return {
        allFields: { // required for doing a lookup and easy setting of the objects
          stamp: { label: 'Stamp', sortable: true },
          clid: { label: "Claim ID", sortable: false },
          fid: { label: 'FID', sortable: true },
          pid: { key: 'policy.insured', label: 'Policy', sortable: true },
          carrier: { label: 'Carrier', sortable: true },
          type: { label: 'Type', sortable: true },
          status: { label: 'Status', sortable: true },
          claimant: { label: 'Claimant', sortable: true },
          insdef: { label: 'Insureds', sortable: true },
          othdef: { label: 'Othdef', sortable: true },
          insuit: { label: 'In Suit', sortable: true },
          resolved: { label: 'Resolved', sortable: true },
          note: { label: 'Note', sortable: true },
          facts: { label: 'Facts', sortable: true },
          engaged: { label: 'Engaged Date', sortable: true },
          error: { label: 'Error Date', sortable: true },
          report: { label: 'Report Date', sortable: true },
          claim: { label: 'Claim Date', sortable: true },
          close: { label: 'Close Date', sortable: true },
          summonitor: { label: 'Sum Monitor', sortable: true },
          maxmonitor: { label: 'Max Monitor', sortable: true },
          minmonitor: { label: 'Min Monitor', sortable: true },
          countmonitor: { label: 'Count Monitor', sortable: true },
          res_mon: { label: 'Monitor Reserve', sortable: true },
          monitor: { label: 'Monitor Incurred', sortable: true },
          res_def: { label: 'Defense Reserve', sortable: true },
          defense: { label: 'Defense Incurred', sortable: true },
          reserve: { label: 'Damages Reserve', sortable: true },
          damages: { label: 'Damages Incurred', sortable: false },
          feefund: { label: 'Fee Fund', sortable: true },
          plt_code: { key: 'claim_plt_code.short', label: 'Plt Code', sortable: false },
          def_code: { key: 'claim_def_code.short', label: 'Def Code', sortable: true },
          aop_code: { key: 'claim_aop_code.long', label: 'Aop Code', sortable: true },
          err_code: { key: 'claim_err_type.short', label: 'Error Type', sortable: true },
          est_size: { label: 'Estate Size', sortable: true },
          // state: { label: 'Claim State', sortable: true },
          zip: { label: 'Zip', key: 'firm.zip', sortable: true },
          clm_ack: { label: 'Acknowledged', sortable: true },
          clm_num: { label: 'Claim Number', sortable: true },
          firm_state: { label: 'Firm State', key: 'firm.state', sortable: true },
          state: { label: 'Claim State', key: 'state', sortable: true },
          adj_cid: { key: 'claim_contact.name1', label: 'Claims Handler', sortable: true },
          adj_plid: { key: 'claim_person.fullName', label: 'Claims Counsel', sortable: true },
          def_cid: { key: 'defense_contact.name1', label: 'Defense Firm', sortable: true },//people
          def_plid: { key: 'defense_person.fullName', label: 'Defense Counsel', sortable: true },
          first_rep: { label: 'First Report', sortable: true },//date
          last_rep  : { label: 'Last Report', sortable: true },//date
          deductible: { label: 'Deductible', sortable: true },
          paid_ded : { label: 'Paid Deductible', sortable: true },
          cur_dam  : { label: 'Current Damages', sortable: true },
          cur_def : { label: 'Current Defense', sortable: true },
          cur_mon  : { label: 'Current Monitor', sortable: true },
        }, // standard columns that exist as attributes
        checkedColumns: ['clid', 'fid', 'clm_num', 'type', 'status', 'error', 'claim', 'report',
          'close', 'carrier', 'claimant', 'insdef', 'res_mon', 'monitor', 'res_def',
          'defense', 'reserve', 'damages' ], //columns specific to the where clause, in table claims
        claims: [],
        claimsHandlers: [],
        claimsCounsel: [],
        colHeaders: [], // for vuetify table
        currentPage: 1, // for pagination
        dialogText: '',
        dialogToShowFullText: false,
        fields: {}, // fields are the displayable fields for the DB
        firm: {},
        firstSearch: true,
        headerValue: '',
        includeColumns: [], // columns that are specific to the include checkboxes
        includeCheckColumns: {}, // columns that are specific to include boxes, but with "where"
        info: [],
        loading: false,
        newSearch: true,
        options: {
          groupBy: [],
          groupDesc: [],
          itemsPerPage: 100,
          multiSort: false,
          mustSort: false,
          page: 1,
          sortBy: [],
          sortDesc: [],
        },
        optionsExpansionOpen: [],
        perPage: 100, // for pagination
        searchResultsLoaded: false,
        sortBy: null, //
        sortDesc: false, //
        totalPages: 0, // for pagination
        totalRecords: 0, // for pagination
        rightsArray: [],
        searchItems: {},
        showEmptySearchMessage: false,
        whereColumns: {}, // columns that are specific to the where clause
      };
    },

    watch: {

      checkedColumns: function(newValue, oldValue) {
        if (!this.checkedColumns.includes('clid')) {this.checkedColumns.unshift('clid')}
      },

      includeCheckColumns(oldIncludeCheckColumns, newIncludeCheckColumns) {
        Object.keys(this.includeCheckColumns).forEach(key => {
          if (!this.includeCheckColumns[key]) {
            delete this.includeCheckColumns[key];
          } else {
            if (!this.includeColumns.includes(key)) {
              this.includeColumns.push(key);
            }
          }
        });
      },

      includeColumns: function(newValue, oldValue) {
        Object.keys(this.includeCheckColumns).forEach(key => {
          if (!this.includeColumns.includes(key)) {
            delete this.includeCheckColumns[key];
          }
        });
      },

      options: {
        // when options change, send new values in searchItems to api
        handler (newValue, oldValue) {
          if (this.firstSearch) {
            this.firstSearch = false;
          } else {
            this.newSearch = false;
            this.setSearchItems();
            this.callApi(this.searchItems);
          }
        },
        deep: true,
      },

      whereColumns: {
        handler() {
          Object.keys(this.whereColumns).forEach(key => {
            if (this.whereColumns[key] === '') {
              delete this.whereColumns[key];
            }
          });
        },
        deep: true,
      },

    },

    created() {
      document.title = 'Claims';
      this.rightsArray = this.loginCheckMixin(['edit-claim']);
    },

    methods: {
      dialogShowFull(text) {
        this.dialogToShowFullText = true;
        this.dialogText = text;
      },

      update(info) {
        this.checkedColumns = info[0];
        this.includeColumns = info[1];
        this.whereColumns   = info[2];
        this.includeCheckColumns = info[3];
      },

      viewClaim (claim) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/viewClaim/' +  claim.clid, '_blank',
          'resizeable=yes, width=' + (900)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (700));
      },

      editClaimPage(claim) {
        if (!claim.clid) return;
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/editClaim/' +  claim.clid, '_blank',
          'resizeable=yes, width=' + (900)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (700));
      },

      setSearchItems () {
        if (!this.firstSearch) { //if not the first search, update searchItems from options, otherwise leave as initialized
          if (!!this.newSearch) { //if new search, then reset page to 1, and update remaining searchItems from options
            this.currentPage = 1;
          } else {
            this.currentPage = this.options.page;
          }
            this.perPage = this.options.itemsPerPage;
            this.sortDesc = this.options.sortDesc[0];
            this.sortBy = this.options.sortBy[0];
        }
        this.cleanColumns();
        if (this.aggregateBool === true) {
          // remove the whereColumn claims ID
          let index = this.checkedColumns.indexOf('clid');
          if (index > -1) {
            this.checkedColumns.splice(index, 1);
          }
        }
        let whereColumnsForSearch  =  _.cloneDeep(this.whereColumns);
        this.searchItems = {
          page: this.currentPage,
          perPage: this.perPage,
          aggregates: this.aggregates,
          checkedColumns: this.checkedColumns,
          whereColumns: whereColumnsForSearch,
          includeCheckColumns: this.includeCheckColumns,
          includeColumns: this.includeColumns,
          sortDesc: this.sortDesc,
          sortBy: this.sortBy,
          simple: false,
        }
        if (!this.searchItems.sortDesc) {this.searchItems.sortDesc = false}

        // trim off any return characters at the end of strings from textareas: note and facts
        if (this.whereColumns.note) {
          this.whereColumns.note = this.whereColumns.note.trim()
        }
        if (this.whereColumns.facts) {
          this.whereColumns.facts = this.whereColumns.facts.trim()
        }
      },

      callApi(searchItems){
        const isEmptyWhere = !Object.values(searchItems.whereColumns).some(x => (x !== null && x !== ''));
        const isEmptyIncludeCheck = !Object.values(searchItems.includeCheckColumns).some(x => (x !== null && x !== ''));
        if (isEmptyWhere && isEmptyIncludeCheck) {
          this.showEmptySearchMessage = true;
          return;
        }
        this.optionsExpansionOpen = [];
        claimAPIService.getClaim(searchItems, this.$router).then((data) => {
          if (data) {
            this.loading = false;
            this.claims = data.result;
            for (let claim of this.claims) {
              if (claim.insuit) {
                if (claim.insuit === 'false' || claim.insuit === '0'
                 || claim.insuit.toUpperCase() === 'NO') {
                    claim.insuit = 'No';
                } else if (claim.insuit === "true" || claim.insuit === '1'
                        || claim.insuit.toUpperCase() === 'YES') {
                           claim.insuit = 'Yes';
                } else {   claim.insuit = '';}
              }

              if (claim.claim_person) {
               if (claim.claim_person.given === null) {
                 claim.claim_person.given = '';
               }
               if (claim.claim_person.surname === null) {
                 claim.claim_person.surname = '';
               }
               claim.claim_person.fullName = claim.claim_person.given + ' ' +
                 claim.claim_person.surname;
               if (claim.claim_person.suffix && claim.claim_person.suffix !==''){
                 claim.claim_person.fullName =
                 claim.claim_person.fullName + ', ' + claim.claim_person.suffix;
               }
             }
              if (claim.defense_person) {
                if (claim.defense_person.given === null) {
                  claim.defense_person.given = '';
                }
                if (claim.defense_person.surname === null) {
                  claim.defense_person.surname = '';
                }
                claim.defense_person.fullName = claim.defense_person.given
                  + ' ' + claim.defense_person.surname;
                if (claim.defense_person.suffix
                  && claim.defense_person.suffix !== '') {
                  claim.defense_person.fullName = claim.defense_person.fullName
                  + ', ' + claim.defense_person.suffix;
                }
              }
            }
            this.searchResultsLoaded = true;
            this.newSearch = false;
            if (data.totalPages && !isNaN(data.totalPages)) {
              this.totalPages = data.totalPages;
              } else {
                this.totalPages = 1;
              }
              if (data.count && !isNaN(data.count)) {
                this.totalRecords = data.count;
              } else {
                this.totalRecords = data.length;
              }
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$refs.errorDialog.showError({
              forDevelopers: e
            })
          })
      },

      searchClaims() {
        if (this.checkedColumns.length <= 0 && this.includeColumns.length <= 0) {
          return false;
        }
        this.newSearch = true;
        this.loading = true;
        this.buildFields();
        this.buildHeaders();
        this.setSearchItems();
        this.callApi(this.searchItems);
      },

      clearSearch() {
        this.claims = [];
        this.fields = {};
        this.claimsCounsel = [];
        this.colHeaders = [];
        this.newSearch = true;
        this.loading = false;
        this.searchResultsLoaded = false;
        this.aggregateBool = false;
        this.totalRecords = 0;
        this.includeColumns = [];
        this.whereColumns = {};
        this.includeCheckColumns = {};
        this.checkedColumns = ['clid', 'fid', 'clm_num', 'type', 'status', 'error', 'claim', 'report',
          'close', 'carrier', 'claimant', 'insdef', 'res_mon', 'monitor', 'res_def',
          'defense', 'reserve', 'damages' ];
        this.totalPages = 0;
        Object.keys(this.includeCheckColumns).forEach((prop) => {
          this.includeCheckColumns[prop] = null;
          delete this.includeCheckColumns[prop];
        });
        Object.keys(this.includeCheckColumns).forEach((prop) => {
          if (!this.includeCheckColumns[prop]) {
            delete this.includeCheckColumns[prop];
          }
        });
        if (this.whereColumns) {
          Object.keys(this.whereColumns).forEach((prop) => {
            if (!this.whereColumns[prop]) {
              delete this.whereColumns[prop];
            }
          });
        }
      },

      // clean columns
      // even though you uncheck certain items, they still might be included in the where clause
      // even though the value is empty. this method cleans that up before sending to the API
      cleanColumns() {
        Object.keys(this.whereColumns).forEach(key => {
          if (this.whereColumns.hasOwnProperty(key)) {
            const value = this.whereColumns[key];
            if (!value || value.length <= 0) {
              // get rid of it
              delete this.whereColumns[key];
            }
          }
        });
        Object.keys(this.includeCheckColumns).forEach(key => {
          if (this.includeCheckColumns.hasOwnProperty(key)) {
            const value = this.includeCheckColumns[key];
            if (!value || value.length <= 0) {
              // get rid of it
              delete this.includeCheckColumns[key];
            }
          }
        });
      },

      // purpose of this method is to build the fields that will be displayed
      buildFields() {
        // loop through each checkedColumn and build out the fields
        // if a column is checked, simply update the fields to reflect it
        this.fields = {}; // wipe out the old fields
        for (let i = 0; i < this.checkedColumns.length; i += 1) {
          // get the corresponding field from allColumns
          const key = this.checkedColumns[i];
          this.fields[key] = this.allFields[key];
        }
        // now loop through all of the includeColumns for any of them that were checked
        for (let i = 0; i < this.includeColumns.length; i += 1) {
          const key = this.includeColumns[i];
          this.fields[key] = this.allFields[key];
          if (this.includeColumns[i] === 'state') {
            this.fields['zip'] = this.allFields['zip'];
          }
        }
      },

      buildHeaders() {
        // using fieldsArray create array of headers needed for v-table and customize widths
        let fieldsArray = Object.entries(this.fields);
        this.colHeaders = [];

        let header = { text: 'Actions', value: 'actions', width: "160px", sortable: false, align: 'center'}
        this.colHeaders.push(header)
        if (fieldsArray.length != 0) {
          for (let i = 0; i < fieldsArray.length; i += 1) {
            let headerText  =  fieldsArray[i][1]['label'];
            if (fieldsArray[i][1]['key']) {
             this.headerValue =  fieldsArray[i][1]['key'];
            } else {
             this.headerValue  = fieldsArray[i][0];
            };
            let isSortable = "true"
            let colWidth = "200px";
            let align = 'start';
            switch(headerText) {
              case 'Firm State':
                isSortable = false;
                colWidth="90px";
                align='center';
                break;
              case 'Type':
              case 'In Suit':
                colWidth="90px";
                break;
              // case 'Firm':
              case 'FID':
                colWidth="100px";
                align='end';
                break;
              case 'Claim State':
                isSortable = true;
                colWidth="120px";
                align='center';
                break;
              case 'Error Type':
              case 'Plt Code':
              case 'Def Code':
              case 'Aop Code':
              case 'Claims Counsel':
              case '':
              case 'Policy':
                isSortable = false;
                colWidth="150px";
                break;
              case 'Monitor Reserve':
              case 'Monitor Incurred':
              case 'Defense Reserve':
              case 'Defense Incurred':
              case 'Current Damages':
              case 'Current Defense':
              case 'Current Monitor':
              case 'Damages Reserve':
              case 'Damages Incurred':
              case 'Deductible':
              case 'Paid Deductible':
              case 'Estate Size':
              case 'Claim Number':
                colWidth="160px";
                align='end';
                break;
              case 'Othdef':
                colWidth="300px";
                break;
              case 'Claims Handler':
              case 'Defense Counsel':
              case 'Claims Counsel':
                isSortable = false;
                colWidth="220px";
                break;
              // case 'Insdef':
              case 'Insureds':
              case 'Claimant':
                colWidth="300px";
                break;
              case 'Defense Firm':
                isSortable = false;
                colWidth="300px";
                break;
              case 'Facts':
              case 'Note':
                colWidth="800px";
                break;
              default:
                colWidth="140px";
            }
            header = {value: this.headerValue, text: headerText, width: colWidth, align: align, sortable: isSortable }
            if (headerText != 'Claim ID') {
            this.colHeaders.push(header);
            }
          }
        }
      },
    },
  }
</script>
<style>

</style>

