<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="deleteCom"
                 title="Are you sure?" max-width="500" confirm-class="white--text" confirm-color="red darken-2"
                 confirm-text="Delete">
    <warning-alert class="mt-4">
      Once commission data is deleted, <strong>it cannot be recovered.</strong> The previous commission rates will become the current rates.
    </warning-alert>
  </action-dialog>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import WarningAlert from "../../WarningAlert";
  import { CommissionsAPIService } from "@/servicehandlers/CommissionsAPIService";

  const commissionsAPIService = new CommissionsAPIService();

  export default {
    name: "DeleteCommissionDialog",
    components: {WarningAlert, ActionDialog},
    props: {
      id: {
        required: true
      },
      value: {
        required: true,
        type: Boolean
      }
    },
    methods: {
      deleteCom() {
        commissionsAPIService.deleteCom(this.id, this.$router)
          .then(() => {
            this.$emit('deleted');
            this.$emit('input', !this.value);
          })
      }
    }
  }
</script>

<style scoped>

</style>