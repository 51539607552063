<template>
  <v-alert dense border="left" type="info" text v-bind="alertProps" color="accent">
    <slot></slot>
  </v-alert>
</template>

<script>
  export default {
    name: "InfoAlert",
    props: {
      alertProps: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

<style scoped>

</style>