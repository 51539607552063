<template>
  <v-card flat>
    <v-card-title class="pb-0 ml-4">
      Contact
      <v-spacer />
      <v-btn
        color="secondary"
        class="mr-2"
        v-if="update"
        :loading="loading"
        @click="onUpdateContactSubmit()"
      >
        update
      </v-btn>
      <v-btn
        outlined
        class="mr-4"
        @click="$emit('update:toggle', false)"
      >
        cancel
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-0" style="font-size: 1.0625rem">
      <div class="d-flex">
        <v-card width="400px" class="flex-shrink-0" flat>
          <v-card-text>
            <v-text-field
              v-model="updatedContact.sort"
              outlined
              dense
              label="Sort"
              hide-details="auto"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.name1"
              outlined
              dense
              autofocus
              label="Name 1"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.name2"
              outlined
              dense
              label="Name 2"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
            <v-select
              v-model="typeVals"
              :items="types"
              chips
              dense
              class="types mt-1"
              label="Type"
              @change="onContactChange()"
              multiple
              outlined
              hide-details="auto"
            />
            <v-text-field
              v-model="updatedContact.address1"
              outlined
              dense
              label="Address 1"
              hide-details="auto"
              class="mt-3"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.address2"
              outlined
              dense
              label="Address 2"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
            <div class="d-flex">
              <v-text-field
                v-model="updatedContact.city"
                outlined
                dense
                label="city"
                hide-details="auto"
                class="mt-1"
                @keyup="onContactChange()"
              />
              <ListViewer
                v-model="updatedContact.state"
                @input="onContactChange()"
                listname="states"
                nameonly
                dense
                outlined
                clearable
                sorttable="short"
                :menuprops="{ maxHeight: 200 }"
                listtitle="State"
                :hide-details="true"
                class="mt-1"
                style="width: 125px"
                title
              />
              <v-text-field
                v-model="updatedContact.zip"
                outlined
                dense
                width="50"
                label="Zip"
                style="width: 90px"
                hide-details="auto"
                class="mt-1"
                @keyup="onContactChange()"
              />
            </div>
            <v-text-field
              v-model="updatedContact.phone"
              outlined
              dense
              label="Phone"
              hide-details="auto"
              class="mt-3"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.fax"
              outlined
              dense
              label="Fax"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.email"
              outlined
              dense
              label="Email"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.website"
              outlined
              dense
              label="Website"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
            <v-text-field
              v-model="updatedContact.fein"
              outlined
              dense
              label="FEIN"
              hide-details="auto"
              class="mt-1"
              @keyup="onContactChange()"
            />
          </v-card-text>
        </v-card>
        <v-divider vertical />
        <v-textarea
          v-model="updatedContact.note"
          outlined
          dense
          height="540px"
          label="Note"
          hide-details="auto"
          class="mt-4 ml-4"
          @keyup="onContactChange()"
        >
        </v-textarea>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
import ListViewer from "@/components/ListViewer";
const contactAPIService = new ContactAPIService();

export default {
  name: "EditContact",
  components: { ListViewer},
  props: ["toggle", "contact", "loading", "success", "error", "errorResponse"],
  data() {
    return {
      userPrefs: {},
      pageRights: [],
      groups: [],
      types: [
        "Adjuster",
        "Association",
        "Broker",
        "Government",
        "Insurer",
        "Panel",
        "Producer",
        "Underwriter",
        "Vendor",
        "BOP",
        "E&O",
        "D&O",
        "PL",
        "WCP"
      ],
      update: false,
      updatedContactError: false,
      updatedContactSuccess: false,
      updatedContactResponse: {},
    };
  },

  computed: {
    typeVals: {
      get() {
        if (
          this.updatedContact.type === undefined ||
          this.updatedContact.type === "" ||
          this.updatedContact.type === null
        ) {
          return [];
        } else {
          return this.updatedContact.type.split(/\~|\-/g);
        }
      },
      set(val) {
        this.updatedContact.type = val.join("~");
        return val;
      }
    }
  },
  created() {
    this.updatedContact = {...this.contact};
  },

  methods: {
    onContactChange() {
      this.$emit("update:updated-contact", this.updatedContact);
      JSON.stringify(this.updatedContact) === JSON.stringify(this.contact)
        ? (this.update = false)
        : (this.update = true);
    },

    isLoading(val) {
      this.$emit("loading", false);
    },

    async onUpdateContactSubmit() {
      this.$emit('loading', true);
      this.updatedContact.stamp = new Date().toUTCString();
      await contactAPIService
        .updateContact(this.updatedContact.cid, this.updatedContact, this.$router)
        .then(res => {
          this.$emit('update:success',  true)
        })
        .catch(err => {
          this.$emit('update:errorResponse',  err)
          this.$emit('update:error',  true)
        });
      this.$emit('view-contact')
      this.$emit('loading', false);
      this.$emit('update:toggle', false);
      
    }
    
  }
};
</script>

<style>
.v-input--dense.v-text-field--outlined .v-chip {
  height: 24px;
  margin-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
}

.v-autocomplete.v-select.v-input--is-focused input #states {
  min-width: 25px !important;
}

.v-autocomplete.v-select.v-input #states {
  min-width: 25px !important;
}
</style>
