<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="createTax"
                 max-width="1000" :title="`Create tax for ${createData.state.name}`" :disabled="!valid">
    <v-form v-model="valid">
      <v-row>
        <v-col class="dense" lg="2">
          <v-text-field v-model="taxData.entity" label="Entity" :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col class="dense" lg="2">
          <v-text-field v-model="taxData.rate" label="Rate" :rules="[rules.numeric, rules.positive]"></v-text-field>
        </v-col>
        <v-col class="dense" lg="2">
          <v-text-field v-model="taxData.flat" label="Flat" :rules="[rules.numeric, rules.positive]"></v-text-field>
        </v-col>
        <v-col class="dense" lg="6">
          <v-autocomplete v-model="taxData.taxed_fees" :items="feeOptions" item-disabled="disabled" multiple clearable
                          label="Taxed Fees"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="dense" lg="2">
          <rounding-select v-model="taxData.round" :rules="[rules.required]"></rounding-select>
        </v-col>
        <v-col class="dense" lg="2">
          <v-checkbox v-model="taxData.admitted" label="Admitted"></v-checkbox>
        </v-col>
        <v-col class="dense" lg="2">
          <v-select v-model="taxData.field" :items="fieldOptions" item-disabled="disabled" label="Field"
                    :rules="[rules.required]"></v-select>
        </v-col>
        <v-col class="dense" lg="3">
          <date-picker-text-field v-model="taxData.start_date" picker-color="secondary" input-color="primary" bottom
                                  offset-y label="Start Date" :rules="[rules.required, rules.date]"></date-picker-text-field>
        </v-col>
      </v-row>
    </v-form>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import DatePickerTextField from "../../DatePickerTextField";
  import ActionDialog from "../../ActionDialog";
  import RoundingSelect from "./RoundingSelect";
  import { TaxesAPIService } from "@/servicehandlers/TaxesAPIService";

  const taxesAPIService = new TaxesAPIService();
  export default {
    name: "CreateTaxDialog",
    components: {RoundingSelect, ActionDialog, DatePickerTextField},
    props: {
      createData: {
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    watch: {
      createData: {
        deep: true,
        handler(data) {
          this.taxData.state = data.state.code;
          for (let field of this.fieldOptions) field.disabled = !data.fields.includes(field.text);
        }
      },
      taxed_fees(fees) {
        if (fees && fees.includes('*')) {
          for (let fee of this.feeOptions) {
            if (fee.value === '*') continue;
            fee.disabled = true;
          }
        } else if (fees && fees.length > 0) {
          for (let fee of this.feeOptions) {
            if (fee.value !== '*') continue;
            fee.disabled = true;
          }
        } else {
          for (let fee of this.feeOptions) {
            fee.disabled = false;
          }
        }
      },
    },
    computed: {
      taxed_fees() {
        return this.taxData.taxed_fees;
      },
    },
    data: () => ({
      feeOptions: [
        {text: 'All', value: '*', disabled: false},
        {text: 'MGA 1st', value: 'depfee', disabled: false},
        {text: 'MGA 2nd', value: 'mgafee', disabled: false},
        {text: 'Producer', value: 'profee', disabled: false},
        {text: 'Sub Producer', value: 'subfee', disabled: false},
        {text: 'Source', value: 'srcfee', disabled: false},
        {text: 'Sponsor', value: 'spnfee', disabled: false},
      ],
      fieldOptions: [
        {text: 'DOI', value: 'DOI', disabled: false},
        {text: 'SLA', value: 'SLA', disabled: false}
      ],
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        numeric: (x) => (!x || !isNaN(parseInt(x))) || 'Must be a numeric value.',
        positive: (x) => (!x || x > 0) || 'Must be a positive number.',
        required: (x) => !!x || 'This field is required.',
      },
      taxData: {
        state: '',
        entity: '',
        rate: '',
        flat: '',
        taxed_fees: [],
        round: 'CENT',
        admitted: false,
        field: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: ''
      },
      valid: false
    }),
    methods: {
      createTax() {
        taxesAPIService.createTax(this.taxData, this.$router)
          .then(() => {
            this.$emit('input', !this.value);
            this.$emit('created');
          })
      }
    }
  }
</script>

<style scoped>

</style>