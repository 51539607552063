<template>
  <div class="d-print-none">
    <v-card block class="px-12 py-0 d-flex flex-row justify-space-around" color="primary" dark
            :key="$_sideMenu" large sticky-offset="$_offset" sticky-z-index="99"
            style="border-top: 1px solid white !important" tile v-sticky>
      <div>
        <button-tip btnclass="px-12" @click="$parent.searchClaims()" hideSmDown="Claims " text tile
                    txt="Search/Refresh" large/>
      </div>
      <div>
        <button-tip btnclass="px-12" @click="$parent.printDiv()" icolor="white" icon iname="mdi-printer"
                    tip="Open print dialog"/>
      </div>
      <div>
        <button-tip btnclass="px-12" @click="$parent.clearSearch()" text tile
                    tip="Clear all the search fields" txt="Clear" large/>
      </div>
    </v-card>
    <v-card tile class="pr-6 mx-0 pt-2 pb-3 mt-3 mb-2 elevation-18">
      <v-row class="my-n1 mx-0 pl-0">
        <v-col class="d-flex justify-center" cols="12" md="6" xl="3">
          <v-row class="px-6 align-center">
            <v-checkbox class="mx-2 mt-0" color="accent1" hide-details value="fid" v-model="checkedColumns"/>
            <search-input @enter="emitThenSearch()" label="FID" v-model="whereColumns.fid"/>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-center" cols="12" md="6" xl="3">
          <v-row class="px-6 align-center">
            <v-checkbox class="mx-2 mt-0" color="accent1" hide-details value="clm_num" v-model="checkedColumns"/>
            <search-input @enter="emitThenSearch()" label="Claim Number" v-model="whereColumns.clm_num"/>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-center" cols="12" md="6" xl="3">
          <v-row class="px-6 align-center" style="z-index: 89">
            <v-checkbox class="mx-2 mt-0" color="accent1" hide-details value="carrier" v-model="checkedColumns"/>
            <search-input @enter="emitThenSearch()" :items="$_lists.carrier" label="Carrier"
                          :type="'VAutocomplete'" v-model="whereColumns.carrier"/>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-center" cols="12" md="6" xl="3">
          <v-row class="px-6 align-center" style="z-index: 89">
            <v-checkbox class="mx-2 mt-0" color="accent1" hide-details value="type" v-model="checkedColumns"/>
            <search-input @enter="emitThenSearch()" :items="$_lists.ptype" label="Type"
                          :type="'VAutocomplete'" v-model="whereColumns.type"/>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-expansion-panels mt-4 multiple v-model="optionsExpansion" tile accordion focusable>
      <v-expansion-panel>
        <v-expansion-panel-header class="dense py-0 subtitle-1">
        More Search Options
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-2 px-0 mx-n4">
          <v-container class="font-italic my-n3">Note: Table columns will appear in the order checked. To
            change the order, you can uncheck the boxes and then check them in the order you want.
            Fields that are greyed out can be checked for adding a column, but will not accept a
            search term. Fields with an arrow allow you to select from a list.
            Fields without an arrow allow you to enter full or partial data and all fields allow you to
            press enter to submit the claims search. If searching a dollar amount,
            just enter numbers and decimals, but no commas or dollar signs.</v-container>
          <v-container fluid pa-0 mx-0>
            <v-row dense px-0>
              <v-col cols="12" md="6" lg="4" xl="3">
                <v-card class="pl-2 pr-8 pb-8">
                  <v-card-title>Counsel</v-card-title>
                  <v-card-text>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="adj_cid" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" disabled :items="claimsHandlers" item-text="name1"
                                 item-value="cid" @input="getClaimsCounsel(includeCheckColumns.adj_cid)"
                                 label="Claims Handlers" :type="'VAutocomplete'" v-model="includeCheckColumns.adj_cid"/>
                    </v-row>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="adj_plid" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" disabled hint="Select Claims Handler first"
                                    :items="claimsCounsel" item-text="sortName" item-value="plid" label="Claims Counsel"
                                    persistent-hint :type="'VAutocomplete'" v-model="includeCheckColumns.adj_plid"/>
                    </v-row>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="def_cid" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" disabled :items="defenseFirm" item-text="name1"
                                    item-value="cid" @input="getClaimsCounsel(includeCheckColumns.adj_cid)"
                                    label="Defense Firm" :type="'VAutocomplete'" v-model="includeCheckColumns.def_cid"/>
                    </v-row>
                    <v-row class="align-center mb-4">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="def_plid" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" disabled hint="Select Defense Firm first"
                                  :items="defenseCounsel" item-text="sortName" item-value="plid" label="Defense Counsel"
                                  persistent-hint :type="'VAutocomplete'" v-model="includeCheckColumns.def_plid"/>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" lg="4" xl="3">
                <v-card class="pl-2 pr-8">
                  <v-card-title>Parties</v-card-title>
                  <v-card-text>
                    <v-row class="align-center mb-1">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="claimant" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" label="Claimant" v-model="whereColumns.claimant"/>
                    </v-row>
                    <v-row class="align-center mb-1">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="plt_code" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" :items="$_lists.plt_code" label="Plaintiff Code"
                                    :type="'VAutocomplete'" v-model="includeCheckColumns.plt_code"/>
                    </v-row>
                    <v-row class="align-center mb-1">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="insdef" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" label="Insured Defendants" v-model="whereColumns.insdef"/>
                    </v-row>
                    <v-row class="align-center mb-1">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="def_code" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" :items="$_lists.def_code" label="Defendant Code"
                                    :type="'VAutocomplete'" v-model="includeCheckColumns.def_code"/>
                    </v-row>
                    <v-row class="align-center" style="margin-bottom: -10px">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="othdef" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" label="Other Defendants" v-model="whereColumns.othdef"/>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="4" xl="3">
                <v-card class="pl-2 pr-8" style="padding-bottom: 13px">
                  <v-card-title>Claim Details</v-card-title>
                  <v-card-text>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="state" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" :items="$_lists.states" label="Claim State"
                                    :type="'VAutocomplete'" v-model="whereColumns.state"/>
                    </v-row>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="firm_state" v-model="includeColumns"/>
                      <search-input disabled @enter="emitThenSearch()" :items="$_lists.states" label="Firm State"
                                    :type="'VAutocomplete'" v-model="includeCheckColumns.firm_state"/>
                    </v-row>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="err_code" v-model="includeColumns"/>
                      <search-input @enter="emitThenSearch()" :items="$_lists.err_code" label="Error Type"
                                    :type="'VAutocomplete'" v-model="whereColumns.err_code"/>
                    </v-row>
                    <v-row class="align-center lowerLeftLabel" style="padding-bottom: 55px">
                      <v-checkbox class="mx-2 mb-n1" color="accent1" value="insuit" v-model="checkedColumns"/>
                      <v-radio-group dense row :style="{'color':'rgba(0, 0, 0, 0.6)',
                                     'margin-bottom': '-14px', 'margin-top': '7px' }" v-model="whereColumns.insuit">
                        In Suit:
                        <v-radio label="Yes" value="yes" class="ml-2 mt-n1" color="accent1"/>
                        <v-radio label="No" value="no" color="accent1" class="ml-n2 mt-n1"/>
                        <v-btn class="px-0 mr-0" @click="whereColumns.insuit = null" color="rgba(0, 0, 0, 0.54)"
                               style="margin-top: 1px" text x-small><v-icon>mdi-close</v-icon></v-btn>
                      </v-radio-group>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="4" xl="3">
                <v-card class="pl-2 pr-8">
                  <v-card-title>More Claim Details</v-card-title>
                  <v-card-text>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="est_size" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" label="Estate Size" v-model="whereColumns.est_size"/>
                    </v-row>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="aop_code" v-model="includeColumns"/>
                      <search-input disabled @enter="emitThenSearch()" :items="$_lists.aop_code" label="AOP"
                                    :type="'VAutocomplete'" v-model="includeCheckColumns.aop_code"/>
                    </v-row>
                    <v-row class="align-center mb-5">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="resolved" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" :items="['Judgement', 'Settlement']" label="Resolution"
                                    :type="'VAutocomplete'" v-model="whereColumns.resolved"/>
                    </v-row>
                    <v-row class="align-center" style="padding-bottom: 60px">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" value="status" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" :items="$_lists.clmstatus" label="Status"
                                    :type="'VAutocomplete'" v-model="whereColumns.status"/>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col v-for="card in dateCards" :key="card.name" cols="12" md="6" lg="4" xl="3">
                <v-card class="pl-2 pr-2 pb-7">
                  <v-card-title class="pb-5">{{ card.name }}</v-card-title>
                  <v-card-text v-for="date in card.array" :key="date.name">
                    <v-row class="align-center mt-n1 mb-n2">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" :value="date.name" v-model="checkedColumns"/>
                      <date-picker-formatted
                        clearable
                        :date-formatted-prop="whereColumns[date.name]"
                        :divWrapStyle="{width: '82%'}"
                        @enter="emitThenSearch()"
                        :format="dateFormat"
                        :format-loosely-valid="dateFormatLooselyValid"
                        :label="date.label + ' date'"
                        menu-wrapper
                        no-title
                        offset-y
                        picker-color="secondary"
                        :textFieldClass="'mt-2'"
                        v-model="whereColumns[date.name]"/>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col v-for="card in amountCards" cols="12" md="6" lg="4" xl="3">
                <v-card class="pl-2 pr-8" :style="{paddingBottom: card.name === 'Deductible' ? '204px' : '102px'}">
                  <v-card-title>{{ card.name }}</v-card-title>
                  <v-card-text v-for="amt in card.array" :key="amt.name">
                    <v-row class="align-center mb-3">
                      <v-checkbox class="mx-2 mt-5 mb-n1" color="accent1" :value="amt.name" v-model="checkedColumns"/>
                      <search-input @enter="emitThenSearch()" :label="amt.label" v-model="whereColumns[amt.name]"/>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="8" xl="6">
                <v-card>
                  <v-card-title>Facts</v-card-title>
                  <v-card-text style="padding-top: 20px; padding-bottom: 66px">
                    <v-row class="align-center">
                      <v-checkbox color="accent1" v-model="checkedColumns" value="facts" hide-details
                                  class="shrink mr-2 ml-4 mt-n6"/>
                      <v-textarea class="mr-4 ml-4 pl-3" outlined hint="Relevant facts go in this expandable box."
                                  v-model="whereColumns.facts"
                                  rows="2"
                                  label="Claim Facts" @keyup.enter="emitThenSearch()"/>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-2">
                  <v-card-title>Notes</v-card-title>
                  <v-card-text style="margin-top: 2px; padding-top: 20px; padding-bottom: 68px">
                    <v-row class="align-center">
                      <v-checkbox color="accent1" v-model="checkedColumns" value="note" hide-details
                                  class="shrink mr-2 ml-4 mt-n6"/>
                      <v-textarea class="mr-4 ml-4 pl-3" outlined hint="Claim notes go in this expandable box."
                                  v-model="whereColumns.note"
                                  rows="2" label="Notes" @keyup.enter="emitThenSearch()"/>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels><br>

   <ErrorDialog ref="errorDialog"/>

  </div>
</template>
<script>
  import ErrorDialog from "../../ErrorDialog";
  import moment from 'moment';
  import { ContactAPIService } from '@/servicehandlers/ContactAPIService';

  const contact = new ContactAPIService();

  export default {
    name: 'ClaimOptions',
    props: {
      optionsExpansionProp: Array,
      info: Array,
      whereProp: Object,
      checkedProp: Array,
      includeCheckProp: Object,
      includeProp: Array,
      default() {
        return {};
      }
    },
    components: { ErrorDialog },
    data() {
      return {
        amountCards: [
          { name: 'Monitoring', array: [
              { name: 'res_mon', label: 'Monitor Reserve' },
              { name: 'monitor', label: 'Monitor Incurred' },
              { name: 'cur_mon', label: 'Current Monitor' },]
          },
          { name: 'Defense', array: [
              { name: 'res_def', label: 'Defense Reserve' },
              { name: 'defense', label: 'Defense Incurred' },
              { name: 'cur_def', label: 'Current Defense' },]
          },
          { name: 'Damages', array: [
              { name: 'reserve', label: 'Damages Reserve' },
              { name: 'damages', label: 'Damages Incurred' },
              { name: 'cur_dam', label: 'Current Damages' },]
          },
          { name: 'Deductible', array: [
              { name: 'deductible',label: 'Deductible' },
              { name: 'paid_ded',  label: 'Paid Deductible' },]
          },
        ],
        checkedColumns: this.checkedProp,
        claimsCounsel: [],
        claimsHandlers: [],
        dateCards: [
          { name: 'Claim Dates', array: [
              { name: 'engaged',  label: 'Engaged' },
              { name: 'error',    label: 'Error' },
              { name: 'claim',    label: 'Claim' },
              { name: 'report',   label: 'Report' }]
          },
          { name: 'Handling Dates', array: [
              { name: 'clm_ack',  label: 'Claims Acknowledged' },
              { name: 'first_rep',label: 'First Report' },
              { name: 'last_rep', label: 'Last Report' },
              { name: 'close',    label: 'Close' }]
          },
        ],
        dateFormat: 'DD MMM YYYY',
        dateFormatLooselyValid: 'D MMM YYYY',// for DatePickerFormatted.vue to accept and add leading zeros
        defenseCounsel: [],
        defenseFirm: [],
        empty: [],
        errors: [],
        includeCheckColumns: this.includeCheckProp,
        includeColumns: this.includeProp,
        menu: [],
        optionsExpansion: this.optionsExpansionProp,
        refresh: false,
        whereColumns: this.whereProp,
      };
    },

    computed: {
      dates() {
        return this.dateCards[0].array.concat(this.dateCards[1].array);
      },
    },

    watch: {
      optionsExpansion: function(newValue, oldValue) {
        this.$parent.optionsExpansionOpen = this.optionsExpansion;
      },

      optionsExpansionProp: function(newValue, oldValue) {
        this.optionsExpansion = this.optionsExpansionProp;
      },

      whereProp: function(newValue, oldValue) {
        this.whereColumns = this.whereProp;
      },

      checkedProp: function(newValue, oldValue) {
        this.checkedColumns = this.checkedProp;
      },

      includeProp: function(newValue, oldValue) {
        this.includeColumns = this.includeProp;
      },

      includeCheckProp: function(newValue, oldValue) {
        this.includeCheckColumns = this.includeCheckProp;
      },

      whereColumns: {
        handler() {
          this.emitColumnInfo();
        },
        deep: true,
      },

      checkedColumns: function(newValue, oldValue) {
        if (!this.checkedColumns.includes('clid')) {this.checkedColumns.unshift('clid')}
        this.emitColumnInfo();
      },

      includeColumns: function(newValue, oldValue) {
        this.emitColumnInfo();
      },

      includeCheckColumns(oldIncludeCheckColumns, newIncludeCheckColumns) {
        this.emitColumnInfo();
      },

    },

    created() {
      const mytype = 'Adjuster' + '';//get claims handler, counsel
      contact.getByType(mytype, this.$route)
        .then((handlers) => {
          //database has extra whitespace, so trim, so it will look right and sort correctly
          for (let handler of handlers) handler.name1 = handler.name1.trim();
          this.claimsHandlers = handlers.sort((a, b) => {
            const textA = a.name1.toUpperCase();
            const textB = b.name1.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        })
        .catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get adjusters.",
            forDevelopers: e
          })
        });

      const type = 'Panel' + '';//get defense firm, counsel
      contact.getByType(type, this.$route)
        .then((defenseFirm) => {
            //database has extra whitespace, so trim
            for (let defense of defenseFirm) defense.name1 = defense.name1.trim();
            this.defenseFirm = defenseFirm.sort((a, b) => {
              const textA = a.name1.toUpperCase();
              const textB = b.name1.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        })
        .catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
    },

    methods: {
      emitColumnInfo() {
        const info = [
          this.checkedColumns,
          this.includeColumns,
          this.whereColumns,
          this.includeCheckColumns];
        this.$emit('update', info);
      },

      emitThenSearch() {
        this.emitColumnInfo();
        this.$parent.searchClaims();
      },

      getClaimsCounsel(id) {
        this.claimsCounsel = [];
        // find claimsCounsel by id
        for (let handler of this.claimsHandlers) {
          if (handler.cid === id) {
            this.claimsCounsel = handler.people;
            for (let person of this.claimsCounsel) {
              if (person) {
                // if a name is missing or whitespace, then don't insert comma
                person.sortName = (!person.surname || !person.surname.trim() || !person.given || !person.given.trim())
                  ? person.surname.trim() + person.given
                  : person.surname.trim() + ", " + person.given;
              }
            }
          }
        }
        this.claimsCounsel.sort((a, b) => {
          const textA = a.sortName.toUpperCase();
          const textB = b.sortName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },

      getDefenseCounsel(id) {
        this.defenseCounsel = [];
        // find defenseCounsel by id
        for (let defense of this.defenseFirm) {
          if (defense.cid === id) {
            this.defenseCounsel = defense.people;
            for (let person of this.defenseCounsel) {
              if (person) {
                // if a name is missing or whitespace, then don't insert comma
                person.sortName = (!person.surname || !person.surname.trim() || !person.given || !person.given.trim())
                  ? person.surname.trim() + person.given
                  : person.surname.trim() + ", " + person.given;
              }
            }
          }
        }
        this.defenseCounsel.sort((a, b) => {
          const textA = a.sortName.toUpperCase();
          const textB = b.sortName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },
    },
  }
</script>

<style>
.lowerLeftLabel .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  margin-top: 5px !important;
  margin-left: -9px;
}
</style>

