import { render, staticRenderFns } from "./TaxDialog.vue?vue&type=template&id=5848c38a&scoped=true"
import script from "./TaxDialog.vue?vue&type=script&lang=js"
export * from "./TaxDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5848c38a",
  null
  
)

export default component.exports