import CommonAPIService from './CommonHandler';

export class ReportsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getReport(search, router) {
    const url = '/api/reports';
    return this.CommonAPIService.postCall(url, search, router);
  }

  getAging(agingType, router) {
    /** Type:
     *    ap - Accounts Payable Aging Report
     *    ar - Accounts Receivable Aging Report
     *    sl - Surplus Line Aging Report
     */

    const orders = '_age,_due,_incept,pid';
    const directions = 'DESC';
    const url = `/api/reports/aging?aging=${agingType}&orders=${orders}&directions=${directions}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getBindAuthorityProduction(search, router) {
    const url = '/api/reports/bindauthority';
    return this.CommonAPIService.postCall(url, search, router);
  }

  getBordereau(search, router) {
    const url = '/api/reports/bordereau';
    return this.CommonAPIService.postCall(url, search, router);
  }

  getAadmmSummary(router) {
    const orders = '_span';
    const directions = 'DESC';
    const url = `/api/reports?directions=${directions}&orders=${orders}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getLostAccountStatus(router) {
    const fields = 'insured,prodname,carrier,type,status,expiry,pid,fid';
    const orders = '_expiry_month,_expiry_day,premium';
    const report = 'lost';
    const url = `/api/reports/status?fields=${fields}&orders=${orders}&report=${report}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getNewlyFirms(router) {
    const fields = '*', orders = 'fid', directions = 'ASC';
    const url = `/api/reports/newly-firms?fields=${fields}&directions=${directions}&orders=${orders}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getNgaSummary(router) {
    const orders = '_span,_member_since';
    const directions = 'DESC,DESC';
    const url = `/api/reports/nga-summary?orders=${orders}&directions=${directions}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getRenewalStatus(router) {
    const fields = 'insured,prodname,carrier,type,status,expiry,pid,fid';
    const orders = '_expiry';
    const report = 'book';
    const url = `/api/reports/status?fields=${fields}&orders=${orders}&report=${report}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getDailyDepositBalances(router) {
    const fields = 'acct';
    const url = `/api/reports/deposit-balances?fields=${fields}&date=day`;
    return this.CommonAPIService.getCall(url, router);
  }

  getMonthlyDepositBalances(router) {
    const fields = 'acct';
    const url = `/api/reports/deposit-balances?fields=${fields}&date=month`;
    return this.CommonAPIService.getCall(url, router);
  }

  getYearlyDepositBalances(router) {
    const fields = 'acct';
    const url = `/api/reports/deposit-balances?fields=${fields}&date=year`;
    return this.CommonAPIService.getCall(url, router);
  }

  getDISIPayablesBalance(router) {
    const orders = 'name';
    const url =  `/api/reports/client-balances?orders=${orders}&client=TRDISI&balance=payables`;
    return this.CommonAPIService.getCall(url, router);
  }

  getDISIReceivablesBalance(router) {
    const orders = 'name';
    const url =  `/api/reports/client-balances?orders=${orders}&client=TRDISI&balance=receivables`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFDCPayablesBalance(router) {
    const orders = 'name';
    const url =  `/api/reports/client-balances?orders=${orders}&client=TRFDC&balance=payables`;
    return this.CommonAPIService.getCall(url, router);
  }

  getFDCReceivablesBalance(router) {
    const orders = 'name';
    const url =  `/api/reports/client-balances?orders=${orders}&client=TRFDC&balance=receivables`;
    return this.CommonAPIService.getCall(url, router);
  }

  getOrderedAccounts(router) {
    const orders = '_incept,policies.type';
    const scope = 'ordered';
    const url = `/api/reports/exceptions?orders=${orders}&scope=${scope}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getBoundAccountsButNotIssued(router) {
    const orders = '_incept,policies.type';
    const scope = 'bound';
    const url = `/api/reports/exceptions?orders=${orders}&scope=${scope}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getIssuedPoliciesWithNoNum(router) {
    const orders = '_incept,policies.type';
    const scope = 'nonum';
    const url = `/api/reports/exceptions?orders=${orders}&scope=${scope}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPastPoliciesWithoutEntries(router) {
    const fields = 'fid,insured,carrier,type,status,note';
    const orders = 'fid,_expiry';
    const url = `/api/reports/past-policies-without-entries?fields=${fields}&orders=${orders}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPayment(paymentType, router) {
    /** Type:
     *    ap - Miscellaneous Accounts Payable
     *    ar - Miscellaneous Accounts Receivable
     *    cap - Client Accounts Payable
     *    car - Client Accounts Receivable
     *    canap - Cananwill Accounts Payable
     *    canar - Cananwill Accounts Receivable
     *    lpp - LPP Statement
     */

    const orders = '_mga,_due,_incept,pid';
    const url = `/api/reports/payments?orders=${orders}&payment=${paymentType}`;
    return this.CommonAPIService.getCall(url, router)
  }

  getPoliciesLedgerCount(check, router) {
    /**
     * @param {string} check one of the following: [book,past,lost,history,other,blank]
     */
    const fields = 'policies.fid,policies.pid';
    const orders = '_count';
    const limit = 30;
    const url = `/api/reports/ledgers-checks?fields=${fields}&orders=${orders}&check=${check}&limit=${limit}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPolicyBalances(plus,router) {
    /**
     * @param {boolean} plus indicates Policy Balances (+) or Policy Balances (-).
     */
    const fields = 'fid,pid';
    const orders = '_sum';
    const limit = 30;
    const directions = plus? 'DESC' : 'ASC';
    const url = `/api/reports/ledgers-checks?fields=${fields}&orders=${orders}&check=policy&limit=${limit}&directions=${directions}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getSummary(reportType, router) {
    /**
     * @param reportType {string} one of the following: ('book', 'carrier', 'mga', 'type')
     */
    const orders = '_sum', directions = 'DESC';
    const url = `/api/reports/summary?orders=${orders}&directions=${directions}&report=${reportType}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getWebin(router) {
    const fields = 'fid';
    const orders = 'fid';
    const url = `/api/reports/webin?fields=${fields}&orders=${orders}`;
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   * @param { string | null | undefined } carrier
   * @param {string} prevCarrier
   * @param {string | string[]} types
   * @param {string | string[]} firmTypes ex: lawyer, accountant
   * @param {number} month
   * @param {number} year
   */
  getRenewalReports(carrier, prevCarrier, types, firmTypes, month, year, router) {
    const params = new URLSearchParams({
      prevCarrier,
      types: Array.isArray(types)? types.join(','): types,
      firmTypes: Array.isArray(firmTypes)? firmTypes.join(','): firmTypes,
      month,
      year
    });

    if ( carrier ) params.append('carrier', carrier);

    const url = `/api/reports/renewals?${params.toString()}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getLossRun(fid, policyType, carrier, router) {
    const fields = 'incept';
    const encodedPolicyType = encodeURIComponent(policyType);
    const url = `/api/reports/loss-run?fid=${fid}&type=${encodedPolicyType}&carrier=${carrier}&fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default ReportsAPIService;
