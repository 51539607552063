<template>
  <v-card-text>
    <v-form v-model="valid">
      <v-row>
        <v-col class="dense">
          <h6 class="font-weight-regular black--text">Rule</h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <contacts-select v-model="commissionData.mga_id" :options="{label: 'MGA', disabled: !newMga}"
                           :rules="[rules.required]"></contacts-select>
        </v-col>
        <v-col>
          <carriers-select v-model="commissionData.carrier" :options="{label: 'Carrier', disabled: update}"></carriers-select>
        </v-col>
        <v-col>
          <policy-types-select v-model="commissionData.types"
                               :options="{multiple: true, label: 'Types', disabled: update}"></policy-types-select>
        </v-col>
        <v-col>
          <v-select :value="commissionData.renewal" :items="placements" label="Placement" :disabled="update"></v-select>
        </v-col>
        <v-col>
          <v-text-field v-model="commissionData.condition" label="Condition" :disabled="update"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dense mt-2">
          <h6 class="font-weight-regular black--text">Details</h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dense">
          <v-text-field v-model="commissionData.commission" label="Commission" :rules="[rules.percent]">
            <template v-slot:prepend-inner>
              <h6 class="mt-1 mx-1">%</h6>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="dense">
          <date-type-select v-model="commissionData.start_date_type" :options="{label: 'Date',
          prependInnerIcon: 'mdi-calendar-start', rules: [rules.required]}"></date-type-select>
        </v-col>
        <v-col class="dense">
          <date-picker-text-field v-model="commissionData.start_date" label="After" picker-color="secondary"
                                  input-color="primary" :rules="[rules.required, rules.date]"
                                  prepend-inner-icon="mdi-calendar-month"></date-picker-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center">
      <v-alert v-if="error" type="error" text>
        An error occurred. Please try again, or contact development if the error persists.
      </v-alert>
    </v-row>
  </v-card-text>
</template>

<script>
  import moment from 'moment';
  import {_} from 'vue-underscore';
  import { CommissionsAPIService } from "@/servicehandlers/CommissionsAPIService";
  import ContactsSelect from "../../../ContactsSelect";
  import CarriersSelect from "../../../CarriersSelect";
  import DatePickerTextField from "../../../DatePickerTextField";
  import ErrorAlert from "../../../ErrorAlert";
  import PolicyTypesSelect from "../../../PolicyTypesSelect";
  import DateTypeSelect from "../../../DateTypeSelect";

  const commissionsAPIService = new CommissionsAPIService();

  export default {
    name: "CommissionForm",
    components: {DateTypeSelect, PolicyTypesSelect, ErrorAlert, DatePickerTextField, CarriersSelect, ContactsSelect},
    props: {
      commission: {
        type: Object,
        default: () => ({})
      },
      startDate: {
        type: String
      },
      newMga: {
        type: Boolean,
        default: false
      },
      update: {
        type: Boolean
      }
    },
    watch: {
      startDate(date) {
        this.commissionData.start_date = date;
      },
      commission(com) {
        this.formatCommission(com);
      },
      valid(valid) {
        this.$emit('valid', valid);
      }
    },
    data: (vm) => ({
      commissionData: {start_date: vm.startDate},
      error: false,
      placements: [
        {text: 'All', value: null},
        {text: 'Renewal', value: true},
        {text: 'New Business', value: false}
      ],
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        fractional: (x) => x <= 1 || 'Must be less than or equal to 1.',
        numeric: (x) => !isNaN(parseInt(x)) || 'Must be a numeric value.',
        percent: (x) => (x >= 0 && x <= 100) || 'Must be a valid percentage.',
        positive: (x) => x > 0 || 'Must be a positive number.',
        required: (x) => !!x || 'This field is required.',
      },
      valid: false
    }),
    created() {
      this.formatCommission(this.commission);
    },
    methods: {
      createCommission() {
        let comData = JSON.parse(JSON.stringify(this.commissionData));
        comData.commission = comData.commission / 100;
        return commissionsAPIService.createCom(comData, this.$router)
          .then(com => {
            this.$emit('created');
            return true;
          })
          .catch(error => {
            this.error = true;
            return false;
          })
      },
      formatCommission(com) {
        this.commissionData.mga_id = com.mga_id;
        this.commissionData.carrier = com.carrier;
        this.commissionData.condition = com.condition;
        this.commissionData.renewal = com.renewal;
        this.commissionData.commission = com.commission;
        if (this.commissionData.types instanceof Object) this.commissionData.types = JSON.parse(JSON.stringify(com.types));
      },
      formatRenewal(renewal) {
        switch(renewal) {
          case true: return 'Renewal';
          case false: return 'New Business';
          default: return '';
        }
      },
    }
  }
</script>

<style scoped>

</style>