<template>
  <div>
    <v-card class="mt-1 ml-1 mr-1 mb-1" style="height: calc(100vh - 62px)">
      <v-tabs slider-size="4">
        <v-tab>
          <v-icon left> mdi-card-bulleted </v-icon>
          INFO
        </v-tab>
        <v-tab>
          <v-icon left> mdi-account-multiple </v-icon>
          PEOPLE
        </v-tab>

        <v-tab-item>
          <v-card v-if="!editContact" flat>
            <v-card-title class="pb-0 ml-4">
              Contact
              <v-spacer />
              <v-btn color="secondary" v-can="'edit-contact'" class="mr-4" @click="editContact = true">
                <v-icon class="mr-1">mdi-pencil</v-icon>
                edit
              </v-btn>
            </v-card-title>
            <v-card-text class="pt-0 body-2">
              <div class="d-flex">
                <v-list width="400px" class="flex-shrink-0">
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Sort</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.sort }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.name1 }}
                      </v-list-item-title>
                      <v-list-item-title>
                        {{ contact.name2 }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip v-for="type in types" :key="type" class="mr-1">
                          {{ type }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Address</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.address1 }}
                      </v-list-item-title>
                      <v-list-item-title>
                        {{ contact.address2 }}
                      </v-list-item-title>
                      <v-list-item-title>
                        {{
                          (contact.city ? contact.city : "") +
                            (contact.state ? " " + contact.state : "") +
                            (contact.zip ? ", " + contact.zip : "")
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Phone</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.phone }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Fax</v-list-item-subtitle>
                      <v-list-item-title> {{ contact.fax }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.email }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>Website</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.website }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="pt-0">
                      <v-list-item-subtitle>FEIN</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ contact.fein }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider vertical />
                <v-list>
                  <v-list-item>
                    <v-list-item-content class="pt-0 ">
                      <v-list-item-subtitle>Note</v-list-item-subtitle>
                      <v-card
                        flat
                        class="scroll"
                        height="500"
                        v-if="contact.note !== null && contact.note !== '' && contact.note !== '\r'"
                      >
                        <div v-html="htmlNote"></div>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <edit-contact
            v-if="editContact"
            ref="editContactScreen"
            :key="JSON.stringify(shortContact)"
            :toggle.sync="editContact"
            :contact="{ ...shortContact }"
            :loading="updateLoading"
            :error.sync="updatedContactError"
            :success.sync="updatedContactSuccess"
            :response.sync="updatedContactResponse"
            @loading="updateLoading = $event"
            v-on:view-contact="viewContact()"
          />
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-title>
              Associated People
              <v-btn icon @click="infoDialog = true">
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
              <v-dialog v-model="infoDialog" width="550">
                <v-card>
                  <v-card-title class="headline lighten-2" primary-title>
                    Information for this page</v-card-title
                  >
                  <v-card-text>
                    This page lists all the people associated with this contact. More Information
                    about specific people associated with this contact can be found by clicking the
                    pop-up button.
                  </v-card-text>
                  <v-card-actions
                    ><v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="infoDialog = false">Got it!</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                @click.stop
                @keydown.enter.prevent
                prepend-icon="mdi-magnify"
                clearable
                single-line
                label="Search Associated People"
                style="width: 200px"
                hide-details
                class="mt-n12 mb-n7 ml-2 mr-2 font-weight-regular"
              >
              </v-text-field>
              <v-btn x-small fab color="secondary" @click="newContactDialog = true">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="state === 'loadingContact'"
                class="peopleTable elevation-2 mt-n3 mb-2 pb-0"
                :dense="dense"
                :search="search"
                scrollable
                loading-text="Loading... Please wait"
                :mobile-breakpoint="0"
                :headers="associatedPeopleHeaders"
                fixed-header
                :sort-by="['name']"
                multi-sort
                :items="contact.people"
              >
                <template v-slot:item.actions="{ item }">
                  <v-lazy>
                    <div>
                      <v-tooltip bottom style="z-index: 99" class="ml-n2 mr-n4 py-0 pl-0 pr-2">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon
                            ><v-icon v-on="on" >
                              mdi-open-in-new</v-icon
                            ></v-btn
                          >
                        </template>
                        <span>Open Popup</span>
                      </v-tooltip>
                    </div>
                  </v-lazy>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <success-snackbar message="Contact successfully updated" :value="updatedContactSuccess" />
    <v-snackbar color="error" v-model="updatedContactError" style="top: 80px;" top>
      Error: {{ updatedContactResponse }}
      <v-btn color="white" @click="updatedContactError = false" text> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
import EditContact from "./EditContact";
import SuccessSnackbar from "../SuccessSnackbar.vue";
const contactAPIService = new ContactAPIService();

export default {
  name: "ViewContact",
  props: {
    id: [String, Number]
  },
  components: { EditContact, SuccessSnackbar },
  data() {
    return {
      search: "",
      editContact: false,
      infoDialog: false,
      dense: true, //table density
      userPrefs: {},
      pageRights: [],
      associatedPeopleHeaders: [
        { value: "actions", text: "Actions", sortable: false, align: "center" },
        { value: "name", text: "Name", width: "200px" },
        { value: "direct", text: "Direct Line" },
        { value: "fax", text: "Direct Fax" },
        { value: "home", text: "Home" },
        { value: "mobile", text: "Mobile" },
        { value: "email", text: "email" }
      ],
      groups: [],
      contact: {},
      updateLoading: false,
      updatedContactSuccess: false,
      updatedContactResponse: {},
      updatedContactError: false,
      shortContact: {},
      state: "loadingContact"
    };
  },

  computed: {
    htmlNote() {
      if (!this.contact.note) {
        return "";
      }
      return this.contact.note.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    types: {
      get() {
        if (
          this.contact.type === undefined ||
          this.contact.type === "" ||
          this.contact.type === null
        ) {
          return [];
        } else {
          return this.contact.type.split(/\~|\-/g).filter(t => t !== "");
        }
      },
      set(val) {
        return val;
      }
    }
  },

  mounted() {
    this.viewContact();
  },

  methods: {
    async viewContact() {
      this.state = "loadingContact";
      this.loading = true;
      await contactAPIService.getContactById(this.id, this.$router).then(contact => {
        const people = contact.people.map(x => ({
          ...x,
          name:
            x.given +
            (x.surname ? " " + x.surname : "") +
            (x.salutation ? " (" + x.salutation + ")" : "")
        }));
        this.contact = { ...contact };
        this.contact.people = people;
        this.shortContact = { ...contact };
        this.shortContact.people = undefined;
        document.title = this.contact.cid + " " + this.contact.sort;
        this.refresh = false;
        if (this.state === "loadingContact") this.state = "showContact";
      });
    }
  }
};
</script>

<style scoped>
.peopleTable .v-data-table__wrapper {
  height: calc(100vh - 160px) !important;
}
.v-list-item-content_wrapper {
  padding-top: none !important;
}
</style>
