<template>
  <div>
    <v-card class="mt-n3 rounded-0">
      <v-card-title>
        <button-tip xsmall fab icon icolor="black" iname="mdi-update"
                    elevation="5" tip="Refresh Claims" @click="getClaimsForThisFirm(firm.fid)"/>
        <v-spacer/>
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable dense label="Search"
                      single-line hide-details class="mt-n6 mb-n2"></v-text-field>
        <button-tip btnclass="ml-2 mt-n1" @click="createNewClaim" color="secondary" elevation="5" fab icolor="white" iname="mdi-plus"
                    btnstyle="" tip="Create Claim" v-can="'edit-claim'" xsmall/>
      </v-card-title>
    </v-card>
    <v-data-table class="elevation-2 left"
                  :class="[!notPopup ? 'popupLargeTableNoFooter' : 'largeSizeTableNoFooter']"
                  @click:row="rowClick"
                  dense
                  fixed-header
                  :headers="colHeaders"
                  hide-default-footer
                  item-key="clid"
                  :items-per-page="-1"
                  :items="claims"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  multi-sort
                  :mobile-breakpoint="0"
                  no-data-text="There is no claims data available for this firm."
                  :search="search"
                  single-select
                  sort-by="clid"
                  :style="$_cssVars">
      <template v-slot:item.actions="{ item }">
        <button-tip @click="editClaim(item)" icon iname="mdi-pencil" tip="Edit Popup" v-can="'edit-claim'"/>
        <button-tip @click="viewClaim(item)" icon iname="mdi-open-in-new" tip="Print/View Popup" v-can="'page-claim'"/>
      </template>
      <template v-for="field in fields" v-slot:[`item.${field}`]="props">
        <v-edit-dialog v-if="editDialog" style="position: relative; left: 20px"
                       :return-value="field === 'claim' ? '' : props.item[field]">
          <div :style="field === 'damages' ? 'marginRight: 18px' : ''" v-if="(props.item[field]
            && (amountFieldsReadonly.includes(field) || amountFieldsEditable.includes(field)))">
            ${{ (parseFloat(props.item[`${field}`])).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
          </div>
          <div v-else-if="field === 'claim'">
            {{ props.item.noDate === true ? ' ' : moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY') }}
          </div>
          <div v-else>{{ props.item[field] }}</div>
          <template v-slot:input v-if="$_can('edit-claim')">
            <v-autocomplete class="mx-2 mb-6" clearable hint="SELECT to Save" @input="closeDialog(), save(props.item)"
                            :items="field === 'type' ? $_lists.ptype : $_lists.clmstatus" persistent-hint
                            v-if="['type','status'].includes(field)" v-model="props.item[field]"/>
            <v-text-field class="mx-2 mb-6" clearable hint="ENTER to Save" @keyup.enter="save(props.item)"
                          persistent-hint v-if="!['clid','id','claim','type','status'].includes(field)
                          && !amountFieldsReadonly.includes(field)" v-model="props.item[field]"/>
            <v-text-field class="px-3" clearable @click:clear="props.item[field] = '', save(props.item, 'datePicker')"
                          :hint="!!props.item.noDate ? 'To Save CLICK day below' : 'To Save CLICK day below OR X for blank date'"
                          persistent-hint readonly v-if="field === 'claim'"
                          v-model="props.item.noDate === true ? '' :
                            moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY')"/>
            <v-date-picker class="ml-n2 pb-4" @input="save(props.item, 'datePicker')" no-title
                           v-if="field === 'claim'"
                           v-model="props.item.claim"/>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>

    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='FirmClaimsTable'"></snack>

  </div>
</template>

<script>
  import moment from 'moment';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { ClaimsAPIService } from "@/servicehandlers/ClaimsAPIService";

  const apiService = new FirmsAPIService();
  const claimAPIService = new ClaimsAPIService();

  export default {
    name: "FirmClaimsTable",
    props: {
      firm: Object,
      notPopup: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        editDialog: true,
        errors: [],
        lists: [],
        loading:false,
        search: '',
        claims: [],
        amountFieldsEditable: ['monitor', 'damages', 'defense'],
        amountFieldsReadonly: ['total_remaining_reserves', 'total_indemnity',
          'total_defense', 'total_fkb', 'total_incurred'],
        fields: ['id', 'claimant', 'claim', 'type', 'status', 'monitor', 'damages', 'defense',
          'total_remaining_reserves', 'total_indemnity', 'total_defense', 'total_fkb',
          'total_incurred'],
        colHeaders: [ // for claims
          {value: 'actions', text: 'Actions',   width: "105px", align: 'center'},
          {value: 'clid',    text: 'Claim ID',  width: "120px", align: 'end'},
          {value: 'claimant',text: 'Claimant',  width: "220px", align: 'start'},
          {value: 'claim',   text: 'Claim Date',width: "120px", align: 'start'},
          {value: 'type',    text: 'Type',      width: "100px", align: 'start'},
          {value: 'status',  text: 'Status',    width: "110px", align: 'start'},
          {value: 'monitor', text: 'Monitor',   width: "120px", align: 'end'},
          {value: 'defense', text: 'Defense',   width: "120px", align: 'end'},
          {value: 'damages', text: 'Damages',   width: "120px", align: 'right'},
          // {value: 'total_remaining_reserves', text: 'Total Remaining Reserves', width: "220px", align: 'end'},
          // {value: 'total_indemnity',text: 'Total Indemnity',width: "220px", align: 'end'},
          // {value: 'total_defense',  text: 'Total Defense',  width: "220px", align: 'end'},
          // {value: 'total_fkb',      text: 'Total FKB',      width: "220px", align: 'end'},
          // {value: 'total_incurred', text: 'Total Incurred', width: "220px", align: 'end'},
        ],
      }
    },

    created () {
      this.getClaimsForThisFirm(this.firm.fid);
    },

    methods: {
      closeDialog() {// set editDialog=false to close dialog, then nextTick editDialog=true to show fields
        this.editDialog = false;
        this.$nextTick(() => this.editDialog = true);
      },

      createNewClaim() {
        claimAPIService.createClaim({fid: this.firm.fid}, this.$router).then((claim) => {
          this.getClaimsForThisFirm(this.firm.fid);
          this.editClaim(claim);
        })
      },

      editClaim (item) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/editClaim/' +  item.clid, 'View App '
          + item.clid, 'resizeable=yes, width=' + (800) +
          ', height=' + (700));
      },

      viewClaim (item) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/viewClaim/' +  item.clid, 'View App '
          + item.clid, 'resizeable=yes, width=' + (900) +
          ', height=' + (700));
      },

      rowClick: function (item, row) {// marks which claim was just opened in a popup window
        row.select(true);
      },

      save(item, field) {
        // make deep copy to change date format of, not original,
        // so datepicker won't receive invalid date format
        if (field === 'datePicker') item.noDate = false;// set noDate=false so claim date won't be set to null
        let saveItem = JSON.parse(JSON.stringify(item));
        //first check date for null value and allow that to be sent back
        if (saveItem.claim == null || saveItem.claim === '' || saveItem.noDate === undefined || saveItem.noDate) {
            saveItem.claim = null;
        } else {
          // if date is valid, check if format is valid for api and if not, convert to correct format
          if (moment(saveItem.claim).isValid()) {
            if (!moment(saveItem.claim, 'DD MMM YYYY', true).isValid()) {
              saveItem.claim = moment(saveItem.claim, 'YYYY-MM-DD').format('DD MMM YYYY');
            }
          } else {// delete the date if format is not acceptable
            delete saveItem.claim;
          }
        }
        return claimAPIService.updateClaim(saveItem.clid, saveItem, this.$router)
          .then((response) => {
            this.getClaimsForThisFirm(item.fid);

            // need delayed editDialog reset for datepicker so it will re-open on first click & not show "invalid date"
            if (field === 'datePicker') setTimeout(()=>{
              this.editDialog = false;
              setTimeout(()=>{ this.editDialog = true }, 10)
              }, 1000);

            this.$nextTick(() => this.$store.dispatch('SET_SNACK', {cmpnt: 'FirmClaimsTable',
              open: true, msg: 'Claim change was saved.'}));
          })
          .catch((error) => {
            this.errors.push(error);
          });
      },

      getClaimsForThisFirm(id) {
        this.loading = true;
        let FID = id.toString();
        apiService.getFirmClaimTotals(FID, this.$router).then((data) => {
          this.loading = false;
          this.claims = data;
          for (let i = 0; i < this.claims.length; i += 1) {
            this.claims[i].noDate = false;
            if (!this.claims[i].claim) {
              //if no date in this field set date to today for datepicker but not for text-field
              this.claims[i].noDate = true;
              this.claims[i].claim = new Date().toISOString().substring(0, 10);// char 0 up to but not including char 10
            } else {
              this.claims[i].claim=moment(this.claims[i].claim,'DD MMM YYYY').format('YYYY-MM-DD');
            }
          }
        })
          .catch((error) => {
            this.errors.push(error);
            if (error.response && error.response.status === 401) {
              this.$router.push({
                name: 'Login'
              });
            }
          });
      },
    },

  }
</script>

<style>
  .left .v-data-table__empty-wrapper {
    text-align: left !important;
  }
</style>

