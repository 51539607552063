import CommonAPIService from './CommonHandler';

export class CommissionsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createCom(comData, router) {
    const url = '/api/commissions';
    return this.CommonAPIService.postCall(url, comData, router);
  }

  deleteCom(id, router) {
    const url = `/api/commissions/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getComsByDate(date, router) {
    const url = `/api/commissions?date=${date}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getComsByPeriod(date1, date2, router) {
    const url = `/api/commissions?period=${date1},${date2}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getComsForProgram(mgaId, carrier, router) {
    const url = `/api/commissions?mga_id=${mgaId}&carrier=${carrier}`;
    return this.CommonAPIService.getCall(url, router);
  }

  list(router) {
    const url = '/api/commissions';
    return this.CommonAPIService.getCall(url, router);
  }

  setComEndDate(id, endDate, endDateType, router) {
    const url = `/api/commissions/${id}`;
    return this.CommonAPIService.putCall(url, {end_date: endDate, end_date_type: endDateType}, router);
  }

  removeComEndDate(id, router){
    const url = `/api/commissions/${id}`;
    return this.CommonAPIService.putCall(url, {end_date: null, end_date_type: null}, router)
  }
}

export default CommissionsAPIService;