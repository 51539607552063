import CommonAPIService from './CommonHandler';
import _ from 'lodash';

export class ClaimsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getSingleClaim(claimId, router) {
    const url = `/api/claims/${claimId}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getClaim(search, router) {
    const url = '/api/claims/search';
    return this.CommonAPIService.postCall(url, search, router);
  }

  updateClaim(id, claim, router) {
    const url = `/api/claims/${id}`;
    claim = _.omit(claim, ['firm', 'files', 'factsHtml', 'noteHtml', 'updates', 'insured_address',
      'retention_deductible','deductible_paid','remaining_mon','paid_mon','remaining_defense','paid_defense',
      'current_defense','current_fkb','current_indemnity','paid_fkb','paid_indemnity','fkb','indemnity','total_paid',
      'total_current','total_expenses','total_indemnity','total_defense','total_fkb','total_reserves',
      'remaining_indemnity','remaining_fkb','total_remaining_reserves','total_incurred','balance','noDate']);
    return this.CommonAPIService.putCall(url, claim, router);
  }

  /**
   Creates a new claim
   * @param {object} claim - The claim object to be created
   * @param {Route} router - Object that contains information about the current route
   * @returns {Promise<object>} - Returns a promise that resolves to the created claim
   */
  createClaim(claim, router) {
    const url = '/api/claims';
    return this.CommonAPIService.postCall(url, claim, router);
  }

  /**
   * Delete a claim
   * @param {number} id - The id of the claim to delete
   * @param {Route} router -  The router object with route information
   * @returns {Promise<unknown> | *} - return a status (OK) when successful or an error
   */
  deleteClaim(id, router) {
    const url = `/api/claims/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  /**
   * Get claims with optional parameters
   * @param { object } params - additional fields that can be passed to the api to achieve certain behavior
   * @param { string } [params.fields] - comma separated list of fields to return, by default it will return all the fields
   * @param { string } [params.orders] - comma separated list of fields to order the results by, default is clid
   * @param { string } [params.directions] - comma separated list of directions to order the results by, default is ascending
   * @param { string } [params.limit] - limit the number of results returned, default is 1000
   * @param { string } [params.page] - get the results from the starting page, default is 1
   * @param { ...object } [params.additionalParams] - fields from claims to filter by.
   * @param { Route } router - object that contains information about the current route
   * @returns {Promise<*> | *} - Returns a promise containing the claims or an error
   * @example getClaims({fields: '*', orders: 'clid,stamp', directions: 'ASC,DESC', limit: '10', page: '2', clid: 1}, this.$route);
   */
  getClaims(params, router) {
    const url = `/api/claims`;
    return this.CommonAPIService.getCall(url, router);
  }

  getClaimAudits(id, router) {
    const orders = 'id,user_id';
    const directions = 'DESC,DESC';
    const limit = '';    // '' returns all logs
    const url = `/api/claims/${id}/logs?orders=${orders}&directions=${directions}&limit=${limit}`
    return this.CommonAPIService.getCall(url, router);
  }

  getClaimLedgers(claimId, router) {
    const fields = '*';
    const url = `/api/claims/${claimId}/ledger?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  addClaimLedger(body, claimId, router) {
    const url = `/api/claims/${claimId}/ledger`;
    return this.CommonAPIService.postCall(url, body, router);
  }

  updateClaimLedger(claimId, itemId, body, router) {
    const url = `/api/claims/${claimId}/ledger/${itemId}`;
    return this.CommonAPIService.putCall(url, body, router);
  }

  deleteClaimLedger(claimId, itemId, router) {
    const url = `/api/claims/${claimId}/ledger/${itemId}`;
    return this.CommonAPIService.deleteCall(url, router);
  }
}

export default ClaimsAPIService;
