<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" title="Tax manager help"
                 confirm-text="Done" max-width="600">
    <v-tabs v-model="active">
      <v-tabs-slider color="secondary"></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.name">{{ tab.name }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="active">
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <v-card flat>
          <v-card-text v-html="tab.text"></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </action-dialog>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  export default {
    name: "TaxInfoDialog",
    components: {ActionDialog},
    props: {
      value: {
        type: Boolean,
        required: true,
      }
    },
    data: () => ({
      active: null,
      tabs: [
        {
          name: 'General',
          text: `This tool is for managing previous and current tax rates. The tax rates you see when this page loads
          represent the rates that would apply by state to a policy with an inception date of today. For information on
          viewing previous or future tax rates, click the "VIEW" tab. For information on updating or creating new tax
          rates, click the "CREATE/UPDATE" tab. For information on deleting tax rates, click the "DELETE" tab.`
        },
        {
          name: 'View',
          text: `The date in the top right corner of the tax management page indicates the date for which
          the displayed taxes apply. The default date for this field is today's date. If you want to see what rates would
          apply for a different inception date, simply click on the date and use the provided date picker to select the
          date of interest.<br><br>

          If you want to update/create a new tax rate, you do not need to set this date to the date you
          want the tax to apply.`
        },
        {
          name: 'Create/Update',
          text: `If a state decides to change an existing rate, navigate to the current tax rate for that state and
          click the pencil icon. This will open a dialog that will allow you to configure a new rate for the selected
          tax.<br><br>

          If a mistake is made while configuring or creating a tax rate, please see the "DELETE" tab for information on
          deleting the rate.<br><br>

          Because historical information for tax rates needs to be maintained, when you update a tax rate the
          existing entry in the tax table isn't changed. Instead a new entry is created in the tax table and an end
          date is set on the previous entry to the start date of the new entry.<br><br>

          In addition, each state is allowed two taxing entities - one that is designated as the "DOI Fee", and the
          other as the "SLA Fee". If a state has less than two active taxing entities, a green "+" button will appear
          in the bottom right corner of the state drop down. This button allows you to add a new taxing entity that
          corresponds to one of these two fields (DOI, or SLA).<br><br>`
        },
        {
          name: 'Delete',
          text: `To delete a tax rate, navigate to the rate you want to delete and click on the trash can icon. Only
          tax rates without an end date can be deleted, in order to avoid gaps. When a tax rate is deleted, the system
          will check for a previous tax rate and remove the end date in order to keep a current tax. If there is no
          previous tax rate, the rate will just be deleted.`
        }
      ]
    })
  }
</script>

<style scoped>

</style>