<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="deleteCom"
                 title="Are you sure?" max-width="500" confirm-class="white--text" confirm-color="red darken-2"
                 confirm-text="Delete" bottom-divider>
    <warning-alert class="mt-4">
      Once a producer's commission data is deleted, <strong>it cannot be recovered.</strong> If no other commissions
      are configured, the producer will receive the default rate..
    </warning-alert>
    <loading-overlay v-model="loading" absolute></loading-overlay>
  </action-dialog>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import WarningAlert from "../../WarningAlert";
  import { ProducerCommissionsAPIService } from "@/servicehandlers/ProducerCommissionsAPIService";
  import LoadingOverlay from "../../LoadingOverlay";

  const producerCommissionsAPIService = new ProducerCommissionsAPIService();

  export default {
    name: "DeleteProducerCommissionDialog",
    components: {LoadingOverlay, WarningAlert, ActionDialog},
    props: {
      id: {
        required: true
      },
      value: {
        required: true,
        type: Boolean
      }
    },
    data: () => ({
      loading: false
    }),
    methods: {
      deleteCom() {
        this.loading = true;
        producerCommissionsAPIService.deleteProdCom(this.id, this.$router)
          .then(() => {
            this.loading = false;
            this.$emit('deleted');
            this.$emit('input', !this.value);
          })
      }
    }
  }
</script>

<style scoped>

</style>