import CommonAPIService from './CommonHandler';
import axios from 'axios';

export class FileobjectsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  uploadFile(files, router) {
    const url = '/api/file_objects';
    return this.CommonAPIService.fileCall(url, files, router)
  }

  uploadFileToFirm(files, fid, router) {
    const url = '/api/file_objects';
    files.set('fid', fid);
    return this.CommonAPIService.fileCall(url, files, router)
  }

  updateFileObject(id, file_object, router) {
    const url = `/api/file_objects/${id}`;
    return this.CommonAPIService.putCall(url, file_object, router);
  }

  listFileObject(id, router) {
    const url = `/api/file_objects/${id}`;
    return this.CommonAPIService.getCall(url, router);
  }

  createFileObject(file_object, router) {
    const url = '/api/file_objects';
    return this.CommonAPIService.postCall(url, file_object, router);
  }

  deleteFileObject(id, router) {
    const url = `/api/file_objects/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getAllForms(router) {
    const fields = 'loid,filename,f_type,file_description,size';
    const orders = 'f_type,file_description';
    const url = `/api/file_objects/forms?fields=${fields}&orders=${orders}`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default FileobjectsAPIService;
