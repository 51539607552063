import CommonAPIService from './CommonHandler';

export class ScopesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createScope(scopeData, router) {
    const url = '/api/scopes';
    return this.CommonAPIService.postCall(url, scopeData, router);
  }

  deleteScope(id, router) {
    const url = `/api/scopes/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  getScopes(router) {
    const url = '/api/scopes';
    return this.CommonAPIService.getCall(url, router);
  }

  getScopeById(id, router) {
    const url = `/api/scopes/${id}`;
    return this.CommonAPIService.getCall(url, router);
  }

  searchScopes(search, router) {
    const url = `/api/scopes?search=${search}`;
    return this.CommonAPIService.getCall(url, router);
  }

  updateScope(id, data, router) {
    const url = `/api/scopes/${id}`;
    return this.CommonAPIService.putCall(url, data, router);
  }
}

export default ScopesAPIService;
