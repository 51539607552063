<template>
  <v-snackbar app :color="color" :value="value" @input="$emit('input', $event)" top>
    <v-row class="justify-space-between">
      <div class="ml-4" style="margin-top: 7px">{{ msg }}</div>
      <v-btn @click="clearStoreSnack" color="white" text>Close</v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snack",
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    msg: {
      required: true,
      default: 'Success'
    },
    value: Boolean
  },

  watch: {
    value() {
      if (!this.value) this.clearStoreSnack();
    }
  },

  methods: {
    clearStoreSnack() {
      this.$store.dispatch('SET_SNACK', { cmpnt: '', open: false, color: 'transparent', msg: ''});
    },
  },
}
</script>

<style scoped>

</style>
