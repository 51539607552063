<template>
  <ViewFirm
    :id="id"
    :notPopup="false"
    noNote>
  </ViewFirm>
</template>

<script>
  import ViewFirm from "@/components/search/firms/ViewFirm";
  export default {
    name: "ViewFirmNoNote",
    props: {
      id: [String, Number],
    },
    components: {
      ViewFirm,
    },
  }
</script>

<style scoped>

</style>
