<template>
  <v-autocomplete :value="value" @input="$emit('input', $event)" :items="contacts" item-text="sort" item-value="cid"
                  v-bind="options"></v-autocomplete>
</template>

<script>
  import { ContactAPIService } from "@/servicehandlers/ContactAPIService";

  const contactsAPIService = new ContactAPIService();

  export default {
    name: "ContactsSelect",
    props: {
      options: {
        default: () => ({})
      },
      value: {
        required: true
      }
    },
    data: () => ({
      contacts: []
    }),
    created() {
      contactsAPIService.getContactList(this.$router)
        .then(contacts => {
          this.contacts = contacts;
          this.$emit('loaded');
        });
    }
  }
</script>

<style scoped>

</style>