<template>
  <v-card class="mt-0 mx-0 px-0 pt-0">
    <v-row no-gutters mx-auto>
      <v-col cols="12" lg="6">
        <v-card class="d-flex flex-column elevation-0 rounded-0 mr-5 py-0 scrollxy" height="400">
          <v-card-title class="mx-auto mt-n2">
            <button-tip btnclass="mx-2" xsmall fab icon icolor="black" iname="mdi-update"
                        elevation="5" tip="Refresh Info" @click="getFirm"/>
            <button-tip btnclass="mx-2" @click="openEditFirmInDialog = true" color="secondary" elevation="5" fab icolor="white"
                        iname="mdi-pencil-outline" tip="Edit Firm Dialog" v-can="'edit-firm'" xsmall/>
          </v-card-title>
          <v-card-text class="pl-2 py-0">
            <v-row no-gutters>

              <v-col cols="6">
                <v-row
                  class="pa-0 mx-0 my-n6"
                  :key="refresh + field.name"
                  v-for="field in firmInLineFields"
                >
                  <v-col cols="6" class="px-1" style="text-align: end">
                    {{ field.name }}:
                  </v-col>
                  <v-col cols="6" class="pl-1 pr-0" style="text-align: start">
                    <a style="cursor: pointer" v-if="field.val === 'phone'" :href="`tel:${firm.phone}`">{{firm.phone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3')}}</a>
                    <span v-if="field.val === 'fax'">{{firm.fax.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3')}}</span>
                    <span v-if="field.val !== 'phone' && field.val !== 'fax'">{{ firm[field.val] }}</span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
               <v-row class="my-n3">
                 <v-col style="text-align: start">
                   <p class="mb-n1" v-show="firm.contact">{{firm.contact}}</p>
                   <p class="mb-n1" v-show="firm.name1">{{firm.name1}}</p>
                   <p class="mb-n1" v-show="firm.name2">{{firm.name2}}</p>
                   <p class="mb-n1" v-show="firm.address1">{{firm.address1}}</p>
                   <p class="mb-n1" v-show="firm.address2">{{firm.address2}}</p>
                   {{firm.city}}, {{firm.state}}  &nbsp {{firm.zip}}
                   <br><br>
                   <p class="mb-n1" v-show="firm.website">{{firm.website}}</p>
                   <p class="mb-n1" v-show="firm.email">{{firm.email}}</p>
                 </v-col>
               </v-row>
              </v-col>
            </v-row>

            <br>
            <v-row
                  class="pa-0 mx-0 my-n6"
                  :key="refresh + field.name + 'long fields'"
                  v-for="field in firmLongFields"
              >
                <v-col cols="3" class="px-1" style="text-align: end">
                  {{ field.name }}:
                </v-col>
                <v-col cols="9" class="pl-1 pr-0" style="text-align: start">
                  <span v-if="field.val === 'sort'" v-html="firm.sort"></span>
                  <span v-else>{{ firm[field.val] }}</span>
                </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" class="px-0 pb-0 mt-n3">
        <v-card class="px-6 py-2 ma-0 elevation-0 rounded-0 scrollxy" height="400">
          <div v-html="htmlNote"></div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="openEditFirmInDialog">
      <v-card class="pa-0 ma-0 fill-height">
        <EditFirm
            v-if="openEditFirmInDialog"
            :id="firm.fid"
            :dialog="true"
            v-on:refresh="getFirm()"
            v-on:close="openEditFirmInDialog = false"
        ></EditFirm>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import EditFirm from '@/components/search/firms/EditFirm';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import InformationDialogIcon from "../../InformationDialogIcon";

  const firmsAPIService = new FirmsAPIService();

  export default {
    name: 'FirmInfo',
    props: {
      rightsArray: Array,
      firmProp: Object,
      notPopup: Boolean,
      default() {
        return {};
      }
    },
    components: { EditFirm, InformationDialogIcon },
    data() {
      return {
        dialogFirm: false,
        firm: this.firmProp,
        firmInLineFields: [
          {name: 'Entity', val: 'entity', list: 'entity', sort: '0'},
          {name: 'Producer', val: 'producer', list: 'name', sort: '1'},
          {name: 'Solicitor', val: 'solicitor', list: 'name', sort: '1'},
          {name: 'Agent', val: 'agent', list: 'name', sort: '1'},
          {name: 'Type', val: 'type', list: 'ftype', sort: '0'},
          {name: 'Status', val: 'status', list: 'fstatus', sort: '0'},
          {name: 'Salutation', val: 'salutation'},
          {name: 'Source', val: 'source', list: 'source', sort: ''},
          {name: 'Phone', val: 'phone', list: 'source', sort: ''},
          {name: 'Fax', val: 'fax', list: 'source', sort: ''},
        ],
        firmItems: [],
        firmLongFields: [
          {name: 'Legal Name', val: 'insured'},
          {name: 'Sort', val: 'sort'},
        ],
        openEditFirmInDialog: false,
        refresh: false,
      };
    },

    computed: {
      htmlNote() {
        return this.firm.note.replace(/(?:\r\n|\r|\n)/g, '<br>');
      },

      styledNote()  { return '<head><style> .styl table {border-collapse: collapse; margin: 0 auto; } .styl table, .styl th, .styl td' +
        '{border: 1px solid black;} .styl td {padding: 1em;}</style></head><body><div class="styl">' + this.firm.note +
        '</div></body>'
      },
    },

    methods: {
      getFirm() {
        firmsAPIService.getSingleFirm(this.firm.fid, this.$router)
          .then((firm) => {
            this.firm = firm;
          })
      },
    },
  };
</script>

<style>
  .scrollxy {
    overflow: scroll;
    white-space: nowrap;
  }
</style>
