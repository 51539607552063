import axios from 'axios';
import { store } from '../store';
import { router } from '../router';

axios.interceptors.request.use( (config) => {
  if (!store.state.timedOut) store.dispatch('RESET_ACTIVE_TIMER');
  return config;
}, (error) => Promise.reject(error));

function handleError(error) {
  if (error.response.status !== 401) return;
  if (error.response.data.status === 'TIMED_OUT') {
    router.push('/idle');
    return;
  }
  if (store.state.loggedIn) store.dispatch('LOGOUT');
}

export class CommonAPIService {
  constructor() {
    this.api_url = process.env.VUE_APP_API_URL;
  }

  postCall(url, parameters, router) {
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, parameters, { withCredentials: true })
      .then((response) => { return response.data })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }

  fileCall(url, files, router) {
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, files,
      {
        headers: {'Content-Type': 'multipart/form-data'},
        withCredentials: true
      })
      .then((response) => { return response })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }

  deleteCall(url, router) {
    const fullUrl = this.api_url + url;
    return axios.delete(fullUrl, { withCredentials: true })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }

  putCall(url, parameters, router) {
    const fullUrl = this.api_url + url;
    return axios.put(fullUrl, parameters, { withCredentials: true })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }

  getCall(url, router) {
    const fullUrl = this.api_url + url;
    return axios.get(fullUrl, { withCredentials: true })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }
}

export default CommonAPIService;
