<template>
  <div>

   <div class="px-2">
    <!--      Buttons    -->
     <v-row class="px-4 pt-1 pb-2">
       <div class="headline pl-2 py-1 my-1">{{ claim.fid }} &nbsp {{claim.firm ? claim.firm.sort : ''}}</div>
         <v-spacer/>
         <v-tooltip bottom>
           <template v-slot:activator="{ on }">
             <v-btn x-large tile text color='secondary darken-2' v-on="on"
                   @click="printDiv()"
             >
              Print
             </v-btn>
           </template>
         <span>Open Print Dialog</span>
        </v-tooltip>
     </v-row>

     <v-card class="rounded-0 elevation-4 px-4 mx-0 mt-n2">
      <div class="px-2 pt-4 mx-2 mt-0 scroll prtclaim">
        <v-row class="h5 pb-0 mx-0 mt-0 mb-n6" justify="center">
<!--          if left but no right then Claim By: left-->
          <span v-if="!!claim.claimant">
            <span v-if="!claim.insdef && !claim.othdef">Claim By: {{claim.claimant}} </span>
            <span v-else>{{claim.claimant}} &nbsp v. &nbsp </span>
          </span>
<!--          if no left and yes right then Claim Against: right-->
          <span v-if="!claim.claimant">Claim</span>
          <span v-if="!claim.claimant && (!!claim.insdef || !!claim.othdef)">&nbspAgainst:&nbsp</span>
          <span v-if="!!claim.insdef && !!claim.othdef">{{claim.insdef}}; {{claim.othdef}}</span>
          <span v-if="!!claim.insdef && !claim.othdef">{{claim.insdef}}</span>
          <span v-if="!claim.insdef && !!claim.othdef">{{claim.othdef}}</span>
        </v-row>
          <br>
          <br>
        <v-row justify="space-around">
          <div v-if="!!claim.carrier"><b>Carrier: </b>{{claim.carrier}}</div>
          <div v-if="!!claim.deductible"><b>Deductible: </b> ${{claim.deductible}}</div>
          <div v-if="!!claim.type"><b>Type: </b>{{claim.type}}</div>
          <div v-if="!!claim.status"><b>Status: </b>{{claim.status}}</div>
          <div v-if="!!claim.state"><b>State: </b>{{claim.state}}</div>
          <div v-if="!!claim.clm_num"><b>Claim #: </b>{{claim.clm_num}}</div>
        </v-row>

        <v-row>
          <v-col cols="12" class="dense">
            <v-card flat>
              <v-card-text class="dense">
                <v-data-table :headers="headers" :items="dates" hide-default-footer dense :mobile-breakpoint="0">
                  <template v-slot:item.engaged="{ item }">
                    <span>{{ item.claim }}</span>&nbsp&nbsp
                    <span>{{ item.date1 }}</span>
                  </template>
                </v-data-table><br><br>
                <v-data-table :headers="amountHeaders" :items="amounts" hide-default-footer dense :mobile-breakpoint="0">
                  <template v-slot:item.reserve="{ item }">
                    <span>{{ item.description }}</span>&nbsp&nbsp
                    <span>{{ item.name1 }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

<!--        <v-row class="mx-6">-->
<!--          <v-col cols="6">-->
<!--            Username-->
<!--          </v-col>-->
<!--          <v-col cols="6">-->
<!--            Timestamp-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--        <v-row v-for="update in claim.updates" :key="refresh" class="mx-6 my-n4 py-0 caption">-->
<!--          <v-col cols="6">-->
<!--            {{ update.username }}-->
<!--          </v-col>-->
<!--          <v-col cols="6">-->
<!--            {{ update.timestamp }}-->
<!--          </v-col>-->
<!--        </v-row>-->
        <br>

        <div v-if="!!claim.facts"><b>Facts:</b>
          <div class="ckblock" v-html="htmlFacts"></div><br>
        </div>
        <div class="mt-n4 pt-0" v-if="!!claim.note"><b>Note:</b>
          <div class="ckblock" v-html="htmlNote"></div>
        </div>

        <br>
        <v-row v-for="file in claim.files" :key="file.loidfid">
          <v-col class="py-0 my-0">
            <a :href="`${url}/download/${file.loidfid}`">
              {{ file.filename }}
            </a>
          </v-col>
        </v-row>
        <br>
      </div>

       <!--   alerts    -->
       <ErrorDialog ref="errorDialog"/>
     </v-card><br><br>
   </div>
  </div>
<!--  </div>-->
</template>

<script>
  import loginCheckMixin from '@/mixin';
  import { ClaimsAPIService } from "@/servicehandlers/ClaimsAPIService";
  import ErrorDialog from "../../ErrorDialog";

  const claimAPIService = new ClaimsAPIService();

  export default {
    name: 'ViewClaim',
    mixins: [ loginCheckMixin ],
    components: { ErrorDialog },
    props: {
      id: [String, Number],
      dialog: Boolean,
    },
    data: () => ({

      //--------- Data Variables ---------//

      amountHeaders: [
        {text: 'Description', value: 'description', sortable: false},
        {text: 'Monitor', value: 'amountMon', sortable: false, align: 'right'},
        {text: 'Defense', value: 'amountDef', sortable: false, align: 'right'},
        {text: 'Damages', value: 'amountDam', sortable: false, align: 'right'},
        {text: 'Total', value: 'amountTot', sortable: false, align: 'right'},
      ],
      amounts: [
        {description: 'Reserve',  amountMon: '', amountDef: '', amountDam: '', amountTot: ''},
        {description: 'Incurred', amountDef: '', amountDef: '', amountDam: '', amountTot: ''},
        {description: 'Current',  amountDam: '', amountDef: '', amountDam: '', amountTot: ''},
        {description: 'Remaining',amountTot: '', amountDef: '', amountDam: '', amountTot: ''},
      ],
      claim: {},
      dates: [
        // {engaged: this.claim.engaged, error: this.claim.error},
        {date1: '', date2: '', name1: 'Engaged',name2: 'Acknowledged'},
        {date1: '', date2: '', name1: 'Error',  name2: 'First Report'},
        {date1: '', date2: '', name1: 'Claim',  name2: 'Latest Report'},
        {date1: '', date2: '', name1: 'Report', name2: 'Closed'},
      ],
      errors: [],
      headers: [
        {text: '', value: 'name1', sortable: false},
        {text: 'Claim', value: 'date1', sortable: false},
        {text: '', value: 'name2', sortable: false},
        {text: 'Handling', value: 'date2', sortable: false},
      ],//engaged, error, claim, report      first report, latest report, closed
      refresh: false,
      styl: '<style> .styl table ' +
        '{border-collapse: collapse; margin: 0 auto; width: auto !important; } ' +
        '.styl table, .styl th, .styl td, .styl tr' +
        '{border: 1px solid black !important;} .styl td {padding: 1em;}</style><div class="styl">',
      url: process.env.VUE_APP_UW_URL,
    }),

    computed: {

      htmlFacts() {
        return this.styl + this.claim.facts.replace(/(?:\r\n|\r|\n)/g, '<br>');
      },

      htmlNote() {
        return this.styl + this.claim.note.replace(/(?:\r\n|\r|\n)/g, '<br>');
      },
    },

    created: async function() {
      await this.getClaim();
      this.getDates();
      this.getAmounts();
    },

    methods: {

      add3 (a,b,c) {
        let A = '';
        let B = '';
        let C = '';
        if (a) { A = parseFloat(a.replace(/[$|,]/g,''));}
        if (b) { B = parseFloat(b.replace(/[$|,]/g,''));}
        if (c) { C = parseFloat(c.replace(/[$|,]/g,''));}
        let sum1 = A + B + C;
        let sum = (sum1).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return sum;
      },

      getAmounts () {
        //change all nulls to zeroes ?
        if (this.claim.res_mon === null) {this.claim.res_mon = 0}
        if (this.claim.monitor === null) {this.claim.monitor = "0.00"}
        if (this.claim.cur_mon === null) {this.claim.cur_mon = "0.00"}

        if (this.claim.res_def === null) {this.claim.res_def = "0.00"}
        if (this.claim.defense === null) {this.claim.defense = "0.00"}
        if (this.claim.cur_def === null) {this.claim.cur_def = "0.00"}

        if (this.claim.reserve === null) {this.claim.reserve = "0.00"}
        if (this.claim.damages === null) {this.claim.damages = "0.00"}
        if (this.claim.cur_dam === null) {this.claim.cur_dam = "0.00"}


        this.amounts[0].amountMon = '$' + this.claim.res_mon.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[1].amountMon = '$' + this.claim.monitor.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[2].amountMon = '$' + this.claim.cur_mon.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[3].amountMon = this.sub2(this.claim.res_mon, this.claim.monitor,
          this.claim.cur_mon);
        this.amounts[0].amountDef = '$' + this.claim.res_def.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[1].amountDef = '$' + this.claim.defense.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[2].amountDef = '$' + this.claim.cur_def.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[3].amountDef = this.sub2(this.claim.res_def, this.claim.defense,
          this.claim.cur_def);
        this.amounts[0].amountDam = '$' + this.claim.reserve.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[1].amountDam = '$' + this.claim.damages.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[2].amountDam = '$' + this.claim.cur_dam.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.amounts[3].amountDam = this.sub2(this.claim.reserve, this.claim.damages,
          this.claim.cur_dam);

        this.amounts[0].amountTot = '$' + this.add3(this.claim.res_mon, this.claim.res_def,
          this.claim.reserve);
        this.amounts[1].amountTot = '$' + this.add3(this.claim.monitor, this.claim.defense,
          this.claim.damages);
        this.amounts[2].amountTot = '$' + this.add3(this.claim.cur_mon, this.claim.cur_def,
          this.claim.cur_dam);
        this.amounts[3].amountTot = this.sub2(this.amounts[0].amountTot, this.amounts[1].amountTot,
          this.amounts[2].amountTot);
      },

      getClaim: async function() {

        try {
          let res = await claimAPIService.getSingleClaim(this.id, this.$router)
          this.claim = res;
          // document.title = this.claim.clid + '\xa0\xa0' + this.claim.claimant;
          if (this.claim.clm_num) document.title = this.claim.clm_num;
          this.claim.updates = [
            {username: 'anthony', timestamp: 'June 25, 2020'},
            {username: 'anthony', timestamp: 'June 25, 2020'},
            {username: 'anthony', timestamp: 'June 25, 2020'}
          ];// TODO remove after api ready
          // this.refresh = !this.refresh;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Claim.",
            forDevelopers: e
          })
        }
      },

      getDates () {
        this.dates[0].date1 = this.claim.engaged;
        this.dates[1].date1 = this.claim.error;
        this.dates[2].date1 = this.claim.claim;
        this.dates[3].date1 = this.claim.report;
        this.dates[0].date2 = this.claim.clm_ack;
        this.dates[1].date2 = this.claim.first_rep;
        this.dates[2].date2 = this.claim.last_rep;
        this.dates[3].date2 = this.claim.close;
      },

      printDiv(divName) {
        window.print();
      },

      sub2 (a,b,c) {
        let A = '';
        let B = '';
        let C = '';
        if (a) { A = a.replace(/[$|,]/g,'');}
        if (b) { B = b.replace(/[$|,]/g,'');}
        if (c) { C = c.replace(/[$|,]/g,'');}
        let diff = 0;
        if (a) {
          if (b) {
            if (c) {
              A - B - C<0 ? diff = '--'
                : diff = '$' + (A-B-C).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              A-B<0 ? diff = '--'
                : diff = '$' + (A - B).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
          } else if (c) {
            A-C<0 ? diff = '--'
              : diff = '$' + (A - C).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
          } else diff = a;
        } else diff = '--';
        return diff;
      },
    },
  };
</script>

<style>
  .ckblock blockquote {
    font-style: italic;
    display: block;
    border: none;
    border-left: 5px solid lightgray;
    padding-left: 25px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  @media print
  {
    body * { visibility: hidden }
    .prtclaim * { visibility: visible }
    .prtclaim { position: absolute !important; top: -70px !important; font-size: 18px; width: 98% !important }
  }

  /*margin for each printed piece of paper*/
  @page {
    margin: 1in !important;
  }

</style>

