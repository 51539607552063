<template>
  <action-dialog :value="value" @input="$emit('input', $event)" persistent @confirm="$refs.idleForm.relogin()"
                 @close="sessionCheck" max-width="500" title="Your session has timed out." :disabled="!valid" >
    <idle-form ref="idleForm" @valid="(newVal) => { valid = newVal }"></idle-form>
    <v-alert v-if="error" type="error" text>
      {{ errorMessage }}
    </v-alert>
  </action-dialog>
</template>

<script>
  import { AuthAPIService } from "../../servicehandlers/AuthAPIService";
  import ActionDialog from "../ActionDialog";
  import IdleForm from "./IdleForm";

  const authAPIService = new AuthAPIService();
  export default {
    name: "IdleDialog",
    components: {IdleForm, ActionDialog},
    props: {
      value: {
        required: true,
        type: Boolean
      }
    },
    data: () => ({
      error: false,
      errorMessage: '',
      valid: true
    }),
    methods: {
      sessionCheck() {
        authAPIService.getSession(this.$router)
          .then(session => {
            if (session.logout) {
              this.$store.dispatch('LOGOUT');
              return;
            }
            if (session.time_remaining < 0) {
              this.error = true;
              this.errorMessage = `The current session is still timed out and will require this form to be filled out.`;
              return;
            }

            this.$store.commit('SET_TIMED_OUT', false);
            this.$store.dispatch('INITIALIZE_ACTIVE_TIMER', session.time_remaining);
          });
      }
    }
  }
</script>

<style scoped>

</style>