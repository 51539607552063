<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @close="$emit('input', !value)" @confirm="createCom"
                 title="New commission" max-width="900" confirm-text="Create" bottom-divider :disabled="!valid">
    <commission-form class="mx-n4 mb-n4" ref="commissionForm" :start-date="moment().format('YYYY-MM-DD')"
                     @valid="(v) => valid = v" new-mga></commission-form>
    <loading-overlay v-model="loading" absolute></loading-overlay>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import CommissionForm from "./forms/CommissionForm";
  import ActionDialog from "../../ActionDialog";
  import LoadingOverlay from "../../LoadingOverlay";


  export default {
    name: "CreateCommissionDialog",
    components: {LoadingOverlay, ActionDialog, CommissionForm},
    props: {
      value: {
        required: true,
        type: Boolean
      }
    },
    data: () => ({
      loading: false,
      valid: false
    }),
    methods: {
      async createCom() {
        this.loading = true;
        if (!await this.$refs.commissionForm.createCommission()) {
          this.loading = false;
          return;
        }
        this.loading = false;
        this.$emit('created');
        this.$emit('input', !this.value);
      },
    }
  }
</script>

<style scoped>

</style>