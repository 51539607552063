<template>
  <v-dialog :value="value" @input="$emit('input', $event)" :max-width="windowOptions[step].width || 500">
    <v-card class="mx-auto" >
      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>{{ windowOptions[step].title }}</span>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <v-form v-model="windowOptions[1].valid">
              <v-row>
                <v-col class="dense">
                  <contacts-select v-model="policyModel.mgaid"
                                   :options="{label: 'MGA', rules: [rules.required]}"></contacts-select>
                </v-col>
                <v-col class="dense">
                  <carriers-select v-model="policyModel.carrier" :options="{label: 'Carrier'}"></carriers-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="dense">
                  <PolicyTypesSelect v-model="policyModel.type" :options="{label: 'Type'}"></PolicyTypesSelect>
                </v-col>
                <v-col class="dense">
                  <policy-placements-select v-model="policyModel.is_new" :options="{label: 'Placement'}"></policy-placements-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="dense">
                  <producer-select v-model="policyModel.prodname" :options="{label: 'Producer',
                  rules: [rules.required]}"></producer-select>
                </v-col>
                <v-col class="dense">
                  <contacts-select v-model="policyModel.spnid" :options="{label: 'Sponsor', clearable: true}"></contacts-select>
                </v-col>
              </v-row>
              <v-row class="mb-6">
                <v-col class="dense">
                  <span class="text-caption grey--text text--darken-1">If the listed producer has left the company,
                  provide the new producer's name in the "Sponsor" field.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="dense">
                  <v-text-field v-model="policyModel.incept" label="Incept"
                                :rules="[rules.required, rules.date]"></v-text-field>
                </v-col>
                <v-col class="dense">
                  <v-text-field v-model="policyModel.expiry" label="Expiry"
                                :rules="[rules.required, rules.date]"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="dense">
                  <v-text-field v-model="policyModel.quoted" label="Quoted"
                                :rules="[rules.required, rules.date]"></v-text-field>
                </v-col>
                <v-col class="dense">
                  <v-text-field v-model="policyModel.booked" label="Booked" :rules="[rules.date]"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="dense">
                  <v-text-field v-model="policyModel.bound" label="Bound" :rules="[rules.date]"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-6" justify="end" v-if="dirty">
                <v-btn class="mr-4" small @click="policyModel = JSON.parse(policy)">Reset</v-btn>
                <v-btn class="mr-2" small @click="updatePolicy()" color="secondary">Update Policy</v-btn>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="dense">
                <info-alert>
                  The information above is collected from the policy. Please review, and make any necessary changes.
                  <span v-if="!policyModel.booked || !policyModel.bound">
                    If no "Booked" or "Bound" dates are specified, today's date ({{ moment().format('MM-DD-YYYY') }})
                    will be used in the commission selection process.
                  </span>
                </info-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text class="mt-n4" v-if="comsWithConditions.length">
            <v-radio-group v-model="commission" :rules="[rules.required]" mandatory>
              <v-radio v-for="commission in comsWithConditions" :key="commission.id" :value="commission"
                       color="secondary">
                <template v-slot:label>
                  <span class="ml-3">{{ commission.condition }}</span>
                </template>
              </v-radio>
              <v-radio :value="defaultCommission" color="secondary">
                <template v-slot:label>
                  <span class="ml-3">None of the above</span>
                </template>
              </v-radio>
            </v-radio-group>
            <span class="text-caption grey--text text--darken-1">This will affect the commission rates.</span>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text v-if="Object.keys(commission).length">
            <v-data-table :items="commission.commission" :headers="headers" hide-default-footer :mobile-breakpoint="0"
                          disable-sort>
              <template v-slot:item.contact_id="{ item }">
                <contacts-select v-model="item.contact_id" :rules="[rules.required]"></contacts-select>
              </template>
              <template v-slot:item.field="{ item }">
                {{ entityMap[item.field] }}
              </template>
            </v-data-table>
            <v-row>
              <v-col class="dense">
                  <span class="text-caption grey--text text--darken-1">This information will appear on the "Entities"
                    screen in Legacy after saving.</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col class="dense">
                  <span class="text-caption grey--text text--darken-1">No matching commissions were found. Please
                    contact accounting or provide commissions by hand.</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="$emit('input', !value)">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="step === 1" text @click="step--">Back</v-btn>
        <v-btn :disabled="windowOptions[step].disabled()" color="secondary" @click="step === 3 ? updateCommissions() : step++">
          {{ windowOptions[step].confirmText || 'Next' }}
        </v-btn>
      </v-card-actions>
      <loading-overlay v-model="loading"></loading-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';
  import PoliciesAPIService from "@/servicehandlers/PoliciesAPIService";
  import {_} from 'vue-underscore';
  import ContactsSelect from "../ContactsSelect";
  import CarriersSelect from "../CarriersSelect";
  import LoadingOverlay from "../LoadingOverlay";
  import PolicyTypesSelect from "../PolicyTypesSelect";
  import PolicyPlacementsSelect from "../PolicyPlacementsSelect";
  import DatePickerTextField from "../DatePickerTextField";
  import InfoAlert from "../InfoAlert";
  import ProducerSelect from "../ProducerSelect";

  const policiesAPIService = new PoliciesAPIService();
  export default {
    name: "CommissionsDialog",
    components: {
      ProducerSelect,
      InfoAlert,
      DatePickerTextField,
      PolicyPlacementsSelect, PolicyTypesSelect, LoadingOverlay, CarriersSelect, ContactsSelect},
    props: {
      pid: {
        required: true,
      },
      value: {
        required: true,
        type: Boolean
      }
    },
    watch: {
      commission(commission) {
        for (const com of commission.commission) {
          com.commission = Math.round(com.commission * 10000) / 10000;
        }
      },
      step(step) {
        if (step === 2) this.getCommissions();
      },
      policyModel: {
        deep: true,
        handler(policyModel) {
          if (JSON.stringify(policyModel) !== this.policy) {
            this.dirty = true;
            return;
          }
          this.dirty = false;
        }
      }
    },
    data: (vm) => ({
      commission: {},
      commissions: [],
      comsWithConditions: [],
      defaultCommission: null,
      dirty: false,
      headers: [
        {text: 'Entity', value: 'entity'},
        {text: 'Name', value: 'contact_id', width: 200},
        {text: 'Commission', value: 'commission'},
        {text: 'Field', value: 'field'}
      ],
      entityMap: {
        mgacom: 'MGA (Final)',
        procom: 'Producer',
        subcom: 'Sub Producer',
        spncom: 'Sponsor'
      },
      error: false,
      errorMessage: '',
      loading: false,
      policy: '',
      policyModel: {},
      rules: {
        date: (x) => (!x || moment(x, 'MM-DD-YYYY', true).isValid()) || 'Must be a valid date.',
        required: (x) => !!x || 'This field is required.',
      },
      step: 1,
      windowOptions: {
        1: {
          title: 'Confirm the information below',
          valid: false,
          disabled: function() { return !this.valid || vm.dirty }
        },
        2: {
          title: 'Which of the following applies to the policy?',
          disabled: () => false
        },
        3: {
          title: 'Applicable commission rates',
          confirmText: 'Save',
          disabled: () => false,
          width: 650
        }
      }
    }),
    created() {
      this.getPolicy();
    },
    methods: {
      getCommissions() {
        this.loading = true;
        policiesAPIService.getCommissions(this.pid, this.$router)
          .then(commissions => {
            this.loading = false;
            if (commissions.length === 1) {
              this.commission = commissions[0];
              this.step++;
              return;
            }

            this.comsWithConditions = commissions.filter(com => com.condition);
            const remaining = _.difference(commissions, this.comsWithConditions);

            if (remaining.length > 1) {
              this.error = true;
              this.errorMessage = 'Multiple applicable commissions. Please contact accounting for correct commissions.';
            }

            this.defaultCommission = remaining[0];
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
            this.errorMessage = `An error occurred while trying to retrieve commissions. Please contact accounting for
            correct commissions.`;
          })
      },
      getPolicy() {
        policiesAPIService.getPolicy(this.pid, this.$router)
          .then(policy => {
            this.policy = JSON.stringify(policy);
            this.policyModel = policy;
          });
      },
      updateCommissions() {
        this.loading = true;
        const updates = this.commission.commission.reduce((updates, com) => {
          updates[com.field] = com.commission;
          updates[com.entity_field] = com.contact_id;
          return updates;
        }, {});

        policiesAPIService.updatePolicy(this.policyModel.pid, updates, this.$router)
          .then(() => {
            this.loading = false;
            this.$emit('input', !this.value);
            this.$emit('updated');
          })
      },
      updatePolicy() {
        for (const key in this.policyModel) {
          if (this.policyModel[key] === undefined) this.policyModel[key] = null;
        }
        this.loading = true;
        policiesAPIService.updatePolicy(this.policyModel.pid, this.policyModel, this.$router)
          .then(() => {
            this.getPolicy();
            this.loading = false;
          });
      }
    }
  }
</script>

<style scoped>

</style>