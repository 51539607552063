<template>
  <div>
    <v-card class="px-1 elevation-0 rounded-0 borderless" :key="$_sideMenu">
      <v-card class="d-flex flex-row elevation-6 justify-space-around mt-0 mx-0 pa-0"
              tile block style="border-top: 1px solid white !important"
              v-sticky sticky-z-index="99" sticky-offset="$_offset">
        <div>
          <button-tip btnclass="px-xs-0 px-sm-12 py-0" @click="seeMoreOptions = false, seeMoreOptionsBelow = false, viewJobs()"
                      hideSmDown="View " small text tile tip="Your Jobs" txt=" Jobs"/>
        </div>
        <div style="width:270px; margin-top: -7px; margin-bottom: 3px">
          <v-text-field
              class="smalltext px-3"
                        @click="searchByFid = true, unisearch = ''"
                        clearable
                        dense
                        hide-details
                        @keyup.enter="searchFirms()"
                        label="Get by FID"
                        single-line
                        v-model="whereColumns.fid"
          ></v-text-field>
        </div>
        <div>
          <button-tip btnclass="pa-0 ma-n1" @click="firmSearchInTab()" icolor="black"
                      icon iname="mdi-arrow-top-right" tip="Show Search Options in New Tab"/>
          <button-tip btnclass="px-xs-0 px-sm-12 py-0" small text tile
                     hideSmDown="Search " txt=" Firms" @click="searchFirmsButton()"
                     :tip="state === 'showFirms' || state === 'loadingFirms'
                       ? 'To see firms, enter a FID or unisearch (2-char min) then hit return'
                       : 'To see firms, enter a FID & press return or click SEARCH FIRMS, enter unisearch (2-char min) then hit return'"/>
        </div>
      </v-card>

      <firms-options
          :checkedProp="checkedColumns"
          @clear="clearOptions()"
          :info="info"
          @search="searchByFid = false, searchFirms()"
          @update="update"
          v-if="seeMoreOptions"
          :whereProp="whereColumns"
      ></firms-options>

      <v-expansion-panels readonly v-model="panels" multiple class="my-0 py-0 rounded-0" v-show="panels.length > 0">
        <v-expansion-panel class="px-0 elevation-24">
          <v-expansion-panel-header color="h5 secondary--text darken-3 font-weight-medium"
                                    class="dense pl-0 pr-4 py-0 my-n1 heading-2" hide-actions id="plain">
            <v-spacer/>
            <span class="mt-n2 mb-n1" style="width: 200px" v-if="state === 'loadingJobs'  || state === 'showJobs'">
              <button-tip btnclass="py-0 mt-n1 mb-0" icon iname="mdi-update" tip="Refresh Jobs" @click="refreshJobs()"/>
              Jobs</span>
            <span class="py-0 mt-n4 mb-n1 mx-0" style="width: 150px" v-if="state === 'loadingFirms' || state === 'showFirms'">
              <button-tip btnclass="py-0 mt-n3 mb-n2 mx-n1" icon iname="mdi-update" tip="Refresh Firms" @click="refreshFirms()"/>
              Firms</span>
            <v-spacer v-if="state === 'loadingJobs' || state === 'showJobs'"></v-spacer>
            <information-dialog-icon confirmClass="mr-3 mb-3 title" confirmColor="secondary darken-1"
              icon-class="mt-n2" title="Information for jobs table" tclass="headline grey lighten-2 mb-8"
              v-if="state === 'loadingJobs' || state === 'showJobs'">
                Drag jobs column headers to change the order of the columns.
            </information-dialog-icon>
            <v-spacer v-if="state === 'loadingJobs' || state === 'showJobs'"></v-spacer>
            <v-spacer v-if="state === 'loadingJobs' || state === 'showJobs'"></v-spacer>
            <v-menu allow-overflow :close-on-content-click="false" :open-on-click="false" v-model="seeMoreOptionsBelow" offset-y
                    nudge-top="9" nudge-width="200" style="max-height: 700px; width: calc(80vw + 200px)">
              <template v-slot:activator="{ on }">
                <v-btn
                    class="text-none px-0"
                    v-on="on"
                    elevation="0"
                    style="background-color: white"
                >
                <v-text-field v-if="state === 'loadingFirms' || state === 'showFirms'"
                              append-icon="mdi-magnify" clearable style="width: 75vw; margin-right: 20px"
                              @click.prevent="whereColumns.fid = '', seeMoreOptions = false, seeMoreOptionsBelow = false"
                              v-model="unisearch"
                              hide-details single-line
                              @keyup.enter="unisearchFirms()"
                              label="Unisearch sort, name, phone, contact, insured"
                              class="pl-0 pr-2 mt-n12 mb-n7 mr-2 font-weight-regular"
                >
                  <template v-slot:prepend>
                    <information-dialog-icon
                        confirmClass="mr-3 mb-3 title" confirmColor="secondary darken-1" icon-class="mt-n1 ml-2 mr-n2"
                        title="Firms Search Options" tclass="headline grey lighten-2 mb-8">
                      <p>Click <v-icon>mdi-arrow-down</v-icon> for options below.</p>
                      <p>Click <v-icon>mdi-close</v-icon>to close options.</p>
                      <p>Click <v-icon>mdi-arrow-up</v-icon> for options above.</p>
                      <p>Click <v-icon>mdi-arrow-top-right</v-icon> for options in a new tab.</p>
                      Table columns will appear in the order checked.
                      To change the order, you can uncheck the boxes (except FID) and then check them
                      in the order you want. Fields with an arrow allow you to search in and select from
                      a list. Fields with no arrow allow you to enter full or partial data.
                      <br><br>
                      <b>Press ENTER in any field to SUBMIT firms search.</b>
                      <br><br>
                      <p><b>Click UNISEARCH field to CLOSE search options.</b></p>
                    </information-dialog-icon>
                  </template>
                  <template v-slot:prepend-inner>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptionsBelow = false" icolor="black"
                                icon iname="mdi-close" tip="Close Search Options Below" v-if="seeMoreOptionsBelow"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptionsBelow = true, seeMoreOptions = false" icolor="black"
                                icon iname="mdi-arrow-down" tip="Show Search Options Below" v-if="!seeMoreOptionsBelow"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptions = false" icolor="black"
                                icon iname="mdi-close" tip="Close Search Options Above" v-if="seeMoreOptions"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptions = true, seeMoreOptionsBelow = false" icolor="black"
                                icon iname="mdi-arrow-up" tip="Show Search Options Above" v-if="!seeMoreOptions"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="firmSearchInTab()" icolor="black"
                                icon iname="mdi-arrow-top-right" tip="Show Search Options in New Tab"/>
                  </template>
                </v-text-field>
              </v-btn>

              </template>
                <firms-options
                    :checkedProp="checkedColumns"
                    @clear="clearOptions()"
                    :info="info"
                    @search="searchByFid = false, searchFirms()"
                    @update="update"
                    :whereProp="whereColumns"
                ></firms-options>
            </v-menu>

            <v-text-field v-if="state === 'loadingJobs' || state === 'showJobs'"
                          v-model="search" @click.stop @keydown.enter.prevent
                          append-icon="mdi-magnify" clearable single-line
                          label="Search Jobs" style="width:300px"  hide-details
                          class="mt-n12 mb-n7 ml-2 mr-2 font-weight-regular">
            </v-text-field>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="noPadding">
            <v-data-table v-if="state === 'loadingJobs' || state === 'showJobs'"
                          :loading="state === 'loadingJobs'"
                          class="jobsTable elevation-2 mt-n3 mb-2 pb-0"
                          :dense="dense"
                          :search="search"
                          v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
                          :key="anIncreasingNumber"
                          loading-text="Loading... Please wait"
                          :headers="headers"
                          fixed-header
                          :mobile-breakpoint="0"
                          :sort-by="['staff', 'status', '_lag', 'cstamp']"
                          :sort-desc="[false, true, false, false]"
                          :style="$_cssVars"
                          multi-sort
                          :items="jobs"
                          :items-per-page="-1"
                          hide-default-footer>
              <template v-slot:item.actions="{ item }">
                <v-lazy>
                  <div>
                    <button-tip @click="openJobFirmInPopup(item)" icon iname="mdi-open-in-new" small tip="Open Popup"/>
                    <button-tip @click="openJobFirmBelow(item)" icon iname="mdi-eye-outline" small tip="Open Below"/>
                  </div>
                </v-lazy>
              </template>
              <template v-slot:item.firm_sort="{ item }">
                <v-tooltip left v-if="item.firm_sort && item.firm_sort.length > 30">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.firm_sort ? item.firm_sort.slice(0, 25) + '...' : '' }}</div>
                  </template>
                  {{ item.firm_sort || '' }}
                </v-tooltip>
                <div v-else>{{ item.firm_sort || '' }}</div>
              </template>
              <template v-slot:item.status="{ item }">
                <div :style="{color: (item.status === 'xtreme' ? 'red'
                  : (item.status === 'urgent' ? 'blue' : 'black'))}">
                  {{ item.status }}
                </div>
              </template>
              <template v-slot:item._lag="{ item }">
                <div :style="{color: (item.status === 'xtreme' ? 'red'
                  : (item.status === 'urgent' ? 'blue' : 'black'))}">
                  {{ item._lag }}
                </div>
              </template>
              <template v-slot:item.re="{ item }">
                <div v-html="item.re"></div>
              </template>
              <template v-slot:item.cstamp="{ item }">
                {{ moment(item.cstamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A')}}
              </template>
            </v-data-table>
            <view-firms-table ref="viewFirmsTable"
                              :firms="firms"
                              :checked-columns="checkedColumns"
                              :dense="dense" :total-records="totalRecords"
                              :is-unisearch="isUnisearch"
                              :edit-firm-rights="$_can('edit-firm')"
                              :state="state"
                              :styleProp="$_cssVars"
                              @pop-up="firmPopup"
                              @view-firm="seeMoreOptions = false, notPopup = true, viewFirmSearch(...arguments)"
                              @search="searchFirmsNow"></view-firms-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-card>

    <v-snackbar app color="error" v-model="overFirmLimit" style="top: 80px;" top>
      Reached 100 firm limit! Please narrow your search.
      <v-btn color="white" @click="overFirmLimit = false" text>Close</v-btn>
    </v-snackbar>

    <v-snackbar app color="error" v-model="noResults" style="top: 80px;" top>
      No search results. Search was too narrow.
      <v-btn color="white" @click="noResults = false" text>Close</v-btn>
    </v-snackbar>

    <v-snackbar app color="error" v-model="emptyFields" style="top: 80px;" top>
      At least one search field must be filled to run a search.
      <v-btn color="white" @click="emptyFields = false" text>Close</v-btn>
    </v-snackbar>

    <ErrorDialog ref="errorDialog"/>
    <view-firm  v-if="showFirmTabs"
                :noNote="noNote"
                :showFirm="showFirm"
                notPopup
                :id="id"
    />
  </div>
</template>

<script>
  // Add back the sortHandle class if it gets stripped away by external code
  function watchClass(targetNode, classToWatch) {
    // watchClass(targetNode, classToWatch) {
    let lastClassState = targetNode.classList.contains(classToWatch);
    const observer = new MutationObserver((mutationsList) => {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i];
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const currentClassState = mutation.target.classList.contains(classToWatch);
          if (lastClassState !== currentClassState) {
            lastClassState = currentClassState;
            if (!currentClassState) {
              mutation.target.classList.add('sortHandle');
            }
          }
        }
      }
    });
    observer.observe(targetNode, { attributes: true });
  }

  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  import ErrorDialog from "../../ErrorDialog";
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import FirmsOptions from "./FirmsOptions";
  import InformationDialogIcon from "../../InformationDialogIcon";
  import ListViewer from "../../ListViewer";
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
  import Sortable from 'sortablejs';
  import ViewFirm from "@/components/search/firms/ViewFirm";
  import ViewFirmsTable from "./firmsSearch/ViewFirmsTable";
  import { GroupsAPIService } from '@/servicehandlers/GroupsAPIService';

  const groupsAPIService = new GroupsAPIService();
  const apiService = new CommonAPIService();
  const firmAPIService = new FirmsAPIService();
  const note = new NotesAPIService();

  export default {
    name: 'SearchFirms',
    components: { ViewFirmsTable, ErrorDialog, FirmsOptions, InformationDialogIcon, ListViewer, ViewFirm },
    data() {
      return {
        info: [], // info from FirmsSearchOptions
        unisearch: '',
        clear: false,
        isUnisearch: false,
        newSearch: false,
        noResults: false,
        overFirmLimit: false,
        emptyFields: false,
        infoDialog: false,
        dense: true, //table density
        userPrefs: {},
        anIncreasingNumber: 1,
        panels: [],
        fullFieldData: [],
        groups: [],
        colHeaders: [], // for firms
        search: '', // for jobs/notes
        headers: [], // for jobs/notes
        jobHeaders: [ // for jobs/notes
          {value: 'actions',  text: 'Actions',  width: '100px', sortable: false, align: 'center'},
          {value: 'firm_sort',text: 'Firm Name',width: '235px'},
          {value: '_exdate',  text: 'Exdate',   width: '103px'},
          {value: 'status',   text: 'Status',   width: '110px'},
          {value: '_lag',     text: 'Age',      width: '90px'},
          {value: 'type',     text: 'Type',     width: '90px'},
          {value: 're',       text: 'Re',       width: '450px'},
          {value: 'cstamp',   text: 'Dated',    width: '180px'},
          {value: 'staff',    text: 'For',      width: '90px'},
          {value: 'closer',   text: 'Last',     width: '72px'},
          {value: 'opener',   text: 'By',       width: '68px'},
        ],
        firm: {},
        firms: [], // data for the b-table
        firmArray: [], // array of firms to view
        // defaults & columns that are specific to the where clause, in table firms
        checkedColumns: ['fid', 'sort', 'phone', 'state' ],
        includeColumns: [], // columns that are specific to the include checkboxes
        whereColumns: {}, // columns that are specific to the where clause
        includeCheckColumns: {}, // columns that are specific to include boxes, but with "where"
        totalPages: 0, // for pagination
        totalRecords: 0, // for pagination
        sortBy: null, // for the b-table
        sortDesc: false, // for the b-table
        filter: null, // for the b-table
        loading: false,
        loggedIn: false,
        userId: 0,
        searchByFid: false,
        searchItems: {},
        savedSearchItems: {},
        savedSearch: false,
        refresh: false,
        id: 0,
        showFirm: true,
        noNote: true,
        notPopup: true,
        isSorting: false,
        seeMoreOptions: false,
        seeMoreOptionsBelow: false,
        state: 'loadingJobs',
        jobs: [],
        showedit: true,
        notesOpen: false, //true if returning from email/note edit page
        showFirmTabs: false,
        nextJobNid: 0,
        nextJobFid: 0,
        loadingJob: false,
        nid: 0,
        tab: null,
        errors: [],
        noteData: undefined,
      };
    },

    watch: {

      seeMoreOptions() {
        if (this.seeMoreOptions) {
          this.state = 'showFirms';
          this.panels = [0];
        }
      },
    },

    created() {
      document.title = 'Firms';
    },

    mounted() {
      this.seeMoreOptions = this.$store.getters.seeMoreFirmsOptions;//flag to open firms search options
      this.$store.dispatch('SET_FIRMS_SEARCH_OPEN', false);
      this.groups = this.$store.getters.groups;
      this.$store.getters.userPreferences.jobHeaders ? this.headers
        = this.$store.getters.userPreferences.jobHeaders : this.headers = this.jobHeaders ;
      this.$store.getters.userPreferences.dense ? this.dense
        = this.$store.getters.userPreferences.dense : false;
      this.noteData = this.$store.getters.noteData;
      this.nextJobFid = this.noteData.nextJobFid ? this.noteData.nextJobFid : 0;
      this.nextJobNid = this.noteData.nextJobNid ? this.noteData.nextJobNid : 0;
      this.viewJobs();
    },

    methods: {
      callApi(searchItems) {
        this.isUnisearch = false;
        firmAPIService.getFirm(searchItems, this.$router).then((data) => {
          if (data['result'].length >= 100  && !this.groups.includes('admin')) {
            this.overFirmLimit = true;
          } else {
            this.firms = data['result'];
            this.totalPages = data['totalpages'];
            this.totalRecords = data['count'];
            if (this.totalRecords === 0) this.noResults = true;
            this.$store.dispatch('SET_NOTES_DATA', this.noteData);
            this.noteData = this.$store.getters.noteData;
          }
          this.state = 'showFirms';
        }).catch((error) => {
          this.$refs.errorDialog.showError({
            errorMessage: 'Error getting firms',
            forDevelopers: error
          })
          this.state = 'showFirms';// end loading animation
        });
      },

      // clean column, even though you uncheck certain items, they still might be included
      // in the where clause even though the value is empty.
      // this method cleans that up before sending to the API
      cleanColumns() {
        Object.keys(this.whereColumns).forEach(key => {
          if (this.whereColumns.hasOwnProperty(key)) {
            const value = this.whereColumns[key];
            if (!value || value.length <= 0) {
              // get rid of it
              delete this.whereColumns[key];
            }
          }
        });
      },

      clearOptions() {
        this.$nextTick(() => {
            for (let key in this.whereColumns) {
              if (key !== 'fid') delete this.whereColumns[key];
            }
            this.checkedColumns = ['fid', 'sort', 'phone', 'state' ];
        });
      },

      firmPopup(id) {//passes fid, no note selected
        let baseUrl = window.location.origin;
        let w = window.open(baseUrl +  '/viewFirmNoNote/' +  id, '_blank',
          'resizeable=yes, width=' + (1140)
          + ', left=' + (300)
          + ', height=' + (730));
      },

      firmSearchInTab () {
        this.$store.dispatch('SET_FIRMS_SEARCH_OPEN', true);
        let baseUrl = window.location;
        window.open(baseUrl, '_blank');
      },

      nextNote: function () {
        this.loadingJob = true;
        this.userId = this.$store.getters.userId;
        note.getNextNote(this.userId, this.$route)
          .then((response) => {
            if (response && response[0]) {
              this.nextJobFid = response[0].fid;
              this.nextJobNid = response[0].nid;
              this.noteData.status = 'Active';
              // use nid to update note with staff=username
              this.user = this.$store.getters.username;
              let parms = {staff: this.user};
              return note.updateNote(this.nextJobNid, parms, this.$router)
                .then((response) => {
                  this.loadingJob = false;
                  this.noNote = false;//tells view component that nid is being sent
                  this.viewFirm(this.nextJobNid, false);
                })
                .catch((error) => {
                  console.debug('error: ', error);
                });
            }
          })
          .catch((error) => {
            console.debug('error: ', error);
          });
      },

      openJobFirmBelow(item) {
        this.seeMoreOptions = false;
        this.notPopup = true;
        this.viewFirmJob(item.nid);
      },

      openJobFirmInPopup(item) {
        this.notPopup = false;
        this.viewFirmJob(item.nid);
      },

      refreshFirms() {
        this.state = 'loadingFirms';
        this.isUnisearch ? this.unisearchFirms() : this.callApi(this.searchItems);
      },

      refreshJobs() {
        this.refresh = true;
        this.viewJobs();
      },

      searchFirms() {
        this.newSearch = true;// this is a new search, not just a new page, so set page to 1 in setSearchItems()
        this.savedSearch = false;
        this.state = 'showFirms';
        this.searchFirmsNow();
      },

      searchFirmsButton() {
        this.state = 'showFirms';
        this.panels = [0];
      },

      searchFirmsNow() {
        const emptyButFid = Object.entries(this.whereColumns).every(([k, v]) => k === "fid" || v === null || v === '');
        if (emptyButFid && !this.searchByFid) {// if all fields are empty except fid and !searchByFid, alert emptyFields
          this.emptyFields = true;
          return;
        } // require narrowing of search to limit firms displayed
        this.seeMoreOptionsBelow = false;
        this.unisearch = '';
        this.panels = [0];
        this.state = 'loadingFirms';
        if (this.savedSearch === true) {
          this.savedSearch = false;
          this.callApi(this.savedSearchItems);
        } else {
          this.setSearchItems();
          this.callApi(this.searchItems);
        }
        this.cleanColumns();
      },

      setSearchItems() {
        // if not searching by fid, remove fid from search, else convert fid to integer so backend will accept
        !this.searchByFid ? delete this.whereColumns.fid : this.whereColumns.fid *=1;
        if (!this.checkedColumns.includes('fid')) this.checkedColumns.push('fid');// if unisearch removed fid, add back
        if (this.newSearch === true) this.$refs.viewFirmsTable.options.page = 1;// page=1 if newSearch, not page change
        this.searchItems = {
          page: this.$refs.viewFirmsTable.options.page,
          perPage: this.$refs.viewFirmsTable.options.itemsPerPage,
          sortBy: this.$refs.viewFirmsTable.options.sortBy[0],
          sortDesc: this.$refs.viewFirmsTable.options.sortDesc[0],
          checkedColumns: this.checkedColumns,
          whereColumns: this.searchByFid ? {'fid': this.whereColumns.fid } : this.whereColumns,
          includeCheckColumns: this.includeCheckColumns,
          includeColumns: this.includeColumns,
          simple: false,
          getall: false,
        };
        this.newSearch = false;// reset newSearch so a page or other option change won't trigger resetting page to 1
      },

      sortTheHeadersAndUpdateTheKey(evt) {//drag job headers
        const headersTmp = this.headers;
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;
        if (newIndex >= headersTmp.length) {
          let k = newIndex - headersTmp.length + 1;
          while (k--) {
            headersTmp.push(undefined);
          }
        }
        headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
        this.headers = headersTmp;
        this.anIncreasingNumber += 1;
        this.userPrefs.jobHeaders = this.headers;
        this.$store.dispatch('SET_USER_PREFERENCES', this.userPrefs);
      },

      unisearchFirms() {
        this.isUnisearch = true;
        this.searchByFid = false;
        if (this.unisearch.length < 2) return;
        this.whereColumns.fid = '';
        this.state = 'loadingFirms';
        this.checkedColumns = [ 'state', 'phone', 'contact', 'sort', 'insured', 'name1', 'name2' ];
        let params = {
          search: this.unisearch
        };
        this.seeMoreOptions = false;
        this.seeMoreOptionsBelow = false;
        this.panels = [0];
        firmAPIService.getUnisearchFirm(params, this.$router).then((data) => {
          if (data.length >= 100) this.overFirmLimit = true;
          if (data.length === 0) this.noResults = true;
          this.firms = data;
          this.totalRecords = this.firms.length;
          this.state = 'showFirms';
        }).catch((error) => {
          this.$refs.errorDialog.showError({
            errorMessage: 'An error occurred while trying to unisearch the firms. ' +
              'Please ensure the form is filled out completely and correctly. ' +
              'If the problem persists please notify the development team with a detailed ' +
              'description of the error.',
            forDevelopers: error
          })
          this.state = 'showFirms';// end loading animation
        });
      },

      update(info) {// from FirmOptions.vue
        this.checkedColumns     = info[0];
        this.whereColumns       = info[1];
      },

      viewFirm(id) {//get firm and add to firmArray
        //props/params for FirmsSearch, ViewFirm, FirmNotesTable, EditNoteEmail
        this.noteData.savedSearchItems = this.searchItems;//saved and used in firmsSearch,
        this.noteData.state = this.state; //saved in firmsSearch, used in firm notes table
        this.noteData.nextJobNid = this.nextJobNid; //saved in firmsSearch, used in firm notes table
        this.noteData.nextJobFid = this.nextJobFid; //saved in firmsSearch, used in firm notes table
        this.$store.dispatch('SET_NOTES_DATA', this.noteData);
        if (!this.notPopup) {
          let baseUrl = window.location.origin;
          let w = window.open(baseUrl +  '/viewFirmNote/' +  id, '_blank',
            'resizeable=yes, width=' + (1140)
            + ', left=' + (300)
            + ', height=' + (730));
        } else {
          this.id = id;// set id for view component - if not going to popup
          this.showFirm = !this.showFirm;// if firm was just closed & opened, trigger ViewFirm watch
          this.panels = [];
          this.showFirmTabs = true;
        }
      },

      viewFirmJob(nid) {//pass job.nid to get note and firm and nextJobFid
        this.noteData.status = 'Active';
        this.noNote = false;//tells view component that nid is being sent
        this.viewFirm(nid);
      },

      viewFirmSearch(fid) {//pass fid to get firm
        this.noteData.status = 'All';
        this.noNote = true;//tells view component that fid is being sent
        this.viewFirm(fid);
      },

      viewJobs() {
        this.state = 'loadingJobs';
        this.panels = [0];
        if (this.jobs.length > 0 && !this.refresh) {
          this.state = 'showJobs';
        } else {// getJobs
          const username = this.$store.getters.username;
          this.loading = true;
          note.getJobs( { usernames: username, job: 'users' }, this.$router)
            .then((jobs) => {
              this.jobs = jobs;
              this.refresh = false;
              if (this.state === 'loadingJobs') this.state = 'showJobs';
            })
        }
      },
    },

    directives: {//drag table column headers
      'sortable-table': {
        inserted: (el, binding) => {
          el.querySelectorAll('th').forEach((draggableEl) => {
            // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
            watchClass(draggableEl, 'sortHandle');
            draggableEl.classList.add('sortHandle');
          });
          Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
        },
      },
    },
  };
</script>

<style>
  .smalltext.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    font-size: 14px !important;
  }
  .custom.v-text-field>.v-input__control>.v-input__slot:before {
    margin-top: 10px;
    font-size: 0.875rem;
    font-weight: 600;
    color: black !important;
    font-family: Roboto, 'Avenir', Helvetica, Arial, sans-serif;
    border-style: none;
  }
  .custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
  }
  .theme--light.v-data-table thead tr:last-child th {
    z-index: 2 !important;
  }
  .noPadding .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-data-table {line-height: 1.2 !important;}
  .fullSizeTable .v-data-table__wrapper{height:calc(100vh - 228px) !important;}
  .firmsJobsTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 120px) !important;}
  .jobsTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 75px) !important;}
  .largeSizeTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 206px) !important;}
  .largeSizeTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 160px) !important;}
  .largeSizePoliciesTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 267px) !important;}
  .popupLargePoliciesTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 195px) !important;}
  .popupLargeTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 142px) !important;}
  .popupLargeTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 96px) !important;}
  .v-toolbar__content, .v-toolbar__extension {
    padding: 0;
  }
  theme--light.v-select .v-select__selection--comma {
    padding-bottom: 10px;
  }
  /*.v-list-item--link::before {*/
  /*  background-color: lightgrey !important;*/
  /*}*/
  #plain .v-btn::before{
    background-color: transparent;
  }
</style>
