<template>
  <div>
    <v-card block class="px-6 py-0 d-flex d-print-none flex-row justify-space-between" color="primary" dark
            :key="$_sideMenu"  large sticky-offset="$_offset" sticky-z-index="99"
            style="border-top: 1px solid white !important" tile v-sticky>
      <div>
        <information-dialog-icon confirmClass="mr-3 mb-1 title" confirmColor="secondary darken-1"
                                 icon-color="white" title="Renewal Report" tclass="headline grey lighten-2 mb-8">
          <p>For the Renewal Report, fill the search fields (all fields except carrier are required), then press return
            or click the RENEWAL<span class="hidden-sm-and-down"> REPORT</span> button.</p>
          <p v-if="$_sideMenu">For better print results, switch to top menu by clicking the lion in the upper-left corner.</p>
          <p>After the report appears below, click <v-icon>mdi-printer</v-icon> to open the print dialog.</p>
          <p>Recommended printer settings include landscape, default margins, and Print backgrounds.</p>
        </information-dialog-icon>
        <button-tip btnclass="px-6" @click="getRenewal()" :disabled="!formValid"
                    hideSmDownAfter=" Report" large text tile tip="Get renewal data" txt="Renewal "/>
      </div>
      <div>
        <button-tip btnclass="px-8" @click="printDiv()" :disabled="!searchResultLoadedShow"
                    icolor="white" icon iname="mdi-printer" tip="Open print dialog"/>
      </div>
      <div>
        <button-tip btnclass="px-8" @click="clearSearch()" large text tile tip="Clear all the search fields"
                    txt="Clear"/>
      </div>
    </v-card>
    <v-card tile class="pr-6 mx-0 pt-2 pb-1 mt-3 mb-2 d-print-none elevation-18">
      <v-form ref="form" v-model="formValid">
        <v-row class="my-n1 mx-0 pl-0">
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input @enter="getRenewal()" :items="$_lists.carrier" label="Carrier"
                            :type="'VAutocomplete'" v-model="carrier"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input @enter="getRenewal()" :items="$_lists.carrier" label="Prior Carrier" :rules="rules"
                            :type="'VAutocomplete'" v-model="carrierPrev"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input deletable-chips @enter="getRenewal()" :items="$_lists.ptype" label="Type" multiple
                            :rules="rules" small-chips :type="'VAutocomplete'" v-model="type"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input deletable-chips @enter="getRenewal()" :items="$_lists.ftype" label="Firm Type" multiple
                            :rules="rules" small-chips :type="'VAutocomplete'" v-model="firmType"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input @enter="getRenewal()" :items="months" label="Month" :rules="rules" :type="'VAutocomplete'"
                            v-model="month"/>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" md="4" xl="3">
            <v-row class="px-6" align="center" style="z-index: 89">
              <search-input @enter="getRenewal()" :items="yearList" label="Year" :rules="rules"
                            :type="'VAutocomplete'" v-model="year"/>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <div class="px-4 pb-12" v-if="searchResultLoadedShow">
      <div class="ma-6 renewalheader text-center" style="line-height: 1.2" v-if="renewalData.length > 0">
        <h4 class="mb-0">{{ carrierPrev }} {{month}} {{year}} Renewal Report</h4>
        <h5 v-if="type">For {{ prettyList(type) }} Policies</h5>
        <h5>Issued to {{ prettyList(firmType) }} Firms</h5>
      </div>
      <v-data-table class="px-0 pb-0 mx-0 mt-2 mb-0 elevation-4 left med-table-rows prtrenewal striped-table table-styl"
                    :headers="colHeaders"
                    hide-default-footer
                    :items="renewalData"
                    :items-per-page="-1"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :mobile-breakpoint="0"
                    no-data-text="There is no data available for that search."/>
    </div>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
  import currency from 'currency.js';
  import ErrorDialog from "../ErrorDialog";
  import InformationDialogIcon from "../InformationDialogIcon";
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { ReportsAPIService } from '@/servicehandlers/ReportsAPIService';

  const firmsAPIService = new FirmsAPIService();
  const reportsAPIService = new ReportsAPIService();

  export default {
    name: 'Renewal',
    components: { ErrorDialog, InformationDialogIcon },

    data() {
      return {
        carrier: '',
        carrierPrev: 'Lloyds',
        colHeaders: [], // for vuetify table
        currencyFieldsToFormat: ['premium', 'prior_premium'],
        firmType: [],
        formValid: false,
        loading: false,

        // example search:
        // prevCarrier: ‘Lloyds’
        // types: ‘MPL,FPL’
        // firmTypes: ‘Fiduciary’,
        // month: 12
        // year: 2021

        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
          'August', 'September', 'October', 'November', 'December'],
        renewalData: [],
        month: '',
        searchResultLoadedShow: false,
        type: ['FPL'],
        rules: [(v) => !!v || 'Field is required'],
        year: '',
      };
    },

    computed: {
      yearList() {
        let years = [];
        let year = 2005;
        let currentYear = parseFloat(new Date().getFullYear());
        while (currentYear + 1 > year) {
          years.unshift(year);
          year++;
        }
        return years;
      },
    },

    watch: {
      carrier() {// IF carrier is changed THEN renewal results no longer match selected search parameters, so don't show
        this.searchResultLoadedShow = false;
      },
      carrierPrev() {// IF carrierPrev is changed THEN renewal results no longer match selected params, so don't show
        this.searchResultLoadedShow = false;
      },
      type() {// IF type is changed THEN loss run search results no longer match selected search params, so don't show
        this.searchResultLoadedShow = false;
      },
      firmType() {// IF firmType is changed THEN renewal results no longer match selected search params, so don't show
        this.searchResultLoadedShow = false;
      },
      month() {// IF month is changed THEN renewal results no longer match selected search parameters, so don't show
        this.searchResultLoadedShow = false;
      },
      year() {// IF year is changed THEN renewal results no longer match selected search parameters, so don't show
        this.searchResultLoadedShow = false;
      },
    },

    created() {
      document.title = 'Renewal Report';
    },

    mounted() {
      this.month = this.months[(new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1)];// init to prev month
    },

    methods: {
      prettyList(list) {// add commas and & to list if needed
        let prettyList = '';
        for (let [idx, item] of list.entries()) {
          // if more than one item add '&' before last one, if more than two items add commas after all but last two
          prettyList = prettyList + (list.length > 1 && idx === list.length-1 ? ' & ' : '') + item
              + (list.length > 2 && idx < list.length-2 ? ', ' : '');
        }
        return prettyList;
      },

      getRenewal() {
        if (!this.formValid) return;
        this.loading = true;
        let monthNum = this.months.indexOf(this.month) + 1;
        this.buildHeaders();
        reportsAPIService.getRenewalReports(this.carrier, this.carrierPrev, this.type, this.firmType, monthNum, 
          this.year, this.$router).then((renewals) => {
            this.loading = false;
            this.searchResultLoadedShow = true;
            for (let row of renewals) for (let f of this.currencyFieldsToFormat) row[f] = currency(row[f]).format();
            this.renewalData = renewals;
        })
          .catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to get renewal data",
              forDevelopers: e
            })
          });
      },

      buildHeaders() {// create header array, push columns on array to allow for future conditional columns
        this.colHeaders =   [{value:'incept',  text:'Incept',  align: 'center',sortable:false}];// create w/ 1st column
        this.colHeaders.push({value:'insured', text:'Insured', align: 'left',sortable: false, width: '380px'});
        this.colHeaders.push({value:'carrier', text:'Carrier', align: 'left',sortable: false});
        this.colHeaders.push({value:'premium', text:'Premium', align: 'right',sortable: false});
        this.colHeaders.push({value:'prior_carrier', text:'Prior Carrier', align: 'left',sortable: false});
        this.colHeaders.push({value:'prior_premium', text:'Prior Premium', align: 'right',sortable: false});
      },

      clearSearch() {
        this.loading = false;
        this.searchResultLoadedShow = false;
        this.carrier = '';
        this.carrierPrev = '';
        this.type = '';
        this.firmType = '';
        this.month = '';
        this.year = '';
      },

      printDiv() {
        window.print();
      },

    },
  }
</script>
<style>
  /* page header style */
  .renewalheader {
    color: black;
    font-size: larger !important;
  }
  /* table header style */
  .table-styl.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    background-color: rgba(0, 0, 0, 0.12);/* grey header color helps hide strange vertical lines in print and pdf */
    border-bottom: 1px solid black;
    color: black;
  }
  /* remove horizontal table row lines */
  .table-styl.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) >
    td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody >
    tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none;
  }
  /* add shading to alternate table rows */
  .striped-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .06);
  }
  /* position and style table for printing */
  @media print
  {
    body * { visibility: hidden }
    .renewalheader * { visibility: visible }
    .renewalheader {position: absolute !important; top: -70px !important; left: -10px !important; width: 98% !important}
    .prtrenewal * { visibility: visible }
    .prtrenewal { position: absolute !important; top: 15px !important; width: 98% !important; height: auto !important }
  }
  @page { margin: .5in .4in .5in .3in !important }
</style>