import CommonAPIService from './CommonHandler';

export class SessionNotesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getTypes(router) {
    const url = '/api/session_notes/types';
    return this.CommonAPIService.getCall(url, router);
  }
}

export default SessionNotesAPIService;
