import CommonAPIService from './CommonHandler';

export class LedgersAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getByLid(lid, router){
    const fields = 'ledgers.type,due,amt,firms.sort,policies.expiry,firms.producer';
    const url = `/api/ledgers/${lid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getLedgers(pid,router) {
    const url = `/api/ledgers/?pid=${pid}`; //pass fields and filters for faster performance
    return this.CommonAPIService.getCall(url, router);
  }

  addLedger(ledgerData, router) {
    const url = '/api/ledgers';
    return this.CommonAPIService.postCall(url, ledgerData, router);
  }

  updateLedger(lid, ledgerData, router) {
    const url = `/api/ledgers/${lid}`;
    return this.CommonAPIService.putCall(url, ledgerData, router);
  }

  updateLedgersArray(ledgerData, router) {
    const url = '/api/ledgers';
    return this.CommonAPIService.putCall(url, ledgerData, router);
  }

  updateLedgersByPid(pid, ledgerData, router) {
    const url = `/api/ledgers?pid=${pid}`;
    return this.CommonAPIService.putCall(url, ledgerData, router);
  }

  updateLedgersByBulk(filtersObject, ledgerData, router) {
    //pass a filterObject such as {fid: number, cid: number, ...} and ledgerData such as {note: 'updated note', ...} to update all ledgers where the filters match
    const filters = new URLSearchParams(filtersObject).toString();
    const url = `/api/ledgers?${filters}`;
    return this.CommonAPIService.putCall(url, ledgerData, router);
  }

  ledgersSearch(searchObject, router) {
    // pass object such  {startDate: '06-12-2006',endDate:'06-12-2006', dateType: 'dep',type: 'Receipt', amount: '0 or less'};
    const filters = new URLSearchParams(searchObject).toString();
    const fields = 'ledgers.type,due,amt,firms.sort,policies.expiry,firms.producer';
    const url = `/api/ledgers/search?fields=${fields}&${filters}&withContactsSort=true`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default LedgersAPIService;
