import { render, staticRenderFns } from "./CreateCommissionDialog.vue?vue&type=template&id=518a1774&scoped=true"
import script from "./CreateCommissionDialog.vue?vue&type=script&lang=js"
export * from "./CreateCommissionDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518a1774",
  null
  
)

export default component.exports