<template>
  <v-alert dense border="left" type="warning" v-bind="alertProps">
    <slot></slot>
  </v-alert>
</template>

<script>
  export default {
    name: "WarningAlert",
    props: {
      alertProps: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

<style scoped>

</style>