<template>
  <div>
    <div class="headline px-3 mb-n4 pt-3">Bind Authority Production Report</div>
    <br>
    <v-btn tile x-large dark block color='primary' class="my-n1 py-0" v-on:click="getReport()"
           :key="$_sideMenu" v-sticky sticky-z-index="9" sticky-offset="$_offset">Generate Report</v-btn>
    <br>
    <v-card v-if="this.searchResultsLoaded" class="pt-1 my-n3" align="center" height="30px" tile dark block
            color='primary'>Total Records: {{ this.totalRecords }}</v-card>
    <br>
    <div v-if="this.searchLaunched">
      <v-data-table :headers="totalsColHeaders"
                    height="100px"
                    hide-default-footer
                    :items="totals"
                    :mobile-breakpoint="0"
                    disable-sort
                    class="elevation-1"
      ></v-data-table>
      <br>
      <v-data-table class="fullSizeTable elevation-1" :loading="this.spinning"
                    loading-text="Loading...Please wait"
                    :headers="colHeaders"
                    :mobile-breakpoint="0"
                    fixed-header
                    :items="data">
        <template v-slot:item.amtNum="{ item }">
          ${{ item.amtNum.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')  }}
        </template>
        <template v-slot:item.receivedNum="{ item }">
          ${{ item.receivedNum.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')  }}
        </template>
      </v-data-table>
      <br>
    </div>
    <v-container class="mt-n5">
      <v-row>
        <v-col>
          Report Date: {{ currentDate }}
        </v-col>
      </v-row>
    </v-container>
    <b-container v-if="errors.length >= 1">
      <v-row>
        <v-col>
          ERROR: {{ errors }}
        </v-col>
      </v-row>
    </b-container>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
  import moment from 'moment';
  import ErrorDialog from "../ErrorDialog";
  import { ReportsAPIService } from '@/servicehandlers/ReportsAPIService';
  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  const reportsApiService = new ReportsAPIService();
  const apiService = new CommonAPIService();

  export default {
    name: 'BindAuthorityProduction',
    components: { ErrorDialog },
    data() {
      return {
        spinning: false,
        errors: [],
        currentDate: '',
        searchResultsLoaded: false,
        searchLaunched: false,
        totals: [],
        totalRecords: 0,
        colHeaders: [], // for vuetify table
        totalsColHeaders: [], // for vuetify table
        data: [],
      }
    },

    created() {
      document.title = 'Bind Authority';
      this.currentDate = moment().local().format('MM-DD-YYYY');
    },

    methods: {
      formatMoney (val) {
        let parsedNoDollarSign = parseFloat(val.replace(/\$/g,'')).toFixed(2);
        return '$' + (parsedNoDollarSign).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },

      getReport() {
        this.totals = [];
        this.data = [];
        if ( this.spinning ) {
          return;
        }
        this.spinning = true;
        this.searchLaunched = true;
        return reportsApiService.getBindAuthorityProduction('', this.$router)
        .then((result) => {
          this.data = result.data;
          this.totalRecords = this.data.length;
          this.searchResultsLoaded = true;
          const tmpData = result.data;
          this.spinning = false;
          this.totals.push({
            totalUnpaid: result.totalUnpaid,
            totalBooked: this.formatMoney(result.totalBooked),
            totalReceived: this.formatMoney(result.totalReceived)
          });
          // we have to pre-sort the data
          tmpData.sort(function(a, b) {
            a = moment(a.stamp, 'MM-DD-YYYY');
            b = moment(b.stamp, 'MM-DD-YYYY');
            return a>b ? -1 : a<b ? 1 : 0;
          });
          this.data = tmpData;
          // process result data to add value:false field and new numerical field with no dollar sign (for Booked and Received) https://codepen.io/anon/pen/aYBjGa?editors=1010
          for (let i = 0; i < this.data.length; i += 1) {
            this.data[i].value = false;
            this.data[i].amtNum = parseFloat(this.data[i].amt.replace(/\$/g,'')).toFixed(2);
            this.data[i].receivedNum = parseFloat(this.data[i].received.replace(/\$/g,'')).toFixed(2);
          }
          // assemble totals header and column headers for vuetify
          this.totalsColHeaders = [{value: 'totalBooked', text: 'Booked', align: 'center'},
                                   {value: 'totalReceived', text: 'Funds Received', align: 'center'}];
          this.colHeaders = [
            { text: 'Policy ID', value: 'pid',        width: "110px", align: 'end'},
            { text: 'Firm ID',   value: 'fid',        width: "100px", align: 'end'},
            { text: 'Incept',    value: 'stamp',      width: "120px", align: 'start'},
            { text: 'Notes',     value: 'error',      width: "140px", align: 'start'},
            { text: 'Booked',    value: 'amtNum',     width: "120px", align: 'end'},
            { text: 'Received',  value: 'receivedNum',width: "120px", align: 'end'},
            { text: 'Sort',      value: 'sort',       width: "300px", align: 'start'},
          ];
        })
        .catch((e) => {
          this.spinning = false;
          this.$refs.errorDialog.showError({
          forDevelopers: e
           })
        });
      }
    }
  }
</script>

<style scoped>

</style>
