<template>
  <component
    :chips="chips"
    :clearable="type !== 'vTextField'"
    :deletable-chips="deletableChips"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    @input="selected"
    :is="type"
    @keydown.enter.prevent="enterPress"
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :menu-props="menuProps"
    :multiple="multiple"
    :no-data-text="noDataText"
    :persistent-hint="persistentHint"
    ref="field"
    :return-object="returnObject"
    :rules="rules"
    :small-chips="smallChips"
    :value="value"
    v-bind="{...inputProps}"
  >
<!--    add margin-right to X in text-field so it will line up with X in autocomplete, combobox, and select -->
    <template v-slot:append v-if="type === 'vTextField' && value">
      <span style="margin-right: 28px"><v-icon @click="$emit('input', '')">mdi-close</v-icon></span>
    </template>
  </component>
</template>

<script>
  import { VAutocomplete, VCombobox, VSelect, VTextField } from "vuetify/lib"

  export default {
    name: "SearchInput",
    components: { VAutocomplete, VCombobox, VSelect, VTextField },
    props: {
      chips: { type: Boolean, default: false },
      deletableChips: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      hint: { type: String },
      inputProps: { type: Object, default: () => ({})},
      items: { type: Array, default: () => ([]) },
      itemText: {},
      itemValue: {},
      label: String,
      menuProps: { type: Object },
      multiple: { type: Boolean, default: false },
      noDataText: { type: String },
      persistentHint: { type: Boolean, default: false },
      returnObject: { type: Boolean, default: false },
      rules: { default: () => [] },
      smallChips: { type: Boolean, default: false },
      stop: { type: Boolean, default: false },
      type: { type: String, default: 'vTextField'},
      value: { required: true }
    },
    data: () => ({
    }),
    methods: {
      enterPress() {
        setTimeout(()=>{ this.$refs.field.isMenuActive = false }, 10);// close dropdown when enter pressed
        this.$emit('enter')
      },

      selected(value) {
        setTimeout(()=>{ this.$refs.field.isMenuActive = false }, 10);// close dropdown when selected
        this.$emit('input', value);
      },

    }
  }
</script>

<style scoped>

</style>
