import CommonAPIService from './CommonHandler';
import { store } from '../store';
import { intervalToMilliseconds } from '../utils';
import {ListsAPIService} from "@/servicehandlers/ListsAPIService";

const listsAPIService = new ListsAPIService();

export class AuthAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getSession(router) {
    const url = '/api/auth/session';
    return this.CommonAPIService.getCall(url, router).then(session => {
      if (session.time_remaining) session.time_remaining = intervalToMilliseconds(session.time_remaining);
      return session;
    });
  }

  login(credentials, router) {
    const url = '/api/auth/login';
    return this.CommonAPIService.postCall(url, credentials, router)
      .then(data => {
        store.dispatch('ADD_USERID', data.id);
        store.dispatch('ADD_USERNAME', data.username);
        store.dispatch('ADD_USER_GROUPS', data.auth_groups);
        store.dispatch('SET_LOGGED_IN', true);
        store.commit('SET_TIMEOUT', intervalToMilliseconds(data.timeout));
        store.dispatch('INITIALIZE_ACTIVE_TIMER');
        return data;
      });
  }

  logout(router) {
    const url = '/api/auth/logout';
    return this.CommonAPIService.getCall(url, router)
      .then(() => {
        router.push({ name: 'Login' });
      });
  }

  relogin(credentials, router) {
    const url = '/api/auth/relogin';
    return this.CommonAPIService.postCall(url, credentials, router)
      .then(() => {
        store.dispatch('INITIALIZE_ACTIVE_TIMER');
      });
  }
}
export default AuthAPIService;