import CommonAPIService from './CommonHandler';

export class EmailAPIService {
  constructor() {
    this.emailVar = {};
    this.CommonAPIService = new CommonAPIService();
  }

  sendEmailToNote(nid, email, router) {
    const url = '/api/emails/' + nid;
    return this.CommonAPIService.postCall(url, email, router);
  }
}

export default EmailAPIService;
