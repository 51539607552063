<template>
  <div>
    <v-skeleton-loader v-if="loading" type="article, list-item-three-line@9"></v-skeleton-loader>
    <div class="pl-2 pr-0 no-printme">

        <!--      Buttons    -->
      <v-row class="px-2 py-1 d-print-none">
        <v-btn-toggle
          v-if="['FPL', 'MML', 'PAL', 'PLL'].includes(policy.type)
            && ['Book','dncLost','Lost','Past'].includes(policy.status)"
          v-model="doc"
          tile
          color='secondary darken-2'
          group
        >
          <v-btn class="ml-2" value="Declarations">
            Declarations
          </v-btn>
          <v-btn value="Binder">
            Binder
          </v-btn>
        </v-btn-toggle>
        <v-spacer/>
        <button-tip @click="printDiv()" color="secondary darken-2" text tile tip="Print Dialog" txt="Print" xlarge/>
      </v-row>

      <v-card id="printableArea" class="printme rounded-0 elevation-4 px-4" width="985">
        <div id="output" class="oldStyles scroll d-print-block div2">
          <div v-if="doc !== 'Binder'" class="text-center h5 mt-2 mb-0 printhead">DECLARATIONS</div>
          <div v-if="doc === 'Binder' && ['Book','dncLost','Lost','Past'].includes(policy.status)"
               class="text-center h5 mt-2 mb-0 printhead">INSURANCE BINDER</div>
          <div v-if="doc === 'Binder' && !['Book','dncLost','Lost','Past'].includes(policy.status)"
               class="text-center h5 mt-2 mb-0 printhead">INSURANCE PROPOSAL</div>
          <div class="text-center">{{ longType || '' }}</div>
          <div v-if="doc !== 'Binder'" class="text-center">Effected with Certain Underwriters at Lloyd's of London</div>
          <div v-if="doc !== 'Binder'" class="text-center"><b><i>Claims Made and Reported Coverage</i></b></div>
          <br>
          <v-row class="py-0 my-n5" v-if="doc === 'Binder'">
            <v-col cols="3"><b>Insurance Carrier:</b></v-col>
            <v-col cols="8">{{ policy.carrier }}</v-col>
          </v-row>
          <v-row class="py-0 my-n5" v-if="doc !== 'Binder' && policy.contract">
            <v-col cols="3"><b>Authority Number:</b></v-col>
            <v-col cols="8">{{ policy.contract }}</v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b>Policy Number:</b></v-col>
            <v-col cols="8">{{ policy.polnum }}</v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b><span v-if="doc !== 'Binder'" >1. </span>Named
              {{ doc === 'Binder' ? 'Insured' : 'Assured' }}:</b>
            </v-col>
            <v-col cols="8" class="mb-n12">
              {{ policy.insured }}
              <div class="mt-n1 mb-12">
                <span v-if="!!firm.address1">{{ firm.address1 + ', '}}</span>
                <span v-if="!!firm.address2">{{ firm.address2 + ', '}}</span>
                <span v-if="!!firm.city">{{ firm.city + ', '}}</span>
                {{ firm.state }} &nbsp{{ firm.zip }}
              </div>
            </v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b><span v-if="doc !== 'Binder'" >2. </span>Period of Insurance:</b></v-col>
            <v-col cols="8">{{ policy.period }}
              <div class="fine_print">
                both dates at 12:01 am
               <span v-if="doc !== 'Binder'" > at the location identified in item 1, above.</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b><span v-if="doc !== 'Binder'" >3. </span>Limits of Liabilty:</b></v-col>
            <v-col cols="8">{{ policy.limits }} Per Claim/Aggregate, Including Costs and Expenses</v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b><span v-if="doc !== 'Binder'" >4. </span>Deductible:</b></v-col>
            <v-col cols="8">{{ policy.deduc }} Per Claim, Including Costs and Expenses</v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b><span v-if="doc !== 'Binder'" >5. </span>Premium:</b></v-col>
            <v-col cols="8" v-if="policy.premium">
              {{ '$' + policy.premium.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,') }}
            </v-col>
          </v-row>
          <v-row class="py-0 my-n5">
            <v-col cols="3"><b><span v-if="doc !== 'Binder'" >6. </span>Retroactive Date:</b></v-col>
            <v-col cols="8">
              <span v-if="moment(policy.retro, 'MM-DD-YYYY', true).isValid()">
                {{ moment(policy.retro, 'MM-DD-YYYY').format('YYYY-MM-DD')  }} (year-month-day)
              </span>
              <span v-else>{{ policy.retro }}</span>
            </v-col>
          </v-row>
          <v-row v-if="doc !== 'Binder'" class="py-0 my-n5">
            <v-col cols="3"><b>7.	Notice of Claim:</b></v-col>
            <v-col cols="8">
              Dominion Insurance Services, Inc.<br>
              341 South Main Street, Suite 100, Alpine, Utah 84004<br>
            </v-col>
          </v-row>
          <v-row v-if="doc !== 'Binder'" class="py-0 my-n5">
            <v-col cols="3"><b>8.	Service of Suit:</b></v-col>
            <v-col cols="8">
              Wilson, Elser, Moskowitz, Edelman & Dicker LLP<br>
              150 East 42nd Street, New York, New York 10017<br>
            </v-col>
          </v-row>
          <v-row v-if="doc !== 'Binder'" class="py-0 my-n5">
            <v-col cols="3"><b>9.	Short Rate Table:</b></v-col>
            <v-col cols="8">As per attached endorsement NMA 45.</v-col>
          </v-row>
          <v-row class="py-0 my-n4">
            <v-col cols="3"><b>Prepared/Issued By:</b></v-col>
            <v-col cols="8">Dominion Insurance Services, Inc.<br>
              <v-row class="justify-start" v-if="['Book','dncLost','Lost','Past'].includes(policy.status)">
                <v-col cols="6">
                  <div class="underline">&nbsp;&nbsp;
                    <img src="https://in.dominioninsurance.com/images/Larry.gif" height="90">
<!--                    <hr style="width:100px;text-align:left;margin-left:0">-->
                  </div>
                  &nbsp;&nbsp;Countersignature
                </v-col>
                <v-col cols="3">
<!--                  style="margin-top: 40px"-->
                    <div class="underline"> &nbsp
                      <img height="90" class="align-bottom">{{ moment(policy.incept, 'MM-DD-YYYY').format('YYYY-MM-DD') }}</img>
                    </div>
                  &nbsp;&nbsp;Date
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br>
          <div v-if="doc === 'Binder' && !!policy.forms" style="font-size: .94rem" class="text-start mt-2 mb-0"
               v-html="policy.forms.replace(/\n/g, '<br />')">
          </div>
          <div v-else-if="doc !== 'Binder' && links && links.length > 0">
            <v-row class="py-0 my-n6">
              <v-col cols="3"><b>Forms & Notices:</b></v-col>
              <v-col cols="8">
                <v-row v-for="link in links" :key="link.loidfid" class="my-n3">
                  <v-col>
                    <a
                       :href="`${url}/download/${link.loidfid}`">
                      <u>{{ link.file_description }}</u>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <br>
          <br>
          <br>
<!--          <div align="right" class="fine_print">Dominion MML Dec (12/15)</div>-->
        </div>
      </v-card>

      <!--   alerts    -->
      <ErrorDialog ref="errorDialog"/>
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../ErrorDialog";
import {FirmsAPIService} from "@/servicehandlers/FirmsAPIService";
import { ListsAPIService } from '../../../servicehandlers/ListsAPIService';
import loginCheckMixin from '@/mixin';
import moment from 'moment';
import { PoliciesAPIService } from "@/servicehandlers/PoliciesAPIService";

const firmsAPIService = new FirmsAPIService();
const listsAPIService = new ListsAPIService();
const policiesAPIService = new PoliciesAPIService();

export default {
  name: 'ViewPolicy',
  mixins: [ loginCheckMixin ],
  components: { ErrorDialog },
  props: {
    id: [String, Number],
    dialog: Boolean,
  },
  data: () => ({

    //--------- Data Variables ---------//

    doc: 'Binder',
    errors: [],
    firm: {},
    links: [],
    loading: false,
    longType: '',
    policy: {},
    url: process.env.VUE_APP_UW_URL,
  }),

  created: async function() {
    await this.getPolicy();
  },

  methods: {
    getLongType(type) {
      listsAPIService.getList('ptype','0',false,false, 'ASC', 'sort', true, this.$router).then((list) => {
        this.longType = list.find(o => o.text === this.policy.type).long;
      });
    },

    getPolicy: async function() {
      this.loading = true;
      try {
        this.policy = await policiesAPIService.getPolicy(this.id, this.$router);
        this.getLongType(this.policy.type);
        let firm = await firmsAPIService.getSingleFirm(this.policy.fid, this.$router);
        this.firm = firm;
        this.loading = false;
        if (this.policy.polnum) document.title = this.policy.polnum;
        this.links = await policiesAPIService.getPolicyFiles(this.policy.pid, this.$router);
        // check if missing incept and/or expiry and format period
        if (this.policy.incept && this.policy.expiry) {
          this.policy.period = moment(this.policy.incept, 'MM-DD-YYYY').format('YYYY-MM-DD') + ' to '
            + moment(this.policy.expiry, 'MM-DD-YYYY').format('YYYY-MM-DD')  + ' (year-month-day)\n'
        }
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Policy.",
          forDevelopers: e
        })
      }
    },

    printDiv(divName) {
      window.print();//
    },
  },
};
</script>

<style>
@media print
{
  body * { visibility: hidden }
  .div2 * { visibility: visible }
  .div2 { position: absolute; left: 30px; font-size: 21px; margin-top: -20px !important; width: 100% !important }
  .printhead { font-size: 30px }
}
</style>


