import { render, staticRenderFns } from "./ViewPolicy.vue?vue&type=template&id=0e37007a"
import script from "./ViewPolicy.vue?vue&type=script&lang=js"
export * from "./ViewPolicy.vue?vue&type=script&lang=js"
import style0 from "./ViewPolicy.vue?vue&type=style&index=0&id=0e37007a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports