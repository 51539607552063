<template>
    <v-snackbar app class="mt-n2 ml-n2" color="secondary" :value="value" @input="$emit('input', $event)" top>
        {{ message }}
        <v-btn color="white" text @click="$emit('input', !value)">Close</v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: "SuccessSnackbar",
        props: {
            message: {
                required: true,
                type: String
            },
            value: Boolean
        }
    }
</script>

<style scoped>

</style>
