<template>
  <v-data-table
    v-if="state === 'loadingFirms' || state === 'showFirms'"
    :loading="state === 'loadingJobs' || state === 'loadingFirms'"
    class="firmsJobsTable elevation-2 px-0 mt-n3 mb-2"
    :dense="dense"
    loading-text="Loading... Please wait"
    no-data-text="To see firms, enter a fid OR unisearch (2-char min) then press return."
    :headers="headers"
    fixed-header
    :mobile-breakpoint="0"
    :server-items-length="!isUnisearch ? totalRecords : -1"
    :options.sync="options"
    :style="styleProp"
    :items="firms"
    :multi-sort="isUnisearch"
    :footer-props="{ 'items-per-page-options': [ 100, 200, 500 ] }">
    <template v-slot:item.actions="{ item }">
      <v-lazy>
        <div>
          <button-tip v-if="editFirmRights" small icon iname="mdi-pencil" tip="Edit Firm" @click="$_editFirm(item)"/>
          <button-tip small icon iname="mdi-open-in-new" tip="Open Popup" @click="$emit('pop-up', item.fid)"/>
          <button-tip small icon iname="mdi-eye-outline" tip="Open Below" @click="$emit('view-firm', item.fid)"/>
        </div>
      </v-lazy>
    </template>
    <template v-slot:item.phone="{ item }">
      <div v-if="item.phone">
        {{ item.phone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}</div>
    </template>
    <template v-slot:item.fax="{ item }">
      <div v-if="item.fax">
        {{ item.fax.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}</div>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: "ViewFirmsTable",
    props: {
      checkedColumns: {
        required: true,
        type: Array
      },
      dense: {
        required: true,
        type: Boolean
      },
      editFirmRights: {
        required: true,
        type: Boolean
      },
      firms: {
        required: true,
        type: Array
      },
      isUnisearch: {
        required: true,
        type: Boolean
      },
      state: {
        required: true
      },
      styleProp: {
        required: true
      },
      totalRecords: {
        required: true
      }
    },
    watch: {
      firms(newVal, oldVal) {
        this.buildHeaders();
      },
      isUnisearch(newVal) {
        if (newVal) {
          this.options.sortBy = ['sort'];
          this.options.sortDesc = [false];
        }
      },
      options: {
        handler (newValue, oldValue) {
          if (this.initial) {
            this.initial = false;
            return;// don't execute this handler when options are set initially, only when user changes options
          }
          if (this.isUnisearch) return;
          if (_.isEqual(oldValue, newValue)) return;
          this.$emit('search');
        },
        deep: true,
      }
    },
    data: () => ({
      initial: true,
      options: {
        page: 1,
        itemsPerPage: 100,
        sortBy: [null],
        sortDesc: [false],
      },
      headers: [],
    }),
    computed: {
      allHeaders() {
        const standardWidth = '200px';

        return {
          actions: {text: 'Actions', value: 'actions', width: '120px', sortable: false, align: 'center'},
          fid: {text: 'FID', value: 'fid', sortable: true, width: '70px', align: 'end'},
          fein: {text: 'FEIN', value: 'fein', sortable: true, width: '130px'},
          source: {text: 'Source', value: 'source', sortable: true, width: standardWidth},
          sort: {text: 'Sort', value: 'sort', sortable: true, width: '300px'},
          insured: {text: 'Insured', value: 'insured', sortable: true, width: standardWidth},
          contact: {text: 'Contact', value: 'contact', sortable: true, width: standardWidth},
          salutation: {text: 'Salutation', value: 'salutation', sortable: true, width: standardWidth},
          name1: {text: 'Name 1', value: 'name1', sortable: true, width: standardWidth},
          name2: {text: 'Name 2', value: 'name2', sortable: true, width: standardWidth},
          address1: {text: 'Address 1', value: 'address1', sortable: true, width: standardWidth},
          address2: {text: 'Address 2', value: 'address2', sortable: true, width: standardWidth},
          city: {text: 'City', value: 'city', sortable: true, width: standardWidth},
          zip: {text: 'Zip', value: 'zip', sortable: true, width: '130px'},
          phone: {text: 'Phone', value: 'phone', sortable: true, width: '130px'},
          fax: {text: 'Fax', value: 'fax', sortable: true, width: '130px'},
          email: {text: 'Email', value: 'email', sortable: true, width: standardWidth},
          website: {text: 'Website', value: 'website', sortable: true, width: standardWidth},
          note: {text: 'Note', value: 'note', sortable: true, width: standardWidth},
          estab: {text: 'Estab', value: 'estab', sortable: true, width: standardWidth},
          sales: {text: 'Sales', value: 'sales', sortable: true, width: standardWidth},
          people: {text: 'People', value: 'people', sortable: false, width: standardWidth},
          state: {text: 'State', value: 'state', sortable: true, width: '90px'},
          status: {text: 'Status', value: 'status', sortable: false, width: '130px'},
          solicitor: {text: 'Solicitor', value: 'solicitor', sortable: false, width: standardWidth},
          producer: {text: 'Producer', value: 'producer', sortable: false, width: standardWidth},
          agent: {text: 'Agent', value: 'agent', sortable: false, width: standardWidth},
          type: {text: 'Type', value: 'type', sortable: false, width: standardWidth}
        }
      }
    },
    created() {
      const store = this.$store.getters;
      this.buildHeaders();

      if (this.$route.params.savedSearch) {
        this.options.page = store.noteData.savedSearchItems.page;
      }
    },
    methods: {
      buildHeaders(){
        let headers = [this.allHeaders.actions];
        for (const key in this.allHeaders) {
          if (this.checkedColumns.includes(key)) headers.push(this.allHeaders[key]);
        }
        this.headers = headers;
      },
    }
  }
</script>

<style scoped>

</style>
