<template>
  <div>
    <div class="headline px-3 pt-5 pb-0 mt-n2 mb-n3">Bordereau</div>

    <v-dialog v-model="dialog" width="350">
      <v-card class="pa-5">
        <v-row>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="dialog = false"
            icon
            class="mr-5 mt-2"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
       <v-card-text class="pb-6">Please be sure to fill out the required fields
         above, then check the box to include larger text items in the results, if desired.</v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="pa-6 mt-4 mb-3">
      <v-row class="d-flex mb-4">
        <v-col class="pt-2" cols="12" md="6" style="z-index: 9">
          <v-autocomplete
            name="claimpol"
            v-model="queryType"
            :items="claimsOrPolicies"
            label="Select Claims or Policies">
          </v-autocomplete>
        </v-col>
        <v-col class="pt-2" cols="12" md="6" style="z-index: 9">
          <v-autocomplete v-model="contract"
                          label="Contract"
                          :items="contractOptions"
                          :rules="rules.contract"
                          hint="Contract is required"
                          persistent-hint
                          required
                          item-text='name'
                          item-value='value'
                          name="contracts">
          </v-autocomplete>
        </v-col>
        <v-col v-show="queryType ==='policies'" class="pt-2" cols="12" md="6" style="z-index: 9">
          <v-autocomplete
            name="searchMonth"
            v-model="searchMonth"
            :items="months"
            :rules="rules.month"
            hint="Select ALL to generate report for all months"
            label="Month is required, please select:">
          </v-autocomplete>
          </v-col>
        <v-col v-show="queryType ==='policies'" class="pt-2" cols="12" md="6" style="z-index: 9">
          <v-autocomplete
            clearable
            @click:clear="searchYear = null"
            name="searchYear"
            v-model="searchYear"
            :items="yearList"
            label="Year (optional)">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card :key="$_sideMenu"
            class="pa-4 d-flex flex-row justify-space-around"
            v-sticky sticky-z-index="99" sticky-offset="$_offset"
            tile dark block color='primary'>
      <v-btn :disabled="disabled" :x-small="$vuetify.breakpoint.name === 'xs'" tile text
             @click="generateReport()">Bordereau</v-btn>
      <span class="d-flex flex-row" v-if="queryType === 'policies'">
        <v-checkbox :disabled="disabled" v-model="all" @click.stop="" class="mt-n2 mt-sm-0 mb-n4 mr-n2"
                    v-if="queryType === 'policies'"></v-checkbox>
        <span class="mt-0 mt-sm-2 caption" :style="disabled ? 'opacity: 0.4' : ''" @click.stop=""
              v-if="queryType === 'policies'"
        > w/forms</span>
      </span>
      <div v-can="('bordereau-download')">
          <download-excel
            :fetch="fetchData"
            :fields="queryType==='policies' ? (all ?  allJsonPoliciesFields : jsonPoliciesFields) : all ? allJsonClaimsFields :jsonClaimsFields"
            worksheet="Bordereaux"
            type="csv"
            name="bordereaux.csv">
            <v-row class="mt-0">
              <v-btn tile text :disabled="disabled"
                     :x-small="$vuetify.breakpoint.name === 'xs'">Download</v-btn>
              <v-checkbox :disabled="disabled" v-model="all" @click.stop="" class="mt-n2 mt-sm-0 mb-n4 mr-n2"
                          v-if="queryType !== 'policies'"></v-checkbox>
              <span class="mt-0 mt-sm-2 caption" :style="disabled ? 'opacity: 0.4' : ''" @click.stop=""
                    v-if="queryType !== 'policies'">w/text</span>
            </v-row>
          </download-excel>
      </div>
    </v-card>

    <div v-if="searchLaunched">
      <div v-if="queryType === 'policies'">
        <br>
        <v-data-table class="fullSizeTable elevation-1" :loading="loading"
                      loading-text="Loading... Please wait"
                      fixed-header
                      :headers="colHeaders"
                      disable-sort
                      :mobile-breakpoint="0"
                      no-data-text="no data available - check fields above, then click bordereau"
                      :options.sync="options"
                      :items="reports"
                      :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100] }"
                      :items-per-page="15"
        >
          <template v-slot:item.forms="{ item }">
            <div class="scrollable" style="height: 43px; min-height: 43px" v-if="item.forms">
                 <div class="inner" v-html="item.forms.replace(/(?:\r\n|\r|\n)/gm, '<br>')"></div>
            </div>
          </template>
        </v-data-table>
      </div>
      <div v-if="queryType !== 'policies'">
        <v-data-table class="fullSizeTable elevation-2"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      fixed-header
                      :headers="colHeaders"
                      disable-sort
                      item-key="clid"
                      :mobile-breakpoint="0"
                      no-data-text="no data available - check fields above, then click bordereau"
                      :options.sync="options"
                      :items="reports"
                      :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100] }"
                      :items-per-page="15"
        >
          <template v-slot:top>
            <br>
            <div style="text-align: center">CLAIMS BORDEREAU</div>
            <div style="text-align: center">{{ contractOptions.find(i => i.value === contract).name }}</div>
            <div style="text-align: center"  v-if="data.contract_start && data.contract_end">
              {{ moment(data.contract_start, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MMMM D, YYYY') }} to
              {{ moment(data.contract_end, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MMMM D, YYYY')}}
            </div>
            <div style="text-align: center">Coverholder Name: Dominion Insurance Services, Inc.</div>
            <v-divider></v-divider>
          </template>

          <template v-slot:header.fkb_file_no="{ header }">
            <p>FKB File No.:</p>
          </template>
          <template v-slot:item.fkb_file_no="{ item }">
            {{ item.fkb_file_no }}
          </template>

          <template v-slot:header.policy_no="{ header }">
            <br><br><p>Policy No. / Certificate Reference</p>
            <p>Policy Period</p>
            <p>Limits</p>
            <v-divider></v-divider>
            <p> Refer to Underwriters (over $50K)</p>
          </template>
          <template v-slot:item.policy_no="{ item }">
            <br><br>{{ item.policy_no }}<br>
            <br>{{ item.incept}} -- {{ item.expiry}}<br><br>
            <v-divider></v-divider>
            {{ item.limits}}<br><br>
          </template>

          <template v-slot:header.risk_code="{ header }">
            <br><br><p>Risk Code</p>
            <p>UWRS Ref. No.:</p>
            <p>Claim State</p>
            <v-divider></v-divider>
            <p>Original Currency</p>
          </template>
          <template v-slot:item.risk_code="{ item }">
            <br><br>{{ item.risk_code }}<br>
            <br>{{ item.uwrs_ref_no}}<br>
            <br>{{ item.occurance_state}}
            <v-divider></v-divider>
            USD<br><br>
          </template>

          <template v-slot:header.assured="{ header }">
            <br><br><p>Assured</p>
            <p>Claimant</p>
            <p>Insured Address</p>
            <v-divider></v-divider>
            <p> Date of Loss</p>
          </template>
          <template v-slot:item.assured="{ item }">
            {{ item.assured }}<br>
            <br>{{ item.claimant}}<br>
            <br>{{ item.address1}} {{ item.address2 }}
<!--            <p v-if="item.address2">{{ item.address2 }}</p>-->
<!--            <p v-else>&nbsp</p>-->
            {{ item.city}}, {{ item.state}} {{ item.zip}}
            <v-divider></v-divider>
            {{ item.date_of_loss }}<br>
          </template>

          <template v-slot:header.claim_acknowledgement_date="{ header }">
            <br><br><br><p style="text-align: center">Claim First Notification Acknowledgment Date</p>
            <p style="text-align: center">Date of Notification to FKB / Claim Opened</p>
            <p style="text-align: center">Date of Initial Report</p>
            <v-divider></v-divider>
            <p style="text-align: center">Date First Reserve Established</p><br>
          </template>
          <template style="text-align: center" v-slot:item.claim_acknowledgement_date="{ item }"><br>
            <br>Acknowledge: &nbsp{{ item.claim_acknowledgement_date }}<br>
            <br>Notification: &nbsp&nbsp&nbsp {{ item.date_of_notification}}<br>
<!--            <br>Initial Report: &nbsp&nbsp{{ "2012-01-12"}}-->
            <br>Initial Report: &nbsp&nbsp{{ item.date_of_initial_report}}
            <v-divider></v-divider>
            Established: &nbsp&nbsp&nbsp {{ item.date_reserve_established}}<br><br><br>
          </template>

          <template v-slot:header.supervising_attorney="{ header }">
            <br><br><p>Supervising Attorney</p>
            <p>Assigned Attorney</p>
            <p>Date of Last Report</p>
            <v-divider></v-divider>
            <p>Status</p>
          </template>
          <template v-slot:item.supervising_attorney="{ item }">
            <br>{{ item.supervising_attorney }}<br>
            <br>{{ item.assigned_attorney}}<br>
            <br>{{ item.date_of_last_report}}<br>
            <v-divider></v-divider>
            {{ item.denial}}<br><br>
          </template>

          <template v-slot:header.retention_deductible="{ header }">
            <br><br><p>Retention/deductible</p>
            <p>Deductible Paid</p><br>
            <v-divider></v-divider>
            <p>Balance</p>
          </template>
          <template v-slot:item.retention_deductible="{ item }">
            <br><br>${{ (parseFloat(item.retention_deductible )).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            <br>${{ (parseFloat(item.deductible_paid)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            <v-divider></v-divider>
            ${{ (parseFloat(item.balance )).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
          </template>

          <template v-slot:header.indemnity="{ header }">
            <p><u>RESERVES</u>:</p>
            <p>Indemnity</p>
            <p>Defense</p>
            <p>FKB</p>
            <v-divider></v-divider>
            <p>Total Reserves</p>
          </template>
          <template v-slot:item.indemnity="{ item }">
            ${{ (parseFloat(item.indemnity)).toFixed(2).toString()
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            ${{ (parseFloat(item.defense)).toFixed(2).toString()
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            ${{ (parseFloat(item.fkb)).toFixed(2).toString()
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            <v-divider></v-divider>
            ${{ (parseFloat(item.total_reserves)).toFixed(2).toString()
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
          </template>

          <template v-slot:header.remaining_indemnity="{ header }">
            <p><u>RESERVES</u>:</p>
            <p>Remaining Indemnity</p>
            <p>Remaining Defense</p>
            <p>Remaining FKB</p>
            <v-divider></v-divider>
            <p>Total Remaining Reserves</p>
          </template>
          <template v-slot:item.remaining_indemnity="{ item }">
            <div v-if="parseFloat(item.remaining_indemnity) >= 0">
              ${{ (parseFloat(item.remaining_indemnity)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            </div>
            <div v-else>--<br><br></div>
            <div v-if="parseFloat(item.remaining_defense) >= 0">
              ${{ (parseFloat(item.remaining_defense)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            </div>
            <div v-else>--<br><br></div>
            <div v-if="parseFloat(item.remaining_fkb) >= 0">
              ${{ (parseFloat(item.remaining_fkb)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            </div>
            <div v-else>--</div>
            <v-divider></v-divider>
            <div v-if="parseFloat(item.total_remaining_reserves) >= 0">
              ${{ (parseFloat(item.total_remaining_reserves)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            </div>
            <div v-else>--</div>
          </template>

          <template v-slot:header.paid_indemnity="{ header }">
            <p><u>PAYMENTS</u>:</p><br>
            <p>Paid Indemnity</p>
            <p>Current Indemnity</p>
            <v-divider></v-divider>
            <p>Total Indemnity</p>
          </template>
          <template v-slot:item.paid_indemnity="{ item }">
            <br><br><br>${{ (parseFloat(item.paid_indemnity)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            ${{ (parseFloat(item.current_indemnity)).toFixed(2).toString()
             .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br>
            <v-divider></v-divider>
            ${{ (parseFloat(item.total_indemnity)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
          </template>

          <template v-slot:header.paid_defense="{ header }">
            <p><u>PAYMENTS</u>:</p><br>
            <p>Paid Defense</p><br>
            <p>Current Defense</p>
            <v-divider></v-divider>
            <p>Total Defense</p>
          </template>
          <template v-slot:item.paid_defense="{ item }">
            <br><br><br>${{ (parseFloat(item.paid_defense)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            ${{ (parseFloat(item.current_defense)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br>
            <v-divider></v-divider>
            ${{ (parseFloat(item.total_defense)).toFixed(2).toString()
             .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
          </template>

          <template v-slot:header.paid_fkb="{ header }">
            <p><u>PAYMENTS</u>:</p><br>
            <p>Paid FKB</p><br>
            <p>Current FKB</p>
            <v-divider></v-divider>
            <p>Total FKB</p>
          </template>
          <template v-slot:item.paid_fkb="{ item }">
            <br><br><br>${{ (parseFloat(item.paid_fkb)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            ${{ (parseFloat(item.current_fkb)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br>
            <v-divider></v-divider>
            ${{ (parseFloat(item.total_fkb)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
          </template>

          <template v-slot:header.total_paid="{ header }">
            <p><u>PAYMENTS</u>:</p><br>
            <p>Total Paid</p><br>
            <p>Total Current</p>
            <v-divider></v-divider>
            <p>Total Expenses</p>
          </template>
          <template v-slot:item.total_paid="{ item }">
            <br><br><br>${{ (parseFloat(item.total_paid)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
            ${{ (parseFloat(item.total_current)).toFixed(2).toString()
             .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br>
            <v-divider></v-divider>
            ${{ (parseFloat(item.total_expenses)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
          </template>
          <template v-slot:header.total_incurred="{ header }">
            <br><br><br><br><br><br><br>
            <v-divider></v-divider>
            <p>Total Incurred (total remaining reserves + total payments)</p>
          </template>
          <template v-slot:item.total_incurred="{ item }">
            <br><br><br><br><br><br>
            <v-divider></v-divider>
            <div v-if="parseFloat(item.total_incurred) >= 0">
              ${{ (parseFloat(item.total_incurred)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            </div>
            <div v-else>--</div>
          </template>

          <template v-slot:header.defense_counsel="{ header }">
            <br><br><br><br><br><br><br><br>
            <p>Defense Counsel</p>
          </template>
          <template v-slot:item.defense_counsel="{ item }">
            <br><br><br><br><br>
            {{ item.defense_counsel}}
          </template>

          <template v-slot:header.summary_of_claim="{ header }">
            <div class="text-center">Summary of Claim</div>
          </template>
          <template v-slot:item.summary_of_claim="{ item }">
            <div class="scrollable" v-if="item.summary_of_claim">
              <div class="inner" v-html="item.summary_of_claim"></div>
            </div>
          </template>

          <template v-slot:header.status="{ header }">
            <div class="text-center">Notes</div>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="scrollable" v-if="item.status">
              <div class="inner" v-html="item.status"></div>
            </div>
          </template>

          <template v-slot:header.forms="{ header }">
            <div class="text-center">Forms and Endorsements</div>
          </template>
          <template v-slot:item.forms="{ item }">
            <div class="scrollable" v-if="item.forms">
              <div class="inner" v-html="item.forms.replace(/(?:\r\n|\r|\n)/gm, '<br>')"></div>
            </div>
          </template>

          <template v-slot:item.reserve="{ item }">
            <br>${{ parseFloat(item.reserve).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            <br>${{ parseFloat(item.reserve).toFixed(2).toString()
             .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
            <br>__________
            <br>${{ (parseFloat(item.reserve) + parseFloat(item.reserve)).toFixed(2).toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}<br><br>
          </template>
        </v-data-table>
      </div>
      <br>
      <v-data-table v-if="queryType ==='policies'"
                    :loading="this.loading"
                    loading-text="Loading... Please wait"
                    height="100px"
                    hide-default-footer
                    :headers="totalHeaders"
                    :items="totals"
                    :mobile-breakpoint="0"
                    disable-sort
                    class="elevation-1"
      ></v-data-table>

    </div>
    <br><br>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>
<script>
  import { ReportsAPIService } from '@/servicehandlers/ReportsAPIService';
  import { PoliciesAPIService } from '@/servicehandlers/PoliciesAPIService';
  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  import ListViewer from "../ListViewer";
  import ErrorDialog from "../ErrorDialog";

  const apiService = new CommonAPIService();
  const reportsAPIService = new ReportsAPIService();
  const policyAPIService = new PoliciesAPIService();

  export default {
    name: 'ReportsSearch',
    components: { ListViewer, ErrorDialog },
    data() {
      return {
        all: false,
        data: [],
        expanded: [],
        rules: {
          month: [
            (v) => !!v || 'Month is required',
          ],
          contract: [
            (v) => !!v ||  'Contract is required.',
          ],
        },
        details: {
          facts: { label: 'Facts', sortable: true },
          note: { label: 'Note', sortable: true },
        },
        jsonPoliciesFields: { // required for doing a lookup and easy setting of the objects
          'Unique Market Reference (UMR) or Agreement No': 'uniquemarketnum',
          'Section Number/Reference fe': 'sectionnum',
          'Certificate Reference': 'polnum',
          'Full Name or Company Name': 'insured',
          // 'Address1': 'address1',
          'Address1': 'zip',
          'State1': 'state',
          // 'Profession': 'profession',
          'Profession': 'type',
          'Insured Revenue or Turnover': {
            field: 'revenue',
            callback: (value) => {
              return Number(value.replace(/[^0-9\.-]+/g,""));
            }
          },
          'Risk Inception Date': 'incept',
          'Risk Expiry Date': 'expiry',
          'Transaction Currency': 'transactioncurrency',
          'Gross Premium Paid This Time': {
            field: 'gross',
            callback: (value) => {
              return Number(value.replace(/[^0-9\.-]+/g,""));
            }
          },
          'Total Premium Sum of Installments': 'totalpremium',
          'Commission %': 'totalcommission',
          'Commission Retained by Coverholder %': 'commission',
          'Brokerage % of Gross Premium': 'millercommission',
          'Final Net Premium': {
            field: 'miller',
            callback: (value) => {
              return Number(value.replace(/[^0-9\.-]+/g,""));
            }
          },
          'Sum Insured Currency': 'suminsuredcurrency',
          'Per Claim Limit': 'claimlimit',
          'Aggregate Limit': 'aggregatelimit',
          'Deductible Currency': 'deductiblecurrency',
          'Deductible': {
            field: 'deduc',
            callback: (value) => {
              return Number(value.replace(/[^0-9\.-]+/g,""));
            }
          },
          'Retro Date': 'retro',
          'Expiring Gross Premium': {
            field: 'exprem',
            callback: (value) => {
              return Number(value.replace(/[^0-9\.-]+/g,""));
            }
          },
          'Expiring Rateable Revenue': {
            field: 'exsales',
            callback: (value) => {
              return Number(value.replace(/[^0-9\.-]+/g,""));
            }
          },
          'US Classification': 'usclassification',
          'State of Filing': 'filerstate',
          'Name': 'filername',
          'License No.': 'filernumber',
          'New Jersey SLA No': 'finnum',
          'Address': 'brokeraddress',
          'State': 'brokerstate',
          'Zip': 'brokerzip',
          'NAIC Code': 'naicode',
          // 'Reason for Cancellation': 'cancellationcode',
          'Reason for Cancellation': 'cancat',
          'Policy Issuance Date': 'issue',
        }, // standard columns that exist as attributes
        jsonClaimsFields: {
          'FKB File No.': 'fkb_file_no',
          'Policy No.': 'policy_no',
          'Incept': 'incept',
          'Expiry': 'expiry',
          'Limits': 'limits',
          'Risk Code': 'risk_code',
          'UWRS Ref. No.': 'uwrs_ref_no',
          'Claim State': 'occurance_state',
          'Assured': 'assured',
          'Claimant': 'claimant',
          'Address1': 'address1',
          'Address2': 'address2',
          'City': 'city',
          'State': 'state',
          'Zip': 'zip',
          'Date of Loss': 'date_of_loss',
          'Claim Acknowledgment': 'claim_acknowledgement_date',
          'Notification': 'date_of_notification',
          'Initial Report': 'date_of_initial_report',
          'Reserve Established': 'date_reserve_established',
          'Supervising Attorney': 'supervising_attorney',
          'Assigned Attorney': 'assigned_attorney',
          'Last Report': 'date_of_last_report',
          'Status': 'denial',
          'Retention/Deductible': 'retention_deductible',
          'Deductible Paid': 'deductible_paid',
          'Balance': 'balance',
          'Indemnity': 'indemnity',
          'Defense': 'defense',
          'FKB': 'fkb',
          'Total Reserves': 'total_reserves',
          'Remaining Indemnity': 'remaining_indemnity',
          'Remaining Defense': 'remaining_defense',
          'Remaining FKB': 'remaining_fkb',
          'Total Remaining Reserves': 'total_remaining_reserves',
          'Paid Indemnity': 'paid_indemnity',
          'Current Indemnity': 'current_indemnity',
          'Total Indemnity': 'total_indemnity',
          'Paid Defense': 'paid_defense',
          'Current Defense': 'current_defense',
          'Total Defense': 'total_defense',
          'Paid FKB': 'paid_fkb',
          'Current FKB': 'current_fkb',
          'Total FKB': 'total_fkb',
          'Total Paid': 'total_paid',
          'Total Current': 'total_current',
          'Total Expenses': 'total_expenses',
          'Total Incurred': 'total_incurred',
        },

        allClaimsFields: {

          fkb_file_no: { label: 'FKB File No.', sortable: false },

          policy_no: { label: 'Policy No.', sortable: false },
          // incept: { label: 'Inception Date', sortable: false },
          // expiry: { label: 'Expiration Date', sortable: false },
          // limits: { label: 'Limits', sortable: false },

          risk_code: { label: 'Risk Code', sortable: false },
          // uwrs_ref_no: { label: 'UWRS Ref. No.', sortable: false },
          // occurance_state: { label: 'Occurance State', sortable: false },

          assured: { label: 'Assured', sortable: false },
          // claimant: { label: 'Claimant', sortable: false },
          // address1: { label: 'Address1', sortable: false },
          // address2: { label: 'Address2', sortable: false },
          // city: { label: 'City', sortable: false },
          // state: { label: 'State', sortable: false },
          // zip: { label: 'Zip', sortable: false },
          // date_of_loss: { label: 'Date of Loss', sortable: false },

          claim_acknowledgement_date: { label: 'Claim Acknowledgment Date', sortable: false },
          // date_of_notification: { label: 'Date of Notification', sortable: false },
          // date_of_initial_report: { label: 'Date of Initial Report', sortable: false },
          // date_reserve_established: { label: 'Date Reserve Established', sortable: false },

          supervising_attorney: { label: 'Supervising Attorney', sortable: false },
          // assigned_attorney: { label: 'Assigned Attorney', sortable: false },
          // date_of_last_report: { label: 'Date of Last Report', sortable: false },
          // denial: { label: 'Denial', sortable: false },

          retention_deductible: { label: 'Retention/Deductible', sortable: false },
          // deductible_paid: { label: 'Deductible Paid', sortable: false },
          // balance: { label: 'Balance', sortable: false },

          indemnity: { label: 'Indemnity', sortable: false },
          // defense: { label: 'Defense', sortable: false },
          // fkb: { label: 'FKB', sortable: false },
          // total_reserves: { label: 'Total Reserves', sortable: false },

          remaining_indemnity: { label: 'Remaining Indemnity', sortable: false },
          // remaining_defense: { label: 'Remaining Defense', sortable: false },
          // remaining_fkb: { label: 'Remaining FKB', sortable: false },
          // total_remaining_reserves: { label: 'Total Remaining Reserves', sortable: false },

          paid_indemnity: { label: 'Paid Indemnity', sortable: false },
          // current_indemnity: { label: 'Current Indemnity', sortable: false },
          // total_indemnity: { label: 'Total Indemnity', sortable: false },

          paid_defense: { label: 'Paid Defense', sortable: false },
          // current_defense: { label: 'Current Defense', sortable: false },
          // total_defense: { label: 'Total Defense', sortable: false },

          paid_fkb: { label: 'Paid FKB', sortable: false },
          // current_fkb: { label: 'Current FKB', sortable: false },
          // total_fkb: { label: 'Total FKB', sortable: false },

          total_paid: { label: 'Total Paid', sortable: false },
          // total_current: { label: 'Total Current', sortable: false },
          // total_expenses: { label: 'Total Expenses', sortable: false },

          total_incurred: { label: 'Total Incurred', sortable: false },

          status: { label: 'Notes', sortable: false },//note

          summary_of_claim: { label: 'Summary of Claim', sortable: false },//facts

          forms: { label: 'Forms and Endorsements', sortable: false },

          // managing_attorney: { label: 'ID', sortable: false },
          // insured_address: { label: 'ID', sortable: false },
          // damages: { label: 'ID', sortable: false },
          // reserve: { label: 'ID', sortable: false },
          // uniquemarketnum: { label: 'Unique Market Reference (UMR) or Agreement No', sortable: false },
          // clid: { label: 'Claim ID', sortable: true },
        },

        allOldClaimsFields: {
          clid: { label: 'Claim ID', sortable: true },

          type: { label: 'Type', sortable: true },
          insdef: { label: 'Ins Def', sortable: true },
          clm_ack: { label: 'Claim Ack', sortable: true },
          adj_cid: { label: 'Adj Cid', sortable: true },
          est_size: { label: 'Estate Size', sortable: true },
          adj_plid: { label: 'Adj Plid', sortable: true },
          damages: { label: 'Damages', sortable: true },
          defense: { label: 'Defense', sortable: true },
          monitor: { label: 'Monitor', sortable: true },
          contract: { label: 'Contract', sortable: false },
          payments: { label: 'Payments', sortable: false },
          stamp: { label: 'Date', sortable: false },
          // error: { label: 'Error', sortable: true },
          // claim: { label: 'Claim', sortable: true },
          // close: { label: 'Close', sortable: true },
          // engaged: { label: 'Engaged', sortable: true },

          note: { label: 'Note', sortable: false },

          facts: { label: 'Facts', sortable: true },

          // report: { label: 'Report', sortable: true },

          fid: { label: 'Firm ID', sortable: true },
          pid: { label: 'Policy ID', sortable: true },
          carrier: { label: 'Carrier', sortable: true },
          status: { label: 'Notes', sortable: true },
          claimant: { label: 'Claimant', sortable: true },
          othdef: { label: 'Oth Def', sortable: true },
          insuit: { label: 'In Suit', sortable: true },
          resolved: { label: 'Resolved', sortable: true },

          reserve: { label: 'Reserve', sortable: true },
          plt_code: { label: 'People', sortable: true },
          def_code: { label: 'Def Code', sortable: true },
          aop_code: { label: 'AOP Code', sortable: true },
          err_code: { label: 'Error Code', sortable: true },
          clm_num: { label: 'Claim Number', sortable: true },
          res_mon: { label: 'Reserve Monitor', sortable: true },
          res_def: { label: 'Reserve Defense', sortable: true },
          id: { label: 'ID', sortable: true },
          con_id: { label: 'Con ID', sortable: true },
          lst_id: { label: 'Lst ID', sortable: true },
          detail: { label: 'Detail', sortable: true },
          carrier_id: { label: 'Carrier ID', sortable: true },
          // incept: { label: 'Incept', sortable: true },
          // expiry: { label: 'Expiry', sortable: true },
          // comm: { label: 'Comm', sortable: true },
          // uniquemarketnum: { label: 'Unique Market Reference (UMR) or Agreement No', sortable: true },
        },
        policiesFields: { // required for doing a lookup and easy setting of the objects
          uniquemarketnum: { label: 'Unique Market Reference (UMR) or Agreement No', sortable: true },
          sectionnum: { label: 'Section Number/Reference fe', sortable: true },
          polnum: { label: 'Certificate Reference', sortable: true },
          insured: { label: 'Full Name or Company Name', sortable: true },
          // address1: { label: 'Address', sortable: true },
          zip: { label: 'Address', sortable: true },
          state: { label: 'State', sortable: true },
          // profession: { label: 'Profession', sortable: true },
          type: { label: 'Profession', sortable: true },
          revenue: { label: 'Insured Revenue or Turnover', sortable: true },
          incept: { label: 'Risk Inception Date', sortable: true },
          expiry: { label: 'Risk Expiry Date', sortable: true },
          transactioncurrency: { label: 'Currency', sortable: true },
          gross: { label: 'Gross Premium Paid This Time', sortable: true },
          totalpremium: { label: 'Total Premium, Sum of Installments', sortable: true },
          totalcommission: { label: 'Commission %', sortable: true },
          commission: { label: 'Commission Retained by Coverholder %', sortable: true },
          millercommission: { label: 'Brokerage % of Gross Premium', sortable: true },
          miller: { label: 'Final Net Premium', sortable: true },
          suminsuredcurrency: { label: 'Currency', sortable: true },
          claimlimit: { label: 'Per Claim Limit', sortable: true },
          aggregatelimit: { label: 'Aggregate Limit', sortable: true },
          deductiblecurrency: { label: 'Currency', sortable: true },
          deduc: { label: 'Deductible', sortable: true },
          retro: { label: 'Retro Date', sortable: true },
          exprem: { label: 'Expiring Gross Premium', sortable: true },
          exsales: { label: 'Expiring Rateable Revenue', sortable: true },
          usclassification: { label: 'US Classification', sortable: true },
          filerstate: { label: 'State of Filing', sortable: true },
          filername: { label: 'Name', sortable: true },
          filernumber: { label: 'License No.', sortable: true },
          finnum: { label: 'New Jersey SLA No', sortable: true },
          brokeraddress: { label: 'Address', sortable: true },
          brokerstate: { label: 'State', sortable: true },
          brokerzip: { label: 'Zip', sortable: true },
          naicode: { label: 'NAIC Code', sortable: true },
          cancat: { label: 'Reason for Cancellation', sortable: true },
          // cancellationcode: { label: 'Reason for Cancellation', sortable: true },
          issue: { label: 'Policy Issuance Date', sortable: true },
        }, // standard columns that exist as attributes
        totalsFields: {
          // totalrevenue: { label: 'Revenue', sortable: true },
          // totaltria: { label: 'Tria', sortable: true },
          totalgross: { label: 'Gross Premium', sortable: true },
          totaldominion: { label: 'Retained by Dominion', sortable: true },
          totalmiller: { label: 'Remitted to Miller', sortable: true },
          // totalexprem: { label: 'Exprem', sortable: true },
          // totalexsales: { label: 'Exsales', sortable: true },
        }, // totals columns
        fields: {}, // fields are the displayable fields for the DB
        totals: [], // fields are the displayable fields for the DB
        colHeaders: [], // for vuetify table
        totalHeaders: [], // for vuetify table
        reports: [], // data for the b-table
        checkedColumns: [], // columns that are specific to the where clause, in table firms
        contracts: [],
        months: ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July',
          'August', 'September', 'October', 'November', 'December'],
        searchDate: null,
        searchMonth: null,
        searchDateMenu: false,
        searchYear: null,
        includeColumns: [], // columns that are specific to the include checkboxes
        whereColumns: { state: 'all' }, // columns that are specific to the where clause
        includeCheckColumns: {}, // columns that are specific to include boxes, but with "where"
        queryType: 'claims', // columns that are specific to include boxes, but with "where"
        contract: null, // columns that are specific to include boxes, but with "where"
        claimsOrPolicies: [{ text: 'Claims', value: 'claims' }, {
          text: 'Policies',
          value: 'policies'
        }],
        totalPages: 0, // for pagination
        contractOptions: [],
        currentPage: 1, // for pagination
        perPage: 100000, // for pagination
        totalRecords: 0, // for pagination
        sortBy: null,
        currentSort: 'state',
        currentSortDir: false,
        spinning: false,
        loading: false,
        dialog: false,
        searchLaunched: false,
        isPolicies: false,
        searchResultsLoaded: false,
        sortDesc: false, // for the b-table
        filter: null, // for the b-table
        options: {
          "page": 1,
          "itemsPerPage": 100,
          "sortBy": [null],
          "sortDesc": [false],
        },
        carrierOptions: [],
        types: [],
      };
    },

    computed: {
      allJsonClaimsFields() {
        return {...this.jsonClaimsFields, 'Notes': 'status', 'Summary of Claim': 'summary_of_claim',
          'Forms and Endorsements': 'forms'};
      },

      allJsonPoliciesFields() {
        return {...this.jsonPoliciesFields, 'Forms and Endorsements': 'forms'};
      },

      allPoliciesFields() {
        return {...this.policiesFields, forms: { label: 'Forms and Endorsements', sortable: true }};
      },

      yearList() {
        let years = [];
        let year = 2005;
        let currentYear = parseFloat(new Date().getFullYear());
        while (currentYear + 1 > year) {
          years.unshift(year);
          year++;
        }
        return years;
      },

      disabled() {
        return !this.contract;
      },

    },

    watch: {
      queryType() {
        this.reports = [];
        this.data = [];
        this.colHeaders = [];
      },

    },

    created() {
      document.title = 'Bordereaux';
      policyAPIService.getContracts('', this.$router).then((data) => {
        const newOptions = [];
        // format data into options we can actually use
        for (let i = 1; i < data.length; i += 1) {
          newOptions.push({
            'name': data[i].contract,
            'value': data[i].id,
          });
        }
        this.contractOptions = newOptions;
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'Login'
          });
        }
      });
    },

    methods: {
      customSort: function (items, index, isDesc) {
        if (index[0]) {
          let key = index[0];
          items.sort((a, b) => {
            let valA = '';
            let valB = '';
            let newKey = '';
            if (key === 'state' || key === 'county') {
              // sort alphanumerically
              valA = a[key];
              valB = b[key];
              if (isDesc[0] === false) {
                if (valA) {
                  return valA.localeCompare(valB);
                } else {
                  return -1;
                }
              } else {
                if (valB) {
                  return valB.localeCompare(valA);
                } else {
                  return -1;
                }
              }
            } else if (key === 'premium') {
              newKey = key + 'num';
              isNaN(a[newKey]) ? valA = 0 : valA = parseInt(a[newKey]);
              isNaN(b[newKey]) ? valB = 0 : valB = parseInt(b[newKey]);
            } else if (key === 'claims' || key === 'policies' || key === 'state' || key === 'locale'
              || key === 'county' || key === 'fid' || key === 'firm') {
              newKey = key;
              valA = parseInt(a[newKey]);
              valB = parseInt(b[newKey]);
            } else if (key === '') {
              newKey = key;
              valA = a[newKey].toString();
              valB = b[newKey].toString();
            } else {
              newKey = key + 'num';
              valA = parseFloat(a[newKey]);
              valB = parseFloat(b[newKey]);
            }
            // If both compared fields are native numbers
            if (isDesc[0] === true) {
              return valA < valB ? 1 : valA > valB ? -1 : 0;
            } else {
              return valA < valB ? -1 : valA > valB ? 1 : 0;
            }
          });
          return items;
        } else return items;
      },

      customSorting(a, b, key) {
        let valA = '';
        let valB = '';
        let newKey = '';
        if (key === 'state' || key === 'county') {
          // sort alphanumerically
          valA = a[key];
          valB = b[key];
          if (valA) {
            return valA.localeCompare(valB);
          } else {
            return -1;
          }
        } else if (key === 'premium') {
          newKey = key + 'num';
          valA = parseInt(a[newKey]);
          valB = parseInt(b[newKey]);
        } else if (key === 'claims' || key === 'policies' || key === 'state' || key === 'locale'
          || key === 'county' || key === 'fid' || key === 'firm') {
          newKey = key;
          valA = parseInt(a[newKey]);
          valB = parseInt(b[newKey]);
        } else if (key === '') {
          newKey = key;
          valA = a[newKey].toString();
          valB = b[newKey].toString();
        } else {
          newKey = key + 'num';
          valA = parseFloat(a[newKey]);
          valB = parseFloat(b[newKey]);
        }
        // If both compared fields are native numbers
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      },

      showReportItem() {
      },

      generateReportDownload() {
        if ((this.queryType ==='policies' && (!this.contract || !this.searchMonth))
          || (this.queryType !=='policies' &&  !this.contract))
        {
          this.dialog = true;
        }
      },

      async fetchData(){
        if ((this.queryType ==='policies' && (!this.contract || !this.searchMonth))
          || (this.queryType !=='policies' &&  !this.contract))
        {
          this.dialog = true;
        } else {
          let monthNum = this.months.indexOf(this.searchMonth);
          if (monthNum === 0) {monthNum = 'all'}
          const searchItems = {
            contract: this.contract,
            queryType: this.queryType,
            month: monthNum,
          };
          // this.reports = [];
          const data = await reportsAPIService.getBordereau(searchItems, this.$router);
          if (data) {
            this.reports = data.data;
            if (this.queryType === 'policies') {
              const getContractName = this.contractOptions.find(name => name.value === this.contract);
              for (let i = 0; i < this.reports.length; i += 1) { //loop through reports customizing some fields
                this.reports[i].uniquemarketnum = getContractName.name;
              }
            }
            return this.reports;
          }
        }
      },

      generateReport() {
        this.colHeaders = [];
        this.fields = {};
        this.searchLaunched = true;
        this.loading = true;
        this.spinning = true;

        let monthNum = this.months.indexOf(this.searchMonth);
        if (monthNum === 0) {monthNum = 'all'}
        const searchItems = {
          contract: this.contract,
          queryType: this.queryType,
          month: monthNum,
          year: this.searchYear
        };
        // we need to formulate the include condition here
        reportsAPIService.getBordereau(searchItems, this.$router).then((data) => {
          if (data && data.data) {
            this.reports = data.data;
            this.data = data;
            //find contract name for contract id number
            const getContractName = this.contractOptions.find(name => name.value === this.contract);

            for (let i = 0; i < this.reports.length; i += 1) { //loop through reports customizing some fields
              this.reports[i].uniquemarketnum = getContractName.name;
            }

            this.totals = [data];

            //if queryType is claims, use allClaimsFields etc, else policiesFields
            let fieldsArray = this.queryType === 'claims'
              ? Object.entries(this.allClaimsFields) : this.all
              ? Object.entries(this.allPoliciesFields) : Object.entries(this.policiesFields);
            // take fields object and create a fieldsArray for vuetify v-table
            this.colHeaders = [];
            this.totalHeaders = [];
            let header = {};

            for (let i = 0; i < fieldsArray.length; i += 1) {
              let colWidth = "120px";
              let colAlign = "center";
              if (fieldsArray[i][1]['label'] === 'Full Name or Company Name' ||
                  fieldsArray[i][1]['label'] === 'Address' ||
                  fieldsArray[i][1]['label'] === 'Claimant' ||
                  fieldsArray[i][1]['label'] === 'Oth Def' || fieldsArray[i][1]['label'] === 'Date')
                colWidth = "250px";
              if (fieldsArray[i][1]['label'] === 'Notes'
                  || fieldsArray[i][1]['label'] === 'Summary of Claim'
                  || fieldsArray[i][1]['label'] === 'Forms and Endorsements'
                ){colWidth = "550px";
                  colAlign = "start";
              }
              if  (fieldsArray[i][1]['label'] === 'Risk Code'
                || fieldsArray[i][1]['label'] === 'Certificate Reference'
                || fieldsArray[i][1]['label'] === 'Supervising Attorney'
                || fieldsArray[i][1]['label'] === 'Remaining Defense'
                || fieldsArray[i][1]['label'] === 'Paid Defense'
                ){colWidth = "180px";
              }
              if  (fieldsArray[i][1]['label'] === 'Policy No.'
              ){colWidth = "220px";
              }
              if  (fieldsArray[i][1]['label'] === 'Remaining Indemnity'
              ){colWidth = "270px";
              }
              if  (fieldsArray[i][1]['label'] === 'Claim Acknowledgment Date'
              ){colWidth = "300px";
                colAlign = "start";
              }
              if  (fieldsArray[i][1]['label'] === 'Total Incurred'
              ){colWidth = "350px";
              }
              if  (fieldsArray[i][1]['label'] === 'Assured'
              ){colWidth = "590px";
              }
              header = {value: fieldsArray[i][0], text: fieldsArray[i][1]['label'], width: colWidth,
                align: colAlign };
              this.colHeaders.push(header);
            }

            fieldsArray = Object.entries(this.totalsFields);
            // using fieldsArray create array of headers needed for v-table
            header = {};
            for (let i = 0; i < fieldsArray.length; i += 1) {
              header = {value: fieldsArray[i][0], text: fieldsArray[i][1]['label'], width: "120px",
                align: 'center'};
              this.totalHeaders.push(header);
            }
            this.spinning = false;
            this.loading = false;
          }
        }).catch((e) => {
            this.spinning = false;
            this.loading = false;
            this.$refs.errorDialog.showError({
              forDevelopers: e
            })
          });
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  div.scrollable {
    cursor: pointer;
    direction: rtl;
    height: 200px;
    min-height: 200px;
    min-width: 550px;
    margin: 0px;
    margin-top: 4px;
    margin-right: 20px;
    overflow: scroll;
    resize: both;
    width: 90%;
  }

  /*direction set to rtl in .scrollable so handle to expand div is on left, then .inner resets direction to ltr*/
  .inner {
    direction: ltr;
    padding-left: 20px;
  }

  .list-horizontal li {
    display: inline-block;
  }

  .list-horizontal li:before {
    content: '\00a0\2022\00a0\00a0';
    color: #999;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
  }

  .list-horizontal li:first-child:before {
    content: '';
  }

</style>
