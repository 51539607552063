<template>
  <action-dialog :value="value" @input="$emit('input', $event)" @confirm="removeEndDate" @close="$emit('input', !value)"
                 title="Remove end date" max-width="500" confirm-text="Remove Date">
    <info-alert v-if="!error" class="mt-4">
      This should only be done on the most recent tax rate. After the date is removed, the selected tax rate will
      become the current tax rate.
    </info-alert>
    <error-alert v-else class="mt-4">{{ error }}</error-alert>
    <loading-overlay absolute v-model="loading" :errors="errors"></loading-overlay>
  </action-dialog>
</template>

<script>
  import moment from 'moment';
  import TaxesAPIService from "@/servicehandlers/TaxesAPIService";
  import ActionDialog from "../../ActionDialog";
  import InfoAlert from "../../InfoAlert";
  import DatePickerTextField from "../../DatePickerTextField";
  import LoadingOverlay from "../../LoadingOverlay";
  import ErrorAlert from "../../ErrorAlert";

  const taxesAPIService = new TaxesAPIService();
  export default {
    name: "RemoveEndDateDialog",
    components: {ErrorAlert, LoadingOverlay, DatePickerTextField, InfoAlert, ActionDialog},
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    data: () => ({
      error: '',
      errors: [],
      loading: false,
    }),
    methods: {
      removeEndDate() {
        this.loading = true;
        taxesAPIService.removeTaxEndDate(this.id, this.$router)
          .then(() => {
            this.$emit('updated');
            this.$emit('input', !this.value);
            this.loading = false;
          })
          .catch(error => {
            if (error.response.data.status === 'INVALID_PARAMS') {
              this.error = `This is not the most recent tax rate of this type. Please try again with the most recent
              tax rate.`;
              this.loading = false;
              return;
            }

            this.errors = [error.message];
          })
      }
    }
  }
</script>

<style scoped>

</style>