<template>
  <div>
    <v-row justify="center">
      <v-col class="mx-2">
        <v-row>
          <v-col cols="7" class="my-0 pt-4">
            <h4 class="pl-4 font-weight-regular mb-5">
              MGA Commissions
            </h4>
          </v-col>
          <v-col cols="2" class="my-0 py-0">
            <v-btn-toggle v-model="getMethod" tile color="secondary" group>
              <v-btn value="date">Date</v-btn>
              <v-btn value="year">Year</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="2" class="my-0 py-0">
            <year-select v-if="getMethod === 'year'" v-model="year" :start-date="moment().format('YYYY')"
                         :options="{label: 'Year'}" :years-before="moment().format('YYYY') - 2020"
                         :years-after="50"></year-select>
            <date-picker-text-field v-if="getMethod === 'date'" v-model="date" offset-y label="Date"
                                    prepend-icon="mdi-calendar-month" :rules="[rules.required, rules.date]"
                                    input-color="primary" picker-color="secondary" ></date-picker-text-field>
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-btn class="elevation-2 mr-5 mb-1" fab small color="secondary" @click="createDialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-row>

          </v-col>
        </v-row>
        <span v-if="!Object.keys(mgaCommissions).length && !loading">There are no commission rates for the selected date.</span>
        <div v-if="loading">
          <v-skeleton-loader v-for="i in 15" :key="i" type="list-item"></v-skeleton-loader>
        </div>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="mga in Object.keys(mgaCommissions).sort()" :key="mga">
            <v-expansion-panel-header>
              <h5 class="font-weight-regular">{{ mga }}</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :items="mgaCommissions[mga]" :headers="headers" multi-sort :mobile-breakpoint="0"
                            hide-default-footer :sort-by="['mga_name', 'carrier', 'condition', 'start_date']">
                <template v-slot:item.carrier="{ item }">
                  {{ item.carrier || 'All'}}
                </template>
                <template v-slot:item.types="{ item }">
                  {{ item.types ? item.types.join(', ') : 'All' }}
                </template>
                <template v-slot:item.renewal="{ item }">
                  {{ formatRenewal(item.renewal) }}
                </template>
                <template v-slot:item.condition="{ item }">
                  {{ item.condition || 'All' }}
                </template>
                <template v-slot:item.commission="{ item }">
                  {{ item.commission }}%
                </template>
                <template v-slot:item.start_date="{ item }">
                  <span class="grey--text text--darken-1">
                    {{ item.start_date_type.charAt(0).toUpperCase() + item.start_date_type.slice(1) }} after
                  </span>
                  {{ item.start_date }}
                </template>
                <template v-slot:item.end_date="{ item }">
                  <span v-if="item.end_date" class="grey--text text--darken-1">
                    {{ item.end_date_type.charAt(0).toUpperCase() + item.end_date_type.slice(1) }} before
                  </span>
                  {{ item.end_date }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div v-if="!item.end_date">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="openUpdateDialog(item)" small>mdi-pencil</v-icon>
                      </template>
                      <span>Change this commission</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-2" v-on="on" @click="openAddEndDateDialog(item.id)" small>mdi-calendar-plus</v-icon>
                      </template>
                      <span>Add end date</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-2" v-on="on" @click="openDeleteDialog(item.id)" small>mdi-trash-can-outline</v-icon>
                      </template>
                      <span>Delete commission</span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-2" v-on="on" @click="openRemoveEndDateDialog(item.id)" small>mdi-calendar-minus</v-icon>
                      </template>
                      <span>Remove end date</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <v-row justify="end">
                <v-btn class="mr-5 mt-2" @click="openEditAllDialog(mgaCommissions[mga])" color="secondary" small>Edit All</v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <add-end-date-dialog v-if="addEndDialog" v-model="addEndDialog" :id="addEndId"
                         @updated="comAction('updated')"></add-end-date-dialog>
    <create-commission-dialog v-if="createDialog" v-model="createDialog"
                              @created="comAction('created')"></create-commission-dialog>
    <remove-end-date-dialog v-if="removeEndDialog" v-model="removeEndDialog" :id="removeEndId"
                            @updated="comAction('updated')"></remove-end-date-dialog>
    <delete-commission-dialog v-model="deleteDialog" :id="deleteId"
                              @deleted="comAction('deleted')"></delete-commission-dialog>
    <edit-all-dialog v-if="editAllDialog" v-model="editAllDialog" :commissions="editCommissions"
                     @created="comAction('created')"></edit-all-dialog>
    <update-commission-dialog v-if="updateDialog" v-model="updateDialog" :commission="updateCom"
                              @updated="comAction('updated')"></update-commission-dialog>
    <success-snackbar v-model="success" :message="successMessage"></success-snackbar>
    <loading-overlay v-model="loading" :errors="errors"></loading-overlay>
  </div>
</template>

<script>
  import moment from 'moment';
  import {_} from 'vue-underscore';
  import { CommissionsAPIService } from "@/servicehandlers/CommissionsAPIService";
  import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
  import UpdateCommissionDialog from "./commissions/UpdateCommissionDialog";
  import DeleteCommissionDialog from "./commissions/DeleteCommissionDialog";
  import SuccessSnackbar from "../SuccessSnackbar";
  import YearSelect from "../YearSelect";
  import LoadingOverlay from "../LoadingOverlay";
  import DatePickerTextField from "../DatePickerTextField";
  import CreateCommissionDialog from "./commissions/CreateCommissionDialog";
  import CommissionsDialog from "../commissions/CommissionsDialog";
  import EditAllDialog from "./commissions/EditAllDialog";
  import RemoveEndDateDialog from "./commissions/RemoveEndDateDialog";
  import AddEndDateDialog from "./commissions/AddEndDateDialog";

  const commissionsAPIService = new CommissionsAPIService();
  const contactAPIService = new ContactAPIService();

  export default {
    name: "CommissionsManager",
    components: {
      AddEndDateDialog,
      RemoveEndDateDialog,
      EditAllDialog,
      CommissionsDialog,
      CreateCommissionDialog,
      DatePickerTextField,
      LoadingOverlay, YearSelect, SuccessSnackbar, DeleteCommissionDialog, UpdateCommissionDialog},
    watch: {
      date(date) {
        if (moment(date, 'YYYY-MM-DD', true).isValid()) this.getByDate();
      },
      getMethod() {
        this.getComs();
      },
      year() {
        this.getByPeriod();
      }
    },
    data: () => ({
      addEndId: '',
      addEndDialog: false,
      contactMap: {},
      createDialog: false,
      date: moment().format('YYYY-MM-DD'),
      deleteDialog: false,
      deleteId: '',
      editAllDialog: false,
      editCommissions: [],
      entityMap: {
        mga: 'MGA (Final)',
        pro: 'Producer'
      },
      errors: [],
      getMethod: 'year',
      headers: [
        {text: 'MGA', value: 'mga_name'},
        {text: 'Carrier', value: 'carrier'},
        {text: 'Types', value: 'types'},
        {text: 'Placement', value: 'renewal', sortable: false},
        {text: 'Condition', value: 'condition'},
        {text: 'Commission', value: 'commission', width: 50},
        {text: 'Start Date', value: 'start_date'},
        {text: 'End Date', value: 'end_date'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      loading: false,
      mgaCommissions: {},
      removeEndId: '',
      removeEndDialog: false,
      rules: {
        date: (x) => moment(x, 'YYYY-MM-DD', true).isValid() || 'Must be a valid date.',
        required: (x) => !!x || 'This field is required.',
      },
      success: false,
      successMessage: '',
      updateCom: {},
      updateDialog: false,
      year: moment().format('YYYY')
    }),
    created() {
      this.loading = true;
      contactAPIService.getContactList(this.$router)
        .then(contacts => {
          this.contactMap = contacts.reduce((contactMap, contact) => {
            contactMap[contact.cid] = contact.sort;
            return contactMap;
          }, {});

          this.getComs();
        });
    },
    methods: {
      comAction(action) {
        this.successMessage = `Commission successfully ${action}.`;
        this.success = true;
        this.getComs();
      },
      formatComs(coms) {
        return coms.reduce((map, com) => {
          com.mga_name = this.contactMap[com.mga_id];
          com.commission = Math.round(com.commission * 100000) / 1000; //Avoid parse float errors
          map[com.mga_name] ? map[com.mga_name].push(com) : map[com.mga_name] = [com];
          return map;
        }, {});
      },
      formatRenewal(renewal) {
        switch(renewal) {
          case true: return 'Renewal';
          case false: return 'New Business';
          default: return 'All';
        }
      },
      getByDate() {
        this.loading = true;
        commissionsAPIService.getComsByDate(this.date, this.$router)
          .then(coms => {
            this.mgaCommissions = this.formatComs(coms);
            this.loading = false;
          })
          .catch(() => {
            this.errors = ['Failed to retrieve commissions.'];
          })
      },
      getByPeriod() {
        this.loading = true;
        commissionsAPIService.getComsByPeriod(`${this.year}-01-01`, `${this.year}-12-31`, this.$router)
          .then(coms => {
            this.mgaCommissions = this.formatComs(coms);
            this.loading = false;
          })
          .catch(() => {
            this.errors = ['Failed to retrieve commissions.'];
          })
      },
      getComs() {
        this.getMethod === 'year' ? this.getByPeriod() : this.getByDate();
      },
      openAddEndDateDialog(id) {
        this.addEndId = id;
        this.addEndDialog = true;
      },
      openDeleteDialog(id) {
        this.deleteId = id;
        this.deleteDialog = true;
      },
      openEditAllDialog(coms) {
        this.editCommissions = coms.filter((com) => !com.end_date);
        this.editAllDialog = true;
      },
      openUpdateDialog(com) {
        this.updateCom = com;
        this.updateDialog = true;
      },
      openRemoveEndDateDialog(id) {
        this.removeEndId = id;
        this.removeEndDialog = true;
      },
    }
  }
</script>

<style scoped>

</style>