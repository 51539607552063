<template>
    <v-alert type="error">
        <slot></slot>
        <ul>
            <li v-for="error in errors" :key="error">
                {{ error }}
            </li>
        </ul>
        <slot name="actions"></slot>
    </v-alert>
</template>

<script>
    export default {
        name: "ErrorAlert",
        props: ['errors']
    }
</script>

<style scoped>

</style>